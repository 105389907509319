import { Button } from "@mui/material";
import "react-image-crop/dist/ReactCrop.css";
import ReactCrop from "react-image-crop";

const CropComponent = ({
	imageCrop,
	setImageCrop,
	circularCrop,
	onCropComplete,
	centerAspectCrop,
	closeModal,
	uploadImage,
	imageSrc,
	setImageRef
}) => {
	return (
		<div className="m-3 mt-0 d-flex flex-column align-items-center">
			<div className="modal-heading mb-3">Upload Photo</div>

			<ReactCrop
				// src={imageSrc}
				// style={{ minWidth: "100px", maxHeight: "500px" }}
				minHeight={100}
				// minWidth={100}
				// maxHeight={300}
				// maxWidth={300}
				// keepSelection={true}
				aspect={1}
				crop={imageCrop}
				onChange={(crop, percentCrop) => {
					setImageCrop(percentCrop);
				}}
				circularCrop={circularCrop ? circularCrop : false}
				ruleOfThirds
				onComplete={onCropComplete}
			>
				<img
					src={imageSrc}
					onLoad={e => {
						setImageRef(e.currentTarget);
						const { width, height } = e.currentTarget;
						setImageCrop(centerAspectCrop(width, height, 1));
					}}
				/>
			</ReactCrop>

			<div className="crop-drawer-buttons">
				<Button
					className="cropsocial_btn"
					onClick={() => {
						closeModal();
					}}
				>
					Cancel
				</Button>
				<Button className="cropsocial_btn primaryColor" onClick={e => uploadImage(e)}>
					Upload
				</Button>
			</div>
		</div>
	);
};

export default CropComponent;
