import axios from "./axios";
import { apiUrl, eventBase, publicBase } from "../config/crud";

const API_URL = apiUrl;
const EVENT = eventBase;
const PUBLIC = publicBase;
const ENTITY = "entity";

// Get list of the entity
export const getEntityDetails = (url: string, moduleNo: number) => {
	return axios.get(`${API_URL}/${EVENT}/${PUBLIC}/${url}/${ENTITY}/${moduleNo}`);
};
