import moment from 'moment';
import './Message.css';
import { getComplementaryColor } from '../../reusable/common';
import { useEvent } from '../../contexts/EventContext';

export const Message = ({ message, dateTime, isSent }: any) => {
    const { eventDetails } = useEvent();
    const urlRegex = /(https?:\/\/)?([\w-]+(\.[\w-]+)+)(\/[\w-.,@?^=%&:/~+#]*)?/gi;
    const formatLink = (url: string) => {
        if (url.startsWith('http://') || url.startsWith('https://')) {
            return url;
        }
        return `http://${url}`;
    };

    const parseMessage = (text: string) => {
        const parts = [];
        let match;
        let lastIndex = 0;

        while ((match = urlRegex.exec(text)) !== null) {
            const [url] = match;
            const startIndex = match.index;

            if (startIndex > lastIndex) {
                parts.push({ type: 'text', content: text.slice(lastIndex, startIndex) });
            }

            parts.push({ type: 'link', content: url });

            lastIndex = startIndex + url.length;
        }

        if (lastIndex < text.length) {
            parts.push({ type: 'text', content: text.slice(lastIndex) });
        }

        return parts;
    };

    const messageParts = parseMessage(message);

    return (
        <div className={`${isSent ? 'sent-message' : 'received-message'}`}>
            <div
                className={`message-wrapper ${isSent ? 'sent-message-wrapper' : 'received-message-wrapper'}`}
                style={{ color: isSent ? getComplementaryColor(eventDetails?.att_app_pri_col) : '' }}
            >
                {messageParts.map((part, index) =>
                    part.type === 'link' ? (
                        <a
                            key={index}
                            href={formatLink(part.content)}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ textDecoration: 'underline', color: isSent ? getComplementaryColor(eventDetails?.att_app_pri_col) : '' }}
                        >
                            {part.content}
                        </a>
                    ) : (
                        <span key={index}>{part.content}</span>
                    )
                )}
                <div className="message-time">{moment.utc(dateTime).local().format('hh:mm a')}</div>
            </div>
        </div>
    );
};
