import axios from "axios";

export const rawAxios = axios.create();

export let jwtToken: string | null = null;

export const setJwtToken = (token: string) => {
	jwtToken = token;
};

axios.interceptors.request.use(
	async (config: any) => {
		let token = jwtToken;
		if (token) {
			if (!config.headers["authorization"] && !config.headers["Authorization"])
				config.headers["Authorization"] = `Bearer ${token}`;
		}
		config["Accept-Encoding"] = "gzip, compress, deflate, br";
		return config;
	},
	error => {
		return Promise.reject(error);
	}
);

axios.interceptors.response.use(
	(response: any) => {
		if (response.data.statusCode >= 400 || response.data.errorMessage || response.data.error) {
			let error = {
				message: "Something went wrong!"
			};
			if (response.data.statusCode === 500) error.message = "Something went wrong!";
			else {
				if (response.data.errorMessage) error.message = response.data.errorMessage;
				if (response.data.error) error.message = response.data.error;
				if (response.data.message) error.message = response.data.message;
				if (response.data.message.message) error.message = response.data.message.message;
			}
			throw error;
		} else {
			if (response.data.error) throw new Error(response.data.error);
		}
		return response;
	},
	error => {
		if (error.response) {
			// DO WHATEVER YOU WANT HERE
		}
		return Promise.reject(error);
	}
);

export default axios;
