import React, { createContext, useContext, useState, useMemo } from "react";
import { subscribeToServices } from "../utils/subscriptions/subscriptions";
import {
	pushNotificationSubscriptionPerTicket,
	pushNotificationSubscriptionOrganizer,
	pushNotificationSubscriptionPublic,
	pushNotificationSubscriptionPerEvent
} from "../utils/subscriptions/subscriptions.list";
import NotificationToast from "./../CommomComponents/NotificationToast";
import { toast } from "react-toastify";
import Modal from "react-modal";
import { useSubscription } from "./SubscriptionContext";
import { trackEntity } from "../crud/tracking.crud";
import { clickTrackingModules, notificationMetricEvents } from "../config/app";

const NOTIFICATION_TYPES = {
	TOASTER: 2,
	MODAL: 1
};

interface NotificationContextType {
	notifications: any[];
	addNotification: (notification: any) => void;
	subscribeToPushNotifications: (attendeeDetails: any) => void;
	subscribeToPushNotificationsPublic: () => void;
	showNotification: (data: any) => void;
}

const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

export const NotificationProvider = ({ children }: { children: React.ReactNode }) => {
	const { setSubscriptions } = useSubscription();
	const [notifications, setNotifications] = useState<any[]>([]);
	const [notificationModal, setNotificationModal] = useState(false);
	const [currentNotification, setCurrentNotification] = useState<any>(null);

	const subscribeToPushNotifications = (attendeeDetails: any) => {
		if (attendeeDetails.is_organiser) {
			const subscription = subscribeToServices(
				pushNotificationSubscriptionOrganizer(onPushNotification)
			);
			setSubscriptions((prev: any) => [...prev, subscription]);
		} else {
			// TODO: This is ticket level notification subscription. This is commented out for now, as it is not supported from AD yet. And also not sure, if this will be used in future.
			/*attendeeDetails?.registrations?.forEach((registration: any) => {
				const subscriptionOptions = pushNotificationSubscriptionPerTicket(
					registration,
					onPushNotification
				);
				if (subscriptionOptions) {
					const subscription = subscribeToServices(subscriptionOptions);
					setSubscriptions((prev: any) => [...prev, subscription]);
				}
			});*/
			// Note: Instead we are subscribing to the entire event for now. This could also be temporary as we might bring in the concept of groups later on.
			const subscription = subscribeToServices(
				pushNotificationSubscriptionPerEvent(onPushNotification)
			);
			setSubscriptions((prev: any) => [...prev, subscription]);
		}
	};

	const subscribeToPushNotificationsPublic = () => {
		const subscription = subscribeToServices(
			pushNotificationSubscriptionPublic(onPushNotification)
		);
		setSubscriptions((prev: any) => [...prev, subscription]);
	};

	const showNotification = (data: any) => {
		if (notifications.some(notification => notification.notificationId === data.notificationId))
			return;
		if (data.notificationType === NOTIFICATION_TYPES.TOASTER) {
			toast(
				<NotificationToast
					message={data.message}
					title={data.title}
					ctaName={data.ctaName}
					ctaTarget={data.ctaTarget}
					notificationId={data.notificationId}
				/>,
				{
					autoClose: false,
					onClose: () => {
						trackEntity(
							localStorage.getItem("eventId") ?? "",
							clickTrackingModules.NOTIFICATIONS,
							{ id: data.notificationId },
							notificationMetricEvents.CLOSE
						);
					}
				}
			);
		}
		if (data.notificationType === NOTIFICATION_TYPES.MODAL) {
			setCurrentNotification(data);
			setNotificationModal(true);
		}
		trackEntity(
			localStorage.getItem("eventId") ?? "",
			clickTrackingModules.NOTIFICATIONS,
			{ id: data.notificationId },
			notificationMetricEvents.READ
		);
	};

	const onPushNotification = (data: any) => {
		if (notifications.some(notification => notification.notificationId === data.notificationId))
			return;
		addNotification(data);
		setTimeout(() => showNotification(data), 1000);
	};

	const addNotification = (notification: any) => {
		setNotifications([...notifications, notification]);
	};

	const closeNotificationModal = () => {
		trackEntity(
			localStorage.getItem("eventId") ?? "",
			clickTrackingModules.NOTIFICATIONS,
			{ id: currentNotification?.notificationId },
			notificationMetricEvents.CLOSE
		);
		setNotificationModal(false);
	};

	const value = useMemo(
		() => ({
			notifications,
			addNotification,
			subscribeToPushNotifications,
			subscribeToPushNotificationsPublic,
			showNotification
		}),
		[
			notifications,
			addNotification,
			subscribeToPushNotifications,
			subscribeToPushNotificationsPublic,
			showNotification
		]
	);

	return (
		<>
			<Modal
				isOpen={notificationModal}
				onRequestClose={closeNotificationModal}
				className="notification-modal"
				contentLabel={currentNotification?.title}
			>
				{/* Close button */}
				<button
					className="notification-close-button"
					onClick={closeNotificationModal}
					aria-label="Close notification modal"
				>
					&times;
				</button>
				<NotificationToast
					message={currentNotification?.message}
					title={currentNotification?.title}
					ctaName={currentNotification?.ctaName}
					ctaTarget={currentNotification?.ctaTarget}
					notificationId={currentNotification?.notificationId}
				/>
			</Modal>
			<NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>
		</>
	);
};

export const useNotificationContext = () => {
	const context = useContext(NotificationContext);
	if (!context) {
		throw new Error("useNotificationContext must be used within a NotificationProvider");
	}
	return context;
};
