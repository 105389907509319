import React, { useState, useEffect } from "react";
import "./ProfilePage.css";
import "./MyProfileBusinessCard/MyProfileBusinessCard";
import "./ProfileForm/ProfileForm";
// import './AddLogoutDrawer/AddLogoutDrawer';
import "./EditBussinessCard/EditBussinessCard";
import { Link } from "react-router-dom";
import { Bell } from "lucide-react";
import { useNavigate } from "react-router-dom";
import LogoutDrawer from "../LogoutDrawer/LogoutDrawer";
import SideNavbar from "../SideNavbar/SideNavbar";
import { getUserById } from "./../../crud/users.crud";
import { addEmptyStringForNullValues } from "../../reusable/common";
import { profileImage } from "../../reusable/images";
import { ClipLoader } from "react-spinners";
import { spinnerStyle } from "../../config/app";
import ModuleHeading from "../../CommomComponents/ModuleHeading/ModuleHeading";

const ProfilePage: React.FC = () => {
	const [isAddDrawerOpen, setIsAddDrawerOpen] = useState(false);
	const [data, setData] = useState({});
	const [pageLoad, setPageLoad] = useState(false);

	const navigate = useNavigate();
	const goBack = () => {
		navigate("/");
	};

	const handleAddLogoutDrawer = () => {
		setIsAddDrawerOpen(false);
		navigate("/logout");
	};

	const handleCloseAddDrawer = () => {
		setIsAddDrawerOpen(false);
	};

	const handleOpenAddDrawer = () => {
		setIsAddDrawerOpen(true);
	};

	const [isModalOpen, setIsModalOpen] = useState(false);

	const toggleModal = () => {
		setIsModalOpen(!isModalOpen);
	};

	async function getProfileAndRegistrations() {
		// setJwtToken(user.session?.tokens?.idToken?.toString());
		setPageLoad(true);
		try {
			const res = await getUserById();
			const userData = addEmptyStringForNullValues(res.data);
			localStorage.setItem("user", JSON.stringify(userData));
			setData(userData);
			setPageLoad(false);
		} catch (error) {
			console.log("error: ", error);
			setPageLoad(false);
			// errorToaster(error.message);
		}
	}

	useEffect(() => {
		getProfileAndRegistrations();
	}, []);

	return (
		<>
			{!pageLoad ? (
				<div className="d-flex">
					<div>
						<div className="desktop-view-side desktop-view">
							<div className="desktop-left-slider">
								<SideNavbar />
							</div>
						</div>
					</div>
					<div className="profile-page_pprofile w-100">
						<header className="profile-header_pprofile">
							<ModuleHeading
								moduleHeading={"My Profile"}
								goBack={goBack}
								hideProfileFromHeader={true}
							/>
							{/* <div className="notification-icon_pprofile">
							<Link to="/notifications">
								{" "}
								<span>
									{" "}
									<Bell className="icon interest_bell right-icon1" />
								</span>{" "}
							</Link>
						</div> */}
						</header>

						<div className="profile-section_pprofile">
							<div className="profile-info_pprofile">
								<div className="profile-photo-container">
									{/* <img src="assets/man.png" alt="profile" className="profile-photo" /> */}
									{profileImage(data.image_url, data.name)}
								</div>
								<div className="profile-name_pprofile">{data?.name}</div>
								<div className="profile-title_pprofile d-flex flex-column ">
									{data?.designation}{" "}
									<span className="profile-title_pprofile_in">
										{data?.organisation}
									</span>
								</div>
								{/* <button className="edit-profile-btn">
								<Link to="/profile-form">
									<span>Edit Profile</span>
								</Link>
							</button> */}
							</div>
						</div>

						<div className="menu-section_pprofile">
							<ul className="menu-list_pprofile">
								<Link className="menu-item_pprofilee" to="/profile-form">
									<li className="menu-item_pprofile">
										Edit Profile
										<span>
											<img src="assets/vector8.png" alt="" />
										</span>
									</li>
								</Link>
								<Link
									className="menu-item_pprofilee"
									to="/my-tickets"
									state={{ usersData: data, loader: pageLoad }}
								>
									<li className="menu-item_pprofile">
										My Tickets
										<span>
											<img src="assets/vector8.png" alt="" />
										</span>
									</li>
								</Link>
								{/* <Link className="menu-item_pprofilee" to="/myevent">
								<li className="menu-item_pprofile">
									My Events{" "}
									<span>
										<img src="assets/vector8.png" alt="" />
									</span>
								</li>
							</Link> */}

								{/* <li className="menu-item_pprofile">
								My Calendar{" "}
								<span>
									<img src="assets/vector8.png" alt="" />
								</span>
							</li> */}

								{/* <Link className="menu-item_pprofilee" to="/notifications">
								<li className="menu-item_pprofile">
									Notifications{" "}
									<span>
										<img src="assets/vector8.png" alt="" />
									</span>
								</li>
							</Link> */}

								{/* <Link className="menu-item_pprofilee" to="/change-password">
								<li className="menu-item_pprofile">
									Change Password{" "}
									<span>
										<img src="assets/vector8.png" alt="" />
									</span>
								</li>
							</Link> */}

								{/* <li
									className="menu-item_pprofile"
									onClick={() => handleOpenAddDrawer()}
								>
									Logout{" "}
									<span>
										<img src="assets/vector8.png" alt="" />
									</span>
								</li> */}

								<div>
									<p
										className="text-danger logout-text px-2"
										onClick={() => handleOpenAddDrawer()}
									>
										Logout
									</p>
								</div>
							</ul>
						</div>
					</div>
					{isAddDrawerOpen && (
						<LogoutDrawer
							isOpen={isAddDrawerOpen}
							onClose={handleCloseAddDrawer}
							onLogout={handleAddLogoutDrawer}
							setIsAddDrawerOpen={setIsAddDrawerOpen}
						/>
					)}
				</div>
			) : (
				<div style={spinnerStyle}>
					<ClipLoader loading={pageLoad} size={50} aria-label="Loading Spinner" />
				</div>
			)}
		</>
	);
};

export default ProfilePage;
