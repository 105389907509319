import React from "react";
import "./CustomBusinessCard.css";
import BusinessCard from "../../../CommomComponents/BusinessCard/BussinessCard";
interface CustomBusinessCardProps {
	cardDetails: any;
}

const CustomBusinessCard: React.FC<CustomBusinessCardProps> = ({ cardDetails }) => {
	// const copyToClipboard = (data: any) => {
	// 	navigator.clipboard.writeText(data);
	// };

	return (
		<>
			<div style={{ padding: "10px" }}>
				<div className="" style={{ width: "auto" }}>
					<BusinessCard width={"auto"} connection={cardDetails} />
				</div>
			</div>
		</>
	);
};

export default CustomBusinessCard;
