import React, { useEffect, useRef, useState } from "react";
import "./CreateMeeting.css";
import CustomModal from "../../../CommomComponents/CustomModal/CustomModal";
import AddPeople from "../AddPeople/AddPeople";
import {
	convertStartEndDateToIST,
	convertToDateObj,
	getISTDateNTime,
	getUTCDateNTime,
	useScreenWidth
} from "../../../reusable/common";
import { uploadImage } from "../../../reusable/images";
import CustomDrawer from "../../../CommomComponents/CustomDrawer/CustomDrawer";
import CustomButton from "../../../CommomComponents/CustomButton/CustomButton";
import moment from "moment";
import CustomDateTimePicker from "../../../CommomComponents/CustomDateTimePicker/CustomDateTimePicker";
import { useFormik } from "formik";
import { validateMeetingData } from "../MeetingValidation";
import ShowFormikError from "../../../CommomComponents/ShowFormikError/ShowFormikError";
import _ from "lodash";
import { useEvent } from "../../../contexts/EventContext";
import ColorPicker from "../ColorPicker/ColorPicker";
import { createMeeting, editMeeting } from "../../../crud/meetings.crud";
import { useLocation, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react/dist/iconify.js";

interface CreateMeetingProps {
	meetingDetails?: any;
	onAddEditMeeting?: () => void;
}

const CreateMeeting: React.FC<CreateMeetingProps> = ({ meetingDetails, onAddEditMeeting }) => {
	const { eventDetails } = useEvent();
	const navigate = useNavigate();
	const location = useLocation();
	const isResponsive = useScreenWidth();
	const [isInitialLoaded, setIsInitialLoaded] = useState(false);
	const [tempTimeStamp, setTempTimeStamp] = useState({
		startDateTime: "",
		endDateTime: ""
	});
	const [startTimeStamp, setStartTimeStamp] = useState({});
	const [endTimeStamp, setEndTimeStamp] = useState({});
	const [loading, setLoading] = useState(false);
	const [fileLoading, setFileLoading] = useState(false);
	const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
	const [openAddPeopleModal, setOpenAddPeopleModal] = useState(false);
	const [openAddPeopleDrawer, setOpenAddPeopleDrawer] = useState(false);
	const [meetingName, setMeetingName] = useState({
		value: "",
		active: false
	});
	const [uploadFileError, setUploadFileError] = useState("");

	const [startEndDateTime, setStartEndDateTime] = useState<any>({});

	const [meetingLocation, setMeetingLocation] = useState({
		locationValue: "",
		locationActive: false,
		meetingLiveValue: "",
		meetingLiveActive: false
	});

	const [description, setDescription] = useState({
		value: "",
		active: false
	});

	const [meetingInitialData, setMeetingInitialData] = useState({
		title: "",
		description: "",
		attachments: [],
		start_timestamp: "",
		end_timestamp: "",
		is_virtual: "",
		location: "",
		meeting_live_link: "",
		attendees: [],
		time_zone: "",
		default_color: ""
	});

	const colors = ["#002E6E", "#EE217E", "#40EE21", "#21EEE0", "#EEDB21", "#000000"];

	const fileInputRef = useRef<HTMLInputElement | null>(null);

	// Function to handle the file selection
	const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		setFileLoading(true);
		const file: any = event.target.files?.[0]; // Get the first selected file, if any
		event.target.value = "";

		// Check if the file is an image or a PDF
		const isImage = file.type.startsWith("image/");
		const isPDF = file.type === "application/pdf";
		if (!isImage && !isPDF) {
			setUploadFileError("Only image and PDF files are allowed.");
			event.target.value = "";
			setFileLoading(false);
			return;
		}

		// Check file size (5 MB = 5 * 1024 * 1024 bytes)
		const maxSize = 5 * 1024 * 1024; // 5 MB
		if (file.size > maxSize) {
			setUploadFileError("File size exceeds 5 MB. Please upload a smaller file.");
			event.target.value = ""; // Clear the input value
			setFileLoading(false);
			return;
		}
		if (file) {
			// You can now use the `file` object as needed
			try {
				setUploadFileError("");
				const response = await uploadImage(file, file.name, 16, file.type);
				setSelectedFiles((each: any) => {
					return [...each, response?.data];
				});
				formik.setFieldValue("attachments", [
					...formik.values.attachments,
					response?.data?.download?.url
				]);
			} catch (error) {
				console.log("error", error);
			} finally {
				setFileLoading(false);
			}
		}
	};

	// Function to trigger the file input click
	const handleDivClick = () => {
		if (!fileLoading) {
			fileInputRef.current?.click(); // Trigger the hidden file input
		}
	};

	const validate = async (values: any) => {
		let errors: any = {};
		errors = validateMeetingData({ values, eventDetails, formik });
		return errors;
	};

	const formik = useFormik({
		initialValues: meetingInitialData,
		enableReinitialize: true,
		validateOnBlur: true,
		validateOnChange: true,
		validate: validate,
		onSubmit: async (values: any) => {
			let _values = {
				...values,
				attendees: [...values.attendees?.map((each: any) => each?.attendee_id)],
				start_timestamp: moment
					.tz(values.start_timestamp, eventDetails?.time_zone)
					.utc()
					.format("YYYY-MM-DD HH:mm:ss"),
				end_timestamp: moment
					.tz(values.end_timestamp, eventDetails?.time_zone)
					.utc()
					.format("YYYY-MM-DD HH:mm:ss"),
				meeting_date_tz: moment
					.tz(values.start_timestamp, "UTC")
					.tz(eventDetails?.time_zone)
					.format("YYYY-MM-DD")
			};
			if (_values["created_by"]) {
				delete _values["created_by"];
			}
			if (_values["id"]) {
				delete _values["id"];
			}
			if (location.pathname.includes("create-meeting")) {
				setLoading(true);
				try {
					const response = await createMeeting(eventDetails?.event_id, _values);
					if (response?.status === 201) {
						navigate("/meetings");
						if (typeof onAddEditMeeting === "function") {
							onAddEditMeeting();
						}
					}
				} catch (error) {
					console.log("error create meeting---", error);
				} finally {
					setLoading(false);
				}
			} else if (location.pathname.includes("edit-meeting")) {
				if (
					_values.start_timestamp === tempTimeStamp.startDateTime &&
					_values.end_timestamp === tempTimeStamp.endDateTime
				) {
					delete _values["start_timestamp"];
					delete _values["end_timestamp"];
				}
				setLoading(true);
				try {
					const response = await editMeeting(
						eventDetails?.event_id,
						formik.values?.id,
						_values
					);
					if (response?.status === 200) {
						navigate("/meetings");
						if (typeof onAddEditMeeting === "function") {
							onAddEditMeeting();
						}
					}
				} catch (error) {
					console.log("error create meeting---", error);
				} finally {
					setLoading(false);
				}
			}
		}
	});

	useEffect(() => {
		let initialValues: any = {};
		if (!isInitialLoaded) {
			const { start_date, start_time, end_date, end_time } = convertStartEndDateToIST(
				eventDetails?.start_date,
				eventDetails?.start_time,
				eventDetails?.end_date,
				eventDetails?.end_time,
				eventDetails?.time_zone
			);
			setStartEndDateTime({ start_date, start_time, end_date, end_time });
			if (location.pathname.includes("create-meeting")) {
				let tempTimeStamp = new Date(
					getISTDateNTime(
						moment.utc(new Date()).format("YYYY-MM-DD HH:mm:ss"),
						eventDetails?.time_zone
					).replace(/ /g, "T")
				);

				// check if current date and time as per event timezone is greater than event date and time
				let start_timestamp =
					tempTimeStamp > new Date(start_date + "T00:00:00")
						? tempTimeStamp
						: new Date(start_date + "T00:00:00");

				let end_timestamp = new Date(moment(start_timestamp).add(1, "hour").toISOString());
				if (
					moment(end_timestamp).isAfter(moment(startEndDateTime.end_date + "T23:59:59"))
				) {
					end_timestamp = new Date(startEndDateTime.end_date + "T23:59:59");
				}

				let _tempTimeStamp =
					tempTimeStamp > new Date(start_date + "T" + start_time)
						? getISTDateNTime(
								moment.utc(new Date()).format("YYYY-MM-DD HH:mm:ss"),
								eventDetails?.time_zone
							).replace(/ /g, "T")
						: start_date + "T" + start_time;
				let _start_timestamp = _tempTimeStamp;
				let _end_timestamp = moment(_start_timestamp)
					.add(1, "hour") // Add 1 hour
					.format("YYYY-MM-DDTHH:mm:ss");

				initialValues = {
					title: "",
					description: "",
					attachments: [],
					start_timestamp: _start_timestamp,
					end_timestamp: _end_timestamp,
					is_virtual: false,
					location: "",
					meeting_live_link: "",
					attendees: [],
					time_zone: eventDetails?.time_zone || "",
					default_color: "#002E6E"
				};
				setStartTimeStamp(start_timestamp);
				setEndTimeStamp(end_timestamp);
				setMeetingInitialData(initialValues);
			} else if (location.pathname.includes("edit-meeting") && meetingDetails) {
				setTempTimeStamp({
					startDateTime: meetingDetails.start_timestamp,
					endDateTime: meetingDetails.end_timestamp
				});
				initialValues = {
					...meetingDetails,
					start_timestamp: getISTDateNTime(
						meetingDetails.start_timestamp,
						eventDetails?.time_zone
					).replace(/ /g, "T"),
					end_timestamp: getISTDateNTime(
						meetingDetails.end_timestamp,
						eventDetails?.time_zone
					).replace(/ /g, "T")
				};
				setMeetingInitialData(initialValues);
				setSelectedFiles(
					meetingDetails?.attachments?.map((each: any, index: number) => {
						return {
							file_name: `Attachment ${index + 1}`,
							download: {
								url: each
							}
						};
					})
				);
				setStartTimeStamp(
					new Date(
						getISTDateNTime(
							meetingDetails?.start_timestamp,
							eventDetails?.time_zone
						).replace(/ /g, "T")
					)
				);
				setEndTimeStamp(
					new Date(
						getISTDateNTime(
							meetingDetails?.end_timestamp,
							eventDetails?.time_zone
						).replace(/ /g, "T")
					)
				);
				if (initialValues.description) {
					setDescription({
						...description,
						active: true,
						value: initialValues.description
					});
				}
			} else {
				navigate("/meetings");
			}
			setIsInitialLoaded(true);
		}
	}, [location.pathname, eventDetails, isInitialLoaded, meetingDetails]);

	return (
		<>
			<div
				className="mx-3 mt-0 text-start relative"
				onClick={() => {
					if (meetingName.value) {
						setMeetingName({
							...meetingName,
							active: true
						});
					}
					if (meetingLocation.locationValue || meetingLocation.meetingLiveValue) {
						if (meetingLocation.locationValue) {
							setMeetingLocation({
								...meetingLocation,
								locationActive: true
							});
						} else {
							setMeetingLocation({
								...meetingLocation,
								meetingLiveActive: true
							});
						}
					}
					if (description.value) {
						setDescription({
							...description,
							active: true
						});
					}
				}}
			>
				<div className="d-flex justify-content-between align-items-center">
					<div className="create-meeting-text">
						{/* {location.pathname.includes("edit-meeting")
							? "Edit Meeting"
							: "Create Meeting"} */}
					</div>
				</div>

				{meetingName.active ? (
					<div
						className="mt-4 create-meeting-name text-start w-50"
						onClick={e => {
							e.stopPropagation();
							setMeetingName({
								...meetingName,
								active: false
							});
						}}
					>
						{formik.values.title}
					</div>
				) : (
					<div className="w-100 text-start">
						<input
							id="title"
							name="title"
							className="create-meeting-input mt-4"
							placeholder="Add title"
							value={formik.values.title}
							onChange={e => {
								e.stopPropagation();
								setMeetingName({
									...meetingName,
									value: e.target.value
								});
								formik.setFieldValue("title", e.target.value);
								formik.handleChange(e);
							}}
							autoFocus={formik.values.title ? true : false}
							onClick={e => {
								e.stopPropagation();
							}}
							onBlur={formik.handleBlur}
							onPaste={formik.handleChange}
						/>
					</div>
				)}
				{formik?.touched?.title && formik?.errors?.title && (
					<ShowFormikError error={formik?.errors?.title} />
				)}
				<div className="mt-4 d-flex align-items-start">
					<Icon
						icon={`material-symbols-light:calendar-month-outline`}
						width={"24px"}
						height={"24px"}
						className="meeting-icons-color"
					/>
					<div className="ms-3">
						<div className="create-meeting-date-time">
							<CustomDateTimePicker
								id="start_timestamp"
								minDate={convertToDateObj(
									startEndDateTime.start_date + "T00:00:00"
								)}
								maxDate={convertToDateObj(startEndDateTime.end_date + "T23:59:59")}
								value={startTimeStamp}
								onHandleDateTimePicker={(e: any) => {
									setStartTimeStamp(e);
									const tempDate = moment(e).format("YYYY-MM-DD HH:mm:ss");
									const newDate: any = tempDate.replace(/ /g, "T");
									formik.setFieldValue("start_timestamp", newDate);
								}}
								handleOnBlur={() => {
									formik.setFieldTouched("start_timestamp", true);
								}}
							/>
						</div>
						to
						<div className="mt-2 create-meeting-date-time">
							<CustomDateTimePicker
								id="end_timestamp"
								minDate={convertToDateObj(formik?.values?.start_timestamp)}
								maxDate={convertToDateObj(startEndDateTime.end_date + "T23:59:59")}
								value={endTimeStamp}
								onHandleDateTimePicker={(e: any) => {
									setEndTimeStamp(e);
									const tempDate = moment(e).format("YYYY-MM-DD HH:mm:ss");
									const newDate: any = tempDate.replace(/ /g, "T");
									formik.setFieldValue("end_timestamp", newDate);
								}}
								handleOnBlur={() => {
									formik.setFieldTouched("end_timestamp", true);
								}}
							/>
						</div>
					</div>
				</div>
				{(formik?.touched?.start_timestamp || formik?.touched?.end_timestamp) &&
					(formik?.errors?.start_timestamp || formik?.errors?.end_timestamp) && (
						<ShowFormikError
							error={formik?.errors?.start_timestamp || formik?.errors?.end_timestamp}
						/>
					)}
				<div className="mt-4 d-flex">
					<Icon
						icon={`material-symbols-light:globe`}
						width={"24px"}
						height={"24px"}
						className="meeting-icons-color"
					/>
					<div className="ms-3 create-meeting-keys">{eventDetails?.time_zone || ""}</div>
				</div>
				{/* <div className="mt-4 d-flex align-items-center">
					<img src="/assets/meeting-clock-rewind.svg" alt="Rewind" />
					<div className="ms-3 create-meeting-keys">Does not repeat</div>
				</div> */}
				<div className="create-meeting-border-line mt-4" />
				<div className="mt-4 d-flex">
					<Icon
						icon={`material-symbols-light:group-outline`}
						width={"24px"}
						height={"24px"}
						className="meeting-icons-color"
					/>
					<div
						role="button"
						className="ms-3 create-meeting-keys"
						onClick={() => {
							if (isResponsive) {
								setOpenAddPeopleDrawer(true);
							} else {
								setOpenAddPeopleModal(true);
							}
						}}
					>
						Add people
					</div>
				</div>
				<div className="d-flex create-meeting-people-wrapper">
					{formik.values?.attendees?.map((each: any) => (
						<div className="mt-3 me-2 create-meeting-peoples d-flex flex-column justify-content-center align-items-center">
							{each?.att_name || each?.name || ""}
						</div>
					))}
				</div>
				{formik?.touched?.attendees && formik?.errors?.attendees && (
					<ShowFormikError error={formik?.errors?.attendees} />
				)}

				<div className="create-meeting-border-line mt-4" />
				<div className="mt-4 d-flex">
					<Icon
						icon={`material-symbols-light:discover-tune`}
						width={"24px"}
						height={"24px"}
						className="meeting-icons-color"
					/>
					<div className="ms-3 create-meeting-keys">Meeting Type</div>
				</div>
				<div className="d-flex mt-4">
					<div
						role="button"
						className={`create-meeting-type ${!formik.values.is_virtual ? "active" : ""} d-flex justify-content-center align-items-center flex-column`}
						onClick={e => {
							e.stopPropagation();
							formik.setFieldValue("is_virtual", false);
							formik.setFieldValue("location", "");
							formik.setFieldValue("meeting_live_link", "");
							setMeetingLocation({
								...meetingLocation,
								meetingLiveValue: "",
								locationValue: "",
								meetingLiveActive: false,
								locationActive: false
							});
						}}
					>
						In-Person
					</div>
					<div
						role="button"
						className={`ms-4 create-meeting-type ${formik.values.is_virtual ? "active" : ""} d-flex justify-content-center align-items-center flex-column`}
						onClick={e => {
							e.stopPropagation();
							formik.setFieldValue("is_virtual", true);
							formik.setFieldValue("location", "");
							formik.setFieldValue("meeting_live_link", "");
							setMeetingLocation({
								...meetingLocation,
								locationValue: "",
								meetingLiveValue: "",
								locationActive: false,
								meetingLiveActive: false
							});
						}}
					>
						Online
					</div>
				</div>
				<div className="create-meeting-border-line mt-4" />
				<div className="mt-4 d-flex">
					{!formik.values.is_virtual ? (
						<Icon
							icon={`material-symbols-light:location-on-outline`}
							width={"24px"}
							height={"24px"}
							className="meeting-icons-color"
						/>
					) : (
						<Icon
							icon={`material-symbols-light:videocam-outline`}
							width={"24px"}
							height={"24px"}
							className="meeting-icons-color"
						/>
					)}

					{meetingLocation.locationActive || meetingLocation.meetingLiveActive ? (
						<div
							className="ms-3 create-meeting-keys w-75"
							onClick={e => {
								e.stopPropagation();
								if (formik.values.is_virtual) {
									setMeetingLocation({
										...meetingLocation,
										meetingLiveActive: false
									});
								} else {
									setMeetingLocation({
										...meetingLocation,
										locationActive: false
									});
								}
							}}
						>
							{formik.values?.meeting_live_link || formik.values?.location || ""}
						</div>
					) : (
						<input
							id={formik.values.is_virtual ? "meeting_live_link" : "location"}
							name={formik.values.is_virtual ? "meeting_live_link" : "location"}
							className="create-meeting-location-input ms-3"
							placeholder={
								formik.values.is_virtual ? "Add meeting URL" : "Add location"
							}
							value={
								formik.values.is_virtual
									? formik.values.meeting_live_link
									: formik.values.location
							}
							onChange={e => {
								e.stopPropagation();
								if (formik.values.is_virtual) {
									setMeetingLocation({
										...meetingLocation,
										meetingLiveValue: e.target.value
									});
								} else {
									setMeetingLocation({
										...meetingLocation,
										locationValue: e.target.value
									});
								}
								if (formik.values.is_virtual) {
									formik.setFieldValue("meeting_live_link", e.target.value);
								} else {
									formik.setFieldValue("location", e.target.value);
								}
							}}
							autoFocus={
								formik.values.meeting_live_link || formik.values.location
									? true
									: false
							}
							onClick={e => {
								e.stopPropagation();
							}}
						/>
					)}
				</div>
				<div className="create-meeting-border-line mt-4" />
				<div className="mt-4 d-flex align-items-center">
					{/* <div className="create-meeting-default-color" />
					<div className="create-meeting-keys default-color">Default color</div> */}
					<ColorPicker colors={colors} formik={formik} />
				</div>
				<div className="create-meeting-border-line mt-4" />
				<div className="mt-4 d-flex w-100">
					<Icon
						icon={`material-symbols-light:sort`}
						width={"24px"}
						height={"24px"}
						className="meeting-icons-color"
					/>
					{description.active ? (
						<div
							className="create-meeting-attachment-key create-meeting-keys w-100"
							onClick={e => {
								e.stopPropagation();
								setDescription({
									...description,
									active: false
								});
							}}
						>
							{formik.values.description}
						</div>
					) : (
						<textarea
							id="description"
							name="description"
							className="create-meeting-location-input ms-3 w-100"
							placeholder="Add description"
							value={formik.values.description}
							onChange={e => {
								e.stopPropagation();
								setDescription({
									...description,
									value: e.target.value
								});
								formik.setFieldValue("description", e.target.value);
							}}
							autoFocus={formik.values.description ? true : false}
							onClick={e => {
								e.stopPropagation();
							}}
						/>
					)}
				</div>
				{formik?.errors?.description && (
					<ShowFormikError error={formik?.errors?.description} />
				)}
				<div className="create-meeting-border-line mt-4" />
				<div className="create-meeting-attachment-wrapper">
					<div className="mt-4 d-flex">
						<Icon
							icon={`material-symbols-light:attach-file`}
							width={"24px"}
							height={"24px"}
							className="meeting-icons-color"
						/>
						{fileLoading ? (
							<div className="create-meeting-attachment-key create-meeting-keys">
								Uploading ...
							</div>
						) : (
							<div
								role="button"
								onClick={handleDivClick}
								className="create-meeting-attachment-key create-meeting-keys"
							>
								Add attachment
							</div>
						)}

						<input
							type="file"
							ref={fileInputRef}
							style={{ display: "none" }}
							onChange={handleFileChange}
							accept=".pdf, image/*" // Allow any file type
						/>
					</div>
					<div className="d-flex mt-3 pb-2">
						{selectedFiles.length > 0 && (
							<>
								<div className="row w-100 mx-0">
									{selectedFiles?.map((each: any) => (
										<div className="mb-3 col-12 meeting-details-attachment-item me-2">
											<div className="d-flex align-items-center">
												<span
													role="button"
													onClick={() => {
														window.open(
															each?.download?.url,
															"_blank",
															"noreferrer"
														);
													}}
													className="meeting-details-attachment-item-text"
												>
													{each?.download?.url?.split("/")?.pop() || ""}
												</span>
												&nbsp;&nbsp;&nbsp;
												<Icon
													role="button"
													icon={`material-symbols-light:delete-outline`}
													width={"18px"}
													height={"18px"}
													className="icons-color-delete meeting-details-delete-icon"
													onClick={() => {
														let _selectedFiles = [...selectedFiles];
														_selectedFiles.splice(
															selectedFiles.indexOf(each),
															1
														);
														setSelectedFiles([..._selectedFiles]);
														formik.setFieldValue("attachments", [
															..._selectedFiles.map(
																(data: any) => data?.download?.url
															)
														]);
													}}
												/>
											</div>
										</div>
									))}
								</div>
							</>
						)}
						{uploadFileError && <ShowFormikError error={uploadFileError} />}
						<div className="mt-2" />
					</div>
				</div>
			</div>
			<div className="d-flex mt-0 mb-auto save-btn-postion">
				<CustomButton
					isBgFill={true}
					minWidth={isResponsive ? "60px" : "100px"}
					paddingTopBottom={isResponsive ? "8px" : "10px"}
					disabled={loading || !formik.dirty}
					onClick={() => formik.handleSubmit()}
					className="meeting-save-btn"
				>
					Save
				</CustomButton>
			</div>
			<CustomModal
				openCustomModal={openAddPeopleModal}
				onClose={() => {
					setOpenAddPeopleModal(false);
				}}
				showModalAction={true}
				component={
					<AddPeople
						formik={formik}
						onClose={() => {
							setOpenAddPeopleModal(false);
						}}
					/>
				}
			/>
			<CustomDrawer
				open={openAddPeopleDrawer}
				onClose={() => {
					setOpenAddPeopleDrawer(false);
				}}
				onOpen={() => {
					setOpenAddPeopleDrawer(true);
				}}
				component={
					<>
						{openAddPeopleDrawer && (
							<AddPeople
								formik={formik}
								onClose={() => {
									setOpenAddPeopleDrawer(false);
								}}
							/>
						)}
					</>
				}
				maxHeight={"92vh"}
				showModalAction={true}
			/>
		</>
	);
};

export default CreateMeeting;
