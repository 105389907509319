import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import './About.css';

const About: React.FC = () => {
  return (
    <Box sx={{ p: 2, mt: 2 }}>
      <Typography variant="body2" className='about-text'>
        I'm Anoyik Soe, an investor at Ultrh, with a deep passion for financial markets and strategic investment.
        I'm dedicated to driving impactful growth and innovation. My extensive background in finance allows
        me to identify high-potential ventures and foster sustainable value creation.
      </Typography>
      <div className='about-loc'>
        <IconButton aria-label="location-eventdrawer">
          <img src="assets/icons/Address.svg" alt="bookmark" className='icons-size-loc' />
        </IconButton>
        <Typography variant="subtitle2" fontWeight="bold" className='about-loc'>
          Mumabi, India
        </Typography>
      </div >
    </Box>
  );
};

export default About;
