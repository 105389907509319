import { stripHtmlTags } from "../reusable/strings";

// Define the types for the notification options
interface NotificationOptions {
	title?: string;
	body?: string;
	icon?: string;
	badge?: string;
	tag?: string;
	data?: any;
	requireInteraction?: boolean;
	silent?: boolean;
	lang?: string;
	dir?: "auto" | "ltr" | "rtl";
}

// Request notification permission
export const requestNotificationPermission = async (): Promise<void> => {
	// if (typeof Notification === "undefined") {
	// 	console.log("This browser does not support notifications.");
	// 	return;
	// }
	if (Notification.permission === "default") {
		const permission = await Notification.requestPermission();
		if (permission === "granted") {
			console.log("Notification permission granted.");
		} else {
			console.log("Notification permission denied.");
		}
	}
};

// Show notification with fallback logic
export const showNotification = async ({
	title,
	body,
	icon,
	badge,
	data,
	requireInteraction = false,
	silent = false,
	lang = "en-US",
	dir = "auto"
}: NotificationOptions): Promise<void> => {
	if (Notification.permission !== "granted") {
		console.log("Notification permission not granted.");
		return;
	}

	const notificationOptions: NotificationOptions = {
		body: stripHtmlTags(body || ""),
		icon: icon || localStorage.getItem("appLogo") || "/favicon512.png",
		badge: badge || localStorage.getItem("appLogo") || "/favicon512.png",
		tag: new Date().toISOString(),
		data,
		requireInteraction,
		silent,
		lang,
		dir
	};

	try {
		await requestNotificationPermission();
		const registration = await navigator?.serviceWorker?.ready;

		// Check if Service Worker is registered and available
		if (registration && "showNotification" in registration) {
			registration.showNotification(title || "New Notification", notificationOptions);
		} else {
			// Fallback to using the Notification API directly
			new Notification(title || "New Notification", notificationOptions);
		}
	} catch (error) {
		console.error("Error showing notification:", error);
	}
};
