import React from 'react';
import { X, Bell } from 'lucide-react';
import './InternalError.css';

const InternalError: React.FC = () => {
    return (
        <>
        <div className='phone-view'> 
        <div className=' font'>
            {/* <div className="icon-container">
                <X className="icon left-icon" />
                <Bell className="icon right-icon" />
            </div> */}
            <div>
                <img
                    src="assets/unknown-error.png"
                    alt="Welcome"
                    className="internalerror-image"
                />
                <h3 className="internalerror-text1 font error-headings">Page Not Found</h3>
                <p className='internalerror-text error-textts'>
                
                Our team has been notified, and we’re <br></br>working to fix the issue as quickly as <br></br> possible. Please try again later.
                </p>
            </div>
        </div>
        </div>

        <div className='desktop-view'>
        <div className=' font'>
           <div className='errorpage d-flex align-items-center'>
            <div>
                <img
                    src="assets/unknown-error.png"
                    alt="Welcome"
                    className="internalerror-image"
                />
               
            </div>
            <div className='errorpage1  align-items-center'>
            <div className="error-headings">Page Not Found</div>
                <p className='error-textts'>
                Our team has been notified, and we’re <br></br>working to fix the issue as quickly as <br></br> possible. Please try again later.
                </p>
            </div>
            </div>
        </div>


        </div>
        </>
    );
};

export default InternalError;