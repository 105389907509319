import React from "react";

interface ScanBadgeNotificationBoxProps {
	message: string;
	isError?: boolean;
}

const ScanBadgeNotificationBox: React.FC<ScanBadgeNotificationBoxProps> = ({
	message,
	isError = false
}) => {
	return <div className={`scanBadge-notification-box ${isError ? "error" : ""}`}>{message}</div>;
};

export default ScanBadgeNotificationBox;
