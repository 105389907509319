import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Notification.css';

const Notification: React.FC = () => {
    const navigate = useNavigate();

    const handleNotificationClick = () => {
        navigate('/interest-page');
    };

    const goBack = () => {
      navigate(-1);
    };
  

    return (
        <> 
        <div className='phone-view'>
        <div className="notification-container">
            {/* <h1 className="skip-image"> <img src='assets/skip.png'></img></h1> */}
            <button  className="skip-image" onClick={goBack}>Skip</button>
            <div><img src="assets/Logo.svg" alt="Center Logo" className="logo notification_logo" /></div>
            <br></br>
           
            <h3 className='beat'>Don't miss a beat!</h3>
            <br></br>
            <img
                src="assets/notification.png"
                alt="notification"
                className="notification-image"
            />
            <div>
            </div>
            <button className="login-button push" onClick={handleNotificationClick}>
                Enable Push Notification
            </button>
        </div>
        </div>



        <div className='desktop-view'>
            <div className='skip-image-div'>
        <button  className="skip-image">Skip</button>
        </div>
            {/* <div><img src="assets/Logo.svg" alt="Center Logo" className=" notification_logo" /></div> */}
        <div className="notification-container-desktop">
            {/* <h1 className="skip-image"> <img src='assets/skip.png'></img></h1> */}
            
            <br></br>
            <br></br>
            <br></br>
            <div className='beat-div'>
            <h3 className='beat'>Don't miss a beat!</h3>

            <br></br>
            <img
                src="assets/notification.png"
                alt="notification"
                className="notification-image"
            />
                        </div>
            <div>
            </div>
            <button className="login-button-notification push" onClick={handleNotificationClick}>
                Enable Push Notification
            </button>
        </div>
        </div>
        </>

    );
};

export default Notification;
