import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './VerifySignupOtp.css';

const VerifySignupOtp: React.FC = () => {
    const [otp, setOtp] = useState(Array(6).fill(''));
    const [timer, setTimer] = useState(30);
    const navigate = useNavigate();

    useEffect(() => {
        const countdown = setInterval(() => {
            setTimer(prev => (prev > 0 ? prev - 1 : 0));
        }, 1000);

        return () => clearInterval(countdown);
    }, []);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const value = e.target.value;
        if (!/^\d*$/.test(value)) return; // Ensure input is a number

        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        // Focus next input field if the current one is filled
        if (value && index < 5) {
            const nextInput = document.getElementById(`otp-input-${index + 1}`);
            nextInput?.focus();
        }
    };

    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
        const paste = e.clipboardData.getData('text');
        if (/^\d{6}$/.test(paste)) { // Validate if the pasted text is a 6-digit number
            const newOtp = paste.split('');
            setOtp(newOtp);
            const nextInput = document.getElementById(`otp-input-5`);
            nextInput?.focus();
        }
        e.preventDefault();
    };

    const handleResendOtp = () => {
        setOtp(Array(6).fill(''));
        setTimer(30);
    };

    const isOtpValid = otp.every(num => num !== '');

    const handleVerify = () => {
        if (isOtpValid) {
            navigate('/home-screen');
        }
    };

    return (
        <div className='verifysignup_otp_container'>
            <div><img src="assets/Logo.png" alt="Center Logo" className="logo4" /></div>
            <div className="verifysignup-otp-container">
                <h3 className='vatsignup'>Verify OTP</h3>
                <div className="otpsignup-inputs">
                    {otp.map((value, index) => (
                        <React.Fragment key={index}>
                            <input
                                id={`otp-input-${index}`}
                                type="text"
                                maxLength={1}
                                value={value}
                                onChange={(e) => handleChange(e, index)}
                                onPaste={handlePaste}
                            />
                            {(index + 1) % 3 === 0 && index < otp.length - 1 && (
                                <span className="hyphen">-</span>
                            )}
                        </React.Fragment>
                    ))}
                </div>
                <div className="timer-text">
                    {timer > 0 ? `00:${timer < 10 ? `0${timer}` : timer}` : '00:00'}
                </div>
                <div className='receive'>
                    Didn't receive OTP? <span className='resend-text' onClick={handleResendOtp}>Resend OTP</span>
                </div>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <div className='bt1'>
                    <button
                        className={`verify-button ${isOtpValid ? 'enabled' : 'disabled'}`}
                        disabled={!isOtpValid}
                        onClick={handleVerify}
                    >
                        Verify
                    </button>
                </div>

                {/* <div className="powered-by">
                <span className="powered-text">Powered by</span>
                <img src="assets/Group.png" alt="Logo" className="logo1" />
            </div> */}

<div className="powered-by">
          <span className="powered-text">Powered by</span>
          <img src="assets/Group.png" alt="Logo" className="logo1" />
        </div>
            </div>
        </div>
    );
};

export default VerifySignupOtp;
