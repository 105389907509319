import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import "./CreateAccount.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Auth } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { toast } from "react-toastify";
import NotificationBox from "../Network/NotificationBox/NotificationBox";

const CreateAccount: React.FC = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const queryParams = new URLSearchParams(location.search); // Parses the query string
	const redirectPath = queryParams.get("redirect") || "/"; // Gets the "redirect" parameter
	const emailValue = queryParams.get("email");
	const [loading, setLoading] = useState(false);
	const [notificationPopup, setNotificationPopup] = useState(false);
	const [toasterMessage, setToasterMessage] = useState("");
	const [email, setEmail] = useState(emailValue);
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [emailError, setEmailError] = useState("");
	const [passwordError, setPasswordError] = useState("");
	const [confirmPasswordError, setConfirmPasswordError] = useState("");
	const [signUpError, setSignUpError] = useState("");
	const [passwordVisible, setPasswordVisible] = useState(false);
	const [confirmpasswordVisible, confirmsetPasswordVisible] = useState(false);
	const [validationStates, setValidationStates] = useState({
		length: false,
		uppercase: false,
		lowercase: false,
		number: false,
		specialChar: false
	});
	const [focused, setFocused] = useState(false);

	const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setPassword(value);

		// Update validation states
		setValidationStates({
			length: value.length >= 6,
			uppercase: /[A-Z]/.test(value),
			lowercase: /[a-z]/.test(value),
			number: /\d/.test(value),
			specialChar: /[@#$%^&!,.<>':;|_~=`+\-/=]/.test(value)
		});
	};

	const getColor = (condition: boolean) => {
		return condition ? "green" : focused ? "#dc3545" : "black";
	};

	const validateEmail = (email: string) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	};

	const togglePasswordVisibility = () => {
		setPasswordVisible(!passwordVisible);
	};
	const togglePasswordVisibilityconfirm = () => {
		confirmsetPasswordVisible(!confirmpasswordVisible);
	};

	const validatePassword = (password: string) => {
		const strongPasswordRegex =
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&!,.<>':;|_~=`+\-/=]).{6,}$/;
		return strongPasswordRegex.test(password);
	};

	const isFormValid =
		email &&
		password &&
		confirmPassword &&
		password === confirmPassword &&
		!emailError &&
		!passwordError &&
		!confirmPasswordError;

	// const handleSignupWithOtp = async () => {
	//   if (isFormValid) {
	//     try {
	//       await signUp({
	//         username: email,
	//         password,
	//         attributes: {
	//           email,
	//         },
	//       });
	//       navigate("/verify-otp", { state: { email } });
	//     } catch (error) {
	//       console.error("Error signing up:", error);
	//       setSignUpError("An error occurred during sign up. Please try again.");
	//     }
	//   }
	// };

	const handleSignupsWithOtp = () => {
		navigate(`/signup-page?redirect=${redirectPath}&email=${email}&signUp=true`);
	};

	const handleTermsandCondition = () => {
		navigate("/terms-condition");
	};

	const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setEmail(value);
		if (!validateEmail(value)) {
			setEmailError("Please enter a valid email address.");
		} else {
			setEmailError("");
		}
	};

	const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setConfirmPassword(value);
		if (value !== password) {
			setConfirmPasswordError("Passwords do not match.");
		} else {
			setConfirmPasswordError("");
		}
	};

	const handleGoogleSignIn = async () => {
		try {
			await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
		} catch (error) {
			console.error("Error signing in with Google:", error);
			setSignUpError("An error occurred during Google sign in. Please try again.");
		}
	};
	const handleEmailBlur = () => {
		if (!email?.trim()) {
			setEmailError("Please enter your email.");
		} else if (!validateEmail(email)) {
			setEmailError("Please enter a valid email address.");
		} else {
			setEmailError("");
		}
	};

	const handlePasswordBlur = () => {
		if (!password.trim()) {
			setPasswordError("Please enter your password.");
		} else if (!validatePassword(password)) {
			setPasswordError("");
		} else {
			setPasswordError("");
		}
	};

	const handleConfirmPasswordBlur = () => {
		if (!confirmPassword.trim()) {
			setConfirmPasswordError("Please confirm your password.");
		} else if (confirmPassword !== password) {
			setConfirmPasswordError("Passwords do not match.");
		} else {
			setConfirmPasswordError("");
		}
	};

	function generateRandomPass() {
		let password = "";

		const specials = "!@#$%^&*()_+{}:\"<>?|[];',./`~";
		const lowercase = "abcdefghijklmnopqrstuvwxyz";
		const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
		const numbers = "0123456789";
		const all = specials + lowercase + uppercase + numbers;

		function pick(exclusions: any, string: any, min: any, max: any) {
			let chars = "";
			let i = 0;
			while (i < max) {
				const character = string.charAt(Math.floor(Math.random() * string.length));
				if (exclusions.indexOf(character) < 0 && chars.indexOf(character) < 0) {
					chars += character;
					i++;
				}
			}
			return chars;
		}

		password += pick(password, specials, 1, 3);
		password += pick(password, lowercase, 1, 3);
		password += pick(password, uppercase, 1, 3);
		password += pick(password, numbers, 1, 3);
		password += pick(password, all, 10, 30);

		return password;
	}

	const resendSignUp = async (message = "") => {
		try {
			await Auth.resendSignUp(email?.toLowerCase() || "");
			setToasterMessage(message);
			setNotificationPopup(true);
			navigate(`/signup-page?redirect=${redirectPath}&email=${email}&signUp=true`);
		} catch (err: any) {
			setToasterMessage(err.message || "Something went wrong!");
			setNotificationPopup(true);
		}
	};
	useEffect(() => {
		if (notificationPopup) {
			const timer = setTimeout(() => {
				setNotificationPopup(false);
			}, 3000);

			return () => clearTimeout(timer);
		}
	}, [notificationPopup]);

	const signUp = async () => {
		if (isFormValid) {
			setLoading(true);
			try {
				try {
					await Auth.signIn(email.toLowerCase(), generateRandomPass());
					setLoading(false);
				} catch (err: any) {
					let error = err.message || "Something went wrong!";
					if (error === "Incorrect username or password.") {
						setToasterMessage("An account is already registered. Please login");
						setNotificationPopup(true);
						setTimeout(() => {
							navigate(`/login-account?redirect=${redirectPath}&email=${email}`);
						}, 2000);
					} else if (error === "User does not exist.") {
						try {
							const signUpDetails = {
								username: email.toLowerCase(),
								password: password,
								attributes: {
									name: email
										?.toLowerCase()
										?.substring(0, email?.toLowerCase()?.indexOf("@")),
									email: email?.toLowerCase(),
									"custom:signUpEventId": localStorage.getItem("eventId") || ""
								}
							};
							await Auth.signUp(signUpDetails);
							setToasterMessage(
								"Verification code sent to your email successfully. Please verify your account by entering the code."
							);
							setNotificationPopup(true);
							setTimeout(() => {
								navigate(
									`/signup-page?redirect=${redirectPath}&email=${email}&signUp=true`
								);
							}, 2000);
						} catch (err: any) {
							let error = err.message || "Something went wrong!";
							if (error === "An account with the given email already exists.") {
								error =
									"An account with the given email id already exists. Please login using that email id.";
							}
							if (
								error ===
								"PreSignUp failed with error You cannot sign in because you already have a deactivated account with the same email id!."
							) {
								error =
									"You cannot sign in because you already have a deactivated account with the same email id!";
							}
							error = error.replace("PreSignUp failed with error ", "");
							setToasterMessage(error);
							setNotificationPopup(true);
						}
					} else if (error === "User is not confirmed.") {
						await resendSignUp("Code sent successfully");
					} else {
						setToasterMessage(error);
						setNotificationPopup(true);
					}
					console.log(error);
				}
			} catch (error) {
				console.log(error);
			}
		}
	};

	const requestOtp = async () => {
		if (!email) {
			setToasterMessage("Please enter your email address");
			setNotificationPopup(true);
			return;
		}
		setLoading(true);
		if (true) {
			try {
				await Auth.signIn(email?.toLowerCase() || "", generateRandomPass());
				setLoading(false);
			} catch (err: any) {
				let error = err.message || "Something went wrong!";
				if (error === "Incorrect username or password.") {
					setToasterMessage("An account is already registered. Please login");
					setNotificationPopup(true);
					setLoading(true);
					setTimeout(() => {
						if (redirectPath === null) {
							navigate(`/login-account?redirect=${"/"}&email=${email}`);
						} else {
							navigate(`/login-account?redirect=${redirectPath}&email=${email}`);
						}
					}, 2000);
					// navigate(`/login-account?redirect=${redirectPath}&email=${email}`);
					// setToasterMessage("An account is already registered. Please login")
					// setNotificationPopup(true);
				} else if (error === "User does not exist.") {
					try {
						const signupDetails: any = {
							username: email?.toLowerCase(),
							password: generateRandomPass(),
							attributes: {
								name: email
									?.toLowerCase()
									?.substring(0, email?.toLowerCase()?.indexOf("@")),
								email: email?.toLowerCase(),
								"custom:signUpEventId": localStorage.getItem("eventId") || ""
							}
						};

						await Auth.signUp(signupDetails);
						setLoading(false);
						setToasterMessage("Code sent successfully");
						setNotificationPopup(true);
						//trackUserSignup(localStorage.getItem("eventId") || "");
						navigate(
							`/signup-page?redirect=${redirectPath}&email=${email}&signUp=true`
						);
						setTimeout(() => {
							navigate(
								`/signup-page?redirect=${redirectPath}&email=${email}&signUp=true`
							);
						}, 2000);
					} catch (error: any) {
						setLoading(false);
						setToasterMessage(error.message);
						setNotificationPopup(true);
					}
				} else if (error === "User is not confirmed.") {
					await resendSignUp("Code sent successfully");
					setLoading(false);
				} else {
					setLoading(false);
					setToasterMessage(error);
					setNotificationPopup(true);
				}
			}
		}
	};

	return (
		<>
			{notificationPopup && (
				<div className="notification-wrapper">
					<NotificationBox message={toasterMessage} />
				</div>
			)}
			<div className="phone-view d-flex d-md-none w-100 h-100 flex-column justify-content-center flex-grow-1">
				<div className="signup-form create-account-cont signup-ph-container">
					{/* <div>
                  {" "}
                  <img src="assets/Logo.svg" alt="Center Logo" className="logo-desktop" />
                </div> */}
					{/* <h3 className="h1">Create Your Account</h3> */}

					<div className="form-group">
						<label className="ltext">Email Address</label>

						<input
							type="email"
							value={email}
							onChange={handleEmailChange}
							onBlur={handleEmailBlur}
							placeholder="Enter your Email Address"
							className="input-field"
							style={{
								fontWeight: email ? "400" : "",
								fontSize: email ? "16px" : ""
							}}
						/>
						{emailError && <span className="error-text error">{emailError}</span>}
					</div>
					{/* password */}
					<div className="form-group">
						<label className="ltext">Password</label>

						<div className="password-input-container">
							<input
								type={confirmpasswordVisible ? "text" : "password"}
								value={password}
								onChange={handlePasswordChange}
								onFocus={() => setFocused(true)}
								onBlur={handlePasswordBlur}
								placeholder="Enter your password"
								className="input-field"
								style={{
									fontWeight: password ? "400" : "",
									fontSize: password ? "16px" : ""
								}}
							/>

							<span onClick={togglePasswordVisibilityconfirm} className="eyeicon">
								{confirmpasswordVisible ? <FaEye /> : <FaEyeSlash />}
							</span>
						</div>
						{passwordError && <span className="error-text error">{passwordError}</span>}
						<div className="text-start mt-2">
							Password must contain a{" "}
							<span style={{ color: getColor(validationStates.length) }}>
								minimum of 6 characters
							</span>
							,{" "}
							<span style={{ color: getColor(validationStates.uppercase) }}>
								1 uppercase letter
							</span>
							,{" "}
							<span style={{ color: getColor(validationStates.lowercase) }}>
								1 lowercase letter
							</span>
							,{" "}
							<span style={{ color: getColor(validationStates.number) }}>
								1 number
							</span>{" "}
							and{" "}
							<span
								style={{
									color: getColor(validationStates.specialChar)
								}}
							>
								1 special character
							</span>
							.
						</div>
					</div>
					{/* confirm password */}
					<div className="form-group confirm_password">
						<label className="ltext">Confirm Password</label>
						<div className="password-input-container">
							<input
								type={passwordVisible ? "text" : "password"}
								value={confirmPassword}
								onChange={handleConfirmPasswordChange}
								onBlur={handleConfirmPasswordBlur}
								placeholder="Confirm your password"
								className="input-field"
								style={{
									fontWeight: confirmPassword ? "400" : "",
									fontSize: confirmPassword ? "14px" : ""
								}}
							/>
							<span onClick={togglePasswordVisibility} className="eyeicon">
								{passwordVisible ? <FaEye /> : <FaEyeSlash />}
							</span>
						</div>
						{confirmPasswordError && (
							<span className="error-text error">{confirmPasswordError}</span>
						)}
					</div>
					<div className="sign_btn">
						<button
							className={`proceed-button ${isFormValid ? "enabled" : "disabled"}`}
							disabled={!isFormValid}
							onClick={signUp}
						>
							Sign Up{" "}
							{loading && (
								<div
									className=" ms-2 spinner-border text-white spinner-border-sm"
									role="status"
								>
									<span className="visually-hidden">Loading...</span>
								</div>
							)}
						</button>
					</div>

					{/* <div className="or-text-container">
                <div className="or_line"></div>
                <div className="or-text">OR</div>
                <div className="or_line"></div>
              </div> */}
					<div className="signup-email-otp" onClick={requestOtp}>
						Request <span className="email-otp">Email OTP</span>
					</div>
					{/* <div className="or-text-container">
                <div className="or_line"></div>
                <div className="or-text">OR</div>
                <div className="or_line"></div>
              </div> */}
					<div className="google-button-auth-pages d-none" onClick={handleGoogleSignIn}>
						<span>
							<div className="create-Account_buttons">
								<button className="create_aacount_btn">
									<img src="assets/google.svg" className="create_google-logo" />
								</button>
								<button className="create_aacount_btn">
									<img src="assets/Facebook.svg" className="create_google-logo" />
								</button>
								<button className="create_aacount_btn">
									<img src="assets/Amazon.svg" className="create_google-logo" />
								</button>
							</div>
						</span>
					</div>

					{signUpError && <span className="error-text error">{signUpError}</span>}

					<div className="Create_account_text text-center">
						{" "}
						By signing up, I accept the KonfHub{" "}
						<a
							href="https://konfhub.com/terms-and-conditions"
							className="terms-text agreement-txt"
							target="_blank"
						>
							Terms & Conditions
						</a>
						<span className="mx-1 agreement-txt"> and</span>
						<a
							href="https://konfhub.com/code-of-conduct"
							className="terms-text agreement-txt"
							target="_blank"
						>
							Code Of Conduct
						</a>
						<span className="mx-1">and have read the</span>
						<a
							href="https://konfhub.com/privacy-policy"
							className="terms-text agreement-txt mr-1"
							target="_blank"
						>
							Privacy Policy.
						</a>
						<span className="mx-2">
							I agree to KonfHub to share my information with the event organizer and
							receive event related updates.
						</span>
					</div>

					<p className="account_text">
						Already have an account?{" "}
						<Link className="login_create" to="/login-account">
							Sign In
						</Link>
					</p>
				</div>
			</div>
			{/* second container */}

			<div className="desktop-view">
				<div className="main-container">
					<div className="right-panel d-flex align-items-center justify-content-center">
						<div className="panel-desktop auth-screen-desktop">
							<div className="signup-form">
								{/* <div>
                  {" "}
                  <img src="assets/Logo.svg" alt="Center Logo" className="logo-desktop" />
                </div> */}
								{/* <h3 className="h1">Create Your Account</h3> */}

								<div className="form-group">
									<label className="ltext">Email Address</label>

									<input
										type="email"
										value={email}
										onChange={handleEmailChange}
										onBlur={handleEmailBlur}
										placeholder="Enter your Email Address"
										className="input-field"
										style={{
											fontWeight: email ? "400" : "",
											fontSize: email ? "16px" : ""
										}}
									/>
									{emailError && (
										<span className="error-text error">{emailError}</span>
									)}
								</div>
								{/* password */}
								<div className="form-group">
									<label className="ltext">Password</label>

									<div className="password-input-container">
										<input
											type={confirmpasswordVisible ? "text" : "password"}
											value={password}
											onChange={handlePasswordChange}
											onFocus={() => setFocused(true)}
											onBlur={handlePasswordBlur}
											placeholder="Enter your password"
											className="input-field"
											style={{
												fontWeight: password ? "400" : "",
												fontSize: password ? "16px" : ""
											}}
										/>

										<span
											onClick={togglePasswordVisibilityconfirm}
											className="eyeicon"
										>
											{confirmpasswordVisible ? <FaEye /> : <FaEyeSlash />}
										</span>
									</div>
									{passwordError && (
										<span className="error-text error">{passwordError}</span>
									)}
									<div className="text-start mt-2">
										Password must contain a{" "}
										<span style={{ color: getColor(validationStates.length) }}>
											minimum of 6 characters
										</span>
										,{" "}
										<span
											style={{ color: getColor(validationStates.uppercase) }}
										>
											1 uppercase letter
										</span>
										,{" "}
										<span
											style={{ color: getColor(validationStates.lowercase) }}
										>
											1 lowercase letter
										</span>
										,{" "}
										<span style={{ color: getColor(validationStates.number) }}>
											1 number
										</span>{" "}
										and{" "}
										<span
											style={{
												color: getColor(validationStates.specialChar)
											}}
										>
											1 special character
										</span>
										.
									</div>
								</div>
								{/* confirm password */}
								<div className="form-group confirm_password">
									<label className="ltext">Confirm Password</label>
									<div className="password-input-container">
										<input
											type={passwordVisible ? "text" : "password"}
											value={confirmPassword}
											onChange={handleConfirmPasswordChange}
											onBlur={handleConfirmPasswordBlur}
											placeholder="Confirm your password"
											className="input-field"
											style={{
												fontWeight: confirmPassword ? "400" : "",
												fontSize: confirmPassword ? "14px" : ""
											}}
										/>
										<span
											onClick={togglePasswordVisibility}
											className="eyeicon"
										>
											{passwordVisible ? <FaEye /> : <FaEyeSlash />}
										</span>
									</div>
									{confirmPasswordError && (
										<span className="error-text error">
											{confirmPasswordError}
										</span>
									)}
								</div>
								<div className="sign_btn">
									<button
										className={`proceed-button ${isFormValid ? "enabled" : "disabled"}`}
										disabled={!isFormValid}
										onClick={signUp}
									>
										Sign Up{" "}
										{loading && (
											<div
												className=" ms-2 spinner-border text-white spinner-border-sm"
												role="status"
											>
												<span className="visually-hidden">Loading...</span>
											</div>
										)}
									</button>
								</div>

								{/* <div className="or-text-container">
                <div className="or_line"></div>
                <div className="or-text">OR</div>
                <div className="or_line"></div>
              </div> */}
								<div className="signup-email-otp" onClick={requestOtp}>
									Request <span className="email-otp">Email OTP</span>
								</div>
								{/* <div className="or-text-container">
                <div className="or_line"></div>
                <div className="or-text">OR</div>
                <div className="or_line"></div>
              </div> */}
								<div
									className="google-button-auth-pages d-none"
									onClick={handleGoogleSignIn}
								>
									<span>
										<div className="create-Account_buttons">
											<button className="create_aacount_btn">
												<img
													src="assets/google.svg"
													className="create_google-logo"
												/>
											</button>
											<button className="create_aacount_btn">
												<img
													src="assets/Facebook.svg"
													className="create_google-logo"
												/>
											</button>
											<button className="create_aacount_btn">
												<img
													src="assets/Amazon.svg"
													className="create_google-logo"
												/>
											</button>
										</div>
									</span>
								</div>

								{signUpError && (
									<span className="error-text error">{signUpError}</span>
								)}

								<div className="Create_account_text text-center">
									{" "}
									By signing up, I accept the KonfHub{" "}
									<a
										href="https://konfhub.com/terms-and-conditions"
										className="terms-text agreement-txt"
										target="_blank"
									>
										Terms & Conditions
									</a>
									<span className="mx-1 agreement-txt"> and</span>
									<a
										href="https://konfhub.com/code-of-conduct"
										className="terms-text agreement-txt"
										target="_blank"
									>
										Code Of Conduct
									</a>
									<span className="mx-1">and have read the</span>
									<a
										href="https://konfhub.com/privacy-policy"
										className="terms-text agreement-txt mr-1"
										target="_blank"
									>
										Privacy Policy.
									</a>
									<span className="mx-1">
										I agree to KonfHub to share my information with the event
										organizer and receive event related updates.
									</span>
								</div>

								<p className="account_text">
									Already have an account?{" "}
									<Link className="login_create" to="/login-account">
										Sign In
									</Link>
								</p>
							</div>
						</div>
						{/* <div className="powered-by">
                <span className="powered-text">Powered by</span>
                <img src="assets/Group.png" alt="Logo" className="logo1" />
            </div> */}

						{/* <p className="sign">
                By signing up, you agree to our{" "}
                <span className="blue-term" onClick={handleTermsandCondition}>
                  Terms and Conditions
                </span>
              </p> */}
						{/* <div className="footers-container">
              <div className='footers-text'>  <span >
                © Konfhub 2024
              </span>
              </div>

              <div>Privacy Policy</div>
            </div> */}
					</div>
					<div
						className="left-panel auth-screen-container"
						style={{ backgroundImage: `url('/assets/layesImage.svg')` }}
					>
						{/* <div className="leftpanel-tag-container">
              <img src="assets/StarforLeft.svg" alt="this is star img" />
              <p className="tagline-for-leftpanel">
                Trusted by over 10,000 event organizers worldwide.
              </p>
            </div> */}
					</div>
				</div>
			</div>
		</>
	);
};

export default CreateAccount;
