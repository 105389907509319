import React, { useEffect, useState } from "react";
import "./EventDrawerDesktop.css";
import SideNavbar from "../SideNavbar/SideNavbar";
import NotificationModal from "../Notification/NotificationModal/NotificationModal";
import { Bell } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { Header } from "../Header/Header";
import { timeZoneAbbreviations } from "../../config/app";
import { Icon } from "@iconify/react/dist/iconify.js";
import moment from "moment";
import "moment-timezone";
import SpeakerDetailsComp from "../SpeakerPage/SpeakerDetailsComp/SpeakerDetailsComp";
import { formatTimestamp } from "../../reusable/common";
import { getSessionDetails } from "../../crud/agenda.crud";
import { useEvent } from "../../contexts/EventContext";
import CustomLoader from "../../CommomComponents/CustomLoader/CustomLoader";
interface Speaker {
	name: string;
	avatar: string;
	dec: string;
}

interface EventFullScreenProps {
	modalData: "";
	setModalOpenDesktop: (val: boolean) => void;
	speakers?: object[];
	eventsData: [];
	setModalData?: () => void;
}

const EventFullScreen: React.FC<EventFullScreenProps> = ({
	setModalOpenDesktop,
	modalData,
	speakers,
	eventsData,
	setModalData
}) => {
	const navigate = useNavigate();

	const searchParams = new URLSearchParams(location.search); // Parse the search string
	const sessionId = searchParams.get("sessionId");
	const speakerId = searchParams.get("speakerId");

	const { eventDetails } = useEvent();
	const [showModal, setShowModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [speakerDetails, setSpeakerDetails] = useState(false);
	const [isSessionDrawerOpen, setIsSessionDrawerOpen] = useState(false);

	const getSessionDetailsOfIndividual = async (eventId: string, speaker: any) => {
		setLoading(true);
		try {
			const res = await getSessionDetails(eventId, speaker?.speaker_id);
			if (res.data) {
				setSpeakerDetails(() => {
					return {
						...speaker,
						sessions: res?.data?.map((each: any) => {
							let _session_speakers = [];
							_session_speakers = each?.session_speakers?.filter((data: object) => {
								const _speaker = speakers?.filter(
									(each: any) => each?.speaker_id === data
								)?.[0];
								data = { ..._speaker };
								return data;
							});
							each.session_speakers = _session_speakers;
							return each;
						})
					};
				});
			}
		} catch (error) {
			console.log("error", error);
		} finally {
			setLoading(false);
		}
	};

	const handleClose = (e: React.MouseEvent<HTMLDivElement>) => {
		if (e.target === e.currentTarget) {
			setShowModal(false);
		}
	};

	return (
		<>
			<div className="menu-desktop">
				<div>
					<SideNavbar />
				</div>
				<div className="eventFullScreen">
					<div className="flex-container-desktops menuuu-desktop">
						<Icon
							icon={`material-symbols-light:arrow-back-ios-rounded`}
							width={"25px"}
							height={"25px"}
							onClick={() => {
								setModalOpenDesktop(false);
							}}
							className="primary-color-icons"
						/>
						<Header setModalOpenDesktop={setModalOpenDesktop} />
					</div>
					{showModal && (
						<div
							onClick={handleClose}
							style={{
								position: "fixed",
								top: 0,
								left: 0,
								right: 0,
								bottom: 0,
								backgroundColor: "rgba(0, 0, 0, 0.5)",
								display: "flex",
								justifyContent: "flex-end",
								alignItems: "flex-start",
								zIndex: 1000
							}}
						>
							<div
								onClick={e => e.stopPropagation()}
								style={{
									width: "400px",
									maxHeight: "80vh",
									overflow: "auto",
									backgroundColor: "white",
									marginTop: "60px",
									marginRight: "20px",
									borderRadius: "8px",
									padding: "20px",
									boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)"
								}}
							>
								<NotificationModal />
							</div>
						</div>
					)}

					<div className="dawdesk">
						{loading ? (
							<CustomLoader />
						) : (
							<div className="hide-in-mobile">
								{speakerDetails ? (
									<div className="hide-in-mobile">
										<SpeakerDetailsComp
											speakerDetails={speakerDetails}
											speakers={speakers}
											setIsSessionDrawerOpen={setIsSessionDrawerOpen}
											setModalOpenDesktop={setModalOpenDesktop}
											eventDetails={eventDetails}
											setModalData={setModalData}
											setSpeakerDetails={setSpeakerDetails}
										/>
									</div>
								) : (
									<div>
										<div className="main-eventDrawer">
											<div className="timesection-event">
												<h4 className="event-time">
													{formatTimestamp(
														modalData?.start_timestamp,
														modalData?.end_timestamp,
														eventsData?.time_zone
													)}
												</h4>
											</div>
											{/* 
                  <div className="Plenary-text">
                    <img
                      src="assets/icons/Address.svg"
                      alt="Location"
                      className="icons-size-loc"
                    />
                    <h5>{modalData.location}</h5>
                  </div> */}
											<h4 className="event-drawer-text">
												{modalData?.session_title}
											</h4>
											{modalData?.session_location && (
												<div
													className="d-flex align-items-center mt-2"
													style={{ marginLeft: "0px" }}
												>
													<Icon
														icon={`fluent:location-16-regular`}
														width={"18px"}
														height={"18px"}
														weight={20}
														className="primary-color-icons"
													/>
													<p
														className="venue-address"
														style={{ marginLeft: "7px" }}
													>
														{modalData?.session_location}
													</p>
												</div>
											)}
											<p
												className="text-edrawer"
												dangerouslySetInnerHTML={{
													__html: modalData?.session_description
												}}
											></p>{" "}
											<div className="speaker-event">
												{modalData?.session_speakers?.length > 0 && (
													<>
														<h6 className="gentle">Speakers</h6>
														<div className="speaker-grid-desktop">
															{modalData?.session_speakers[0]
																?.name ? (
																<>
																	{modalData?.session_speakers?.map(
																		(speaker, index) => (
																			<div
																				className="speaker-avatar"
																				style={{
																					marginRight:
																						"15px"
																				}}
																				key={index}
																			>
																				<img
																					style={{
																						borderRadius:
																							"50%"
																					}}
																					src={
																						speaker.image_url
																					}
																					alt={
																						speaker.name
																					}
																					className="avatar-img"
																					onClick={() => {
																						getSessionDetailsOfIndividual(
																							eventDetails?.event_id,
																							speaker
																						);
																					}}
																				/>
																				<p className="eventdraw-name">
																					{speaker?.name}
																				</p>
																				<p className="eventdraw-dec-desktop">
																					{speaker?.dec}
																				</p>
																			</div>
																		)
																	)}
																</>
															) : (
																<>
																	{modalData?.session_speakers
																		?.map((data: object) => {
																			const _speaker =
																				speakers?.filter(
																					each =>
																						each?.speaker_id ===
																						data
																				)[0];
																			data = { ..._speaker };
																			return data;
																		})
																		.map((speaker, index) => (
																			<div
																				className="speaker-avatar"
																				style={{
																					marginRight:
																						"15px"
																				}}
																				key={index}
																			>
																				<img
																					style={{
																						borderRadius:
																							"50%"
																					}}
																					src={
																						speaker.image_url
																					}
																					alt={
																						speaker.name
																					}
																					className="avatar-img"
																				/>
																				<p className="eventdraw-name">
																					{speaker?.name}
																				</p>
																				<p className="eventdraw-dec-desktop">
																					{speaker?.dec}
																				</p>
																			</div>
																		))}
																</>
															)}
														</div>
													</>
												)}

												{modalData?.tags?.length > 0 && (
													<>
														<h6 className="gentle">Tags</h6>
														<div className="tags-container">
															{modalData.tags.map((tag, index) => (
																<span
																	key={index}
																	className="tag-chip"
																>
																	{tag.name}
																</span>
															))}
														</div>
													</>
												)}
											</div>
										</div>
									</div>
								)}
							</div>
						)}

						<div className="dawdesk2">{/* Additional content here */}</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default EventFullScreen;
