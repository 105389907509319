import React, { useState, useRef, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./MyEvent.css";
import { Sheet } from "react-modal-sheet";
import LiveEventsFilter from "../LiveEventsFilter/LiveEventsFilter";
import { Bell } from "lucide-react";
import { Link, useNavigate, useParams } from "react-router-dom";
import api from "../../api";
import SideNavbar from "../SideNavbar/SideNavbar";
import { Header } from "../Header/Header";
// import AnnouncementOverlay from "../AnnouncementOverlay/AnnouncementOverlay";

const MyEvent: React.FC = () => {
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState("");
    const [isOpen, setOpen] = useState(false);
    const [isFilterOpen, setFilterOpen] = useState(false);
    const [otp, setOtp] = useState(Array(6).fill(""));

    const [eventid, setEventId] = useState("");
    const [eventurl, setEventurl] = useState("");

    const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

    const [events, setEvents] = useState([]);
    const [showAnnouncement, setShowAnnouncement] = useState(false);

    const handleCardClick = (id: string, url: string) => {
        sendOtpToEmail();
        setEventId(id);
        setEventurl(url);
        setOpen(true);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await api.get("/event/public/rtce");
            setEvents(response.data);
        } catch (err) {
            console.log(err);
        }
    };

    const sendOtpToEmail = () => {
    };

    const handleOtpChange = (index: number, value: string) => {
        if (/^\d*$/.test(value)) {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);

            if (value !== "" && index < otp.length - 1) {
                inputRefs.current[index + 1]?.focus();
            }
        }
    };

    const handleKeyDown = (
        index: number,
        e: React.KeyboardEvent<HTMLInputElement>
    ) => {
        if (e.key === "Backspace" && index > 0 && otp[index] === "") {
            inputRefs.current[index - 1]?.focus();
        }
    };

    const handleJoinClick = () => {
        setOpen(false);

        // First, navigate to the event details page
        navigate("/event-app/" + eventid + "/" + eventurl);

        // Then, after a short delay to allow the navigation to complete,
        // show the announcement overlay
        setTimeout(() => {
            setShowAnnouncement(true);
        }, 100);
    };

    const handleAnnouncementClose = () => {
        setShowAnnouncement(false);
    };




    const handleImageClick = () => {
        navigate('/profile-page');
    };

    const goBack = () => {
        navigate(-1);
    };


    return (
        <>
            <div className="phone-view">
                <div className="home-screen phone-container-home font">

                    <div className="flex-container menuuus">
                        <img className='left_uparrow search_left_arrow' src="/assets/uparrow.svg" alt="Go Back" onClick={goBack} />
                        <h1 className="rest1">My Events</h1>
                    </div>

                    <div className="live-events-container">
                        {/* {events.map((event) => ( */}
                        <div
                            key={events.event_id}
                            className="live-events-card"
                            onClick={() => handleCardClick(events.event_id, events.event_url)}
                        >
                            <div className="carousel-container">
                                <Carousel
                                    showThumbs={false}
                                    showStatus={false}
                                    infiniteLoop
                                    useKeyboardArrows
                                    autoPlay
                                    interval={2000}
                                >
                                    <div>
                                        <img src={events.poster_thumbnail} alt="Slide 1" />
                                    </div>
                                    <div>
                                        <img src={events.poster_thumbnail} alt="Slide 2" />
                                    </div>
                                    <div>
                                        <img src={events.poster_thumbnail} alt="Slide 3" />
                                    </div>
                                </Carousel>
                                <div className="live-button-container">
                                    <div className="live-button">
                                        <div className="live-dot"></div>
                                        Live
                                    </div>
                                </div>
                                <div className="conference-title">
                                    <img
                                        className="card_heading_image"
                                        src={events.att_app_logo}
                                        alt=""
                                    />
                                    <h3 className="home_card_header">{events.name}</h3>
                                </div>
                            </div>

                            <div className="event-details">
                                <div className="details">
                                    <p className="det1">
                                        <span>
                                            <img
                                                src="/assets/Address.svg"
                                                alt="Address Icon"
                                                className="small-icon"
                                            />
                                        </span>
                                        <span>Address: {events.city}</span>
                                    </p>
                                    <p className="det1">
                                        <span>
                                            <img
                                                src="/assets/calendar-1.svg"
                                                alt="Date Icon"
                                                className="small-icon"
                                            />
                                        </span>
                                        <span>
                                            {events.start_date} - {events.end_date}
                                        </span>
                                    </p>
                                    <p className="det1">
                                        <span>
                                            <img
                                                src="/assets/clock-rewind.svg"
                                                alt="Time Icon"
                                                className="small-icon"
                                            />
                                        </span>
                                        <span>Time: {events.start_time}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* ))} */}

                        <div className="live-events-card" onClick={handleCardClick}>
                            <div className="carousel-container">
                                <Carousel
                                    showThumbs={false}
                                    showStatus={false}
                                    infiniteLoop
                                    useKeyboardArrows
                                    autoPlay
                                    interval={2000}
                                >
                                    <div>
                                        <img src="assets/blue.jpeg" alt="Slide 1" />
                                    </div>
                                    <div>
                                        <img src="assets/blue.jpeg" alt="Slide 2" />
                                    </div>
                                    <div>
                                        <img src="assets/blue.jpeg" alt="Slide 3" />
                                    </div>
                                </Carousel>
                                <div className="live-button-container">
                                    <div className="live-button">
                                        <div className="live-dot"></div>
                                        Live
                                    </div>
                                </div>
                                <div className="conference-title">
                                    <img className="card_heading_image" src="assets/ely.png" alt="" />
                                    <h3 className="home_card_header">
                                        International Conference <br /> for Latest Developments
                                        <br /> in Communication Technologies (2024)
                                    </h3>
                                </div>
                            </div>

                            <div className="event-details">
                                <div className="details">
                                    <p className="det1">
                                        <span>
                                            <img
                                                src="/assets/Address.svg"
                                                alt="Address Icon"
                                                className="small-icon"
                                            />
                                        </span>
                                        <span>Address: 123 Event St, City, Country</span>
                                    </p>
                                    <p className="det1">
                                        <span>
                                            <img
                                                src="/assets/calendar-1.svg"
                                                alt="Date Icon"
                                                className="small-icon"
                                            />
                                        </span>
                                        <span>Date: September 10, 2024</span>
                                    </p>
                                    <p className="det1">
                                        <span>
                                            <img
                                                src="/assets/clock-rewind.svg"
                                                alt="Time Icon"
                                                className="small-icon"
                                            />
                                        </span>
                                        <span>Time: 10:00 AM - 2:00 PM</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {showAnnouncement && (
                        <AnnouncementOverlay onClose={handleAnnouncementClose} />
                    )}

                    <Sheet isOpen={isOpen} onClose={() => setOpen(false)} snapPoints={[0.5]}>
                        <Sheet.Container style={{ height: "auto", maxHeight: "40%" }}>
                            <Sheet.Header />
                            <Sheet.Content>
                                <div className="cont">
                                    <div className="otp-section otp-section-card">
                                        <p className="acc">Enter event access code</p>
                                        <div className="otp-inputs">
                                            {otp.map((value, index) => (
                                                <React.Fragment key={index}>
                                                    <input
                                                        ref={(el) => (inputRefs.current[index] = el)}
                                                        type="text"
                                                        maxLength={1}
                                                        className="hotp"
                                                        value={value}
                                                        onChange={(e) => handleOtpChange(index, e.target.value)}
                                                        onKeyDown={(e) => handleKeyDown(index, e)}
                                                    />
                                                    {(index + 1) % 3 === 0 && index < otp.length - 1 && (
                                                        <span className="hyphen homehyphen">-</span>
                                                    )}
                                                </React.Fragment>
                                            ))}
                                        </div>
                                        <br />
                                        <br />
                                        <div className="home_join">
                                            <button className="join-button" onClick={handleJoinClick}>
                                                Join
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Sheet.Content>
                        </Sheet.Container>
                        <Sheet.Backdrop />
                    </Sheet>
                </div>
            </div>



            <div className="desktop-view">
                <div className="menu-desktop">
                    <div>
                        <SideNavbar />
                    </div>
                    <div className="home-screen phone-container-home font">
                        <Header />
                        <div className="live-events-container">
                            <div className="events-container">

                                <div
                                    key={events.event_id}
                                    className="live-events-card"
                                    onClick={() => handleCardClick(events.event_id, events.event_url)}
                                >
                                    <div className="carousel-container">
                                        <Carousel
                                            showThumbs={false}
                                            showStatus={false}
                                            infiniteLoop
                                            useKeyboardArrows
                                            autoPlay
                                            interval={2000}
                                        >
                                            <div>
                                                <img src={events.poster_thumbnail} alt="Slide 1" />
                                            </div>
                                            <div>
                                                <img src={events.poster_thumbnail} alt="Slide 2" />
                                            </div>
                                            <div>
                                                <img src={events.poster_thumbnail} alt="Slide 3" />
                                            </div>
                                        </Carousel>
                                        <div className="live-button-container">
                                            <div className="live-button">
                                                <div className="live-dot"></div>
                                                Live
                                            </div>
                                        </div>
                                        <div className="conference-title">
                                            <img
                                                className="card_heading_image"
                                                src={events.att_app_logo}
                                                alt=""
                                            />
                                            <h3 className="home_card_header">{events.name}</h3>
                                        </div>
                                    </div>

                                    <div className="event-details">
                                        <div className="details">
                                            <p className="det1">
                                                <span>
                                                    <img
                                                        src="/assets/Address.svg"
                                                        alt="Address Icon"
                                                        className="small-icon"
                                                    />
                                                </span>
                                                <span>Address: {events.city}</span>
                                            </p>
                                            <p className="det1">
                                                <span>
                                                    <img
                                                        src="/assets/calendar-1.svg"
                                                        alt="Date Icon"
                                                        className="small-icon"
                                                    />
                                                </span>
                                                <span>
                                                    {events.start_date} - {events.end_date}
                                                </span>
                                            </p>
                                            <p className="det1">
                                                <span>
                                                    <img
                                                        src="/assets/clock-rewind.svg"
                                                        alt="Time Icon"
                                                        className="small-icon"
                                                    />
                                                </span>
                                                <span>Time: {events.start_time}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {/* ))} */}

                                <div className="live-events-card" onClick={handleCardClick}>
                                    <div className="carousel-container">
                                        <Carousel
                                            showThumbs={false}
                                            showStatus={false}
                                            infiniteLoop
                                            useKeyboardArrows
                                            autoPlay
                                            interval={2000}
                                        >
                                            <div>
                                                <img src="assets/blue.jpeg" alt="Slide 1" />
                                            </div>
                                            <div>
                                                <img src="assets/blue.jpeg" alt="Slide 2" />
                                            </div>
                                            <div>
                                                <img src="assets/blue.jpeg" alt="Slide 3" />
                                            </div>
                                        </Carousel>
                                        <div className="live-button-container">
                                            <div className="live-button">
                                                <div className="live-dot"></div>
                                                Live
                                            </div>
                                        </div>
                                        <div className="conference-title">
                                            <img className="card_heading_image" src="assets/ely.png" alt="" />
                                            <h3 className="home_card_header">
                                                International Conference <br /> for Latest Developments
                                                <br /> in Communication Technologies (2024)
                                            </h3>
                                        </div>
                                    </div>

                                    <div className="event-details">
                                        <div className="details">
                                            <p className="det1">
                                                <span>
                                                    <img
                                                        src="/assets/Address.svg"
                                                        alt="Address Icon"
                                                        className="small-icon"
                                                    />
                                                </span>
                                                <span>Address: 123 Event St, City, Country</span>
                                            </p>
                                            <p className="det1">
                                                <span>
                                                    <img
                                                        src="/assets/calendar-1.svg"
                                                        alt="Date Icon"
                                                        className="small-icon"
                                                    />
                                                </span>
                                                <span>Date: September 10, 2024</span>
                                            </p>
                                            <p className="det1">
                                                <span>
                                                    <img
                                                        src="/assets/clock-rewind.svg"
                                                        alt="Time Icon"
                                                        className="small-icon"
                                                    />
                                                </span>
                                                <span>Time: 10:00 AM - 2:00 PM</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>


                                <div
                                    key={events.event_id}
                                    className="live-events-card"
                                    onClick={() => handleCardClick(events.event_id, events.event_url)}
                                >
                                    <div className="carousel-container">
                                        <Carousel
                                            showThumbs={false}
                                            showStatus={false}
                                            infiniteLoop
                                            useKeyboardArrows
                                            autoPlay
                                            interval={2000}
                                        >
                                            <div>
                                                <img src={events.poster_thumbnail} alt="Slide 1" />
                                            </div>
                                            <div>
                                                <img src={events.poster_thumbnail} alt="Slide 2" />
                                            </div>
                                            <div>
                                                <img src={events.poster_thumbnail} alt="Slide 3" />
                                            </div>
                                        </Carousel>
                                        <div className="live-button-container">
                                            <div className="live-button">
                                                <div className="live-dot"></div>
                                                Live
                                            </div>
                                        </div>
                                        <div className="conference-title">
                                            <img
                                                className="card_heading_image"
                                                src={events.att_app_logo}
                                                alt=""
                                            />
                                            <h3 className="home_card_header">{events.name}</h3>
                                        </div>
                                    </div>

                                    <div className="event-details">
                                        <div className="details">
                                            <p className="det1">
                                                <span>
                                                    <img
                                                        src="/assets/Address.svg"
                                                        alt="Address Icon"
                                                        className="small-icon"
                                                    />
                                                </span>
                                                <span>Address: {events.city}</span>
                                            </p>
                                            <p className="det1">
                                                <span>
                                                    <img
                                                        src="/assets/calendar-1.svg"
                                                        alt="Date Icon"
                                                        className="small-icon"
                                                    />
                                                </span>
                                                <span>
                                                    {events.start_date} - {events.end_date}
                                                </span>
                                            </p>
                                            <p className="det1">
                                                <span>
                                                    <img
                                                        src="/assets/clock-rewind.svg"
                                                        alt="Time Icon"
                                                        className="small-icon"
                                                    />
                                                </span>
                                                <span>Time: {events.start_time}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {/* ))} */}



                            </div>


                            <div className="events-container">

                                <div
                                    key={events.event_id}
                                    className="live-events-card"
                                    onClick={() => handleCardClick(events.event_id, events.event_url)}
                                >
                                    <div className="carousel-container">
                                        <Carousel
                                            showThumbs={false}
                                            showStatus={false}
                                            infiniteLoop
                                            useKeyboardArrows
                                            autoPlay
                                            interval={2000}
                                        >
                                            <div>
                                                <img src={events.poster_thumbnail} alt="Slide 1" />
                                            </div>
                                            <div>
                                                <img src={events.poster_thumbnail} alt="Slide 2" />
                                            </div>
                                            <div>
                                                <img src={events.poster_thumbnail} alt="Slide 3" />
                                            </div>
                                        </Carousel>
                                        <div className="live-button-container">
                                            <div className="live-button">
                                                <div className="live-dot"></div>
                                                Live
                                            </div>
                                        </div>
                                        <div className="conference-title">
                                            <img
                                                className="card_heading_image"
                                                src={events.att_app_logo}
                                                alt=""
                                            />
                                            <h3 className="home_card_header">{events.name}</h3>
                                        </div>
                                    </div>

                                    <div className="event-details">
                                        <div className="details">
                                            <p className="det1">
                                                <span>
                                                    <img
                                                        src="/assets/Address.svg"
                                                        alt="Address Icon"
                                                        className="small-icon"
                                                    />
                                                </span>
                                                <span>Address: {events.city}</span>
                                            </p>
                                            <p className="det1">
                                                <span>
                                                    <img
                                                        src="/assets/calendar-1.svg"
                                                        alt="Date Icon"
                                                        className="small-icon"
                                                    />
                                                </span>
                                                <span>
                                                    {events.start_date} - {events.end_date}
                                                </span>
                                            </p>
                                            <p className="det1">
                                                <span>
                                                    <img
                                                        src="/assets/clock-rewind.svg"
                                                        alt="Time Icon"
                                                        className="small-icon"
                                                    />
                                                </span>
                                                <span>Time: {events.start_time}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {/* ))} */}

                                <div className="live-events-card" onClick={handleCardClick}>
                                    <div className="carousel-container">
                                        <Carousel
                                            showThumbs={false}
                                            showStatus={false}
                                            infiniteLoop
                                            useKeyboardArrows
                                            autoPlay
                                            interval={2000}
                                        >
                                            <div>
                                                <img src="assets/blue.jpeg" alt="Slide 1" />
                                            </div>
                                            <div>
                                                <img src="assets/blue.jpeg" alt="Slide 2" />
                                            </div>
                                            <div>
                                                <img src="assets/blue.jpeg" alt="Slide 3" />
                                            </div>
                                        </Carousel>
                                        <div className="live-button-container">
                                            <div className="live-button">
                                                <div className="live-dot"></div>
                                                Live
                                            </div>
                                        </div>
                                        <div className="conference-title">
                                            <img className="card_heading_image" src="assets/ely.png" alt="" />
                                            <h3 className="home_card_header">
                                                International Conference <br /> for Latest Developments
                                                <br /> in Communication Technologies (2024)
                                            </h3>
                                        </div>
                                    </div>

                                    <div className="event-details">
                                        <div className="details">
                                            <p className="det1">
                                                <span>
                                                    <img
                                                        src="/assets/Address.svg"
                                                        alt="Address Icon"
                                                        className="small-icon"
                                                    />
                                                </span>
                                                <span>Address: 123 Event St, City, Country</span>
                                            </p>
                                            <p className="det1">
                                                <span>
                                                    <img
                                                        src="/assets/calendar-1.svg"
                                                        alt="Date Icon"
                                                        className="small-icon"
                                                    />
                                                </span>
                                                <span>Date: September 10, 2024</span>
                                            </p>
                                            <p className="det1">
                                                <span>
                                                    <img
                                                        src="/assets/clock-rewind.svg"
                                                        alt="Time Icon"
                                                        className="small-icon"
                                                    />
                                                </span>
                                                <span>Time: 10:00 AM - 2:00 PM</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>


                                <div
                                    key={events.event_id}
                                    className="live-events-card"
                                    onClick={() => handleCardClick(events.event_id, events.event_url)}
                                >
                                    <div className="carousel-container">
                                        <Carousel
                                            showThumbs={false}
                                            showStatus={false}
                                            infiniteLoop
                                            useKeyboardArrows
                                            autoPlay
                                            interval={2000}
                                        >
                                            <div>
                                                <img src={events.poster_thumbnail} alt="Slide 1" />
                                            </div>
                                            <div>
                                                <img src={events.poster_thumbnail} alt="Slide 2" />
                                            </div>
                                            <div>
                                                <img src={events.poster_thumbnail} alt="Slide 3" />
                                            </div>
                                        </Carousel>
                                        <div className="live-button-container">
                                            <div className="live-button">
                                                <div className="live-dot"></div>
                                                Live
                                            </div>
                                        </div>
                                        <div className="conference-title">
                                            <img
                                                className="card_heading_image"
                                                src={events.att_app_logo}
                                                alt=""
                                            />
                                            <h3 className="home_card_header">{events.name}</h3>
                                        </div>
                                    </div>

                                    <div className="event-details">
                                        <div className="details">
                                            <p className="det1">
                                                <span>
                                                    <img
                                                        src="/assets/Address.svg"
                                                        alt="Address Icon"
                                                        className="small-icon"
                                                    />
                                                </span>
                                                <span>Address: {events.city}</span>
                                            </p>
                                            <p className="det1">
                                                <span>
                                                    <img
                                                        src="/assets/calendar-1.svg"
                                                        alt="Date Icon"
                                                        className="small-icon"
                                                    />
                                                </span>
                                                <span>
                                                    {events.start_date} - {events.end_date}
                                                </span>
                                            </p>
                                            <p className="det1">
                                                <span>
                                                    <img
                                                        src="/assets/clock-rewind.svg"
                                                        alt="Time Icon"
                                                        className="small-icon"
                                                    />
                                                </span>
                                                <span>Time: {events.start_time}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {/* ))} */}



                            </div>
                        </div>

                        {showAnnouncement && (
                            <AnnouncementOverlay onClose={handleAnnouncementClose} />
                        )}

                        <Sheet isOpen={isOpen} onClose={() => setOpen(false)} snapPoints={[0.5]}>
                            <Sheet.Container style={{ height: "auto", maxHeight: "40%" }}>
                                <Sheet.Header />
                                <Sheet.Content>
                                    <div className="cont">
                                        <div className="otp-section otp-section-card">
                                            <p className="acc">Enter event access code</p>
                                            <div className="otp-inputs">
                                                {otp.map((value, index) => (
                                                    <React.Fragment key={index}>
                                                        <input
                                                            ref={(el) => (inputRefs.current[index] = el)}
                                                            type="text"
                                                            maxLength={1}
                                                            className="hotp"
                                                            value={value}
                                                            onChange={(e) => handleOtpChange(index, e.target.value)}
                                                            onKeyDown={(e) => handleKeyDown(index, e)}
                                                        />
                                                        {(index + 1) % 3 === 0 && index < otp.length - 1 && (
                                                            <span className="hyphen homehyphen">-</span>
                                                        )}
                                                    </React.Fragment>
                                                ))}
                                            </div>
                                            <br />
                                            <br />
                                            <div className="home_join">
                                                <button className="join-button" onClick={handleJoinClick}>
                                                    Join
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Sheet.Content>
                            </Sheet.Container>
                            <Sheet.Backdrop />
                        </Sheet>
                    </div>
                </div>
            </div>

        </>
    );
};

export default MyEvent;