import React, { useState, useEffect } from "react";
import { ModuleItem } from "./CustomModulesTypes";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { getModule } from "../../crud/module.crud";
import { useEvent } from "../../contexts/EventContext";
import ModuleHeading from "../../CommomComponents/ModuleHeading/ModuleHeading";
import { spinnerStyle } from "../../config/app";
import { ClipLoader } from "react-spinners";
import Footer from "../Footer/Footer";
import DocumentViewer from "./DocumentViewer";
import SideNavbar from "../SideNavbar/SideNavbar";

const ModuleItemCard: React.FC = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const { eventDetails } = useEvent();
	const { moduleId, itemId } = useParams();

	const data = location.state || null;
	const [itemData, setItemData] = useState(data);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (!data) {
			fetchItem();
		}
	}, []);

	const fetchItem = async () => {
		setLoading(true);
		try {
			const modules = await getModule(eventDetails?.event_id, moduleId || "");
			setItemData(
				modules.data.find((moduleItem: ModuleItem) => moduleItem.item_id === itemId)
			);
			setLoading(false);
		} catch (err) {
			console.log(err);
			setLoading(false);
		}
	};

	const navigateToModuleItems = () => {
		navigate(`/m/${moduleId}`);
	};

	return (
		<>
			<div className="mobile-view">
				<div className="search-page-container">
					<div>
						<div className="en">
							<ModuleHeading moduleHeading={itemData?.name} />
						</div>
						{loading ? (
							<div style={spinnerStyle}>
								<ClipLoader
									loading={loading}
									size={50}
									aria-label="Loading Spinner"
								/>
							</div>
						) : (
							<div className="module-item">
								<DocumentViewer
									url={itemData?.item_url}
									navigateToModuleItems={navigateToModuleItems}
								/>
							</div>
						)}
						<Footer />
					</div>
				</div>
			</div>
			<div className="desktop-view">
				<div className="desktop-view-side">
					<div className="desktop-left-slider">
						<SideNavbar />
					</div>
					<div className="home-screens desktop-left-container font">
						<div className="">
							<ModuleHeading moduleHeading={itemData?.name} />
						</div>
						{loading ? (
							<div className="loader-container" style={spinnerStyle}>
								<ClipLoader
									color="#4A90E2"
									loading={loading}
									size={50}
									aria-label="Loading Spinner"
								/>
							</div>
						) : (
							<div className="module-desktop-view-container">
								<div className="module-item module-desktop-view">
									<DocumentViewer
										url={itemData?.item_url}
										navigateToModuleItems={navigateToModuleItems}
									/>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
			<style>{`
				.module-desktop-view-container {
					text-align: center;
				}
				.module-desktop-view {
					width: 65%;
					display: inline-block;
				}
            `}</style>
		</>
	);
};

export default ModuleItemCard;
