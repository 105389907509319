import React, { useState } from 'react';
import { Modal, Button, TextField, Box, Typography } from '@mui/material';
import './AddSocialMediaModal.css';

interface AddSocialMediaModalProps {
  isOpen: boolean;
  onClose: () => void;
  platform: string;
  onAdd: (url: string) => void;
}

const AddSocialMediaModal: React.FC<AddSocialMediaModalProps> = ({ isOpen, onClose, platform, onAdd }) => {
  const [newUrl, setNewUrl] = useState('');

  const handleAdd = () => {
    onAdd(newUrl);
    setNewUrl('');
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{
        '& .MuiModal-paper': {
          border: 'none',  
          backgroundColor: 'rgb(0 0 0 / 18%);', 
          justifyContent:'center'
        },
      }}

     
      aria-labelledby="add-social-media-title"
      aria-describedby="add-social-media-description"
      BackdropProps={{
        style: { backgroundColor: 'rgba(0, 0, 0, 0.2)' }, // Semi-transparent black background
      }}
    >
      <Box className="add-modal-content">
        <Typography id="add-social-media-title" variant="h6" className="edit-modal-titles">
          Add {platform}
        </Typography>
        <Typography id="add-social-media-description" className="yu">
          URL
        </Typography>
        <TextField
          fullWidth 
          value={newUrl}
          onChange={(e) => setNewUrl(e.target.value)}
          placeholder={`Enter ${platform} URL`}
        />
        <div className="edit-modal-actions">
          <Button className="edit-modal-button cancel" onClick={onClose}>Cancel</Button>
          <Button
            className={`edit-modal-button add ${newUrl ? 'active-add-button' : ''}`}
            sx={{ color: "white", backgroundColor: newUrl ? "#002E6E" : "grey" }}
            onClick={handleAdd}
            disabled={!newUrl}
          >
            Add 
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default AddSocialMediaModal;
