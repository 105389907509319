import React, { useState } from 'react';
import QuizCard from '../Quizcard/Quizcard';         // Live quiz card
import ExpiredQuizCard from '../ExpiredQuizCard/ExpiredQuizCard'; // Expired quiz card
import './QuizList.css';

const QuizList = () => {
  const [activeTab, setActiveTab] = useState('live');

  return (
    <div className="quiz-list">
      {/* Tabs for switching between live and expired quizzes */}
      <div className="quiz_tabs">
        <span
          className={activeTab === 'live' ? 'active' : ''}
          onClick={() => setActiveTab('live')}
        >
          Live Quizzes
        </span>
        <span
          className={activeTab === 'expired' ? 'active' : ''}
          onClick={() => setActiveTab('expired')}
        >
          Expired Quizzes
        </span>
      </div>

      {/* Render different quizzes based on the active tab */}
      <div className="quizzes-container">
        {activeTab === 'live' ? (
          <>
            {/* Live Quizzes cards */}
            <QuizCard />
            <QuizCard />
            <QuizCard />
          </>
        ) : (
          <>
            {/* Expired Quizzes cards */}
            <ExpiredQuizCard />
            <ExpiredQuizCard />
            <ExpiredQuizCard />
          </>
        )}
      </div>
    </div>
  );
};

export default QuizList;
