import React, { useState, useEffect } from 'react';
import { Modal, Box, TextField, Button, Typography } from '@mui/material';
import './EditSocialMediaModal.css';

interface EditSocialMediaModalProps {
  isOpen: boolean;
  onClose: () => void;
  platform: string;
  initialUrl: string;
  onSave: (url: string) => void;
}

const EditSocialMediaModal: React.FC<EditSocialMediaModalProps> = ({
  isOpen,
  onClose,
  platform,
  initialUrl,
  onSave,
}) => {
  const [url, setUrl] = useState(initialUrl);
  const [isUrlChanged, setIsUrlChanged] = useState(false);

  useEffect(() => {
    setUrl(initialUrl);
    setIsUrlChanged(false);
  }, [initialUrl]);

  const handleUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(event.target.value);
    setIsUrlChanged(true);
  };

  const handleSave = () => {
    onSave(url);
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="edit-modal-title"
      aria-describedby="edit-modal-description"
      BackdropProps={{
        style: { backgroundColor: 'rgba(0, 0, 0, 0.2)' }, // Semi-transparent black background
      }}
    >
      <Box className="edit-modal-content">
        <Typography variant="h6" className="edit-modal-titles" id="edit-modal-title">
          Edit {platform}
        </Typography>
        <Typography variant="body1" className="edit-modal-subtitle">
          URL
        </Typography>
        <TextField
          fullWidth
          value={url}
          onChange={handleUrlChange}
          className="edit-modal-input"
        />
        <div className="edit-modal-actions">
          <Button onClick={onClose} className="edit-modal-button cancel">
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            className={`edit-modal-button save ${isUrlChanged ? 'active' : ''}`}
          >
            Save
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default EditSocialMediaModal;
