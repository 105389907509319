import React from "react";
import { TABS } from "../../../config/app";

interface TabsNavigationProps {
	activeTab: string;
	setActiveTab: (tab: string) => void;
	countMetrics: any;
}

const TabsNavigation: React.FC<TabsNavigationProps> = ({
	activeTab,
	setActiveTab,
	countMetrics
}) => {
	const handleTabSwitch = (tab: string) => {
		setActiveTab(tab);
	};

	return (
		<nav className="network_tabs">
			{Object.values(TABS).map((tab) => (
				<button
					key={tab}
					className={`tab_network ${activeTab === tab ? "active" : ""}`}
					onClick={() => handleTabSwitch(tab)}
				>
					{tab}
					{tab === TABS.RECEIVED && countMetrics?.received_count > 0 && <span style={{ marginLeft: '8px' }}>({countMetrics?.received_count})</span>}
					{tab === TABS.MY_NETWORK && countMetrics?.connected_count > 0 && <span style={{ marginLeft: '8px' }}>({countMetrics?.connected_count})</span>}
				</button>
			))}
		</nav>
	);
};

export default TabsNavigation;
