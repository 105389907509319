import React from "react";
import "./MeetingDetails.css";
import { useEvent } from "../../../contexts/EventContext";
import moment from "moment";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import { timeZoneAbbreviations } from "../../../config/app";
import Accepted from "../../../../public/assets/meeting-attendee-accepted.svg";
import Rejected from "../../../../public/assets/meeting-attendee-rejected.svg";
import { updateMeetingAttendeeStatus } from "../../../crud/meetings.crud";
import { getISTDateNTime } from "../../../reusable/common";
import { Icon } from "@iconify/react/dist/iconify.js";

interface MeetingDetailsProps {
	meetingData?: any;
	isRequestsTab?: boolean;
	activeRequestsTab: string;
	fetchMeetings: (date: string) => void;
	activeTab: string;
	selectedDate: any;
}

const SmallAvatar = styled(Avatar)(({ theme }) => ({
	width: 22,
	height: 22
}));

const MeetingDetails: React.FC<MeetingDetailsProps> = ({
	meetingData,
	isRequestsTab,
	activeRequestsTab,
	fetchMeetings,
	activeTab,
	selectedDate
}) => {
	const attendeeId = localStorage.getItem("attendeeId");
	const { eventDetails } = useEvent();

	let startDate = moment(
		new Date(getISTDateNTime(meetingData?.start_timestamp, eventDetails?.time_zone))
	).format("dddd, ll");

	let startTime = moment(
		new Date(getISTDateNTime(meetingData?.start_timestamp, eventDetails?.time_zone))
	).format("LT");
	let endTime = moment(
		new Date(getISTDateNTime(meetingData?.end_timestamp, eventDetails?.time_zone))
	).format("LT");
	const timeZoneAbbreviation =
		timeZoneAbbreviations[eventDetails.time_zone] || eventDetails.time_zone;

	const updateStatus = async (status: number) => {
		try {
			const response = await updateMeetingAttendeeStatus(
				eventDetails?.event_id,
				meetingData?.id,
				status
			);
			if (response?.status === 200) {
				fetchMeetings(selectedDate);
			}
		} catch (error) {
			console.log("error", error);
		}
	};

	const shouldShowRejectIgnore =
		meetingData?.attendees?.filter(
			(each: any) => each?.attendee_id === attendeeId && each?.status === 1
		)?.length > 0 && meetingData?.created_by !== attendeeId
			? true
			: false;

	return (
		<>
			<div className="meeting-details-wrapper">
				<div className="mt-4">
					<div className="d-flex align-items-center">
						<div
							className="meeting-box-bgColor"
							style={{ backgroundColor: meetingData?.default_color }}
						></div>
						<div className="meeting-title ms-2">{meetingData?.title || ""}</div>
					</div>
					<div className="meeting-date-time mt-1">
						<div>{`${startDate} ${startTime} - ${endTime} (${timeZoneAbbreviation})`}</div>
					</div>
				</div>
				{(meetingData?.meeting_live_link || meetingData?.location) && (
					<div className="mt-3 meeting-location-heading-wrapper">
						<div className="meeting-location-heading">
							{meetingData?.meeting_live_link ? "Meeting Link" : "Location"}
						</div>

						{meetingData?.meeting_live_link ? (
							<div
								role="button"
								className="mt-1 meeting-link-url"
								onClick={() => {
									window.open(`${meetingData?.meeting_live_link}`, "_blank");
								}}
							>
								{meetingData?.meeting_live_link || ""}
							</div>
						) : (
							<div className="mt-1 meeting-no-guests-text">
								{meetingData?.location || ""}
							</div>
						)}
					</div>
				)}

				{meetingData?.attendees?.length > 0 && (
					<>
						<div className="mt-3">
							<div className="d-flex align-items-center">
								<Icon
									icon={`material-symbols-light:group-outline`}
									width={"22px"}
									height={"22px"}
									className="meeting-icons-color"
								/>
								<div className="ms-2 meeting-no-guests-text">{`${meetingData?.attendees?.length} People`}</div>
							</div>
							<div className="mt-3" />
							{meetingData?.attendees?.map((each: any) => (
								<div className="mb-1 d-flex align-items-center meeeting-guest-name-wrapper">
									<Badge
										overlap="circular"
										anchorOrigin={{
											vertical: "bottom",
											horizontal: "right"
										}}
										badgeContent={
											each.status === 2 ? (
												<SmallAvatar
													alt="Rejected"
													src={Rejected}
													sx={{ width: 12, height: 12 }}
												/>
											) : each.status === 1 ? (
												<SmallAvatar
													alt="Accepted"
													src={Accepted}
													sx={{ width: 12, height: 12 }}
												/>
											) : (
												<></>
											)
										}
									>
										<Avatar
											sx={{ width: 25, height: 25, fontSize: "12px" }}
											alt={each?.name}
											src={each?.image_url || ""}
										>
											{each?.name?.substring(0, 1).toUpperCase() || ""}
										</Avatar>
									</Badge>
									{/* <div className="meeeting-guest-name-image d-flex align-items-center justify-content-center">
										<div className="meeeting-guest-name-text">
											{each?.att_name?.substring(0, 1).toUpperCase() || ""}
										</div>
									</div> */}
									<div className="ms-2 meeting-details-attendee-name">
										{`${each?.name || ""} ${each?.attendee_id === meetingData?.created_by ? "(Organizer)" : ""}`}
									</div>
								</div>
							))}
						</div>
					</>
				)}

				{meetingData?.description && (
					<div className="mt-3 d-flex align-items-start">
						<Icon
							icon={`material-symbols-light:sort`}
							width={"22px"}
							height={"22px"}
							className="meeting-icons-color meeting-details-description-icon"
						/>
						<div className="text-start ms-2 meeting-details-description-text">
							{meetingData?.description || ""}
						</div>
					</div>
				)}

				{meetingData?.attachments?.length > 0 && (
					<div className="mt-3 d-flex align-items-start">
						<Icon
							icon={`material-symbols-light:attach-file`}
							width={"18px"}
							height={"18px"}
							className="meeting-icons-color meeting-details-attachment-icon me-2 mb-2"
						/>
						<>
							<div className="row px-2 meeting-details-attachment-root">
								{meetingData?.attachments?.map((each: any) => (
									<div
										role="button"
										className="mb-3 col-12 d-flex justify-content-center align-items-center flex-column meeting-details-attachment-item me-2"
										onClick={() => {
											window.open(each, "_blank", "noreferrer");
										}}
									>
										<div className="meeting-details-attachment-item-text">
											{each?.split("/")?.pop() || ""}
										</div>
									</div>
								))}
							</div>
						</>
					</div>
				)}
			</div>

			{isRequestsTab && (
				<div className="meeting-modal-action-footer">
					<div className="d-flex justify-content-end align-items-center h-100">
						{((activeTab === "upcoming" && shouldShowRejectIgnore) ||
							(activeTab !== "upcoming" && activeTab !== "completed" && activeRequestsTab !== "rejected")) && (
							<div
								role="button"
								className="meeting-action text-center d-flex justify-content-center align-items-center me-4"
								onClick={() => {
									updateStatus(2);
								}}
							>
								Reject
							</div>
						)}

						{((activeTab === "upcoming" && shouldShowRejectIgnore) ||
							(activeTab !== "upcoming" && activeTab !== "completed" && activeRequestsTab !== "ignored")) && (
							<div
								role="button"
								className="meeting-action text-center d-flex justify-content-center align-items-center me-4"
								onClick={() => {
									updateStatus(3);
								}}
							>
								Ignore
							</div>
						)}

						{(activeRequestsTab === "rejected" ||
							activeRequestsTab === "requests" ||
							activeRequestsTab === "ignored") &&
							activeTab !== "upcoming" && activeTab !== "completed" && (
								<div
									role="button"
									className="meeting-action meeting-action-accept text-center d-flex justify-content-center align-items-center"
									onClick={() => {
										updateStatus(1);
									}}
								>
									Accept
								</div>
							)}
					</div>
				</div>
			)}
		</>
	);
};

export default MeetingDetails;
