import api from "./../api";

const EVENT_PUBLIC = "/event/public/";

// Get Event Details
export const getEventDetails = (domain: string) => {
    return api.get(
        EVENT_PUBLIC + (domain) + "?source=2"
      );
}
