import React, { useState } from "react";
import "./Survey.css";
import Footer from "../Footer/Footer";
import { useNavigate, useParams } from "react-router-dom";
import SideNavbar from "../SideNavbar/SideNavbar";
import NotificationModal from "../Notification/NotificationModal/NotificationModal";
import { Bell } from "lucide-react";
import { Header } from "../Header/Header";

interface Option {
  value: string;
  label: string;
}

const options: Option[] = [
  { value: "excellent", label: "Excellent" },
  { value: "good", label: "Good" },
  { value: "fair", label: "Fair" },
  { value: "poor", label: "Poor" },
];

const SurveyQuestion: React.FC = () => {
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);

  const handleOptionSelect = (value: string) => {
    setSelectedOption(value);
  };

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  const handleOpen = () => {
    setShowModal(true);
  };

  const handleClose = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      setShowModal(false);
    }
  };

  return (
    <div>
      <div className="desktop-view">
        <div className="desktop-view-side">
          <div>
            <SideNavbar />
          </div>
          <div className="desktop-left-container">
            <Header />
            {showModal && (
              <div
                onClick={handleClose}
                style={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-start',
                  zIndex: 1000
                }}
              >
                <div
                  onClick={(e) => e.stopPropagation()}
                  style={{
                    width: '400px',
                    maxHeight: '80vh',
                    overflow: 'auto',
                    backgroundColor: 'white',
                    marginTop: '60px',
                    marginRight: '20px',
                    borderRadius: '8px',
                    padding: '20px',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
                  }}
                >
                  <NotificationModal />
                </div>
              </div>
            )}
            <div className="survey-question-desktop">
              {/* <div className="en">
                <div>
                  <img
                    className="left_uparrow search_left_arrow"
                    src="/assets/uparrow.svg"
                    alt="Go Back"
                    onClick={goBack}
                  />{" "}
                </div>
                <div>
                  <span className="common-event-title">ELYSIAN - 2024</span>
                </div>
              </div> */}
              <h2 className="survey_rate">Rate overall event experience</h2>
              <p className="survey_text">You can select one answer</p>
              <div className="survey_options-grid">
                {options.map((option) => (
                  <button
                    key={option.value}
                    className={`option ${selectedOption === option.value ? "selected" : ""
                      }`}
                    onClick={() => handleOptionSelect(option.value)}
                  >
                    {option.label}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="phone-view">
        <div className="survey-question">
          <div className="en">
            <div>
              <img
                className="left_uparrow search_left_arrow"
                src="/assets/uparrow.svg"
                alt="Go Back"
                onClick={goBack}
              />{" "}
            </div>
            <div>
              <span className="common-event-title">ELYSIAN - 2024</span>
            </div>
          </div>
          <h2 className="survey_rate">Rate overall event experience</h2>
          <p className="survey_text">You can select one answer</p>
          <div className="survey_options-grid">
            {options.map((option) => (
              <button
                key={option.value}
                className={`option ${selectedOption === option.value ? "selected" : ""
                  }`}
                onClick={() => handleOptionSelect(option.value)}
              >
                {option.label}
              </button>
            ))}
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <Footer eventId="1" eventName="Tech Conference" />
      </div>
    </div>
  );
};

export default SurveyQuestion;
