import { useState, useEffect, useCallback } from "react";

interface UsePWAInstallPromptReturn {
	isInstallPromptSupported: boolean;
	showInstallPrompt: () => void;
	isAppInstalled: boolean;
}

export const usePWAInstallPrompt = (): UsePWAInstallPromptReturn => {
	const [isInstallPromptSupported, setIsInstallPromptSupported] = useState(false);
	const [deferredPrompt, setDeferredPrompt] = useState<Event | null>(null);
	const [isAppInstalled, setIsAppInstalled] = useState(false);

	useEffect(() => {
		const checkIfAppIsInstalled = () => {
			const isInstalled =
				window.matchMedia("(display-mode: standalone)").matches ||
				(navigator as any).standalone;

			setIsAppInstalled(isInstalled);
		};

		checkIfAppIsInstalled();
	}, []);

	useEffect(() => {
		const handleBeforeInstallPrompt = (event: Event) => {
			event.preventDefault();
			setDeferredPrompt(event);

			setIsInstallPromptSupported(true);
		};
		window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

		return () => {
			window.removeEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
		};
	}, []);

	const showInstallPrompt = useCallback(() => {
		if (deferredPrompt) {
			(deferredPrompt as any).prompt();
			(deferredPrompt as any).userChoice.then(() => {
				setDeferredPrompt(null);
				setIsInstallPromptSupported(false);
			});
		}
	}, [deferredPrompt]);

	return { isInstallPromptSupported, showInstallPrompt, isAppInstalled };
};
