import React, { useState } from "react";
import "./Address.css";
import Footer from "../Footer/Footer";
import VenueCard from "./VenueCard/VenueCard";
import SideNavbar from "../SideNavbar/SideNavbar";
import { Header } from "../Header/Header";
import { useEvent } from "../../contexts/EventContext";
import { useNavigate } from "react-router-dom";
import ModuleHeading from "../../CommomComponents/ModuleHeading/ModuleHeading";
import ModuleDescription from "../../CommomComponents/ModuleDescription/ModuleDescription";
import { getModuleDescriptionByName, getModuleNameByType } from "../../reusable/common";
import { useModule } from "../../contexts/ModuleContext";

const AddressPage: React.FC = () => {
	const navigate = useNavigate();
	const { modulesDetails } = useModule()
	const { eventDetails } = useEvent();
	const [event, setEvents] = useState(eventDetails);

	const goBack = () => {
		navigate("/");
	};
	const moduleHeading =getModuleNameByType(modulesDetails,5);

	return (
		<>
			<div className="phone-view">
				<div className="address-page-container">
					<div>
						<ModuleHeading
							moduleHeading={moduleHeading}
							goBack={() => {
								goBack();
							}}
						/>
						<ModuleDescription description={getModuleDescriptionByName(modulesDetails, moduleHeading)} />

						<div className="en">
							{/* <div className="common-event-title">{event?.event_short_name}</div> */}
						</div>
						<div className="add_ress">
							<VenueCard
								name={event.name}
								address={event.venue}
								rating={4}
								maxRating={5}
								imageUrl={event.poster_thumbnail}
								latitude={event.latitude}
								longitude={event.longitude}
							/>
						</div>

						<Footer />
					</div>
				</div>
			</div>

			{/* desktop container */}

			<div className="desktop-view">
				<div className="menu-desktop">
					<div>
						<SideNavbar />
					</div>
					<div className="address-page-container-desktop">
						<div>
							<ModuleHeading
								moduleHeading={moduleHeading}
								goBack={() => {
									goBack();
								}}
							/>
							<ModuleDescription description={getModuleDescriptionByName(modulesDetails, moduleHeading)} />

							<div className="add_ress">
								<VenueCard
									name={event.name}
									address={event.venue}
									rating={4}
									maxRating={5}
									imageUrl={event.poster_thumbnail}
									latitude={event.latitude}
									longitude={event.longitude}
								/>
							</div>

							{/* <Footer /> */}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default AddressPage;
