import React from "react";
import "./MeetingsCard.css";
import { getISTDateNTime } from "../../../reusable/common";
import { useEvent } from "../../../contexts/EventContext";
import moment from "moment";
import { timeZoneAbbreviations } from "../../../config/app";
import { updateMeetingAttendeeStatus } from "../../../crud/meetings.crud";

interface MeetingsCardProps {
	onClickMeetingCard: (val: any) => void;
	data: any;
	position: number;
	isRequestsTab: boolean;
	activeRequestsTab: string;
	fetchMeetings: (val: any) => void;
	activeTab: string;
	selectedDate: any;
}

const MeetingsCard: React.FC<MeetingsCardProps> = ({
	onClickMeetingCard,
	data,
	position,
	isRequestsTab,
	activeRequestsTab,
	fetchMeetings,
	activeTab,
	selectedDate
}) => {
	const attendeeId = localStorage.getItem("attendeeId");
	const { eventDetails } = useEvent();

	let startDate = moment(
		new Date(getISTDateNTime(data?.start_timestamp, eventDetails?.time_zone))
	).format("ll");
	let endDate = moment(
		new Date(getISTDateNTime(data?.end_timestamp, eventDetails?.time_zone))
	).format("ll");
	let startTime = moment(
		new Date(getISTDateNTime(data?.start_timestamp, eventDetails?.time_zone))
	).format("LT");
	let endTime = moment(
		new Date(getISTDateNTime(data?.end_timestamp, eventDetails?.time_zone))
	).format("LT");
	const timeZoneAbbreviation =
		timeZoneAbbreviations[eventDetails.time_zone] || eventDetails.time_zone;

	const startDateTimeStamp = moment(
		new Date(getISTDateNTime(data?.start_timestamp, eventDetails?.time_zone))
	).format("LT");

	const endDateTimeStamp = moment(
		new Date(getISTDateNTime(data?.end_timestamp, eventDetails?.time_zone))
	).format("LT");

	const shouldShowRejectIgnore =
		data?.attendees?.filter(
			(each: any) => each?.attendee_id === attendeeId && each?.status === 1
		)?.length > 0 && data?.created_by !== attendeeId
			? true
			: false;

	const updateStatus = async (status: number) => {
		try {
			const response = await updateMeetingAttendeeStatus(
				eventDetails?.event_id,
				data?.id,
				status
			);
			if (response?.status === 200) {
				fetchMeetings(selectedDate);
			}
		} catch (error) {
			console.log("error", error);
		}
	};

	return (
		<div
			role="button"
			className={`meetings-box-wrapper mt-${position === 0 ? "0" : "4"}`}
			style={{ background: "", borderLeft: `6px solid ${data?.default_color}` }}
			onClick={() => {
				onClickMeetingCard(data);
			}}
		>
			<div className="d-flex align-items-center">
				<div className="row w-100 mx-0">
					<div className="text-start col-12 col-md-6 px-0">
						<div className="meeting-time">
							{isRequestsTab
								? `${startDate} ${startTime} - ${endDate} ${endTime} (${timeZoneAbbreviation})`
								: `${startDateTimeStamp} - ${endDateTimeStamp} (${timeZoneAbbreviation})`}
						</div>
						<div className="meeting-text">{data?.title || ""}</div>
					</div>
					<div className="col-12 col-md-6 px-0">
						{isRequestsTab && (
							<div className="hide-in-mobile h-100">
								<div className="d-flex justify-content-end align-items-center h-100">
									{((activeTab === "upcoming" && shouldShowRejectIgnore) ||
										(activeTab !== "upcoming" && activeTab !== "completed" && activeRequestsTab !== "rejected")) && (
										<div
											className="meeting-action text-center d-flex justify-content-center align-items-center me-4"
											onClick={e => {
												e.stopPropagation();
												updateStatus(2);
											}}
										>
											Reject
										</div>
									)}

									{((activeTab === "upcoming" && shouldShowRejectIgnore) ||
										(activeTab !== "upcoming" && activeTab !== "completed" && activeRequestsTab !== "ignored")) && (
										<div
											className="meeting-action text-center d-flex justify-content-center align-items-center me-4"
											onClick={e => {
												e.stopPropagation();
												updateStatus(3);
											}}
										>
											Ignore
										</div>
									)}

									{(activeRequestsTab === "rejected" ||
										activeRequestsTab === "requests" ||
										activeRequestsTab === "ignored") &&
										activeTab !== "upcoming" && activeTab !== "completed" && (
											<div
												className="meeting-action meeting-action-accept text-center d-flex justify-content-center align-items-center"
												onClick={e => {
													e.stopPropagation();
													updateStatus(1);
												}}
											>
												Accept
											</div>
										)}
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default MeetingsCard;
