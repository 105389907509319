import React from 'react';
import './VCardPage.css';

const VCardPage: React.FC = () => {
  // Static data for the vCard
  const name = "AnonyXi Soe";
  const title = "Investor, Ultrh";
  const phone = "+91 76987 23234";
  const email = "anonyxilsoe@obustudio.com";
  const location = "Mumbai, India";
  const avatar = "assets/lisa.png";

  return (
    <div className="vcard-container">
      <div className="vcard">
        <div className='vacrd_mask'> 
        <div>
        <img src={avatar} alt={name} className="vcard-avatar" />
        </div>
        <div>
            <img src="assets/Mask group.svg" alt="" />
        </div>
        </div>
       
        <div className="vcard-details">
          <div className='vcard_name'>{name}</div>
          <div className='vcard-title'>{title}</div>
          <div  className='vcard-phone1'> <span className='vcard_icon'><img src="assets/phone.svg" alt="" /></span> {phone}</div>
          <div className='vcard-phone'>  <span  className='vcard_icon'><img src="assets/email.svg" alt="" /></span> {email}</div>
          <div className='vcard-phone'>  <span  className='vcard_icon'><img src="assets/locations.svg" alt="" /></span> {location}</div>
          <div className="vcard-icons">
            <img src="assets/icons/linkedin.svg" alt="LinkedIn" />
            <img src="assets/icons/twitter.svg" alt="Twitter" />
            <img src="assets/icons/whatsapp.svg" alt="WhatsApp" />
            <img src="assets/icons/website.svg" alt="Website" />
            <img src="assets/icons/message.svg" alt="Message" />
            <img src="assets/icons/calendar1.svg" alt="Calendar" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VCardPage;
