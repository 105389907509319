import React from "react";
import { ModuleItem } from "./CustomModulesTypes";
import { trackEntity } from "../../crud/tracking.crud";
import { useEvent } from "../../contexts/EventContext";
import { clickTrackingModules } from "../../config/app";

const itemTypes = {
	PDF: 1,
	LINK: 2
};

interface ModuleItemCardProps {
	data: ModuleItem;
	onSelect: (item: ModuleItem) => void;
}

const ModuleItemCard: React.FC<ModuleItemCardProps> = ({ data, onSelect }) => {
	const { eventDetails } = useEvent();
	return (
		<>
			{data.item_type === itemTypes.PDF ? (
				<div
					className="module-item-card"
					onClick={e => {
						e.preventDefault();
						e.stopPropagation();
						trackEntity(
							eventDetails?.event_id,
							clickTrackingModules.CUSTOM_MODULE_ITEM,
							{ id: data.item_id }
						);
						onSelect(data);
					}}
				>
					<div className="module-item-name">{data.name}</div>
					<div className="arrow-icon">&#x203A;</div>
				</div>
			) : (
				<div
					className="module-item-card"
					onClick={e => {
						e.preventDefault();
						e.stopPropagation();
						trackEntity(
							eventDetails?.event_id,
							clickTrackingModules.CUSTOM_MODULE_ITEM,
							{ id: data.item_id }
						);
						window.open(data.item_url, "_blank");
					}}
				>
					<div className="module-item-name">{data.name}</div>
					<div className="arrow-icon">&#x203A;</div>
				</div>
			)}
			<style>{`
                .module-item-card {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 15px;
                    background-color: #f5faff;
                    border-radius: 8px;
                    margin-bottom: 10px;
                    transition: background-color 0.3s;
                    cursor: pointer;
                }

                .module-item-card:hover {
                    background-color: #eaf4ff;
                }

                .module-item-name {
                    font-size: 16px;
                    color: #333;
                }

                .arrow-icon {
                    font-size: 16px;
                    color: #002f6c;
                }
            `}</style>
		</>
	);
};

export default ModuleItemCard;
