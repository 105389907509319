import React, { useState } from 'react';
import { Drawer, Button, TextField, SwipeableDrawer } from '@mui/material';
import { Modal,Box } from '@mui/material';
import './AddSocialMedia.css';

interface AddSocialMediaProps {
  isOpen: boolean;
  onClose: () => void;
  platform: string;
  onAdd: (url: string) => void;
  onOpen: () => void
}

const AddSocialMedia: React.FC<AddSocialMediaProps> = ({ isOpen, onClose, platform, onAdd, onOpen }) => {
  const [newUrl, setNewUrl] = useState('');

  const handleAdd = () => {
    onAdd(newUrl);
    setNewUrl('');
    onClose();

  };
  

  return (
    <>
   
    <SwipeableDrawer
      anchor="bottom"
      open={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      className="add-drawer"
    >
      <div className="add-drawer-content">
        <h2 className='yu'>Add {platform}</h2>
        <p className='yu'>URL</p>
        <TextField
          fullWidth
          value={newUrl}
          onChange={(e) => setNewUrl(e.target.value)}
          placeholder={`Enter ${platform} URL`}
        />
        <div className="add-drawer-buttons">
          <Button className='addsocial_btn' onClick={onClose}>Cancel</Button>
          <Button className='addsocial_btn' sx={{color: "white", backgroundColor: "#002E6E"}}>Add</Button>
         
        </div>
      </div>
    </SwipeableDrawer>
   

    
    
    </>
  );
};

export default AddSocialMedia;