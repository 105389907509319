import React, { useState } from "react";
import { centerCrop, makeAspectCrop } from "react-image-crop";
import { v4 as uuidv4 } from "uuid";
import { UploadImage } from "../../crud/imageUpload";
import CustomDrawer from "../../CommomComponents/CustomDrawer/CustomDrawer";
// import CustomModal from "../../CommomComponents/CustomModal/CustomModal";
import "./CropImage.css";
import "react-image-crop/dist/ReactCrop.css";
import CropComponent from "./CropComponent";
import { SwipeableDrawer } from "@mui/material";

const CropImageModal = props => {
	const [imagePreviewUrl, setImagePreviewUrl] = useState("");

	function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
		return centerCrop(
			makeAspectCrop(
				{
					unit: "%",
					width: 90
				},
				aspect,
				mediaWidth,
				mediaHeight
			),
			mediaWidth,
			mediaHeight
		);
	}

	const onCropComplete = async crop => {
		if (props.imageRef && crop.width && crop.height)
			getCroppedImg(props.imageRef, crop, props.imageName);
	};

	const getCroppedImg = (image, crop, fileName) => {
		const canvas = document.createElement("canvas");
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		canvas.width = crop.width;
		canvas.height = crop.height;
		const ctx = canvas.getContext("2d");
		if (!ctx) {
			throw new Error("No 2d context");
		}
		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width,
			crop.height
		);

		return new Promise((resolve, reject) => {
			canvas.toBlob(blob => {
				if (!blob) {
					//reject(new Error('Canvas is empty'));
					console.error("Canvas is empty");
					return;
				}
				blob.name = fileName;
				window.URL.revokeObjectURL(imagePreviewUrl);
				props.setCroppedImageUrl(blob);
				setImagePreviewUrl(window.URL.createObjectURL(blob));
				resolve(null);
			}, "image/png");
		});
	};

	const uploadImage = async e => {
		if (props.croppedImageUrl === "") {
			console.log("Image upload failed. Please try cropping the image again");
			return;
		}
		try {
			const imageUrl = await UploadImage(
				props.croppedImageUrl,
				`${Date.now()}-${uuidv4()}.${props.imageName.split(".")[props.imageName.split(".").length - 1]}`,
				props.moduleNo,
				props.imageType,
				props?.event_id,
				props?.faceImage,
				props?.formID,
				props?.profile
			);
			props.imageHandler(imageUrl, imagePreviewUrl, props.imageName);
			props.closeModal();
		} catch (err) {
			console.log("err: ", err);
		}
	};

	function handleTopLineMouseDown(event) {
		event.stopPropagation();
	}

	function handleContentTouchMove(event) {
		const { touches } = event;
		const touch = touches[0];
		const startY = touch.clientY; // Get the initial Y position of the touch

		// Check if the touch movement is within a certain threshold from the top (e.g., 50px)
		if (touch.clientY - startY < 50) {
			event.stopPropagation(); // Prevent default swipe behavior if within the top area
		}
	}
	return (
		<>
			<SwipeableDrawer
				open={props.show}
				onOpen={() => props.setImageModal(true)}
				onClose={() => props.closeModal()}
				anchor="bottom"
			>
				<>
					<div className="drawer-top-line" onMouseDown={handleTopLineMouseDown} />
					<div className="drawer-content pt-2" onTouchMove={handleContentTouchMove}>
						<CropComponent
							imageCrop={props.imageCrop}
							setImageCrop={props.setImageCrop}
							circularCrop={props.circularCrop}
							onCropComplete={onCropComplete}
							centerAspectCrop={centerAspectCrop}
							closeModal={props.closeModal}
							uploadImage={uploadImage}
							imageSrc={props.imageSrc}
							setImageRef={props.setImageRef}
						></CropComponent>
					</div>
				</>
			</SwipeableDrawer>
			<style>
				{`
						.hide-in-desktop{
						display: block!important;
						}
					`}
			</style>
		</>
	);
};

export default CropImageModal;
