import React from "react";
import { Helmet } from "react-helmet";
import { stripHtmlTags } from "../reusable/common";

interface EventData {
	event_id: string;
	name: string;
	event_url: string;
	event_website: string;
	fav_icon_urls: Record<string, string>;
	event_poster_url: string;
	description: string;
}

const DynamicMetaTags: React.FC<{ eventData: EventData }> = ({ eventData }) => {
	return (
		<Helmet>
			<title>{eventData.name}</title>
			<meta
				name="description"
				content={stripHtmlTags(eventData.description) || eventData.name}
			/>
			<meta property="og:title" content={eventData.name} />
			<meta
				property="og:description"
				content={stripHtmlTags(eventData.description) || eventData.name}
			/>
			<meta property="og:url" content={eventData.event_website} />
			<meta property="og:image" content={eventData.event_poster_url} />
			<meta name="twitter:card" content="summary_large_image" />
			<meta name="twitter:title" content={eventData.name} />
			<meta
				name="twitter:description"
				content={stripHtmlTags(eventData.description) || eventData.name}
			/>
			<meta name="twitter:image" content={eventData.event_poster_url} />

			{Object.entries(eventData.fav_icon_urls).map(([key, url]) => {
				if (key.startsWith("favicon")) {
					// For general favicons like 16x16, 32x32, etc.
					const size = key.split("-")[1] || "32"; // Fallback size
					return (
						<link
							key={key}
							rel="icon"
							type="image/png"
							href={url}
							sizes={`${size}x${size}`}
						/>
					);
				}

				if (key.startsWith("apple-touch-icon")) {
					// For apple touch icons
					const size = key.split("-")[2] || "120"; // Fallback size
					return <link key={key} rel="apple-touch-icon" sizes={size} href={url} />;
				}

				if (key.startsWith("mstile")) {
					// For Microsoft tiles
					const size = key.split("-")[1] || "70"; // Fallback size
					return <link key={key} rel="mstile" href={url} sizes={size} />;
				}

				return null;
			})}
		</Helmet>
	);
};

export default DynamicMetaTags;
