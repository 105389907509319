import React, { useState, useEffect, useRef } from 'react';
import "./Agenda.css";
import { getComplementaryColor } from '../../reusable/common';

interface DateSelectorContainerProps {
    dates: Array<{ originalDate: Date; formattedDate: string }>;
    selectedDate: string;
    setSelectedDate: (date: string) => void;
}

const DateSelectorContainer: React.FC<DateSelectorContainerProps> = ({
    dates,
    selectedDate,
    setSelectedDate,
}) => {
    const dateRefs = useRef<any>({});
    const [isOverflowing, setIsOverflowing] = useState(false);
    const dateSelectorRef = useRef<HTMLDivElement>(null);

    const checkOverflow = () => {
        const element = dateSelectorRef.current;
        if (element) {
            setIsOverflowing(element.scrollWidth > element.clientWidth);
        }
    };

    useEffect(() => {
        checkOverflow();
        window.addEventListener('resize', checkOverflow);
        return () => window.removeEventListener('resize', checkOverflow);
    }, [dates]);

    // Scroll the container left
    const handleScrollLeft = () => {
        const element = dateSelectorRef.current;
        if (element) {
            element.scrollBy({ left: -100, behavior: 'smooth' });
        }
    };

    const handleScrollRight = () => {
        const element = dateSelectorRef.current;
        if (element) {
            element.scrollBy({ left: 100, behavior: 'smooth' });
        }
    };

    useEffect(() => {
        if (selectedDate && dateRefs.current[selectedDate]) {
          dateRefs.current[selectedDate].scrollIntoView({
            behavior: 'smooth',
            inline: 'center',
            block: 'nearest',
          });
        }
      }, [selectedDate]);

    return (
        <>
            <div className="date-selector-container" ref={dateSelectorRef}>
                <div className="date-selector" style={{ display: 'flex', gap: '8px' }}>
                    {dates?.length > 0 && (
                        <>
                            {dates.map((dateObj) => {
                                const { originalDate, formattedDate } = dateObj;
                                const day = originalDate.getDate().toString().padStart(2, '0');
                                const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(originalDate);

                                const buttonStyles = {
                                    height: '42px',
                                    minWidth: '42px',
                                    width: '36px',
                                    backgroundColor: formattedDate === selectedDate ? 'var(--primary-color)' : 'var(--secondary-color)',
                                    color: formattedDate === selectedDate ? 'var(--comp-primary-color)' : 'var(--comp-secondary-color)',
                                    border: 'none',
                                    fontSize: '12px',
                                    borderRadius: '4px',
                                    fontWeight: formattedDate === selectedDate ? 600 : 500,
                                    cursor: 'pointer',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    transition: 'background-color 0.3s',
                                };

                                return (
                                    <div
                                        ref={(el) => (dateRefs.current[formattedDate] = el)}
                                        key={formattedDate}
                                        onClick={() => {
                                            setSelectedDate(formattedDate);
                                        }}
                                        style={buttonStyles}
                                        className="date-button"
                                    >
                                        <div className="date-text">
                                            <div className="agenda_Date">{day}</div>
                                            <div className="agenda_Month">{month}</div>
                                        </div>
                                    </div>
                                );
                            })}
                        </>
                    )}
                </div>
            </div>

            {isOverflowing && (
                <div className="scroll-btn-container">
                    <button onClick={handleScrollLeft} className="scroll-button-left">
                        &lt;
                    </button>
                    <button onClick={handleScrollRight} className="scroll-button">
                        &gt;
                    </button>
                </div>
            )}
        </>
    );
};

export default DateSelectorContainer;
