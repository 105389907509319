import React from 'react';
import { X, Bell } from 'lucide-react';
import './PageNotFound.css';

const PageNotFound: React.FC = () => {
    return (
        <> 
        <div className='phone-view'> 
        <div className='phone-container font'>
            <div className="icon-container pg-not-found-cont">
                <X className="icon left-icon" />
                <Bell className="icon right-icon" />
            </div>
            <div>
                <img
                    src="/public/assets/page-not-found.svg"
                    alt="Welcome"
                    className="notfound-image"
                />
                <h3 className="notfound-text1 font">Page Not Found</h3>
                <p className='notfound-text'>
                    We apologize, but the page you are <br /> trying to access cannot be found
                </p>
            </div>
        </div>
        </div>
       


        <div className='desktop-view'> 
        <div className=' font'>
           <div className='errorpage'>
            <div>
                <img
                    src="/public/assets/page-not-found.svg"
                    alt="Welcome"
                    className="notfound-image"
                />
              
            </div>
            <div className='errorpage1'>
            <div className="error-headings">Page Not Found</div>
                <p className='error-textts'>
                    We apologize, but the page you are <br /> trying to access cannot be found
                </p>
            </div>
            </div>
        </div>
        </div>
       

        </>
    );
};

export default PageNotFound;