import React from "react";
import "./ItemDrawer.css";
import { SwipeableDrawer } from "@mui/material";
import { Icon } from "@iconify/react/dist/iconify.js";

interface SponsorDrawerProps {
	open: boolean;
	onClose: () => void;
	onOpen: () => void;
	selectedEntity: [];
}

const ItemDrawer: React.FC<SponsorDrawerProps> = ({ open, onOpen, onClose, selectedEntity }) => {
	return (
		<SwipeableDrawer
			anchor="bottom"
			open={open}
			onOpen={onOpen}
			onClose={onClose}
			classes={{
				paper: "sponsors-drawer"
			}}
			sx={{ paddingTop: "20px 30px" }}
		>
			<div
				className="sponsors-drawer-content"
				style={{ padding: "0px 20px 70px 20px", maxHeight: "573px" }}
			>
				<div
					className="drawer-top-line"
					aria-hidden="true"
					onClick={() => {
						onClose();
					}}
				></div>
				<div className="d-flex justify-content-end mb-1">
					<Icon
						role="button"
						icon={`material-symbols-light:close`}
						width={"24px"}
						height={"24px"}
						onClick={() => {
							onClose();
						}}
					/>
				</div>

				<div className="sponsors-header" style={{ marginTop: "33px" }}>
					<img
						src={selectedEntity.image_url}
						alt={selectedEntity.entity_name}
						className="sponsors-logo"
					/>
					<div className="sps_head">
						<h2 className="sps_name">{selectedEntity.entity_name}</h2>
						<p className="sps_lead">
							{" "}
							<span className="sps_us">{selectedEntity.location}</span>
						</p>
						<div className="sponsor-subtitle p-0">
							{selectedEntity.website_url ? (
								<a
									href={selectedEntity.website_url}
									target="_blank"
									rel="noopener noreferrer"
									className="visit-link"
								>
									Visit{" "}
									<Icon
										icon="oui:link"
										width={13}
										height={13}
										style={{ marginLeft: "5px", verticalAlign: "text-top" }}
									/>
								</a>
							) : (
								""
							)}
						</div>
					</div>
				</div>
				<p
					className="sponsors-description"
					dangerouslySetInnerHTML={{ __html: selectedEntity.description }}
				></p>
				{selectedEntity.booth_number && (
					<div className="sponsors-details-desktop">
						<div className="detail-value-desktop" style={{ width: "100%" }}>
							<span style={{ fontSize: "16px" }} className="detail-label">
								Booth Number :{" "}
							</span>

							<span className=""> {selectedEntity.booth_number}</span>
						</div>
					</div>
				)}

				{selectedEntity?.poc && (
					<div className="contact-section" style={{ padding: "16px 12px" }}>
						<h3 className="sps_con">Contact us</h3>
						<div className="contact-person">
							{selectedEntity?.poc?.image_url ? (
								<img
									src={selectedEntity?.poc?.image_url}
									alt="Simon Robben"
									className="contact-image"
								/>
							) : (
								<Icon icon={"proicons:person-circle"} className="contact-imagee" />
							)}
							<div className="contact-details">
								<h4 className="sps_names">{selectedEntity?.poc?.name}</h4>
								{/* {selectedEntity?.poc?.designation && (
                  <p className='sps_in'>
                    {selectedEntity?.poc?.designation}
                    {selectedEntity?.poc?.organization && ', '}
                    <span className='sps_lin'>{selectedEntity?.poc?.organization}</span>
                  </p>
                )} */}
								{[
									selectedEntity?.poc?.designation,
									selectedEntity?.poc?.organization
								]?.filter(item => item).length > 0 && (
									<p className="sps_in">
										{[
											selectedEntity?.poc?.designation,
											selectedEntity?.poc?.organization
										]
											?.filter(item => item)
											.join(", ")}
									</p>
								)}
							</div>
						</div>
						<div className="">
							{selectedEntity?.poc?.contact_number && (
								<div className="contact-info">
									<div className="contact-info">
										<Icon
											height={25}
											width={25}
											icon={"ph:phone-thin"}
											className=""
										/>
									</div>
									<span>{selectedEntity?.poc?.contact_number}</span>
								</div>
							)}
							{selectedEntity?.poc?.email && (
								<div className="contact-info">
									<Icon
										height={25}
										width={25}
										icon={"mdi-light:email"}
										className=""
									/>

									<span>{selectedEntity?.poc?.email}</span>
								</div>
							)}
						</div>
					</div>
				)}

				{selectedEntity?.representatives && (
					<div className="representatives-section" style={{ marginBottom: "15px" }}>
						<h3 className="sps_industry">Representative(s)</h3>
						<div className="representatives-list-desktop">
							{selectedEntity?.representatives?.map((Representative, index) => (
								<div key={index} className="representative">
									{Representative.image_url ? (
										<img
											src={Representative.image_url}
											alt={Representative.name}
											className="rep-image-desktop"
										/>
									) : (
										<Icon
											icon={"proicons:person-circle"}
											style={{ marginRight: "0px" }}
											className="contact-imagee"
										/>
									)}
									<div className="rep-details">
										<h4 className="sps_del">{Representative.name}</h4>
										<p className="sps_in">
											{/* {Representative.designation}
                      {Representative.organization ? `,, ` : ''} 
                      <span className='sps_ins'>{Representative.organization}</span> */}
											{[
												Representative.designation,
												Representative.organization
											]?.filter(item => item).length > 0 && (
												<span className="sps_in">
													{[
														Representative.designation,
														Representative.organization
													]
														?.filter(item => item)
														.join(", ")}
												</span>
											)}
										</p>
									</div>
								</div>
							))}
						</div>
					</div>
				)}

				{selectedEntity?.tags.length > 0 && (
					<div className="">
						<h3 className="sps_industry">Tags</h3>
						<div className="tags_section">
							{selectedEntity?.tags?.map((tags, index) => (
								<div key={index}>
									<div className="tags" style={{ display: "flex" }}>
										<span className="tag-desktop">{tags.name}</span>
									</div>
								</div>
							))}
						</div>
					</div>
				)}
			</div>
		</SwipeableDrawer>
	);
};

export default ItemDrawer;
