import React from "react";
import "./SpeakerCardsComponent.css";
import { useNavigate } from "react-router-dom";

interface CardProps {
	image: string;
	name: string;
	designationAndOrganization: string[];
	location: string;
	designation: string;
	organisation: string;
	tags: any[];
	actionType: "connect" | "decision";
	speakerDetails: object;
	setSpeakerDetails: (details: object) => void;
	setOpenDrawer: (val: boolean) => void;
}

const CardComponent: React.FC<CardProps> = ({
	image,
	name,
	designationAndOrganization,
	location,
	tags,
	actionType,
	speakerDetails,
	setSpeakerDetails,
	setOpenDrawer,
	designation,
	organisation
}) => {
	return (
		<>
			<div className="d-flex flex-column align-items-end h-100 ">
				<div
					role="button"
					className="speaker__card-desktop d-flex flex-row align-items-center gap-2 w-100 h-100 py-2 px-1"
					onClick={() => {
						setSpeakerDetails(speakerDetails);
						setOpenDrawer(true);
					}}
				>
					<div className="speaker__card-content">
						<div className="speaker__card-header">
							{/* <div
								className="speaker__card-background"
								style={{ backgroundImage: `url(${image})` }}
							></div> */}

							<img src={image} alt={name} className="speaker__card-avatar" />
						</div>
					</div>
					<div className="speaker__btn">
						<div className="speaker__card-info">
							<div className="speaker-card-name">{name}</div>
							{designationAndOrganization?.filter(item => item).length > 0 && (
								<div className="speaker__card-title">
									<div>{designation}</div>
									<div className="mt-1">{organisation}</div>
								</div>
							)}
							{location && (
								<div className="d-flex align-items-center mt-2">
									<img
										width={12}
										src="/assets/speaker-location.svg"
										alt="location"
									/>
									<div className="ms-2 speaker__card-location">{location}</div>
								</div>
							)}
							{/* {tags?.length > 0 && (
								<div className="speaker__card-tags mt-1">
									{tags.map((tag, index) => (
										<div
											key={index}
											className="mt-1 speaker__card-tag"
										>{`#${tag}`}</div>
									))}
								</div>
							)} */}
						</div>
						{/* <div className="">
							{actionType === "connect" ? (
								<button className="speaker__connect-button">Connect</button>
							) : (
								<div className="action-buttons sp">
									<img
										className="decline_network"
										src="assets/decline.svg"
										alt=""
									/>
									<img
										className="accept_network"
										src="assets/accept.svg"
										alt=""
									/>
								</div>
							)}
						</div> */}
					</div>
				</div>
			</div>
		</>
	);
};

export default CardComponent;
