import React, { useState, useEffect } from 'react';
import '../components/Sponsors/Sponsors.css';
import { Icon } from '@iconify-icon/react';
import { useNavigate } from 'react-router-dom';

interface Sponsor {
  id: number;
  image_url: string;
  entity_name: string;
  website_url?: string;
}

interface Category {
  category_id: string;
  category_name: string;
  entity: Sponsor[];
}

interface ItemCardProps {
  filteredCategories: Category[];
  filteredUncategorized: Sponsor[];
  setSelectedSponsor: (sponsor: Sponsor | null) => void; // Fix this type
  setItemDetailsPage: (value: boolean) => void; // Fix this type
  unCategrizedname: "";
  routeDetails?: any;
  route?: any
}

const ItemCard: React.FC<ItemCardProps> = ({
  filteredCategories,
  filteredUncategorized,
  setSelectedSponsor,
  setItemDetailsPage,
  unCategrizedname,
  routeDetails,
  route
}) => {
  const navigate = useNavigate();
  const [openCategories, setOpenCategories] = useState<{ [key: string]: boolean }>({});


  useEffect(() => {
    const initialOpenState: { [key: string]: boolean } = {};

    filteredCategories.forEach(category => {
      initialOpenState[category.category_id] = true;
    });

    if (filteredUncategorized.length > 0) {
      initialOpenState['uncategorized'] = true;
    }

    setOpenCategories(initialOpenState);
  }, [filteredCategories, filteredUncategorized]);



  const toggleCategory = (categoryId: string) => {
    setOpenCategories((prev) => ({ ...prev, [categoryId]: !prev[categoryId] }));
  };

  return (
    <>
      {filteredUncategorized.length > 0 && (
        <div className="sponsor-section">
          <div className="sponsor-header" onClick={() => toggleCategory('uncategorized')}>
            {/* <h2>Uncategorized {unCategrizedname}</h2> */}
            <h2></h2>

            {openCategories['uncategorized'] ? (
              <Icon
                icon="material-symbols:keyboard-arrow-up"

                width={30}
                height={30}
                color="gray"
                style={{ transition: 'transform 0.3s' }}



              />
            ) : (
              <Icon
                icon="material-symbols:keyboard-arrow-down-rounded"

                width={30}
                height={30}
                color="gray"
                style={{ transition: 'transform 0.3s' }}

              />
            )}
          </div>
          {openCategories['uncategorized'] && (
            <div className="sponsor-list sponsor-list-cont">
              {filteredUncategorized?.map((sponsor) => (
                <div key={sponsor.id} className="sponsor-card">
                  <img
                    onClick={() => {
                      setSelectedSponsor(sponsor);
                      setItemDetailsPage(true);
                    }}
                    className="sponsor-card-image"
                    src={sponsor.image_url}
                    alt={sponsor.entity_name}
                  />
                  <div className="sponsor-name">{sponsor.entity_name}</div>
                  {/* <div className="sponsor-subtitle">
                    {sponsor.website_url ? (
                      <a
                        href={sponsor.website_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="visit-link"
                      >
                        Visit <Icon icon="oui:link" width={13} height={13} style={{ marginLeft: "5px", verticalAlign: "text-top" }}
                        />
                      </a>
                    ) : (
                      'No website available'
                    )}
                  </div> */}
                </div>
              ))}
            </div>
          )}
        </div>
      )}

      {filteredCategories.length > 0 &&
        <>
          {filteredCategories?.map((category) => (
            <div key={category.category_id} className="sponsor-section">
              <div className="sponsor-header" onClick={() => toggleCategory(category.category_id)}>
                <h2>{category.category_name}</h2>
                {openCategories[category.category_id] ? (
                  <Icon
                    icon="material-symbols:keyboard-arrow-up"

                    width={30}
                    height={30}
                    color="gray"
                    style={{ transition: 'transform 0.3s' }}



                  />
                ) : (
                  <Icon
                    icon="material-symbols:keyboard-arrow-down-rounded"

                    width={30}
                    height={30}
                    color="gray"
                    style={{ transition: 'transform 0.3s' }}

                  />
                )}
              </div>
              {openCategories[category.category_id] && (
                <div className="sponsor-list sponsor-list-cont">
                  {category?.entity?.map((sponsor) => (
                    <div key={sponsor.id} className="sponsor-card">
                      {sponsor?.image_url && <img
                        onClick={() => {
                          setSelectedSponsor(sponsor);
                          setItemDetailsPage(true);
                        }}
                        className="sponsor-card-image"
                        src={sponsor.image_url}
                        alt={sponsor.entity_name}
                      />}
                      <div className="sponsor-name">{sponsor.entity_name}</div>
                      {/* <div className="sponsor-subtitle">
                        {sponsor.website_url ? (
                          <a
                            href={sponsor.website_url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="visit-link"
                          >
                            Visit <Icon icon="oui:link" width={13} height={13} style={{ marginLeft: "5px", verticalAlign: "text-top" }}
                            />
                          </a>
                        ) : (
                          'No website available'
                        )}
                      </div> */}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </>

      }
    </>
  );
};

export default ItemCard;
