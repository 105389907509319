import { Auth, API, Amplify } from "aws-amplify";
import { SubscribeToServicesParams } from "./subscriptions.types";
import { trackEntity } from "../../crud/tracking.crud";
import { clickTrackingModules, notificationMetricEvents } from "../../config/app";

// Get the ID token from the current session
const getIdToken = async (): Promise<string | null> => {
	try {
		const session = await Auth.currentSession();
		return session?.getIdToken()?.getJwtToken() || null;
	} catch (error) {
		console.error("Error getting ID token:", error);
		return null;
	}
};

// Store active subscriptions to check before subscribing again
let activeSubscriptions: Set<string> = new Set();

// Store active subscription objects for unsubscribing
const subscriptions: Map<string, any> = new Map();

// Helper function to generate a unique key for each subscription
const generateSubscriptionKey = (query: string, variables: any): string => {
	return `${query}:${JSON.stringify(variables)}`;
};

// Exponential backoff logic for reconnecting
const reconnectWithBackoff = (
	retryCount: number,
	subscriptionParams: SubscribeToServicesParams
) => {
	const backoffTime = Math.min(1000 * Math.pow(2, retryCount), 30000); // Exponential backoff with max 30s

	setTimeout(() => {
		subscribeToServices(subscriptionParams, retryCount + 1);
	}, backoffTime);
};

// Subscribe to GraphQL Notifications
export const subscribeToServices = async (
	{
		subscriptionQuery,
		subscriptionVariables,
		onSubscribe,
		onError,
		onComplete,
		name,
		callback,
		isPublic
	}: SubscribeToServicesParams,
	retryCount: number = 3
): Promise<any> => {
	const subscriptionKey = generateSubscriptionKey(subscriptionQuery, subscriptionVariables);

	// Prevent duplicate subscriptions
	if (activeSubscriptions.has(subscriptionKey)) {
		return;
	}

	try {
		// Build GraphQL options
		const graphqlOptions: any = {
			query: subscriptionQuery,
			variables: subscriptionVariables
		};

		// Start subscription
		const subscriptionResp: any = await API.graphql(graphqlOptions);
		const subscription = subscriptionResp.subscribe({
			next: (data: any) => {
				const notificationId =
					data.value.data?.[Object.keys(data.value.data)[0]]?.notificationId;
				if (notificationId) {
					trackEntity(
						localStorage.getItem("eventId") ?? "",
						clickTrackingModules.NOTIFICATIONS,
						{ id: notificationId },
						notificationMetricEvents.RECEIVED
					);
				}
				onSubscribe(data);
				if (callback) callback(data);
			},
			error: (error: any) => {
				console.error(`Error with ${name} subscription:`, error);
				if (onError) onError(error);

				// Reconnect with backoff
				reconnectWithBackoff(retryCount, {
					subscriptionQuery,
					subscriptionVariables,
					onSubscribe,
					onError,
					onComplete,
					name,
					callback,
					isPublic
				});
			},
			complete: () => {
				if (onComplete) onComplete();
			}
		});

		// Track active subscriptions
		activeSubscriptions.add(subscriptionKey);
		subscriptions.set(subscriptionKey, subscription);

		return subscription;
	} catch (error) {
		console.error(`Failed to subscribe to ${name} service:`, error);

		// Retry on failure
		if (retryCount < 5) {
			reconnectWithBackoff(retryCount, {
				subscriptionQuery,
				subscriptionVariables,
				onSubscribe,
				onError,
				onComplete,
				name,
				callback,
				isPublic
			});
		}
	}
};

// Unsubscribe from all active subscriptions
export const unsubscribeAllServices = () => {
	try {
		if (subscriptions.size === 0) {
			return;
		}

		subscriptions.forEach(subscription => {
			if (subscription && typeof subscription.unsubscribe === "function") {
				subscription.unsubscribe();
			}
		});

		// Clear all active subscriptions
		subscriptions.clear();
		activeSubscriptions.clear();
	} catch (error) {
		console.error("Error unsubscribing from all services:", error);
	}
};
