import React, { useEffect, useState } from "react";
import "./Menu.css";
import { Carousel } from "react-responsive-carousel";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "../Footer/Footer";
import api from "../../api";
import { useLocation } from "react-router-dom";
import { Bell } from "lucide-react";
import SideNavbar from "../SideNavbar/SideNavbar";
import { Icon } from "@iconify/react";
import { useModule } from "../../contexts/ModuleContext";

const Menu: React.FC = () => {
	const location = useLocation();
	const { menuItems } = useModule();
	const [showAnnouncement, setShowAnnouncement] = useState(false);

	const params = useParams();
	let productId = params.id;
	let producturl = params.url;

	const [isExpanded, setIsExpanded] = useState(false);
	const [isReadMore, setIsReadMore] = useState(false);
	const [events, setEvents] = useState([]);
	const navigate = useNavigate();

	useEffect(() => {
		fetchData();
	}, []);

	const handleToggle = () => {
		setIsExpanded(!isExpanded);
	};

	const fetchData = async () => {
		try {
			const response = await api.get("/event/public/" + producturl);
			setEvents(response.data);
		} catch (err) {
			console.log(err);
		}
	};

	const Agenda = () => {
		navigate("/agenda");
	};

	const toggleReadMore = () => {
		setIsReadMore(!isReadMore);
	};

	useEffect(() => {
		// Check if the announcement should be shown
		if (location.state && location.state.showAnnouncement) {
			setShowAnnouncement(true);
		}
	}, [location.state]);

	const handleAnnouncementClose = () => {
		setShowAnnouncement(false);
	};

	const goBack = () => {
		navigate(-1);
	};

	return (
		<>
			<div className="phone-view">
				<div className="menu-container-event">
					<div className="sian menu_s">
						<div className="event-app">
							<div className="flex-container menuuu">
								<img
									className="left_uparrow search_left_arrow"
									src="/assets/uparrow.svg"
									alt="Go Back"
									onClick={goBack}
								/>
								<h1 className="rest">ELYSIAN - 2024</h1>
							</div>

							<div className="menu-grid">
								{menuItems.map((item, index) => (
									<div
										key={index}
										onClick={() => item.onClick && item.onClick(item)}
									>
										{" "}
										<Link to={item.url}>
											<div className="menu-item">
												<img
													src={item.icon}
													alt={item.label}
													className="icon-image"
												/>
											</div>
										</Link>
										<div className="itm">{item.label}</div>
									</div>
								))}
							</div>
						</div>
						<Footer />
					</div>
				</div>
			</div>

			{/*desktop container  */}
			<div className="desktop-view">
				<div className="menu-desktop">
					<div>
						<SideNavbar />
					</div>
					<div className="menu-container-event">
						<div className="sian-desktop menu_s">
							<div className="event-app-desktop">
								<div className="flex-container-desktop menuuu-desktop">
									<img
										className="left_uparrow search_left_arrow"
										src="/assets/uparrow.svg"
										alt="Go Back"
										onClick={goBack}
									/>
									<div className="b1">
										<Link to="/notifications">
											<span>
												<Bell className="icon interest_bell right-icon1" />
											</span>
										</Link>
										<div>
											<img
												className="h_image"
												src="assets/deep.png"
												alt="Center Logo"
												onClick={() => navigate("/profile-page")}
											/>
										</div>
									</div>
								</div>

								<div className="menu-grid-desktop">
									{menuItems.map((item, index) => (
										<div
											key={index}
											className="menu-item-desktop-card"
											onClick={() => item.onClick && item.onClick(item)}
										>
											{" "}
											<Link to={item.url}>
												<div className="menu-item-desktop">
													<Icon
														icon={item.icon}
														className="primary-color-icons"
													/>
												</div>
											</Link>
											<div className="itm-desktop">{item.label}</div>
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Menu;
