import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./HomeScreen.css";
import { Link } from 'react-router-dom';
import { Sheet } from "react-modal-sheet";
import { Bell } from "lucide-react";
import api from "../../api";
import { Modal, Button } from "react-bootstrap";
import NotificationModal from "../Notification/NotificationModal/NotificationModal";

const HomeScreen: React.FC = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setOpen] = useState(false);
  const [isLiveOpen, setLiveOpen] = useState(false); // Dropdown for live events
  const [isUpcomingOpen, setUpcomingOpen] = useState(false); // Dropdown for upcoming events
  const [isPastOpen, setPastOpen] = useState(false); // Dropdown for past events
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [eventid, setEventId] = useState("");
  const [eventurl, setEventurl] = useState("");
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [events, setEvents] = useState([]);
  const dataFetchedRef = useRef(false);
  const [showAnnouncement, setShowAnnouncement] = useState(false);
  // const [open, setOpen] = useState(false);

  const [showModal, setShowModal] = useState(false);

    

  const handleCardClick = (id: string, url: string) => {
    sendOtpToEmail();
    setEventId(id);
    setEventurl(url);
    setOpen(true);
  };

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    // setLiveOpen(!isLiveOpen)
    localStorage.removeItem('eventId');
    localStorage.removeItem('eventUrl');
    fetchData();
  }, [1]);

  const fetchData = async () => {
    try {
      const response = await api.get("/event/public/krunal-test-event");
      setEvents(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const sendOtpToEmail = () => {
  };

  const handleJoinClick = () => {
    setOpen(false);
    navigate("/event-app/" + eventid + "/" + eventurl);

    setTimeout(() => {
      setShowAnnouncement(true);
    }, 2000);
  };

  const redirectUserToTheHome = (id : string,url : string) => {
    localStorage.setItem('eventId', id);
    localStorage.setItem('eventUrl', url);
    navigate("/event-details");
  }

  const handleExplore = () => {
    setShowAnnouncement(false);
  };

 

  const handleOpen = () => {
    setShowModal(true); // Open the modal
  };

  const handleClose = () => {
    setShowModal(false); // Close the modal
  };
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  const renderEventCard = (event: any) => (
    <div key={event.event_id} className="live-events-card" onClick={() => handleCardClick(event.event_id, event.event_url)}>
      <div className="carousel-container">
        <Carousel showThumbs={false} showStatus={false} infiniteLoop useKeyboardArrows autoPlay interval={5000}>
          <div><img src={event.poster_thumbnail} alt="Slide 1" /></div>
          <div><img src={event.poster_thumbnail} alt="Slide 2" /></div>
          <div><img src={event.poster_thumbnail} alt="Slide 3" /></div>
        </Carousel>
        <div className="live-button-container">
          <div className="live-button"><div className="live-dot"></div>Live</div>
        </div>
        <div className="conference-title">
          <img className="card_heading_image" src={event.att_app_logo} alt="" />
          <h3 className="home_card_header">{event.name}</h3>
        </div>
      </div>
      <div className="event-details">
        <p className="det1"><span><img src="/assets/Address.svg" alt="Address Icon" className="small-icon" /></span><span>Address: {event.city}</span></p>
        <p className="det1"><span><img src="/assets/calendar-1.svg" alt="Date Icon" className="small-icon" /></span><span>{event.start_date} - {event.end_date}</span></p>
        <p className="det1"><span><img src="/assets/clock-rewind.svg" alt="Time Icon" className="small-icon" /></span><span>Time: {event.start_time}</span></p>
      </div>
    </div>
  );

  return (
    <>
      <div className="phone-view">
        <div className="home-screen phone-container-home font">
          <div className="headerss">
            <div><img src="assets/logoo.svg" alt="Center Logo" /></div>
            <div className="top-icons-home-screen">
              <Link to="/notifications"><span><Bell className="icon interest_bell right-icon1" /></span></Link>
              <div><img className="h_image" src="assets/deep.png" alt="Center Logo" onClick={() => navigate('/profile-page')} /></div>
            </div>
          </div>

          <div className="search-bars no-margin">
            <div className="search-input-wrapper">
              <img className="search-icon" src="assets/search-1.svg" alt="" />
              <input type="text" placeholder="Search" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} className="search-input" />
            </div>
            <div className="interest_filter_btns">
              <img src="assets/filter.svg" alt="Center Logo" className="filter_image" />
            </div>
          </div>

          {/* Live Events Dropdown */}
          <div className="live-events-header" onClick={() => setLiveOpen(!isLiveOpen)}>
            <h5 className="_mainheader_live">Live Events</h5>
            <img
              className={`liveevents_arrow`}
              src={isLiveOpen ? "/assets/Icon-u.png" : "/assets/Icon-d.png"}
              alt="Toggle Arrow"
            />
          </div>
          {isLiveOpen && (
            <div className="live-events-container" onClick={() => {
              redirectUserToTheHome(events.event_id,events.event_url)
            }}>
              <div className="carousel-container">
                <Carousel
                  showThumbs={false}
                  showStatus={false}
                  infiniteLoop
                  useKeyboardArrows
                  autoPlay
                  interval={5000}
                >
                  <div>
                    <img src={events.poster_thumbnail} alt="Slide 1" />
                  </div>
                  <div>
                    <img src={events.poster_thumbnail} alt="Slide 2" />
                  </div>
                  <div>
                    <img src={events.poster_thumbnail} alt="Slide 3" />
                  </div>
                </Carousel>
                <div className="live-button-container">
                  <div className="live-button">
                    <div className="live-dot"></div>
                    Live
                  </div>
                </div>
                <div className="conference-title">
                  <img
                    className="card_heading_image"
                    src={events.att_app_logo}
                    alt=""
                  />
                  <h3 className="home_card_header">{events.name}</h3>
                </div>
              </div>

              <div className="event-details">
                <div className="details">
                  <p className="det1">
                    <span>
                      <img
                        src="/assets/Address.svg"
                        alt="Address Icon"
                        className="small-icon"
                      />
                    </span>
                    <span>Address: {events.city}</span>
                  </p>
                  <p className="det1">
                    <span>
                      <img
                        src="/assets/calendar-1.svg"
                        alt="Date Icon"
                        className="small-icon"
                      />
                    </span>
                    <span>
                      {events.start_date} - {events.end_date}
                    </span>
                  </p>
                  <p className="det1">
                    <span>
                      <img
                        src="/assets/clock-rewind.svg"
                        alt="Time Icon"
                        className="small-icon"
                      />
                    </span>
                    <span>Time: {events.start_time}</span>
                  </p>
                </div>
              </div>

              <div className="live-events-card" onClick={handleCardClick}>
                <div className="carousel-container">
                  <Carousel
                    showThumbs={false}
                    showStatus={false}
                    infiniteLoop
                    useKeyboardArrows
                    autoPlay
                    interval={5000}
                  >
                    <div>
                      <img src="assets/blue.jpeg" alt="Slide 1" />
                    </div>
                    <div>
                      <img src="assets/blue.jpeg" alt="Slide 2" />
                    </div>
                    <div>
                      <img src="assets/blue.jpeg" alt="Slide 3" />
                    </div>
                  </Carousel>
                  <div className="live-button-container">
                    <div className="live-button">
                      <div className="live-dot"></div>
                      Live
                    </div>
                  </div>
                  <div className="conference-title">
                    <img className="card_heading_image" src="assets/ely.png" alt="" />
                    <h3 className="home_card_header">
                      International Conference <br /> for Latest Developments
                      <br /> in Communication Technologies (2024)
                    </h3>
                  </div>
                </div>

                <div className="event-details">
                  <div className="details">
                    <p className="det1">
                      <span>
                        <img
                          src="/assets/Address.svg"
                          alt="Address Icon"
                          className="small-icon"
                        />
                      </span>
                      <span>Address: 123 Event St, City, Country</span>
                    </p>
                    <p className="det1">
                      <span>
                        <img
                          src="/assets/calendar-1.svg"
                          alt="Date Icon"
                          className="small-icon"
                        />
                      </span>
                      <span>Date: September 10, 2024</span>
                    </p>
                    <p className="det1">
                      <span>
                        <img
                          src="/assets/clock-rewind.svg"
                          alt="Time Icon"
                          className="small-icon"
                        />
                      </span>
                      <span>Time: 10:00 AM - 2:00 PM</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Upcoming Events Dropdown */}
          <div className="live-events-header" onClick={() => setUpcomingOpen(!isUpcomingOpen)}>
            <h5 className="_mainheader_live">Upcoming Events</h5>
            <img
              className={`liveevents_arrow`}
              src={isUpcomingOpen ? "/assets/Icon-u.png" : "/assets/Icon-d.png"}
              alt="Toggle Arrow"
            />
          </div>
          {isUpcomingOpen && (
            <div className="live-events-container">
              <div className="carousel-container">
                <Carousel
                  showThumbs={false}
                  showStatus={false}
                  infiniteLoop
                  useKeyboardArrows
                  autoPlay
                  interval={5000}
                >
                  <div>
                    <img src={events.poster_thumbnail} alt="Slide 1" />
                  </div>
                  <div>
                    <img src={events.poster_thumbnail} alt="Slide 2" />
                  </div>
                  <div>
                    <img src={events.poster_thumbnail} alt="Slide 3" />
                  </div>
                </Carousel>
                <div className="live-button-container">
                  <div className="live-button">
                    <div className="live-dot"></div>
                    Live
                  </div>
                </div>
                <div className="conference-title">
                  <img
                    className="card_heading_image"
                    src={events.att_app_logo}
                    alt=""
                  />
                  <h3 className="home_card_header">{events.name}</h3>
                </div>
              </div>

              <div className="event-details">
                <div className="details">
                  <p className="det1">
                    <span>
                      <img
                        src="/assets/Address.svg"
                        alt="Address Icon"
                        className="small-icon"
                      />
                    </span>
                    <span>Address: {events.city}</span>
                  </p>
                  <p className="det1">
                    <span>
                      <img
                        src="/assets/calendar-1.svg"
                        alt="Date Icon"
                        className="small-icon"
                      />
                    </span>
                    <span>
                      {events.start_date} - {events.end_date}
                    </span>
                  </p>
                  <p className="det1">
                    <span>
                      <img
                        src="/assets/clock-rewind.svg"
                        alt="Time Icon"
                        className="small-icon"
                      />
                    </span>
                    <span>Time: {events.start_time}</span>
                  </p>
                </div>
              </div>




              <div className="live-events-card" onClick={handleCardClick}>
                <div className="carousel-container">
                  <Carousel
                    showThumbs={false}
                    showStatus={false}
                    infiniteLoop
                    useKeyboardArrows
                    autoPlay
                    interval={5000}
                  >
                    <div>
                      <img src="assets/blue.jpeg" alt="Slide 1" />
                    </div>
                    <div>
                      <img src="assets/blue.jpeg" alt="Slide 2" />
                    </div>
                    <div>
                      <img src="assets/blue.jpeg" alt="Slide 3" />
                    </div>
                  </Carousel>
                  <div className="live-button-container">
                    <div className="live-button">
                      <div className="live-dot"></div>
                      Live
                    </div>
                  </div>
                  <div className="conference-title">
                    <img className="card_heading_image" src="assets/ely.png" alt="" />
                    <h3 className="home_card_header">
                      International Conference <br /> for Latest Developments
                      <br /> in Communication Technologies (2024)
                    </h3>
                  </div>
                </div>

                <div className="event-details">
                  <div className="details">
                    <p className="det1">
                      <span>
                        <img
                          src="/assets/Address.svg"
                          alt="Address Icon"
                          className="small-icon"
                        />
                      </span>
                      <span>Address: 123 Event St, City, Country</span>
                    </p>
                    <p className="det1">
                      <span>
                        <img
                          src="/assets/calendar-1.svg"
                          alt="Date Icon"
                          className="small-icon"
                        />
                      </span>
                      <span>Date: September 10, 2024</span>
                    </p>
                    <p className="det1">
                      <span>
                        <img
                          src="/assets/clock-rewind.svg"
                          alt="Time Icon"
                          className="small-icon"
                        />
                      </span>
                      <span>Time: 10:00 AM - 2:00 PM</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Past Events Dropdown */}
          <div className="live-events-header" onClick={() => setPastOpen(!isPastOpen)}>
            <h5 className="_mainheader_live">Past Events</h5>
            <img
              className={`liveevents_arrow`}
              src={isPastOpen ? "/assets/Icon-u.png" : "/assets/Icon-d.png"}
              alt="Toggle Arrow"
            />
          </div>
          {isPastOpen && (
            <div className="live-events-container">
              <div className="carousel-container">
                <Carousel
                  showThumbs={false}
                  showStatus={false}
                  infiniteLoop
                  useKeyboardArrows
                  autoPlay
                  interval={5000}
                >
                  <div>
                    <img src={events.poster_thumbnail} alt="Slide 1" />
                  </div>
                  <div>
                    <img src={events.poster_thumbnail} alt="Slide 2" />
                  </div>
                  <div>
                    <img src={events.poster_thumbnail} alt="Slide 3" />
                  </div>
                </Carousel>
                <div className="live-button-container">
                  <div className="live-button">
                    <div className="live-dot"></div>
                    Live
                  </div>
                </div>
                <div className="conference-title">
                  <img
                    className="card_heading_image"
                    src={events.att_app_logo}
                    alt=""
                  />
                  <h3 className="home_card_header">{events.name}</h3>
                </div>
              </div>

              <div className="event-details">
                <div className="details">
                  <p className="det1">
                    <span>
                      <img
                        src="/assets/Address.svg"
                        alt="Address Icon"
                        className="small-icon"
                      />
                    </span>
                    <span>Address: {events.city}</span>
                  </p>
                  <p className="det1">
                    <span>
                      <img
                        src="/assets/calendar-1.svg"
                        alt="Date Icon"
                        className="small-icon"
                      />
                    </span>
                    <span>
                      {events.start_date} - {events.end_date}
                    </span>
                  </p>
                  <p className="det1">
                    <span>
                      <img
                        src="/assets/clock-rewind.svg"
                        alt="Time Icon"
                        className="small-icon"
                      />
                    </span>
                    <span>Time: {events.start_time}</span>
                  </p>
                </div>
              </div>




              <div className="live-events-card" onClick={handleCardClick}>
                <div className="carousel-container">
                  <Carousel
                    showThumbs={false}
                    showStatus={false}
                    infiniteLoop
                    useKeyboardArrows
                    autoPlay
                    interval={5000}
                  >
                    <div>
                      <img src="assets/blue.jpeg" alt="Slide 1" />
                    </div>
                    <div>
                      <img src="assets/blue.jpeg" alt="Slide 2" />
                    </div>
                    <div>
                      <img src="assets/blue.jpeg" alt="Slide 3" />
                    </div>
                  </Carousel>
                  <div className="live-button-container">
                    <div className="live-button">
                      <div className="live-dot"></div>
                      Live
                    </div>
                  </div>
                  <div className="conference-title">
                    <img className="card_heading_image" src="assets/ely.png" alt="" />
                    <h3 className="home_card_header">
                      International Conference <br /> for Latest Developments
                      <br /> in Communication Technologies (2024)
                    </h3>
                  </div>
                </div>

                <div className="event-details">
                  <div className="details">
                    <p className="det1">
                      <span>
                        <img
                          src="/assets/Address.svg"
                          alt="Address Icon"
                          className="small-icon"
                        />
                      </span>
                      <span>Address: 123 Event St, City, Country</span>
                    </p>
                    <p className="det1">
                      <span>
                        <img
                          src="/assets/calendar-1.svg"
                          alt="Date Icon"
                          className="small-icon"
                        />
                      </span>
                      <span>Date: September 10, 2024</span>
                    </p>
                    <p className="det1">
                      <span>
                        <img
                          src="/assets/clock-rewind.svg"
                          alt="Time Icon"
                          className="small-icon"
                        />
                      </span>
                      <span>Time: 10:00 AM - 2:00 PM</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="joinAccess_home">
            <button className="JoinAccess" onClick={() => handleCardClick(events.event_id, events.event_url)}>Join Using Access Code</button>
          </div>

          {/* OTP Modal */}
          <Sheet isOpen={isOpen} onClose={() => setOpen(false)} snapPoints={[0.5]}>
            <Sheet.Container style={{ height: "auto", maxHeight: "40%" }}>
              <Sheet.Header />
              <Sheet.Content>
                <div className="cont">
                  <div className="otp-section otp-section-card">
                    <p className="acc">Enter event access code</p>
                    <div className="otp-inputs">
                      {otp.map((value, index) => (
                        <React.Fragment key={index}>
                          <input
                            ref={(el) => (inputRefs.current[index] = el)}
                            type="text"
                            maxLength={1}
                            className="hotp"
                            value={value}
                            onChange={(e) => handleOtpChange(index, e.target.value)}
                            onKeyDown={(e) => handleKeyDown(index, e)}
                          />
                          {(index + 1) % 3 === 0 && index < otp.length - 1 && (
                            <span className="hyphen homehyphen">-</span>
                          )}
                        </React.Fragment>
                      ))}
                    </div>
                    <br />
                    <br />
                    <div className="">
                      <button className="join-button home_join" onClick={handleJoinClick}>Join</button>
                    </div>
                  </div>
                </div>
              </Sheet.Content>
            </Sheet.Container>
            <Sheet.Backdrop />
          </Sheet>
        </div>
      </div>




      {/* desktop containeer */}
      <div className="desktop-view">
        <div className="home-screen-desktop phone-container-home font">
          <div className="headerss">
            <div><img src="assets/logoo.svg" alt="Center Logo" /></div>
            <div className="top-icons-home-screen">
               <span onClick={handleOpen}><Bell className="icon interest_bell right-icon1" /></span>
              <div><img className="h_image" src="assets/deep.png" alt="Center Logo" onClick={() => navigate('/profile-page')} /></div>
              {/* {showModal && (
                <div className="notification-modal-not123" style={{position: 'fixed', top: '45%', left: '75%', transform: 'translate(-50%, -50%)', zIndex: 1000}}>
                  <div className="modal-content-not" style={{width: '40%',  maxHeight: '70vh', overflow: 'auto'}}>
                    <button className="close-modal-not" onClick={handleClose}>X</button> 
                    <NotificationModal/> 
                  </div>
                </div>
              )} */}
              {showModal && (
                <div 
                  onClick={handleClose}
                  style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-start',
                    zIndex: 1000
                  }}
                >
                  <div 
                    onClick={(e) => e.stopPropagation()}
                    style={{
                      width: '400px',
                      maxHeight: '80vh',
                      overflow: 'auto',
                      backgroundColor: 'white',
                      marginTop: '60px',
                      marginRight: '20px',
                      borderRadius: '8px',
                      padding: '20px',
                      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
                    }}
                  >
                    <NotificationModal />
                  </div>
                </div>
              )}
            </div>
        
          </div>
          <div className="home-desk">
            <div className="search-bars-home no-margin">
              <div className="search-input-wrapper-desktop">
                <img className="search-icon" src="assets/search-1.svg" alt="" />
                <input type="text" placeholder="Search" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} className="search-input" />
              </div>
              <div className="interest_filter_btns">
                <img src="assets/filter.svg" alt="Center Logo" className="filter_image" />
              </div>
            </div>

            <div>
              <button className="JoinAccess-desktop" onClick={() => handleCardClick(events.event_id, events.event_url)}>Join Using Access Code</button>
            </div>

          </div>

          {/* Live Events Dropdown */}
          <div className="live-events-header" onClick={() => setLiveOpen(!isLiveOpen)}>
            <h5 className="_mainheader_live">Live Events</h5>
            <img
              className={`liveevents_arrow`}
              src={isLiveOpen ? "/assets/Icon-u.png" : "/assets/Icon-d.png"}
              alt="Toggle Arrow"
            />
          </div>
          {isLiveOpen && (
            <div className="screen-desktop" onClick={() => {
              redirectUserToTheHome(events.event_id,events.event_url)
            }}>
              <div className="live-events-container-desktop">
                <div className="live-events-card">
                  <Carousel
                    showThumbs={false}
                    showStatus={false}
                    infiniteLoop
                    useKeyboardArrows
                    autoPlay
                    interval={5000}
                  >
                    <div>
                      <img src={events.poster_thumbnail} alt="Slide 1" />
                    </div>
                    <div>
                      <img src={events.poster_thumbnail} alt="Slide 2" />
                    </div>
                    <div>
                      <img src={events.poster_thumbnail} alt="Slide 3" />
                    </div>
                  </Carousel>
                  <div className="live-button-container">
                    <div className="live-button">
                      <div className="live-dot"></div>
                      Live
                    </div>
                  </div>
                  <div className="conference-title">
                    <img
                      className="card_heading_image"
                      src={events.att_app_logo}
                      alt=""
                    />
                    <h3 className="home_card_header">{events.name}</h3>
                  </div>
                  <div className="event-details">
                    <div className="details">
                      <p className="det1">
                        <span>
                          <img
                            src="/assets/Address.svg"
                            alt="Address Icon"
                            className="small-icon"
                          />
                        </span>
                        <span>Address: {events.city}</span>
                      </p>
                      <p className="det1">
                        <span>
                          <img
                            src="/assets/calendar-1.svg"
                            alt="Date Icon"
                            className="small-icon"
                          />
                        </span>
                        <span>
                          {events.start_date} - {events.end_date}
                        </span>
                      </p>
                      <p className="det1">
                        <span>
                          <img
                            src="/assets/clock-rewind.svg"
                            alt="Time Icon"
                            className="small-icon"
                          />
                        </span>
                        <span>Time: {events.start_time}</span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="live-events-card" onClick={handleCardClick}>
                  <div className="carousel-container">
                    <Carousel
                      showThumbs={false}
                      showStatus={false}
                      infiniteLoop
                      useKeyboardArrows
                      autoPlay
                      interval={5000}
                    >
                      <div>
                        <img src="assets/blue.jpeg" alt="Slide 1" />
                      </div>
                      <div>
                        <img src="assets/blue.jpeg" alt="Slide 2" />
                      </div>
                      <div>
                        <img src="assets/blue.jpeg" alt="Slide 3" />
                      </div>
                    </Carousel>
                    <div className="live-button-container">
                      <div className="live-button">
                        <div className="live-dot"></div>
                        Live
                      </div>
                    </div>
                    <div className="conference-title">
                      <img className="card_heading_image" src="assets/ely.png" alt="" />
                      <h3 className="home_card_header">
                        International Conference <br /> for Latest Developments
                        <br /> in Communication Technologies (2024)
                      </h3>
                    </div>
                  </div>

                  <div className="event-details">
                    <div className="details">
                      <p className="det1">
                        <span>
                          <img
                            src="/assets/Address.svg"
                            alt="Address Icon"
                            className="small-icon"
                          />
                        </span>
                        <span>Address: 123 Event St, City, Country</span>
                      </p>
                      <p className="det1">
                        <span>
                          <img
                            src="/assets/calendar-1.svg"
                            alt="Date Icon"
                            className="small-icon"
                          />
                        </span>
                        <span>Date: September 10, 2024</span>
                      </p>
                      <p className="det1">
                        <span>
                          <img
                            src="/assets/clock-rewind.svg"
                            alt="Time Icon"
                            className="small-icon"
                          />
                        </span>
                        <span>Time: 10:00 AM - 2:00 PM</span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="live-events-card">
                  <Carousel
                    showThumbs={false}
                    showStatus={false}
                    infiniteLoop
                    useKeyboardArrows
                    autoPlay
                    interval={5000}
                  >
                    <div>
                      <img src={events.poster_thumbnail} alt="Slide 1" />
                    </div>
                    <div>
                      <img src={events.poster_thumbnail} alt="Slide 2" />
                    </div>
                    <div>
                      <img src={events.poster_thumbnail} alt="Slide 3" />
                    </div>
                  </Carousel>
                  <div className="live-button-container">
                    <div className="live-button">
                      <div className="live-dot"></div>
                      Live
                    </div>
                  </div>
                  <div className="conference-title">
                    <img
                      className="card_heading_image"
                      src={events.att_app_logo}
                      alt=""
                    />
                    <h3 className="home_card_header">{events.name}</h3>
                  </div>
                  <div className="event-details">
                    <div className="details">
                      <p className="det1">
                        <span>
                          <img
                            src="/assets/Address.svg"
                            alt="Address Icon"
                            className="small-icon"
                          />
                        </span>
                        <span>Address: {events.city}</span>
                      </p>
                      <p className="det1">
                        <span>
                          <img
                            src="/assets/calendar-1.svg"
                            alt="Date Icon"
                            className="small-icon"
                          />
                        </span>
                        <span>
                          {events.start_date} - {events.end_date}
                        </span>
                      </p>
                      <p className="det1">
                        <span>
                          <img
                            src="/assets/clock-rewind.svg"
                            alt="Time Icon"
                            className="small-icon"
                          />
                        </span>
                        <span>Time: {events.start_time}</span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="live-events-card" onClick={handleCardClick}>
                  <div className="carousel-container">
                    <Carousel
                      showThumbs={false}
                      showStatus={false}
                      infiniteLoop
                      useKeyboardArrows
                      autoPlay
                      interval={5000}
                    >
                      <div>
                        <img src="assets/blue.jpeg" alt="Slide 1" />
                      </div>
                      <div>
                        <img src="assets/blue.jpeg" alt="Slide 2" />
                      </div>
                      <div>
                        <img src="assets/blue.jpeg" alt="Slide 3" />
                      </div>
                    </Carousel>
                    <div className="live-button-container">
                      <div className="live-button">
                        <div className="live-dot"></div>
                        Live
                      </div>
                    </div>
                    <div className="conference-title">
                      <img className="card_heading_image" src="assets/ely.png" alt="" />
                      <h3 className="home_card_header">
                        International Conference <br /> for Latest Developments
                        <br /> in Communication Technologies (2024)
                      </h3>
                    </div>
                  </div>

                  <div className="event-details">
                    <div className="details">
                      <p className="det1">
                        <span>
                          <img
                            src="/assets/Address.svg"
                            alt="Address Icon"
                            className="small-icon"
                          />
                        </span>
                        <span>Address: 123 Event St, City, Country</span>
                      </p>
                      <p className="det1">
                        <span>
                          <img
                            src="/assets/calendar-1.svg"
                            alt="Date Icon"
                            className="small-icon"
                          />
                        </span>
                        <span>Date: September 10, 2024</span>
                      </p>
                      <p className="det1">
                        <span>
                          <img
                            src="/assets/clock-rewind.svg"
                            alt="Time Icon"
                            className="small-icon"
                          />
                        </span>
                        <span>Time: 10:00 AM - 2:00 PM</span>
                      </p>
                    </div>
                  </div>
                </div>


              </div>
            </div>
          )}

          {/* Upcoming Events Dropdown */}
          <div className="live-events-header" onClick={() => setUpcomingOpen(!isUpcomingOpen)}>
            <h5 className="_mainheader_live">Upcoming Events</h5>
            <img
              className={`liveevents_arrow`}
              src={isUpcomingOpen ? "/assets/Icon-u.png" : "/assets/Icon-d.png"}
              alt="Toggle Arrow"
            />
          </div>
      

          {/* Past Events Dropdown */}
          <div className="live-events-header" onClick={() => setPastOpen(!isPastOpen)}>
            <h5 className="_mainheader_live">Past Events</h5>
            <img
              className={`liveevents_arrow`}
              src={isPastOpen ? "/assets/Icon-u.png" : "/assets/Icon-d.png"}
              alt="Toggle Arrow"
            />
          </div>


       

          {/* OTP Modal */}
          <div className={`homes-modal ${isOpen ? "open" : ""}`}>
      <div className="homes-modal-content">
        <h2 className="acc">Enter Event Access Code</h2>
        <div className="homes-otp-section homes-otp-section-card">
          <div className="otp-inputs pin">
            {otp.map((value, index) => (
              <React.Fragment key={index}>
                <input
                  ref={(el) => (inputRefs.current[index] = el)}
                  type="text"
                  maxLength={1}
                  className="homes-hotp"
                  value={value}
                  onChange={(e) => handleOtpChange(index, e.target.value)}
                  onKeyDown={(e) => handleKeyDown(index, e)}
                />
                {(index + 1) % 3 === 0 && index < otp.length - 1 && (
                  <span className="homes-hyphen homes-homehyphen">-</span>
                )}
              </React.Fragment>
            ))}
          </div>
          <br />
          <br />
          <div className="">
            <button className="join-button home_join" onClick={handleJoinClick}>
              Join
            </button>
          </div>
        </div>
      </div>
      <div className="homes-modal-backdrop" onClick={() => setOpen(false)}></div>
    </div>
        </div>
      </div>

    </>
  );
};

export default HomeScreen;
