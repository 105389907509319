import React, { useEffect, useState, useMemo, useRef } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./Agenda.css";
import { useNavigate } from "react-router-dom";
import Footer from "../Footer/Footer";
import SideNavbar from "../SideNavbar/SideNavbar";
import NotificationModal from "../Notification/NotificationModal/NotificationModal";
import { getTrackDetails } from "./../../crud/agenda.crud";
import { getFilterDetails } from "./../../crud/filter.crud";
import DateSelectorContainer from "./Datecard";
import EventAgenda from "../../CommomComponents/SessionDetailssDesktop";
import EventCard from "../../CommomComponents/SessionCard";
import EventFullScreen from "../EventDrawerDesktop/EventDrawerDesktop";
import EventDrawer from "../EventDrawer/EventDrawer";
import { ClipLoader } from "react-spinners";
import { useEvent } from "../../contexts/EventContext";
import { filterEntity } from "../../config/app";
import SearchAndFilter from "../../CommomComponents/SearchAndFilter/SearchAndFilter";
import SearchSection from "../../CommomComponents/SearchAndFilter/SearchSection";
import FilterSection from "../../CommomComponents/SearchAndFilter/FilterSection";
import ModuleHeading from "../../CommomComponents/ModuleHeading/ModuleHeading";
import { spinnerStyle } from "../../config/app";
import { getSessionDetails } from "./../../crud/agenda.crud";
import { getAllSpeakers } from "../../apis/speakers/speakers.crud";
import ModuleDescription from "../../CommomComponents/ModuleDescription/ModuleDescription";
import { useModule } from "../../contexts/ModuleContext";
import { getModuleDescriptionByName, getModuleNameByType } from "../../reusable/common";
import moment from "moment";

const Agenda: React.FC = () => {
	const navigate = useNavigate();
	const { eventDetails } = useEvent();
	const { modulesDetails } = useModule();
	const events = eventDetails;

	const searchParams = new URLSearchParams(location.search); // Parse the search string
	const sessionId = searchParams.get("sessionId");
	const speakerId = searchParams.get("speakerId");
	const [openDrawer, setOpenDrawer] = useState(false);

	const [searchTerm, setSearchTerm] = useState("");
	const [isFilterDrawerOpen, setFilterDrawerOpen] = useState(false);
	const [isEventDrawerOpen, setEventDrawerOpen] = useState(false);
	//const [events, setEvents] = useState<any>(eventDetails);
	const [dates, setDates] = useState([]);
	const [errorMessage, setErrorMessage] = useState("");
	const [isAgendaLoaded, setIsAgendaLoaded] = useState(false);
	const [agenda, setAgenda] = useState([]);
	const [modalOpenDesktop, setModalOpenDesktop] = useState(false);
	const [selectedDate, setSelectedDate] = useState<any>(null);
	const [showModal, setShowModal] = useState(false);
	const [modalData, setModalData] = useState<any>("");
	const [filterTags, setFilterTags] = useState<any>([]);
	const [loading, setLoading] = useState(false);
	const [agendaLoading, setAgendaLoading] = useState(false);
	const [filterData, setFilterData] = useState([]);
	const [filterLoading, setFilterLoading] = useState(false); // Loader state
	const [speakers, setSpeakers] = useState<object[]>([]);

	const moduleHeading = getModuleNameByType(modulesDetails, 8);

	const handleFilterClick = () => {
		setFilterDrawerOpen(true);
	};

	const handleOpen = () => {
		setShowModal(true);
	};

	const handleClose = (e: React.MouseEvent<HTMLDivElement>) => {
		if (e.target === e.currentTarget) {
			setShowModal(false);
		}
	};

	const fetchDates = (event: any) => {
		const startDate = new Date(event.start_date);
		const endDate = new Date(event.end_date);
		const dateArray = [];
		for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
			const year = date.getFullYear().toString();
			const month = (date.getMonth() + 1).toString().padStart(2, "0");
			const day = date.getDate().toString().padStart(2, "0");
			const formattedDate = `${year}-${month}-${day}`;
			dateArray.push({
				originalDate: new Date(date),
				formattedDate: formattedDate
			});
		}

		return dateArray;
	};

	const memoizedDates = useMemo(() => {
		if (events.start_date && events.end_date) {
			return fetchDates(events);
		}
		return [];
	}, [events.start_date, events.end_date]);

	useEffect(() => {
		if (memoizedDates.length) {
			setDates(memoizedDates);
			const today = new Date();
			const todayYear = today.getFullYear().toString();
			const todayMonth = (today.getMonth() + 1).toString().padStart(2, "0");
			const todayDay = today.getDate().toString().padStart(2, "0");
			const todayFormatted = `${todayYear}-${todayMonth}-${todayDay}`;
			if (moment.utc(new Date()).format("YYYY-MM-DD") >= eventDetails?.start_date) {
				setSelectedDate(todayFormatted);
			} else {
				if (
					memoizedDates.length > 0 &&
					!memoizedDates.some((dateObj: any) => dateObj.formattedDate === selectedDate)
				) {
					const firstDate = memoizedDates[0].formattedDate;
					setSelectedDate(firstDate);
				}
			}
		}
	}, [memoizedDates]);

	const handleEventClick = (modalData: any) => {
		setEventDrawerOpen(true);
	};

	const handleCloseEventDrawer = () => {
		setEventDrawerOpen(false);
		setOpenDrawer(false);
	};

	const goBack = () => {
		navigate("/");
	};

	const getAgendaFunction = async () => {
		setAgendaLoading(true);
		try {
			const res = await getTrackDetails(events.event_id);

			if (res.data) {
				setAgenda(res.data);
				setAgendaLoading(false);
			} else {
				setAgenda([]);
				setErrorMessage("No tracks available for the selected date.");
			}
		} catch (error) {
			setAgenda([]);
		} finally {
		}
	};
	const getSelectedTagsArray = tagsObject => {
		return Object.values(tagsObject).flat();
	};

	const fetchSpeakers = async () => {
		try {
			const response = await getAllSpeakers(eventDetails?.event_url);
			let _speakers = [...response?.data];
			setSpeakers(_speakers);
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getFilters();
		fetchSpeakers();
	}, []);

	const selectedTagNames = getSelectedTagsArray(filterTags);

	const filteredEvents = agenda
		.filter(session => {
			// Match based on session title
			const matchesSearch = session.session_title
				.toLowerCase()
				.includes(searchTerm.toLowerCase());

			const sessionTagNames = session.tags.map(tag => tag.name);
			const matchesTags =
				selectedTagNames.length === 0 ||
				sessionTagNames.some(tagName => selectedTagNames.includes(tagName));

			const matchSpeaker = session.session_speakers.some(speaker =>
				speaker.name.toLowerCase().includes(searchTerm.toLowerCase())
			);

			const sessionDate = session.start_timestamp.split(" ")[0];
			const matchesDate = !selectedDate || sessionDate === selectedDate;

			return (matchesSearch || matchSpeaker) && matchesTags && matchesDate;
		})
		.sort((a, b) => new Date(a.start_timestamp) - new Date(b.start_timestamp));

	const getFilters = async () => {
		setFilterLoading(true);
		try {
			const res = await getFilterDetails(eventDetails.event_id, filterEntity.AGENDA);
			if (res.data.length > 0) {
				setFilterData(res.data);
			} else {
				setFilterData([]);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setFilterLoading(false);
		}
	};

	useEffect(() => {
		if (!isAgendaLoaded) {
			getAgendaFunction();
			setIsAgendaLoaded(true);
		}
	}, [selectedDate, isAgendaLoaded]);

	return (
		<>
			{modalOpenDesktop ? (
				<EventFullScreen
					setModalOpenDesktop={setModalOpenDesktop}
					modalData={modalData}
					eventsData={events}
					setModalData={setModalData}
					speakers={speakers}
				/>
			) : (
				<div className="desktop-view">
					<div className="desktop-view-side">
						<SideNavbar />
						{loading ? (
							<div style={spinnerStyle}>
								<ClipLoader
									loading={loading}
									size={50}
									aria-label="Loading Spinner"
								/>
							</div>
						) : (
							<div className="home-screens-dekstop desktop-left-container font">
								<div className="fixed-till-date">
									<div className="agenda_sec">
										<ModuleHeading
											moduleHeading={moduleHeading}
											goBack={goBack}
										/>
										<ModuleDescription
											description={getModuleDescriptionByName(
												modulesDetails,
												moduleHeading
											)}
										/>
										{showModal && (
											<div
												onClick={handleClose}
												style={{
													position: "fixed",
													top: 0,
													left: 0,
													right: 0,
													bottom: 0,
													backgroundColor: "rgba(0, 0, 0, 0.5)",
													display: "flex",
													justifyContent: "flex-end",
													alignItems: "flex-start",
													zIndex: 1000
												}}
											>
												<div
													onClick={e => e.stopPropagation()}
													style={{
														width: "400px",
														maxHeight: "80vh",
														overflow: "auto",
														backgroundColor: "white",
														marginTop: "60px",
														marginRight: "20px",
														borderRadius: "8px",
														padding: "20px",
														boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)"
													}}
												>
													<NotificationModal />
												</div>
											</div>
										)}
										<div className="event-section">
											<div className="start-items">
												<div className="agenda_sec">
													<div
														className="event-section  mt-0 "
														style={{ whiteSpace: "nowrap" }}
													>
														<div className="start-items">
															<h4 className="event-agenda-head">
																Event Agenda
															</h4>
														</div>
													</div>
												</div>
											</div>
											<div className="end-items">
												<div className="search-bar_network">
													<div className="search-bars no-margin w-100">
														<div
															className="search-input-wrapper"
															style={{ maxWidth: "100%" }}
														>
															<SearchSection
																searchTerm={searchTerm}
																setSearchTerm={setSearchTerm}
																handleFilterClick={
																	handleFilterClick
																}
															/>
														</div>
													</div>
													<div className="download-section">
														<FilterSection
															filterLoading={filterLoading}
															filterData={filterData}
															getFilters={getFilters}
															setFilterTags={setFilterTags}
															filterTags={filterTags}
														/>
													</div>
												</div>
											</div>
										</div>
										<div>
											<DateSelectorContainer
												dates={dates}
												selectedDate={selectedDate}
												setSelectedDate={date => {
													setSelectedDate(date);
												}}
												getAgendaFunction={getAgendaFunction}
											/>
										</div>
									</div>
								</div>
								<br></br>
								<div className="scrollable-div">
									{agendaLoading ? (
										<div style={spinnerStyle}>
											<ClipLoader
												loading={agendaLoading}
												size={50}
												aria-label="Loading Spinner"
											/>
										</div>
									) : filteredEvents && filteredEvents?.length > 0 ? (
										filteredEvents?.map((event, index) => (
											<EventAgenda
												key={index}
												data={event}
												handleEventClick={handleEventClick}
												setModalOpenDesktop={setModalOpenDesktop}
												setModalData={setModalData}
												loading={agendaLoading}
												eventsData={events}
											/>
										))
									) : (
										<div className="no-results" style={{ textAlign: "center" }}>
											No Sessions found
										</div>
									)}
								</div>
								<br />
								<br />
							</div>
						)}
					</div>
				</div>
			)}

			{loading ? (
				<div style={spinnerStyle}>
					<ClipLoader loading={loading} size={50} aria-label="Loading Spinner" />
				</div>
			) : (
				<div className="phone-view h-100">
					<div className="home-screens-mobile agenda-container phone-container-home font h-100">
						<div className="fixed-till-date">
							<div className="agenda_sec">
								<ModuleHeading
									moduleHeading={moduleHeading}
									goBack={() => {
										goBack();
									}}
								/>
								<ModuleDescription
									description={getModuleDescriptionByName(
										modulesDetails,
										moduleHeading
									)}
								/>

								<div className="search-bar_network mt-3 mb-3">
									<div className="search-bars no-margin w-100">
										<div
											className="search-input-wrapper"
											style={{ maxWidth: "100%" }}
										>
											<SearchSection
												searchTerm={searchTerm}
												setSearchTerm={setSearchTerm}
												handleFilterClick={handleFilterClick}
											/>
										</div>
									</div>
									{
										<div className="download-section">
											<FilterSection
												filterLoading={filterLoading}
												filterData={filterData}
												getFilters={getFilters}
												setFilterTags={setFilterTags}
												handleFilterClick={handleFilterClick}
												showSearchbox={false}
												filterTags={filterTags}
											/>
										</div>
									}
								</div>
								<DateSelectorContainer
									dates={dates}
									selectedDate={selectedDate}
									setSelectedDate={date => {
										setSelectedDate(date);
									}}
									getAgendaFunction={getAgendaFunction} // Pass it here
								/>
							</div>
							<br></br>
							<div className="eventCard-container_agenda">
								{agendaLoading ? (
									<div style={spinnerStyle}>
										<ClipLoader
											loading={agendaLoading}
											size={50}
											aria-label="Loading Spinner"
										/>
									</div>
								) : filteredEvents && filteredEvents?.length > 0 ? (
									filteredEvents?.map((event, index) => (
										<EventCard
											key={index}
											data={event}
											setModalData={setModalData}
											handleEventClick={handleEventClick}
											loading={agendaLoading}
											eventsData={events}
										/>
									))
								) : (
									<div className="no-results" style={{ textAlign: "center" }}>
										No Sessions found
									</div>
								)}
								{/* {filteredEvents?.length > 0 ? (
									filteredEvents.map((event, index) => (
										<EventCard
											key={index}
											data={event}
											setModalData={setModalData}
											handleEventClick={handleEventClick}
											loading={agendaLoading}
											eventsData={events}
										/>
									))
								) : (
									<div className="no-results">No session found</div>
								)} */}
							</div>
							<EventDrawer
								open={isEventDrawerOpen}
								onOpen={() => {
									setEventDrawerOpen(true);
								}}
								onClose={handleCloseEventDrawer}
								modalData={modalData}
								eventsData={events}
								speakers={speakers}
								setModalData={setModalData}
								setOpenDrawer={setOpenDrawer}
								openDrawer={openDrawer}
							/>
						</div>
					</div>
				</div>
			)}
			<Footer />
		</>
	);
};

export default Agenda;
