import axios from "axios";
import { apiUrl } from "./config/crud";

const api = axios.create({
	baseURL: apiUrl,
	headers: {
		"Content-Type": "application/json"
	}
});

export let jwtToken = "";

export const setJwtToken = (token: string) => {
	jwtToken = token;
};

axios.interceptors.request.use(
	async config => {
		const token = jwtToken;
		if (token !== undefined && token !== "") {
			if (!config.headers["authorization"] && !config.headers["Authorization"])
				config.headers["Authorization"] = `Bearer ${token}`;
		}
		config["Accept-Encoding"] = "gzip, compress, deflate, br";
		return config;
	},
	error => {
		return Promise.reject(error);
	}
);

export default api;
