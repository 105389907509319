import React from "react";
import "./ShowFormikError.css";

interface ShowFormikErrorProps {
    error: any
}

const ShowFormikError: React.FC<ShowFormikErrorProps> = ({error}) => {
    return (
        <>
            <div className="modules-error mt-1">
                {error}
            </div>
        </>
    )
}

export default ShowFormikError;