import React, { useState } from 'react';
import { Modal, Button, Checkbox, FormControlLabel, Typography, Box } from '@mui/material';
import BusinessCard from '../../ProfileView/BusinessCard/BusinessCard';

interface EditModalProps {
  open: boolean;
  onClose: () => void;
}

const socialLinks = [
  { icon: 'assets/icons/linkdin.svg', url: 'https://in.linkedin.com' },
  { icon: 'assets/icons/tweet.svg', url: 'https:/x.com' },
  { icon: 'assets/icons/whatsapp1.svg', url: 'https://whatsapp.com' },
  { icon: 'assets/icons/glob.svg', url: 'mailto:angyl@example.com' },
];

const EditModal: React.FC<EditModalProps> = ({ open, onClose }) => {
  const [checkboxes, setCheckboxes] = useState({
    firstName: false,
    lastName: false,
    designation: false,
    company: false,
    whatsapp: false,
    linkedin: false,
    twitter: false,
    website: false,
    email: false,
    phoneNumber: false,
    photo: false,
  });

  const isAnyCheckboxChecked = Object.values(checkboxes).some(checked => checked);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, name: string) => {
    setCheckboxes({ ...checkboxes, [name]: event.target.checked });
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="edit-modal-title"
      sx={{
        '& .MuiModal-paper': {
          border: 'none', // Remove the black border
        },
      }}

      aria-describedby="edit-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '48%',
          maxWidth: 900,
          height:'45%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: '6px',
          maxHeight: '90vh',
          overflowY: 'auto',
        }}
      >
        <Typography id="edit-modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
          Edit
        </Typography>

        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '10px' }}>
          <div>
            <BusinessCard
              name="Anoyiki Soe"
              title="Investor, Infy"
              avatarUrl="assets/lisa.png"
              qrCodeUrl="assets/photos/QR.png"
              socialLinks={socialLinks}
            />
          </div>

          <div>
            {['firstName', 'designation', 'whatsapp', 'twitter', 'photo'].map((key) => (
              <FormControlLabel
                key={key}
                control={
                  <Checkbox
                    checked={checkboxes[key as keyof typeof checkboxes]}
                    onChange={(e) => handleCheckboxChange(e, key)}
                  />
                }
                label={key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                sx={{ mt: 0, mb: 0, pt: 0, pb: 0 }} // Remove padding and margin

              />
            ))}
          </div>

          <div>
            {['lastName', 'company', 'linkedin', 'website', 'phoneNumber'].map((key) => (
              <FormControlLabel
                key={key}
                control={
                  <Checkbox
                    checked={checkboxes[key as keyof typeof checkboxes]}
                    onChange={(e) => handleCheckboxChange(e, key)}
                  />
                }
                label={key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                sx={{ mt: 0, mb: 0, pt: 0, pb: 0 }} // Remove padding and margin

              />
            ))}
          </div>
        </div>

        <Button
          variant="contained"
          sx={{
            backgroundColor: isAnyCheckboxChecked ? '#002E6E' : '#7A7C8A',
            color: 'white',
            position: 'absolute',
            right: '30px',
            bottom: '20px',
            width:'100px',
            '&:hover': {
              backgroundColor: isAnyCheckboxChecked ? '#002E6E' : '#7A7C8A',
            },
          }}
        >
          Save
        </Button>
      </Box>
    </Modal>
  );
};

export default EditModal;