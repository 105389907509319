import React from 'react';
import './Badge.css';

interface BadgeProps {
  eventName: string;
  eventLogo: string;
  location: string;
  organizer: string;
  date: string;
  time: string;
  qrCode: string;
}

const Badge: React.FC<BadgeProps> = ({
  eventName,
  eventLogo,
  location,
  organizer,
  date,
  time,
  qrCode,
}) => {
  return (
    <> 
    <div className='phone-view'>
    <div className="ticket-card-container">
      <div className="ticket-card">
        {/* Event Logo */}
        <div className="ticket-logo-container">
          <img src="assets/ely.png" alt="Event Logo" className="ticket-logo" />
        </div>

        {/* Event Name */}
        <div className="ticket-title">
          <div className='nameee'>{eventName}</div>
        </div>

        {/* Event Details */}
        <div className="badge__">
          <div className="badge-loc">
            <div className="base-line-align">
              <img src="assets/icons/Address.svg" alt="Location Icon" className="icons-size-loc" />
              <span className="event-detail-badge">Location</span>
            </div>
            <div className="badge-ticket-dec">{location}</div>
          </div>

          <div className="badge-loc">
            <div className="base-line-align">
              <img src="assets/userss.svg" alt="Organizer Icon" className="icons-size-loc" />
              <span className="event-detail-badge">Organized by</span>
            </div>
            <div className="badge-ticket-dec">{organizer}</div>
          </div>

          <div className="date-time-badge">
            <div className="badge-loc">
              <div className="base-line-align">
                <img src="assets/calendars.svg" alt="Date Icon" className="icons-size-loc" />
                <span className="event-detail-badge">Date</span>
              </div>
              <div className="badge-ticket-decs">{date}</div>
            </div>
            <div className="badge-loc">
              <div className="base-line-align">
                <img src="assets/icons/clock-rewind.svg" alt="Time Icon" className="icons-size-loc" />
                <span className="event-detail-badge">Time</span>
              </div>
              <div className="badge-ticket-decs">{time}</div>
            </div>
          </div>
        </div>

        {/* Divider */}
        <div className="divider">
          ----------------------------------------
        </div>

        {/* QR Code */}
        <div className="qr-code-container">
          <img src={qrCode} alt="QR Code" className="qr-code-badge" />
        </div>
      </div>
    </div>
    </div>

    <div className='desktop-view'>
    <div className="ticket-card-container">
      <div className="ticket-card-desktop">
        {/* Event Logo */}
        <div className='ticket1'> 
        <div className="ticket-logo-container">
          <img src="assets/ely.png" alt="Event Logo" className="ticket-logo" />
        </div>

        {/* Event Name */}
        <div className="ticket-title">
          <div className='nameee-desktop'>{eventName}</div>
        </div>

        {/* Event Details */}
        <div className="badge__desktop">
          <div className="badge-loc">
            <div className="base-line-align">
              <img src="assets/icons/Address.svg" alt="Location Icon" className="icons-size-loc" />
              <span className="event-detail-badge">Location</span>
            </div>
            <div className="badge-ticket-dec">{location}</div>
          </div>

          <div className="badge-loc">
            <div className="base-line-align">
              <img src="assets/userss.svg" alt="Organizer Icon" className="icons-size-loc" />
              <span className="event-detail-badge">Organized by</span>
            </div>
            <div className="badge-ticket-dec">{organizer}</div>
          </div>

          <div className="date-time-badge">
            <div className="badge-loc">
              <div className="base-line-align">
                <img src="assets/calendars.svg" alt="Date Icon" className="icons-size-loc" />
                <span className="event-detail-badge">Date</span>
              </div>
              <div className="badge-ticket-decs">{date}</div>
            </div>
            <div className="badge-loc">
              <div className="base-line-align">
                <img src="assets/icons/clock-rewind.svg" alt="Time Icon" className="icons-size-loc" />
                <span className="event-detail-badge">Time</span>
              </div>
              <div className="badge-ticket-decs">{time}</div>
            </div>
          </div>
        </div>
        </div>
        {/* Divider */}
        <div className="view-dashed-vertical-line"></div>


        {/* QR Code */}
        <div className="qr-code-container">
          <img src={qrCode} alt="QR Code" className="qr-code-badge-desktop" />
        </div>


      </div>
    </div>
    </div>
    

    </>
  );
};

export default Badge;
