import api from "../../api";

const EVENT_PUBLIC = "/event/public/";

// Get list of speakers
export const getAllSpeakers = (url: string) => {
    return api.get(
        EVENT_PUBLIC + (url) + "/speakers"
      );
}
