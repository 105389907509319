
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Auth } from "aws-amplify";
import './VerifyOtp.css';

const VerifyOtp: React.FC = () => {
    const [otp, setOtp] = useState(Array(6).fill(''));
    const [timer, setTimer] = useState(30);
    const [verificationError, setVerificationError] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const email = location.state?.email;
    const isLogin = location.state?.isLogin;

    useEffect(() => {
        const countdown = setInterval(() => {
            setTimer(prev => (prev > 0 ? prev - 1 : 0));
        }, 1000);

        return () => clearInterval(countdown);
    }, []);

   



    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const value = e.target.value;
        if (!/^\d*$/.test(value)) return;
    
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);
    
        if (value && index < 5) {
            setTimeout(() => {
                const nextInput = document.getElementById(`otp-input-${index + 1}`);
                nextInput?.focus();
            }, 50); // Small delay to ensure state is updated first
        }
    };
    

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        if (e.key === 'Backspace' && !otp[index] && index > 0) {
            const newOtp = [...otp];
            newOtp[index - 1] = '';
            setOtp(newOtp);
            const prevInput = document.getElementById(`otp-input-${index - 1}`);
            prevInput?.focus();
        }
    };

    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
        const paste = e.clipboardData.getData('text');
        if (/^\d{6}$/.test(paste)) {
            const newOtp = paste.split('');
            setOtp(newOtp);
            const nextInput = document.getElementById(`otp-input-5`);
            nextInput?.focus();
        }
        e.preventDefault();
    };

    const handleResendOtp = () => {
        // TODO: Implement resend OTP functionality with AWS Amplify
        setOtp(Array(6).fill(''));
        setTimer(30);
        setVerificationError('');
    };

    const isOtpValid = otp.every(num => num !== '');

    const handleVerify = async () => {
        // if (isOtpValid && email) {
        //     try {
        //         // Attempt to confirm sign up
        //         await confirmSignUp({
        //             username: email,
        //             confirmationCode: otp.join('')
        //         });

        //         console.log("Sign-up confirmation successful");
        //         setVerificationError('');
        //         navigate('/welcome'); // or wherever you want to redirect after successful confirmation

        //     } catch (error) {
        //         console.error('Error verifying OTP:', error);
        //         if (error instanceof Error) {
        //             if (error.message.includes('User cannot be confirmed. Current status is CONFIRMED')) {
        //                 // User is already confirmed, attempt to sign in
        //                 try {
        //                     const { isSignedIn } = await signIn({ username: email, password: location.state?.password });
        //                     if (isSignedIn) {
        //                         navigate('/welcome');
        //                     } else {
        //                         navigate('/welcome');
        //                     }
        //                 } catch (signInError) {
        //                     console.error('Error signing in:', signInError);
        //                     navigate('/welcome');
        //                 }
        //             } else {
        //                 if(error.message == "Invalid verification code provided, please try again."){
        //                     setVerificationError(`Error: ${error.message}`);
        //                 }
        //                 else{
        //                     console.log("heoo");
        //                     navigate('/welcome');
        //                 }
        //             }
        //         } else {
        //             setVerificationError('An unknown error occurred. Please try again.');
        //         }
        //     }
        // } else {
        //     setVerificationError('Please ensure all fields are filled correctly.');
        // }
    };

    return (
        <> 
        <div className='phone-view'>
        <div className='font '>
            <div><img src="assets/Logo.svg" alt="Center Logo" className="logo4" /></div>
            <div className="verify-otp-container">
                <h3 className='vat'>Verify OTP </h3>
                <div className="otp-inputss">
                    {otp.map((value, index) => (
                        <React.Fragment key={index}>
                            <input
                                id={`otp-input-${index}`}
                                type="text"
                                maxLength={1}
                                value={value}
                                onChange={(e) => handleChange(e, index)}
                                onKeyDown={(e) => handleKeyDown(e, index)}
                                onPaste={handlePaste}
                            />
                            {(index + 1) % 3 === 0 && index < otp.length - 1 && (
                                <span className="hyphen">-</span>
                            )}
                        </React.Fragment>
                    ))}
                </div>
                {verificationError && <div className="error-message">{verificationError}</div>}
                <div className="timer-text">
                    {timer > 0 ? `00:${timer < 10 ? `0${timer}` : timer}` : '00:00'}
                </div>
                <div className='receive'>
                    Didn't receive OTP? <span className='resend-text' onClick={handleResendOtp}>Resend OTP</span>
                </div>
                
                <div className='bt1'>
                    <button
                        className={`verify-button ${isOtpValid ? 'enabled' : 'disabled'}`}
                        disabled={!isOtpValid}
                        onClick={handleVerify}
                    >
                        Verify
                    </button>
                </div>
            </div>
        </div>  
        </div>


        {/* desktop-view */}
        <div className="desktop-view">
        <div className="main-container">
         
          <div className="right-panel ">
            <div className="panel-desktop-otp">
            <div className='font '>
            <div><img src="assets/Logo.svg" alt="Center Logo" className="logo-desk-otp" /></div>
            <div className="verify-otp-container">
                <h3 className='vat'>Verify OTP </h3>
                <div className="otp-inputss">
                    {otp.map((value, index) => (
                        <React.Fragment key={index}>
                            <input
                                id={`otp-input-${index}`}
                                type="text"
                                maxLength={1}
                                value={value}
                                onChange={(e) => handleChange(e, index)}
                                onKeyDown={(e) => handleKeyDown(e, index)}
                                onPaste={handlePaste}
                            />
                            {(index + 1) % 3 === 0 && index < otp.length - 1 && (
                                <span className="hyphen">-</span>
                            )}
                        </React.Fragment>
                    ))}
                </div>
                {verificationError && <div className="error-message">{verificationError}</div>}
                <div className="timer-text">
                    {timer > 0 ? `00:${timer < 10 ? `0${timer}` : timer}` : '00:00'}
                </div>
                <div className='receive'>
                    Didn't receive OTP? <span className='resend-text' onClick={handleResendOtp}>Resend OTP</span>
                </div>
                
                <div className='bt1'>
                    <button
                        className={`verify-button ${isOtpValid ? 'enabled' : 'disabled'}`}
                        disabled={!isOtpValid}
                        onClick={handleVerify}
                    >
                        Verify
                    </button>
                </div>
            </div>
            <div className="powered-by">
                <span className="powered-text">Powered by</span>
                <img src="assets/Group.png" alt="Logo" className="logo1" />
            </div>
        </div>  
            </div>
           
          </div>
          <div className="left-panel"  style={{backgroundImage: `url('/assets/layesImage.svg')`}}>
            <div className="leftpanel-tag-container">
              <img src="assets/StarforLeft.svg" alt="this is star img" />
              <p className="tagline-for-leftpanel">
                Trusted by over 10,000 event organizers worldwide.
              </p>
            </div>
          </div>
        </div>
      </div>
        
        </>
    );
};

export default VerifyOtp;