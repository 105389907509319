import React, { useEffect, useState } from "react";
import "./SpeakerPage.css";
import Footer from "../Footer/Footer";
import SideNavbar from "../SideNavbar/SideNavbar";
import { getAllSpeakers } from "../../apis/speakers/speakers.crud";
import SpeakerList from "./SpeakerList";
import SpeakerDetailsComp from "./SpeakerDetailsComp/SpeakerDetailsComp";
import SearchAndFilter from "../../CommomComponents/SearchAndFilter/SearchAndFilter";
import SearchSection from "../../CommomComponents/SearchAndFilter/SearchSection";
import FilterSection from "../../CommomComponents/SearchAndFilter/FilterSection";
import ModuleHeading from "../../CommomComponents/ModuleHeading/ModuleHeading";
import { useNavigate } from "react-router-dom";

import { getFilterDetails } from "../../crud/filter.crud";
import EventFullScreen from "../EventDrawerDesktop/EventDrawerDesktop";
import EventDrawer from "../EventDrawer/EventDrawer";
import { useEvent } from "./../../contexts/EventContext";
import CustomDrawer from "../../CommomComponents/CustomDrawer/CustomDrawer";
import { getModuleDescriptionByName, getModuleNameByType } from "../../reusable/common";
import ModuleDescription from "../../CommomComponents/ModuleDescription/ModuleDescription";
import { useModule } from "../../contexts/ModuleContext";
import { filterEntity } from "../../config/app";

const SpeakerPage: React.FC = () => {
	const navigate = useNavigate();
	const { eventDetails } = useEvent();
	const { modulesDetails } = useModule();
	const eventData = eventDetails;
	const [searchTerm, setSearchTerm] = useState("");

	const [speakers, setSpeakers] = useState<object[]>([]);
	const [loading, setLoading] = useState(true);
	const [speakerDetails, setSpeakerDetails] = useState<object | null>(null);

	const [openDrawer, setOpenDrawer] = useState(false);

	const [modalOpenDesktop, setModalOpenDesktop] = useState<boolean>(false);
	const [modalData, setModalData] = useState<object | null>(null);
	const [isSessionDrawerOpen, setIsSessionDrawerOpen] = useState(false);

	const [filterData, setFilterData] = useState([]);
	const [filterLoading, setFilterLoading] = useState(false); // Loader state
	const [filterTags, setFilterTags] = useState<any>({});

	const moduleHeading = getModuleNameByType(modulesDetails, 2);

	const getFilters = async () => {
		setFilterLoading(true);
		try {
			const res = await getFilterDetails(eventData.event_id, filterEntity.SPEAKERS);
			if (res.data.length > 0) {
				setFilterData(res.data);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setFilterLoading(false);
		}
	};

	const goBack = () => {
		setSpeakerDetails(null);
		setOpenDrawer(false);
		navigate("/");
	};

	const fetchSpeakers = async () => {
		try {
			const response = await getAllSpeakers(eventDetails?.event_url);
			let _speakers = [...response?.data];
			setSpeakers(_speakers);
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (loading) {
			if (eventDetails?.event_id) {
				fetchSpeakers();
				getFilters();
			}
		}
	}, [loading, eventDetails]);

	return (
		<div className="h-100">
			{modalOpenDesktop ? (
				<div className="hide-in-mobile">
					<EventFullScreen
						setModalOpenDesktop={setModalOpenDesktop}
						modalData={modalData}
						speakers={speakers}
						eventsData={eventDetails}
					/>
				</div>
			) : (
				<div className="h-100">
					<div className="d-flex h-100">
						<div className="hide-in-mobile">
							<SideNavbar />
						</div>
						<div className="h-100 w-100">
							<div className="speaker-container">
								<ModuleHeading
									moduleDetails={speakerDetails}
									goBack={goBack}
									moduleHeading={moduleHeading}
								/>
								{!openDrawer && (
									<ModuleDescription
										description={getModuleDescriptionByName(
											modulesDetails,
											moduleHeading
										)}
									/>
								)}
								<div className="search-bar_network-desktop">
									<div className="search-bars speaker-search no-margin">
										<div className="search-input-wrapper">
											<SearchSection
												searchTerm={searchTerm}
												setSearchTerm={setSearchTerm}
											/>
										</div>
									</div>

									<div className="download-section">
										<FilterSection
											filterLoading={filterLoading}
											filterData={filterData}
											filterTags={filterTags}
											getFilters={getFilters}
											setFilterTags={setFilterTags}
										/>
									</div>
								</div>

								<div className="hide-in-mobile">
									{speakerDetails ? (
										<>
											<div className="hide-in-mobile">
												<SpeakerDetailsComp
													setModalData={setModalData}
													speakerDetails={speakerDetails}
													setModalOpenDesktop={setModalOpenDesktop}
													speakers={speakers}
													setIsSessionDrawerOpen={setIsSessionDrawerOpen}
													eventDetails={eventDetails}
												/>
											</div>
										</>
									) : (
										<SpeakerList
											loading={loading}
											searchTerm={searchTerm}
											speakers={speakers}
											setSpeakerDetails={setSpeakerDetails}
											setOpenDrawer={setOpenDrawer}
											appliedTags={[
												...Object.values(filterTags).flat(Infinity)
											]}
										/>
									)}
								</div>
								<div className="hide-in-desktop">
									<SpeakerList
										loading={loading}
										searchTerm={searchTerm}
										speakers={speakers}
										setSpeakerDetails={setSpeakerDetails}
										setOpenDrawer={setOpenDrawer}
										appliedTags={[...Object.values(filterTags).flat(Infinity)]}
									/>
									<CustomDrawer
										onOpen={() => {
											setOpenDrawer(true);
										}}
										showModalAction={true}
										component={
											<SpeakerDetailsComp
												setModalData={setModalData}
												setModalOpenDesktop={setModalOpenDesktop}
												speakerDetails={speakerDetails}
												speakers={speakers}
												setIsSessionDrawerOpen={setIsSessionDrawerOpen}
												eventDetails={eventDetails}
											/>
										}
										onClose={() => {
											setOpenDrawer(false);
											setTimeout(() => {
												setSpeakerDetails(null);
											}, 500);
										}}
										open={openDrawer}
									/>
								</div>
							</div>
							<div className="hide-in-desktop">
								<Footer />
							</div>
						</div>
					</div>
				</div>
			)}
			{isSessionDrawerOpen && (
				<EventDrawer
					open={isSessionDrawerOpen}
					onOpen={() => {
						setIsSessionDrawerOpen(true);
					}}
					onClose={() => {
						setIsSessionDrawerOpen(false);
						setModalOpenDesktop(false);
					}}
					modalData={modalData}
					speakers={speakers}
					eventsData={eventDetails}
					setModalData={setModalData}
				/>
			)}
		</div>
	);
};

export default SpeakerPage;
