import React, { useState } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import "./MyProfileBusinessCard.css";
import BusinessCard from "../../ProfileView/BusinessCard/BusinessCard";
import { IconButton } from "@mui/material";
// import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";

import DeleteSocialIcon from "../DeleteSocialIcon/DeleteSocialIcon";
import EditSocialMediaDrawer from "../EditSocialMedia/EditSocialMedia";
import AddSocialMedia from "../AddSocialMedia/AddSocialMedia";
import EditDrawer from "../EditBussinessCard/EditBussinessCard";
import { Bell } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";
import SideNavbar from "../../SideNavbar/SideNavbar";
import EditModal from "../EditBusinessModal/EditBusinessModal";
import { Pagination } from "@mui/material";
// import EditSocialMediaModal from '../Modals/EditSocialMediaModal/EditSocialMediaModal';
import AddSocialMediaModal from "../Modals/AddSocialMediaModal/AddSocialMediaModal";
import DeleteSocialIconModal from "../Modals/DeleteSocialIconModal/DeleteSocialIconModal";
import SocialMediaList from "../../SocialMediaList/SocialMediaList";
import EditSocialMediaModal from "../Modals/EditSocialMediaModal/EditSocialMediaModal";

const RequestCard = ({ name, title, company, isPending }) => (
	<div className="request-card">
		<div className="request-info">
			<div className="avatar-desk">
				<img src="assets/deep.png" alt="" />
			</div>
			<div className="user-details">
				<div className="request-name">{name}</div>
				<div className="request-title">
					{title}, <span className="request-company">{company}</span>
				</div>
			</div>
		</div>
		<div className="action-buttons-desktop">
			{isPending ? (
				<button className="withdraw-btn">Withdraw</button>
			) : (
				<>
					<button className="decline_network-desktop">Decline</button>
					<button className="accept_network-desktop">Accept</button>
				</>
			)}
		</div>
	</div>
);

const MyProfileBusinessCard: React.FC = () => {
	const [tabValue, setTabValue] = useState(0);
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
	const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
	const [editingPlatform, setEditingPlatform] = useState("");
	const [editingUrl, setEditingUrl] = useState("");
	const [isAddDrawerOpen, setIsAddDrawerOpen] = useState(false);
	const [addingPlatform, setAddingPlatform] = useState("");
	const [isEditBusinessCardOpen, setIsEditBusinessCardOpen] = useState(false);

	const [activeRequestTab, setActiveRequestTab] = useState("Received Requests");
	const [showCards, setShowCards] = useState(true);
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 8;
	const handleRequestTabClick = tab => {
		setActiveRequestTab(tab);
		setShowCards(true);
		setCurrentPage(1);
	};

	const receivedRequestsData = [
		{ name: "Sam John", title: "Business Analyst", company: "Everest Group" },
		{ name: "Jane Doe", title: "Software Engineer", company: "Tech Solutions" },
		{ name: "Mike Smith", title: "Project Manager", company: "Innovative Corp" },
		{ name: "Sam John", title: "Business Analyst", company: "Everest Group" },
		{ name: "Jane Doe", title: "Software Engineer", company: "Tech Solutions" },
		{ name: "Mike Smith", title: "Project Manager", company: "Innovative Corp" }
	];

	const pendingRequestsData = [
		{ name: "Sam John", title: "Business Analyst", company: "Everest Group" },
		{ name: "Jane Doe", title: "Software Engineer", company: "Tech Solutions" },
		{ name: "Mike Smith", title: "Project Manager", company: "Innovative Corp" },
		{ name: "Sam John", title: "Business Analyst", company: "Everest Group" },
		{ name: "Jane Doe", title: "Software Engineer", company: "Tech Solutions" },
		{ name: "Mike Smith", title: "Project Manager", company: "Innovative Corp" }
	];

	const getCurrentPageData = () => {
		const data =
			activeRequestTab === "Pending Requests" ? pendingRequestsData : receivedRequestsData;
		const startIndex = (currentPage - 1) * itemsPerPage;
		return data.slice(startIndex, startIndex + itemsPerPage);
	};

	const handlePageChange = (event, value) => {
		setCurrentPage(value);
	};
	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		setTabValue(newValue);
	};
	// for edit drawer

	const handleOpenEditDrawer = (platform: string, url: string) => {
		setEditingPlatform(platform);
		setEditingUrl(url);
		setIsEditDrawerOpen(true);
	};

	const [showDownloadOptions, setShowDownloadOptions] = useState(false);
	const handleDownloadClick = () => {
		setShowDownloadOptions(!showDownloadOptions);
	};

	const handleCloseEditDrawer = () => {
		setIsEditDrawerOpen(false);
	};

	const handleSaveEditDrawer = (newUrl: string) => {
		// Implement the logic to save the new URL
		// Update the socialLink array with the new URL
		const updatedSocialLinks = socialLink.map(link =>
			link.platform === editingPlatform ? { ...link, url: newUrl } : link
		);
		// Update the state or send to backend
		// setSocialLink(updatedSocialLinks);
	};

	const handleOpenAddDrawer = (platform: string) => {
		setAddingPlatform(platform);
		setIsAddDrawerOpen(true);
	};

	const handleCloseAddDrawer = () => {
		setIsAddDrawerOpen(false);
	};

	const handleAddSocialMedia = (newUrl: string) => {
	};

	const [isEditModalOpen, setIsEditModalOpen] = useState(false);

	const handleOpenEditModal = () => {
		setIsEditModalOpen(true);
	};

	const handleCloseEditModal = () => {
		setIsEditModalOpen(false);
	};

	// for delete drawer start

	const handleOpenDeleteDialog = () => setIsDeleteDialogOpen(true);
	const handleCloseDeleteDialog = () => setIsDeleteDialogOpen(false);
	const handleConfirmDelete = () => {
		setIsDeleteDialogOpen(false);
	};

	// for delete drawer end
	interface SocialLink {
		id: number;
		platform: string;
		icon: string;
		url: string;
	}

	// for edit business drawer
	const handleOpenEditBusinessCard = () => {
		setIsEditBusinessCardOpen(true);
	};

	const handleCloseEditBusinessCard = () => {
		setIsEditBusinessCardOpen(false);
	};

	const socialLinks = [
		{ icon: "assets/icons/linkdin.svg", url: "https://in.linkedin.com" },
		{ icon: "assets/icons/tweet.svg", url: "https:/x.com" },
		{ icon: "assets/icons/whatsapp1.svg", url: "https://whatsapp.com" },
		{ icon: "assets/icons/glob.svg", url: "mailto:angyl@example.com" }
	];

	const socialLink: SocialLink[] = [
		{
			id: 1,
			platform: "WhatsApp",
			icon: "assets/whatsapp-blue.png",
			url: "https://www.linkedin.com/in/deepak-singh-ceo"
		},
		{
			id: 2,
			platform: "Twitter",
			icon: "assets/twitter-grey.png",
			url: "https://www.linkedin.com/in/deepak-singh-ceo"
		},
		{
			id: 3,
			platform: "LinkedIn",
			icon: "assets/linkdln-grey.png",
			url: "https://www.linkedin.com/in/deepak-singh-ceo"
		},
		{
			id: 4,
			platform: "Website",
			icon: "assets/globe-grey.png",
			url: "https://www.linkedin.com/in/deepak-singh-ceo"
		}
	];

	const [socialMediaList] = useState([
		{ platform: "WhatsApp", icon: "https://cdn-icons-png.flaticon.com/512/124/124034.png" },
		{ platform: "Twitter", icon: "https://cdn-icons-png.flaticon.com/512/124/124021.png" },
		{ platform: "LinkedIn", icon: "https://cdn-icons-png.flaticon.com/512/124/124011.png" },
		{ platform: "Website", icon: "https://cdn-icons-png.flaticon.com/512/1198/1198431.png" }
	]);

	const navigate = useNavigate();
	const goBack = () => {
		navigate(-1);
	};

	// for edit social media modal
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedPlatform, setSelectedPlatform] = useState("");
	const [selectedUrl, setSelectedUrl] = useState("");

	const handleOpenEditModals = (platform: string, url: string) => {
		setSelectedPlatform(platform);
		setSelectedUrl(url);
		setIsModalOpen(true); // Open the modal
	};

	const handleCloseModal = () => {
		setIsModalOpen(false); // Close the modal
	};

	const handleSaveUrl = (newUrl: string) => {
		// Handle save logic here
	};

	// for add social media modal

	const [isAddModalOpen, setAddModalOpen] = useState(false);

	const openAddModal = () => {
		setAddModalOpen(true);
	};

	const closeAddModal = () => {
		setAddModalOpen(false);
	};

	const saveSocialMediaUrl = (newUrl: string) => {
	};

	// for delete social media modal
	const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
	const [selectedPlatforms, setSelectedPlatforms] = useState<string>(""); // State for the selected platform

	// Open the modal and set the selected platform

	// Close the delete modal
	const closeDeleteModal = () => {
		setDeleteModalOpen(false);
	};

	// Handle the confirm delete action
	const handleConfirmDeletes = () => {
		closeDeleteModal();
	};

	const socialPlatforms = ["Twitter", "Facebook", "Instagram"];

	const openDeleteModal = (platform: string | undefined) => {
		if (typeof platform === "string") {
			setSelectedPlatform(platform); // Set platform only if it's a valid string
			setDeleteModalOpen(true);
		} else {
			console.error("Invalid platform type");
		}
	};

	return (
		<>
			<div className="phone-view">
				<div className="profile-container_b">
					<img
						src="assets/Rectangle 50.png"
						alt="Profile Background"
						className="profile-background_b"
					/>
					<div className="profile-header_b">
						{/* <Link to="/event-app"> <span><img className='vv' src='/assets/Vector.png' />  </span></Link> */}
						<img
							className="left_uparrow search_left_arrow ev"
							src="/assets/uparrow.svg"
							alt="Go Back"
							onClick={goBack}
						/>
						<span>
							{" "}
							<Bell className="icon interest_bell right-icon1 notification-icon_b" />
						</span>
					</div>
				</div>

				{/* Profile Section */}
				<div className="ion">
					<div className="profile-section_pprofile">
						<div className="profile-info_pprofiles">
							<div className="profile-photo-container">
								<img src="assets/man.png" alt="profile" className="profile-photo" />
								<img src="assets/edit.svg" alt="edit" className="edit-icon" />
							</div>
							<div className="profile-name_pprofile">Deepak</div>
							<div className="profile-title_pprofile">CEO Infy</div>
							<button className="edit-profile-btn">
								<Link to="/myprofile-businesscard">
									<span>Edit Profile</span>
								</Link>
							</button>
						</div>
					</div>

					{/* Tabs Below Edit Profile Button */}
					<Box className="tabs-container_tt">
						<Tabs
							value={tabValue}
							onChange={handleTabChange}
							classes={{ indicator: "tabIndicator" }}
							aria-label="Profile Tabs"
						>
							<Tab
								label="Social Media"
								className={tabValue === 0 ? "selectedTab" : "tab"}
							/>
							<Tab
								label="Business Card"
								className={tabValue === 1 ? "selectedTab" : "tab"}
							/>
						</Tabs>
					</Box>

					{/* Social media section
					 */}
					{tabValue === 0 && (
						<div className="social-media-section_w">
							<div className="social-links-container_w">
								{socialLink.map(link => (
									<div className="icon-card">
										<div className="social-link_w" key={link.id}>
											<div className="icon-center">
												<img
													src={link.icon}
													alt={link.platform}
													className="social-icon_w"
													onClick={() =>
														handleOpenAddDrawer(link.platform)
													}
												/>
												<span className="social-platform_w">
													{link.platform}
												</span>
											</div>

											<IconButton
												className="edit-button_w"
												aria-label="edit"
												onClick={() =>
													handleOpenEditDrawer(link.platform, link.url)
												}
											>
												{/* <EditIcon /> */}
											</IconButton>

											{/* for delete */}
											{/* <IconButton className="delete-button_w" aria-label="delete" onClick={handleOpenDeleteDialog}>
                      <DeleteIcon />
                    </IconButton> */}

											<DeleteSocialIcon
												isOpen={isDeleteDialogOpen}
												onClose={handleCloseDeleteDialog}
												onConfirm={handleConfirmDelete}
												socialPlatform="LinkedIn"
											/>

											<EditSocialMediaDrawer
												isOpen={isEditDrawerOpen}
												onClose={handleCloseEditDrawer}
												platform={editingPlatform}
												initialUrl={editingUrl}
												onSave={handleSaveEditDrawer}
												onOpen={()=>{
													setIsEditDrawerOpen(true);
												}}
											/>

											<AddSocialMedia
												isOpen={isAddDrawerOpen}
												onOpen={()=>{
													setIsAddDrawerOpen(true);
												}}
												onClose={handleCloseAddDrawer}
												platform={addingPlatform}
												onAdd={handleAddSocialMedia}
											/>
										</div>

										{/* Delete icon at the end */}
										<div className="delete-images">
											<img
												onClick={handleOpenDeleteDialog}
												src="assets/delete.svg"
												className="delete-image"
												alt="Delete"
											/>
										</div>
									</div>
								))}
							</div>
						</div>
					)}
					{tabValue === 1 && (
						<div className="business-card-section_b">
							{/* Business Card Content */}
							<div className="myprofile_businesscard">
								<BusinessCard
									name="Anoyiki Soe"
									title="Investor, Infy"
									avatarUrl="assets/lisa.png"
									qrCodeUrl="assets/photos/QR.png"
									socialLinks={socialLinks}
								/>
							</div>

							<div className="business-card-actions_cc">
								<button className="scan-button">
									Scan
									<img className="an" src="assets/scan.png" alt="" />
								</button>
								<button
									className="edit-button_mm-desktop"
									onClick={handleOpenEditBusinessCard}
								>
									<span>Edit</span>
									<img className="an" src="assets/edit.png" alt="" />
								</button>
								<EditDrawer
									open={isEditBusinessCardOpen}
									onClose={handleCloseEditBusinessCard}
									onOpen={()=>{
										setIsEditBusinessCardOpen(true);
									}}
								/>
							</div>
							{/* Requests Section */}
							<div className="requests-section">
								<div className="request-tabs">
									<div className="request-tab active">Received Requests</div>
									<div className="request-tab">Pending Requests</div>
									<div className="request-tab">Your Contacts</div>
								</div>
								<div className="erc">
									<input
										type="text"
										placeholder="Search"
										className="search-bar_cc"
									/>{" "}
									<span className="ng">
										{" "}
										<img src="assets/download.png" alt="" />
									</span>
								</div>
								<p className="no-requests-text">You have no received requests</p>
							</div>
						</div>
					)}
				</div>
			</div>

			<div className="desktop-view">
				<div className="menu-desktop">
					<div>
						<SideNavbar />
					</div>

					<div className="myprofile_div">
						<div className="flex-container-desktop1 multi-menu-desktop">
							<img
								className="left_uparrow search_left_arrow"
								src="/assets/uparrow.svg"
								alt="Go Back"
								onClick={goBack}
							/>
							<div className="b1">
								<Link to="/notifications">
									<span>
										<Bell className="icon interest_bell right-icon1" />
									</span>
								</Link>
								<div>
									<img
										className="h_image"
										src="assets/deep.png"
										alt="Center Logo"
										onClick={() => navigate("/profile-page")}
									/>
								</div>
							</div>
						</div>

						{/* Profile Section */}
						<div className="ion-desktop">
							<div className="desktop-profile-section_pprofile">
								<div className="profile-info_pprofile-desktop">
									<div className="profile-photo-container">
										<img
											src="assets/man.png"
											alt="profile"
											className="profile-photo"
										/>
										<img
											src="assets/edit.svg"
											alt="edit"
											className="edit-icon"
										/>
									</div>
									<div className="busin_name">
										<div className="profile-name_pprofile">Deepak</div>
										<div className="profile-title_pprofile">
											CEO{" "}
											<span className="profile-title_pprofile_in">Infy</span>
										</div>
									</div>
								</div>
								<Link to="/profile-form">
									<button className="edit-profile-btn">
										<span>Edit Profile</span>
									</button>
								</Link>
							</div>

							{/* Tabs Below Edit Profile Button */}
							<Box className="tabs-container_tt-desktop">
								<Tabs
									value={tabValue}
									onChange={handleTabChange}
									classes={{ indicator: "tabIndicator" }}
									aria-label="Profile Tabs"
								>
									<Tab
										label="Social Media"
										className={tabValue === 0 ? "selectedTab" : "tab"}
									/>
									<Tab
										label="Business Card"
										className={tabValue === 1 ? "selectedTab" : "tab"}
									/>
								</Tabs>
							</Box>

							{/* Social media section
							 */}
							{tabValue === 0 && (
								<div className="social-media-section_w">
									<div className="social-links-container_w-desktop">
										{socialLink.map(link => (
											<div className="icon-card">
												<div
													className="social-link_w"
													style={{ width: "100%" }}
													key={link.id}
												>
													<img
														src={link.icon}
														alt={link.platform}
														className="social-icon_w"
														onClick={openAddModal}
													/>
													<span className="social-platform_w">
														{link.platform}
													</span>

													<AddSocialMediaModal
														isOpen={isAddModalOpen}
														onClose={closeAddModal}
														platform="Twitter"
														onAdd={saveSocialMediaUrl}
													/>

													<IconButton
														className="edit-button_w"
														aria-label="edit"
														onClick={() =>
															handleOpenEditModals(
																link.platform,
																link.url
															)
														}
													>
														{/* <EditIcon /> */}
													</IconButton>

													<EditSocialMediaModal
														isOpen={isModalOpen}
														onClose={handleCloseModal}
														platform={selectedPlatform}
														initialUrl={selectedUrl}
														onSave={handleSaveUrl}
													/>

													{/* <IconButton aria-label="delete" >
                         <DeleteIcon />
                       </IconButton> */}

													<DeleteSocialIconModal
														isOpen={isDeleteModalOpen}
														onClose={closeDeleteModal}
														onConfirm={handleConfirmDeletes}
														socialPlatform={selectedPlatform}
													/>
												</div>

												{/* Delete icon at the end */}
												<div className="delete-images">
													<img
														onClick={() =>
															openDeleteModal(socialPlatforms[0])
														}
														src="assets/delete.svg"
														className="delete-image"
														alt="Delete"
													/>
												</div>
											</div>
										))}
									</div>
								</div>
							)}
							{tabValue === 1 && (
								<div className="business-card-section_b-desktop">
									<div className="mycard-desktop">
										<div>
											{/* Business Card Content */}
											<div className="myprofile_businesscard-desktop">
												<BusinessCard
													name="Anoyiki Soe"
													title="Investor, Infy"
													avatarUrl="assets/lisa.png"
													qrCodeUrl="assets/photos/QR.png"
													socialLinks={socialLinks}
												/>
											</div>

											<div className="business-card-actions_cc-desktop">
												<button className="scan-button">
													Scan
													<img
														className="an"
														src="assets/scan.png"
														alt=""
													/>
												</button>

												<button
													className="edit-button_mm"
													onClick={handleOpenEditModal}
												>
													<span>Edit</span>
													<img
														className="an"
														src="assets/edit.png"
														alt=""
													/>
												</button>
												<EditModal
													open={isEditModalOpen}
													onClose={handleCloseEditModal}
												/>
											</div>
										</div>

										<div className="ness-card">
											<div className="requests-section">
												<div className="request-tabs-desktop">
													{[
														"Received Requests",
														"Pending Requests",
														"Your Contacts"
													].map(tab => (
														<div
															key={tab}
															className={`request-tab ${activeRequestTab === tab ? "active" : ""}`}
															onClick={() =>
																handleRequestTabClick(tab)
															}
														>
															{tab}
														</div>
													))}
												</div>
												<div className="erc-desktop">
													<input
														type="text"
														placeholder="Search"
														className="search-bar_cc"
													/>
													<span className="ng">
														<img src="assets/download.png" alt="" />
													</span>
												</div>
												{showCards ? (
													<>
														<div className="cards-container">
															{getCurrentPageData().map(
																(request, index) => (
																	<RequestCard
																		key={index}
																		{...request}
																		isPending={
																			activeRequestTab ===
																			"Pending Requests"
																		}
																	/>
																)
															)}
														</div>
														<div className="pagination-container">
															<Pagination
																count={Math.ceil(
																	(activeRequestTab ===
																	"Pending Requests"
																		? pendingRequestsData.length
																		: receivedRequestsData.length) /
																		itemsPerPage
																)}
																page={currentPage}
																onChange={handlePageChange}
																color="primary"
															/>
														</div>
													</>
												) : (
													<p className="no-requests-text-desktop">
														You have no {activeRequestTab.toLowerCase()}
													</p>
												)}
											</div>
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default MyProfileBusinessCard;
