// ConfirmationDialog.tsx
import React, { useState } from "react";
import { Dialog, Box, Typography, Avatar } from "@mui/material";
import { Icon } from "@iconify-icon/react/dist/iconify.mjs";

import { actionButtons, networkEntity } from "../../../config/app";
import { firstLetterFromString } from "../../../reusable/common";
import BusinessCard from "../../../CommomComponents/BusinessCard/BussinessCard";


interface ConfirmationDialogProps {
	open: boolean;
	title: string;
	message: string;
	onConfirm: () => void;
	onCancel: () => void;
	selectedConnection: string;
	connectionButton: string;
	actionUpdate: () => void;
	setConnectionStatus: string;
	setSelectedConnection: string;
	createUserConnection: () => void;
	removeConnectionId: boolean;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
	open,
	onCancel,
	selectedConnection,
	connectionButton,
	actionUpdate,
	setConnectionStatus,
	createUserConnection,
	removeConnectionId
}) => {
	// const [copied, setCopied] = useState(false);


	return (
		<Dialog
			open={open}
			onClose={onCancel}
			aria-labelledby="confirmation-dialog-title"
			aria-describedby="confirmation-dialog-description"
			PaperProps={{
				style: {
					width: "30%",
					maxWidth: "500px",
					padding: "25px"
				}
			}}
		>
			{selectedConnection && !removeConnectionId ? (
				<>

					<Icon
						icon="oui:cross"
						width="20px"
						height="20px"
						className="cross-icon"
						onClick={() => { onCancel() }}
					/>


					<div className="" style={{ width: "auto" }}>
						<BusinessCard
							// key={selectedConnection?.id}
							width={"auto"}
							connection={selectedConnection}
						/>
					</div>

					<div >
						{connectionButton &&
							connectionButton !== actionButtons.ACCEPT && (
								<button
									className="accept_network-desktop modal-screen"
									onClick={() => {
										if (
											connectionButton !==
											actionButtons.CONNECT

										) {
											actionUpdate(
												selectedConnection
											);

										} else {
											createUserConnection(
												selectedConnection?.connection_id
											);

										}
										// setDrawerOpen(false);
										// setNotificationPopup(true);
										onCancel();

									}}
								>
									{connectionButton}
								</button>
							)}
					</div>




				</>) : (<div>
					<div className="confirm-remove-section ">
						<div className="remove-connection">
							<p>Remove Connection</p>
						</div>
						<p className="deleted-line">
							Are you sure that you want to remove this connection?
						</p>
						<div className="confirm-remove_container d-flex justify-content-center">
							<button
								className="cancel-button"
								onClick={() => {
									// setRemoveConnectionId(false);
									onCancel();
								}}
								style={{ padding: "10px 53px" }}
							>
								Cancel
							</button>
							<button
								className="remove-button-new "
								onClick={() => {
									actionUpdate(selectedConnection, networkEntity.REMOVE);
									onCancel();
								}}
							>
								Yes
							</button>
						</div>
					</div>
				</div>
			)}
		</Dialog>
	);
};

export default ConfirmationDialog;
