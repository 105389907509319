import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Auth } from "aws-amplify";
import "./LoginAccount.css";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { useUser } from "../../contexts/AuthContext";
import { toast } from "react-toastify";
import NotificationBox from "../Network/NotificationBox/NotificationBox";

const LoginAccount: React.FC = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const queryParams = new URLSearchParams(location.search); // Parses the query string
	const redirectPath = queryParams.get("redirect") || "/";
	const emailValue = queryParams.get("email") || "";
	const { setUser } = useUser();
	const [notificationPopup, setNotificationPopup] = useState(false);
	const [toasterMessage, setToasterMessage] = useState("");
	const [email, setEmail] = useState(emailValue);
	const [password, setPassword] = useState("");
	const [emailError, setEmailError] = useState("");
	const [passwordError, setPasswordError] = useState("");
	const [loginError, setLoginError] = useState("");
	const [passwordVisible, setPasswordVisible] = useState(false);
	const [loading, setLoading] = useState(false);

	const isFormValid = email && password && !emailError && !passwordError;

	const validateEmail = (email: string) => {
		if (!email?.trim()) {
			setEmailError("Please Enter Your Email Address"); // Handles empty email
		} else {
			const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
			if (!emailRegex.test(email)) {
				setEmailError("Please enter a valid email address."); // Handles invalid email
			} else {
				setEmailError(""); // Clears error if valid
			}
		}
	};

	const validatePassword = (password: string) => {
		if (!password.trim()) {
			setPasswordError("Please Enter Your Password"); // Handles empty password
		} else if (password.length < 6) {
			setPasswordError("Password must be at least 6 characters long."); // Handles short passwords
		} else {
			setPasswordError(""); // Clears error if valid
		}
	};

	const handleLoginOtp = async () => {
		if (isFormValid) {
			setLoading(true);
			try {
				// Check if there's an existing session and sign out if necessary
				try {
					await Auth.currentAuthenticatedUser();
					await Auth.signOut();

				} catch (error) {
					// No current user, proceed with sign in
				}

				const userDetails = await Auth.signIn(email, password);

				if (userDetails) {
					const sessionResponse = await Auth.currentSession();
					setUser((user: any) => ({
						...user,
						session: sessionResponse,
						user: userDetails
					}));
					setLoading(false)
					if (redirectPath) {
						// navigate(`${redirectPath},{ replace: true }`); // Navigate to the specified path
						window.location.reload();
						window.location.href = redirectPath;

					} else {
						navigate("/",{ replace: true }); // Default to the home page if redirectPath is null
					}
				}
			} catch (error) {
				console.error("Error during login:", error);
				if (error instanceof Error) {
					setLoginError(`Login failed: ${error.message}`);
					setLoading(false)
				} else {
					setLoginError("Invalid email or password. Please try again.");
					setLoading(false)
				}
			}
		}
	};

	// const handleLoginWithOtp =async () => {
	//     // navigate('/singup-page');
	//     if(email.length==0){
	//         setEmailError("Please enter a valid email address.")
	//     }else{
	//         try {
	//             await resetPassword({ username: email });
	//             // Redirect to OTP screen
	//             navigate("/signup-page", { state: { email } })
	//           } catch (error) {
	//             console.error("Error initiating password reset:", error);
	//           }

	//     }

	// };
	const handleLoginWithOtp = async () => {
		if (email) navigate(`/signup-page?email=${email}&redirect=${redirectPath}&signIn=true`);
		else {
			// toast.info("Please enter your registered email address");
			setToasterMessage("Please enter your registered email address")
			setNotificationPopup(true);
		}
	};

	const handleTermsandCondition = () => {
		navigate("/terms-condition");
	};

	const togglePasswordVisibility = () => {
		setPasswordVisible(!passwordVisible);
	};

	const signInWithGoogle = async () => {
		try {
			await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Facebook });
		} catch (error) {
			console.error("Error during Google Sign-In:", error);
		}
	};
	const signInWithAmazon = async () => {
		try {
			await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Facebook });
		} catch (error) {
			console.error("Error during Amazon Sign-In:", error);
		}
	};

	const signInWithFacebook = async () => {
		try {
			await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Facebook });
		} catch (error) {
			console.error("Error during Facebook Sign-In:", error);
		}
	};
	useEffect(() => {
		if (notificationPopup) {
			const timer = setTimeout(() => {
				setNotificationPopup(false);
			}, 3000);
			return () => clearTimeout(timer);
		}
	}, [notificationPopup]);

	return (
		<>
			{notificationPopup && (
				<div className="notification-wrapper">
					<NotificationBox message={toasterMessage} />
				</div>
			)}
			<div className="phone-view d-flex d-md-none w-100 h-100 flex-column justify-content-center flex-grow-1">
				<div className="login-account-container font">
					<div className="ltmargin">
						<label className="ltext">Email Address</label>
						<input
							type="email"
							value={email}
							className="input-field"
							style={{
								fontWeight: email ? "400" : "",
								fontSize: email ? "16px" : ""
							}}
							onChange={e => {
								setEmail(e.target.value);
								validateEmail(e.target.value);
							}}
							onBlur={() => validateEmail(email)} // Validate on losing focus
							placeholder="Enter your Email Address"
						/>
						{emailError && <p className="error-text">{emailError}</p>}
					</div>

					<div className="ltmargin">
						<label className="ltext">Password</label>
						<div className="password-input-container">
							<input
								type={passwordVisible ? "text" : "password"}
								value={password}
								className="input-field"
								style={{
									fontWeight: password ? "400" : "",
									fontSize: password ? "16px" : ""
								}}
								onChange={e => {
									setPassword(e.target.value);
									validatePassword(e.target.value); // Validate on input change
								}}
								onBlur={() => validatePassword(password)} // Validate on losing focus
								placeholder="Enter your password"
							/>
							<span onClick={togglePasswordVisibility} className="eyeicon">
								{passwordVisible ? <FaEye /> : <FaEyeSlash />}
							</span>
						</div>
						{passwordError && <p className="error-text">{passwordError}</p>}
						{loginError && <p className="error-text">{loginError}</p>}
					</div>

					<span className="forgot-password">

						<a className="for-link " href="/forgot-password">
							Forgot Password?
						</a>

					</span>

					<button
						className={`sign-in proceed-button ${isFormValid ? "enabled" : "disabled"}`}
						disabled={!isFormValid}
						onClick={handleLoginOtp}
					>
						Sign In {loading && <div className=" ms-2 spinner-border text-white spinner-border-sm" role="status">
							<span className="visually-hidden">Loading...</span>
						</div>}
					</button>

					<div className="signup-email-otp" onClick={handleLoginWithOtp}>
						Sign In with <span className="email-otp-forotp">Email OTP</span>
					</div>

					<div className="or-text-container">
						<div className="or_line"></div>
						<div className="or-text">or</div>
						<div className="or_line"></div>
					</div>
					<br></br>
					<div className=" google_btn d-none">
						<span>
							<div className="create-Account_buttons">
								<button className="create_aacount_btn" onClick={signInWithGoogle}>
									<img src="assets/google.svg" className="create_google-logo" />
								</button>
								<button className="create_aacount_btn" onClick={signInWithAmazon}>
									<img src="assets/Facebook.svg" className="create_google-logo" />
								</button>
								<button className="create_aacount_btn" onClick={signInWithFacebook}>
									<img src="assets/Amazon.svg" className="create_google-logo" />
								</button>
							</div>
						</span>
					</div>

					<div className="new_user_signup_text">
						Don't have an account?{" "}
						<Link to="/create-account" className="yo">
							Sign up
						</Link>
					</div>
				</div>
			</div>
			{/* second container */}
			<div className="desktop-view">
				<div className="main-container">
					<div className="right-panel d-flex align-items-center justify-content-center">
						<div className="panel-desktop auth-screen-desktop">
							<div className="login-account-container font">
								<div className="ltmargin">
									<label className="ltext">Email Address</label>
									<input
										type="email"
										value={email}
										className="input-field"
										style={{
											fontWeight: email ? "400" : "",
											fontSize: email ? "16px" : ""
										}}
										onChange={e => {
											setEmail(e.target.value);
											validateEmail(e.target.value);
										}}
										onBlur={() => validateEmail(email)} // Validate on losing focus
										placeholder="Enter your Email Address"
									/>
									{emailError && <p className="error-text">{emailError}</p>}
								</div>

								<div className="ltmargin">
									<label className="ltext">Password</label>
									<div className="password-input-container">
										<input
											type={passwordVisible ? "text" : "password"}
											value={password}
											className="input-field"
											style={{
												fontWeight: password ? "400" : "",
												fontSize: password ? "16px" : ""
											}}
											onChange={e => {
												setPassword(e.target.value);
												validatePassword(e.target.value);
											}}
											onBlur={() => validatePassword(password)} // Validate on losing focus
											placeholder="Enter your password"
										/>
										<span
											onClick={togglePasswordVisibility}
											className="eyeicon"
										>
											{passwordVisible ? <FaEye /> : <FaEyeSlash />}
										</span>
									</div>
									{passwordError && <p className="error-text">{passwordError}</p>}
									{loginError && <p className="error-text">{loginError}</p>}
								</div>

								<span className="forgot-password">

									<a className="for-link " href="/forgot-password">
										Forgot Password?
									</a>

								</span>

								<button
									className={`sign-in proceed-button ${isFormValid ? "enabled" : "disabled"}`}
									disabled={!isFormValid}
									onClick={handleLoginOtp}
								>
									Sign In  {loading && <div className=" ms-2 spinner-border text-white spinner-border-sm" role="status">
							<span className="visually-hidden">Loading...</span>
						</div>}
								</button>

								<div className="signup-email-otp" onClick={handleLoginWithOtp}>
									Sign In with <span className="email-otp-forotp">Email OTP</span>
								</div>

								<div className="or-text-container">
									<div className="or_line"></div>
									<div className="or-text">or</div>
									<div className="or_line"></div>
								</div>
								<br></br>
								<div className=" google_btn d-none">
									<span>
										<div className="create-Account_buttons">
											<button
												className="create_aacount_btn"
												onClick={signInWithGoogle}
											>
												<img
													src="assets/google.svg"
													className="create_google-logo"
												/>
											</button>
											<button
												className="create_aacount_btn"
												onClick={signInWithAmazon}
											>
												<img
													src="assets/Facebook.svg"
													className="create_google-logo"
												/>
											</button>
											<button
												className="create_aacount_btn"
												onClick={signInWithFacebook}
											>
												<img
													src="assets/Amazon.svg"
													className="create_google-logo"
												/>
											</button>
										</div>
									</span>
								</div>

								<div className="new_user_signup_text">
									Don't have an account?{" "}
									<Link to="/create-account" className="yo">
										Sign up
									</Link>
								</div>
							</div>
						</div>
					</div>

					<div
						className="left-panel auth-screen-container"
						style={{ backgroundImage: `url('/assets/layesImage.svg')` }}
					></div>
				</div>
			</div>
		</>
	);
};

export default LoginAccount;
