import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './LoginAccEmail.css';

const LoginAccount: React.FC = () => {
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const navigate = useNavigate();

    const isFormValid = email && !emailError;

    const validateEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setEmailError('Please enter a valid email address.');
        } else {
            setEmailError('');
        }
    };
    const handleLoginOtp = () => {
        {
            // navigate('/verifysingup-otp');
        }
    };
    const handleTermsandCondition = () => {
        navigate('/terms-condition');
    };

    const handleSignupsWithOtp = () => {
        navigate('/signup-page');
    };

    return (
        <div className="login-account-container ">
            <div><img src="assets/Logo.png" alt="Center Logo" className="logo124" /></div>
            <div className='con mt-5'>
                <h3 className='account'>Login into your account</h3>
                <label>Email ID/Mobile No.</label>
                <input
                    type="email"
                    value={email}
                    className='put'
                    style={{
                        color: email ? '#002E6E' : '',
                        fontWeight: email ? 'bold' : '',
                        fontSize: email ? '16px' : '',
                        height: email? '15px' : '',
                    }}
                    onChange={(e) => {
                        setEmail(e.target.value);
                        validateEmail(e.target.value);

                    }}
                    placeholder="Enter registered email ID/mobile no."
                />
                {emailError && <p className="error-text">{emailError}</p>}
            </div>
            <br></br>

            <div className='mt-4'>
                <div className="or-text-container">
                    <div className="or_line"></div>
                    <div className="or-text">OR</div>
                    <div className="or_line"></div>
                </div>
                <div className="signup-email-otp">Signup with <span className="email-otp" onClick={handleSignupsWithOtp}>Email OTP</span></div>
                <div className="or-text-container">
                    <div className="or_line"></div>
                    <div className="or-text">OR</div>
                    <div className="or_line"></div>
                </div>
            </div>

            <div className="">
            <span >
            <div className="create-Account_buttons">
                  <button className="google-button">
                    <img src="assets/google.svg" className="create_google-logo" />
                    {/* <span className="g_le">Google</span> */}
                  </button>
                  <button className="google-button">
                    <img src="assets/Amazon.svg" className="create_google-logo" />
                    {/* <span className="g_le">Google</span> */}
                  </button>

            </div>
        </span>
            </div>
            <div className='mt-5'>
                <p className='sign'>By signing up, you agree to our <span className="blue-term" onClick={handleTermsandCondition} >Terms and Conditions</span></p>

                <button
                    className={`proceed-button ${isFormValid ? 'enabled' : 'disabled'}`}
                    disabled={!isFormValid}
                    onClick={handleLoginOtp}
                >
                    Proceed
                </button>

                <p className='ount'>Don't have an account? <a href="/signup-page" className='yo'>Signup</a></p>
                <div className="powered-by">
                    <span className="powered-text">Powered by</span>
                    <img src="assets/Group.png" alt="Logo" className="logo1" />
                </div>
            </div>
        </div>
    );
};

export default LoginAccount;
