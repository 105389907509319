import React, { useState } from "react";
import "./Feedback.css";
import Footer from "../Footer/Footer";
import { useNavigate, useParams } from "react-router-dom";
import ProgressIndicator from "./ProgressIndicator/ProgressIndicator";
import SideNavbar from "../SideNavbar/SideNavbar";
import NotificationModal from "../Notification/NotificationModal/NotificationModal";
import { Bell } from "lucide-react";
import { Header } from "../Header/Header";

interface Option {
  value: string;
  label: string;
}

const options: Option[] = [
  { value: "excellent", label: "Excellent" },
  { value: "good", label: "Good" },
  { value: "fair", label: "Fair" },
  { value: "poor", label: "Poor" },
];

const Feedback: React.FC = () => {
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const handleOptionSelect = (value: string) => {
    setSelectedOption(value);
  };
  const goBack = () => {
    navigate(-1);
  };

  const handleOpen = () => {
    setShowModal(true);
  };

  const handleClose = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      setShowModal(false);
    }
  };

  return (
    <>
      <div className="desktop-view">
        <div className="desktop-view-side">
          <div className="desktop-left-slider">
            <SideNavbar />
          </div>
          <div className="desktop-feedback-question">
            <div className="feedback-question">
              <Header />
              {showModal && (
                <div
                  onClick={handleClose}
                  style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-start',
                    zIndex: 1000
                  }}
                >
                  <div
                    onClick={(e) => e.stopPropagation()}
                    style={{
                      width: '400px',
                      maxHeight: '80vh',
                      overflow: 'auto',
                      backgroundColor: 'white',
                      marginTop: '60px',
                      marginRight: '20px',
                      borderRadius: '8px',
                      padding: '20px',
                      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
                    }}
                  >
                    <NotificationModal />
                  </div>
                </div>
              )}

              <div className="p-4">
                <ProgressIndicator current={1} total={5} />
              </div>
              <h2 className="feedback_rate">Rate overall event experience</h2>
              <p className="feedback_text">You can select one answer</p>
              <div className="feedback_options-grid">
                {options.map((option) => (
                  <button
                    key={option.value}
                    className={` all_options ${selectedOption === option.value ? "selected" : ""
                      }`}
                    onClick={() => handleOptionSelect(option.value)}
                  >
                    {option.label}
                  </button>
                ))}
              </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      </div>

      <div className="phone-view">
        <div>
          <div className="feedback-question">
            <div className="en">
              <img
                className="left_uparrow search_left_arrow"
                src="/assets/uparrow.svg"
                alt="Go Back"
                onClick={goBack}
              />
              <div>
                {/* <div className="common-event-title">{events?.event_short_name}</div> */}
              </div>
            </div>

            <div className="p-4">
              <ProgressIndicator current={1} total={5} />
            </div>
            <h2 className="feedback_rate">Rate overall event experience</h2>
            <p className="feedback_text">You can select one answer</p>
            <div className="feedback_options-grid">
              {options.map((option) => (
                <button
                  key={option.value}
                  className={`option ${selectedOption === option.value ? "selected" : ""
                    }`}
                  onClick={() => handleOptionSelect(option.value)}
                >
                  {option.label}
                </button>
              ))}
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Feedback;
