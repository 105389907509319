import React, { useState } from "react";
import "./QuizPage.css";
import QuizList from "./QuizList/QuizList";
import Footer from "../Footer/Footer";
import { useNavigate, useParams } from "react-router-dom";
import SideNavbar from "../SideNavbar/SideNavbar";
import NotificationModal from "../Notification/NotificationModal/NotificationModal";
import { Bell } from "lucide-react";
import { Header } from "../Header/Header";

const QuizPage: React.FC = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const goBack = () => {
    navigate(-1);
  };

  const handleOpen = () => {
    setShowModal(true);
  };

  const handleClose = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      setShowModal(false);
    }
  };

  return (
    <>
      <div className="desktop-view">
        <div className="desktop-view-side">
          <div className="desktop-left-slider">
            <SideNavbar />
          </div>
          <div className="desktop-left-container font">
            <div className="desktop-quizpage_container">
              <Header />
              {showModal && (
                <div
                  onClick={handleClose}
                  style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-start",
                    zIndex: 1000,
                  }}
                >
                  <div
                    onClick={(e) => e.stopPropagation()}
                    style={{
                      width: "400px",
                      maxHeight: "80vh",
                      overflow: "auto",
                      backgroundColor: "white",
                      marginTop: "60px",
                      marginRight: "20px",
                      borderRadius: "8px",
                      padding: "20px",
                      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <NotificationModal />
                  </div>
                </div>
              )}



              <QuizList />
            </div>
          </div>
        </div>
      </div>

      <div className="phone-view">
        <div className="quizpage_container">
          <div className="en">
            <div>
              <img
                className="left_uparrow search_left_arrow"
                src="/assets/uparrow.svg"
                alt="Go Back"
                onClick={goBack}
              />{" "}
            </div>
            <div>
              <span className="common-event-title">ELYSIAN - 2024</span>
            </div>
          </div>
          <QuizList />
          <Footer />
        </div>
      </div>
    </>
  );
};

export default QuizPage;
