import React, { createContext, useContext, useState, ReactNode } from "react";

// Define the context type
interface SubscriptionContextType {
	subscriptions: object | null | boolean | any;
	setSubscriptions: (subscription: object | null | boolean | any) => void;
}

// Create the UserContext
const SubscriptionContext = createContext<SubscriptionContextType>({
	subscriptions: [],
	setSubscriptions: () => {}
});

// Define props that include children for UserProvider
interface SubscriptionProviderProps {
	children: ReactNode; // Prop to accept children
}

const SubscriptionProvider: React.FC<SubscriptionProviderProps> = ({ children }) => {
	const [subscriptions, setSubscriptions] = useState<object | null | boolean | any>([]); // State can be any object or nul

	return (
		<SubscriptionContext.Provider
			value={{
				subscriptions,
				setSubscriptions
			}}
		>
			{children}
		</SubscriptionContext.Provider>
	);
};

// Hook to use the User context
const useSubscription = () => useContext(SubscriptionContext);

export { SubscriptionProvider, useSubscription };
