import React, { useEffect } from "react";
import {
	Box,
	List,
	ListItemButton,
	ListItemText,
	Checkbox,
	Button,
	Typography,
	FormControlLabel
} from "@mui/material";
import { ClipLoader } from "react-spinners";
import "./FilterDrawer.css";
import _ from "lodash";

interface FilterDrawerProps {
	filterData: any;
	filterLoading: boolean;
	setFilterTags: (selectedTags: { [key: string]: string[] }) => void;
	onClose: () => void;
	filterTags?: any 
}

const FilterDrawerDesktop: React.FC<FilterDrawerProps> = ({
	filterData,
	filterLoading,
	setFilterTags,
	onClose,
	filterTags
}) => {
	const [isInitialLoaded, setIsInitialLoaded] = React.useState(false);
	const [selectedMenu, setSelectedMenu] = React.useState<string>(!_.isEmpty(filterTags) ? Object.keys(filterTags)?.[0] : filterData?.[0]?.name);
	const [tempSelectedFilters, setTempSelectedFilters] = React.useState<{ [key: string]: string[] }>({});

	useEffect(() => {
		if (filterData?.length > 0 && !selectedMenu && !isInitialLoaded) {
			setSelectedMenu(filterTags ? Object.keys(filterTags)[0] : filterData?.[0]?.name);
			if(filterTags){
				setTempSelectedFilters(filterTags)
			}
			setIsInitialLoaded(true);
		}
	}, [filterData, filterTags]);

	const toggleFilterItem = (menuName: string, itemName: string) => {
		setTempSelectedFilters(prev => {
			const currentItems = prev[menuName] || [];
			const newItems = currentItems.includes(itemName)
				? currentItems.filter(item => item !== itemName)
				: [...currentItems, itemName];

			return {
				...prev,
				[menuName]: newItems
			};
		});
	};
	const handleClear = () => {
		setTempSelectedFilters({});
	};

	const handleSelectAll = () => {
		const currentItems =
			filterData?.find((item: any) => item.name === selectedMenu)?.tags.map((tag: any) => tag.name) || [];
		setTempSelectedFilters(prev => ({
			...prev,
			[selectedMenu]:
				(tempSelectedFilters[selectedMenu] || []).length === currentItems.length
					? []
					: currentItems
		}));
	};

	// Function to apply the filters when "Apply" button is clicked
	const handleApplyFilters = () => {
		setFilterTags(tempSelectedFilters);
	};

	return (
		<div className="filter-drawer-section" style={{ height: `${filterLoading ? "410px" : ""}` }}>
			<Typography variant="h6" className="headText_filter">
				Filters
			</Typography>
			<Box className="filter-container" style={{ height: `${filterLoading ? "290px" : ""}` }}>
				{filterLoading ? (
					<div className="d-flex align-items-center justify-content-center" style={{ margin: "auto" }}>
						<ClipLoader
							loading={filterLoading}
							size={50}
							aria-label="Loading Filters"
						/>
					</div>
				) : filterData?.length > 0 ? (
					<>
						<Box className="filter-sidebar">
							<List>
								{filterData?.length > 0 && (
									<>
										{filterData?.map((filterItem: any) => (
											<ListItemButton
												key={filterItem.id}
												onClick={() => setSelectedMenu(filterItem.name)}
												selected={selectedMenu === filterItem.name}
												className="menu-item_filter"
												style={{
													backgroundColor: selectedMenu === filterItem.name ? 'var(--primary-color) !important' : '',
													color: selectedMenu === filterItem.name ? "var(--comp-secondary-color) !important" : "",
												}}
											>
												<ListItemText
													className="menu-item_text"
													primary={filterItem.name}
												/>
											</ListItemButton>
										))}
									</>
								)}
							</List>
						</Box>

						<Box className="filter-content">
							{filterData?.length > 0 && (
								<>
									{filterData?.map((filterItem: any) =>
										selectedMenu === filterItem.name ? (
											<List key={filterItem.id}>
												<ListItemButton>
													<FormControlLabel
														control={

															<Checkbox
																style={{
																	color: (tempSelectedFilters[selectedMenu] || []).length ===
																		filterItem.tags.length
																		? 'var(--primary-color)'
																		: ''
																}}
																checked={
																	(tempSelectedFilters[selectedMenu] || []).length ===
																	filterItem.tags.length
																}
																onChange={handleSelectAll}
															/>
														}
														label={`All`}
													/>
												</ListItemButton>
												{filterItem?.tags?.length > 0 && (
													<>
														{filterItem.tags.map((tag: any) => (
															<ListItemButton key={tag.id}>
																<FormControlLabel
																	control={
																		<Checkbox
																			style={{
																				color: (tempSelectedFilters[selectedMenu] || []).includes(tag.name)
																					? 'var(--primary-color)'
																					: ''
																			}}
																			checked={(
																				tempSelectedFilters[selectedMenu] || []
																			).includes(tag.name)}
																			onChange={() =>
																				toggleFilterItem(
																					selectedMenu,
																					tag.name
																				)
																			}
																		/>
																	}
																	label={tag.name}
																/>
															</ListItemButton>
														))}
													</>
												)}
											</List>
										) : null
									)}
								</>
							)}
						</Box>
					</>
				) : (
					<div style={{ margin: "auto" }} className="no-results">
						Filter not found
					</div>
				)}
			</Box>

			<Box className="filter-actions">
				<Button
					variant="outlined"
					onClick={() => {
						handleClear();
					}}
					className="btn_agenda clear-btn text-capitalize"
				>
					Clear all
				</Button>
				<Button
					variant="contained"
					onClick={handleApplyFilters}
					className="applybtn_agenda btn_agenda text-capitalize"
				>
					Apply
				</Button>
			</Box>
		</div>
	);
};

export default FilterDrawerDesktop;