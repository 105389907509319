import React, { useState, useEffect } from "react";

interface InstallPromptProps {
	primaryColor?: string;
	secondaryColor?: string;
	onInstall: () => void;
}

const InstallPrompt: React.FC<InstallPromptProps> = ({
	primaryColor = "#007BFF",
	secondaryColor = "#f8f9fa",
	onInstall
}) => {
	const [isVisible, setIsVisible] = useState(false);

	useEffect(() => {
		const showPrompt = () => {
			if (!localStorage.getItem("dontShowInstallPrompt")) {
				setIsVisible(true);
			}
		};

		showPrompt();
	}, []);

	const handleInstall = () => {
		onInstall();
		setIsVisible(false);
	};

	const handleDoNotShowAgain: React.MouseEventHandler<HTMLButtonElement> = () => {
		localStorage.setItem("dontShowInstallPrompt", "true");
		setIsVisible(false);
	};

	if (!isVisible) return null;

	return (
		<div className="install-prompt">
			<h2>Install App</h2>
			<p>Get quick access by installing this app on your device.</p>
			<div className="prompt-actions">
				<button
					style={{ backgroundColor: primaryColor, color: "#fff" }}
					onClick={handleInstall}
				>
					Install
				</button>
				<button
					style={{ backgroundColor: secondaryColor, color: primaryColor }}
					onClick={handleDoNotShowAgain}
				>
					Do not show again
				</button>
			</div>
			<style>{`
                .install-prompt {
                    position: fixed;
                    bottom: 20px;
                    left: 20px;
                    right: 20px;
                    max-width: 320px;
                    padding: 16px;
                    border-radius: 8px;
                    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
                    background-color: ${secondaryColor};
                    color: ${primaryColor};
                    z-index: 1000;
                }
                .install-prompt h2 {
                    margin: 0 0 8px;
                    font-size: 1.2rem;
                }
                .install-prompt p {
                    margin: 0 0 16px;
                    font-size: 1rem;
                }
                .prompt-actions {
                    display: flex;
                    gap: 8px;
                }
                .prompt-actions button {
                    padding: 8px 16px;
                    border: none;
                    border-radius: 4px;
                    cursor: pointer;
                    font-size: 0.9rem;
                    transition: background-color 0.3s ease;
                }
                .prompt-actions button:hover {
                    opacity: 0.9;
                }
            `}</style>
		</div>
	);
};

export default InstallPrompt;
