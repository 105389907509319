import React, { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import "./ForgotPasswordDesktop.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Auth } from "aws-amplify";
import NotificationBox from "../Network/NotificationBox/NotificationBox";

const ForgotPasswordDesktop: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [signUpError, setSignUpError] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmpasswordVisible, confirmsetPasswordVisible] = useState(false);
  const [notificationPopup, setNotificationPopup] = useState(false);
	const [toasterMessage, setToasterMessage] = useState("");

  const navigate = useNavigate();

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };




  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const togglePasswordVisibilityconfirm = () => {
    confirmsetPasswordVisible(!confirmpasswordVisible);
  };

  const validatePassword = (password: string) => {
    const strongPasswordRegex =/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&!,.<>':;|_~=`+\-/=]).{6,}$/;
    return strongPasswordRegex.test(password);
  };

  const isFormValid =
    email &&
    password &&
    confirmPassword &&
    password === confirmPassword &&
    !emailError &&
    !passwordError &&
    !confirmPasswordError;

 

  const handleSignupWithOtp = async () => {
 
  };

  const handleSignupsWithOtp = () => {
    navigate("/signup-page");
  };

  const handleTermsandCondition = () => {
    navigate("/terms-condition");
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);
    if (!validateEmail(value)) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError("");
    }
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPassword(value);
    if (!validatePassword(value)) {
      setPasswordError(
        "Password must contain a minimum of 6 characters, 1 uppercase, 1 lowercase, 1 number, and 1 special character"
      );
    } else {
      setPasswordError("");
    }
  };

  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    setConfirmPassword(value);
    if (value !== password) {
      setConfirmPasswordError("Passwords do not match.");
    } else {
      setConfirmPasswordError("");
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      await signInWithRedirect({ provider: "Google" });
    } catch (error) {
      console.error("Error signing in with Google:", error);
      setSignUpError(
        "An error occurred during Google sign in. Please try again."
      );
    }
  };

  const handleForgotPassword = async () => {
    if (email && !emailError) {
      try {
        await  Auth.forgotPassword(email);
        // Redirect to OTP screen
        navigate("/forgotpassword-desktop-otp", { state: { email } });
      } catch (error) {
        console.error("Error initiating password reset:", error);
        setSignUpError("An error occurred. Please try again.");
      }
    }
  };

  const handleEmailBlur = () => {
		if (!email.trim()) {
			setEmailError("Please enter your email.");
		} else if (!validateEmail(email)) {
			setEmailError("Please enter a valid email address.");
		} else {
			setEmailError("");
		}
	};

  return (
    <>
    {notificationPopup && (
				<div className="notification-wrapper">
					<NotificationBox message={toasterMessage} />
				</div>
			)}
      <div className="phone-view">
      <div className="signup-form forgot-ph-cont">
                    {/* <div>
                        {" "}
                        <img src="assets/Logo.svg" alt="Center Logo" className="logo" />
                    </div> */}
               <div onClick={()=>navigate('/login-account')} className="d-flex gap-2 text-black mb-3 cursor-pointer">
               <img  src="assets/back-btn.svg" alt="Center Logo" className="logo-mr-0 ml-auto d-flex" />
               <span>Back</span>
                </div>

                    <div className="otp_info mb-3">We will send you One Time Password (OTP) to your email.</div>

                    <div className="form-group">
                        <label className="ltext">Email Address</label>

                        <input
                            type="email"
                            value={email}
                            onChange={handleEmailChange}
                            placeholder="Enter your Email Address"
                            className="input-field"
                            style={{
                                fontWeight: email ? "400" : "",
                                fontSize: email ? "16px" : "",
                            }}
                        />
                        {emailError && <span className="error-text error">{emailError}</span>}
                    </div>
                   

                    <button
                        className={`proceed-button con ${email && !emailError ? "enabled" : "disabled"}`}
                        disabled={!email || !!emailError}
                        onClick={handleForgotPassword}
                    >
                       Continue
                    </button>

                    {signUpError && <span className="error-text error">{signUpError}</span>}

                    <p className="new_user_signup_text mt-4">
                    Try signing in again?{" "}
                        <Link className="login_create" to="/login-account">
                            Sign In
                        </Link>
                    </p>
                    <br></br>
                </div>
      </div>
      {/* second container */}

      <div className="desktop-view">
        <div className="main-container">

          <div className="right-panel m-auto">
            <div className="send-email-panel-desktop auth-screen-desktop">
             <div className="singup-form">
                    {/* <div>
                        {" "}
                        <img src="assets/Logo.svg" alt="Center Logo" className="logo" />
                    </div> */}
               <div onClick={()=>navigate('/login-account')} className="d-flex gap-2 text-black mb-3 cursor-pointer">
                    <img  src="assets/back-btn.svg" alt="Center Logo" className="logo-mr-0 ml-auto d-flex" />
               <span>Back</span>
                </div>

                    <div className="otp_info">We will send you One Time Password (OTP) to your email.</div>

                    <div className="form-group">
                        <label className="ltext">Email Address</label>

                        <input
                            type="email"
                            value={email}
                            onChange={handleEmailChange}
                            onBlur={handleEmailBlur}
                            placeholder="Enter your Email Address"
                            className="input-field"
                            style={{
                                fontWeight: email ? "400" : "",
                                fontSize: email ? "16px" : "",
                            }}
                        />
                        {emailError && <span className="error-text error">{emailError}</span>}
                    </div>
                   

                    <button
                        className={`proceed-button con ${email && !emailError ? "enabled" : "disabled"}`}
                        disabled={!email || !!emailError}
                        onClick={handleForgotPassword}
                    >
                       Continue
                    </button>

                    {signUpError && <span className="error-text error">{signUpError}</span>}

                    <p className="new_user_signup_text mt-4">
                    Try signing in again?{" "}
                        <Link className="login_create" to="/login-account">
                            Sign In
                        </Link>
                    </p>
                    <br></br>
                </div>
            </div>
           
            {/* <div className="footers-container">
              <div className='footers-text'>  <span >
                © Konfhub 2024
              </span>
              </div>

              <div>Privacy Policy</div>
            </div> */}


          </div>
          <div className="left-panel auth-screen-container"  style={{backgroundImage: `url('/assets/layesImage.svg')`}}>
            {/* <div className="leftpanel-tag-container">
              <img src="assets/StarforLeft.svg" alt="this is star img" />
              <p className="tagline-for-leftpanel">
                Trusted by over 10,000 event organizers worldwide.
              </p>
            </div> */}
          </div>
        </div>



      </div>
    </>
  );

};

export default ForgotPasswordDesktop;
