import React, { useCallback, useEffect, useState } from "react";
import "./AddPeople.css";
import { getListOfAttendees } from "../../../crud/meetings.crud";
import { useEvent } from "../../../contexts/EventContext";
import { ClipLoader } from "react-spinners";
import CustomButton from "../../../CommomComponents/CustomButton/CustomButton";
import InfiniteScroll from "react-infinite-scroller";
import { debounce } from "lodash";
import { Icon } from "@iconify/react/dist/iconify.js";
import { profileImage } from "../../../reusable/images";

interface AddPeopleProps {
	onClose: () => void;
	formik: any;
}

const AddPeople: React.FC<AddPeopleProps> = React.memo(({ onClose, formik }) => {
	const { eventDetails } = useEvent();
	const [loading, setLoading] = useState(false);
	const [hasMore, setHasMore] = useState(true);
	const [searchTerm, setSearchTerm] = useState("");
	const [attendees, setAttendees] = useState<any[]>([]);
	const [searchedAttendees, setSearchedAttendees] = useState<any[]>([]);
	const [selectedPeople, setSelectedPeople] = useState<number[]>(formik?.values?.attendees);

	const attendeesList = searchTerm ? searchedAttendees : attendees;

	const fetchAttendeesSearch = useCallback(
		debounce(async () => {
			if (loading || !hasMore) return;

			setLoading(true);
			try {
				const limit = 10;
				const offset = searchedAttendees.length;
				const response = await getListOfAttendees({
					eventId: eventDetails?.event_id,
					limit,
					offset,
					searchTerm
				});
				const newAttendees = response.data?.attendees;
				setSearchedAttendees(prevAttendees => [
					...prevAttendees.filter(
						(each: any) =>
							!newAttendees
								.map((each: any) => each?.attendee_id)
								.includes(each?.attendee_id)
					),
					...newAttendees
				]);
			} catch (error) {
				console.log("Error fetching attendees:", error);
				// You can display a message or set an error state here
			} finally {
				setLoading(false);
			}
		}, 500),
		[searchTerm] // Dependencies for debounce
	);

	const fetchAttendees = debounce(async () => {
		if (loading || !hasMore) return;

		setLoading(true);

		try {
			const limit = 10;
			const offset = attendees.length;

			const response = await getListOfAttendees({
				eventId: eventDetails?.event_id,
				limit,
				offset
			});
			const newAttendees = response.data?.attendees;

			setAttendees(prevAttendees => [
				...prevAttendees.filter(
					(each: any) =>
						!newAttendees
							.map((each: any) => each?.attendee_id)
							.includes(each?.attendee_id)
				),
				...newAttendees
			]);
			setHasMore(newAttendees.length === limit);
		} catch (error) {
			console.error("Error fetching attendees:", error);
		} finally {
			setLoading(false);
		}
	}, 500);

	const handleAddSelectedAttendee = () => {
		formik.setFieldValue("attendees", selectedPeople);
		onClose();
	};

	useEffect(() => {
		if (searchTerm) {
			setSearchedAttendees([]);
			fetchAttendeesSearch();
			return () => {
				fetchAttendeesSearch.cancel(); // Cleanup debounce function on unmount
			};
		} else {
			setSearchedAttendees([]);
		}
	}, [searchTerm]);

	return (
		<>
			<div className="pb-1 pb-lg-4 position-relative">
				<div className="meeting-add-people-select-text px-4">Select</div>
				<div className="mt-3 w-100">
					<>
						<div className="w-100 px-3">
							<div className="meeting-people-searchbar-input-wrapper w-100">
								{/* <img
									className="meeting-search-icon"
									src="/assets/search-icon.svg"
									alt=""
								/> */}
								<input
									type="text"
									placeholder="Search"
									value={searchTerm}
									onChange={e => {
										setSearchTerm(e.target.value.toLowerCase());
									}}
									className="meeting-searchbar-input"
								/>

								<Icon
									icon={
										searchTerm
											? `oui:cross`
											: `material-symbols-light:search-rounded`
									}
									className="searchbar-search-icon"
									onClick={() => {
										setSearchTerm("");
									}}
									style={{ cursor: "pointer" }}
								/>
							</div>
						</div>
						<div className="meeting-people-list-wrapper mt-3 px-3">
							<InfiniteScroll
								loadMore={() => {
									if (!searchTerm) {
										fetchAttendees();
									}
								}}
								hasMore={hasMore}
								loader={
									<>
										{loading && (
											<div className="d-flex justify-content-center align-items-center flex-column">
												<ClipLoader
													color="var(--primary-color)"
													loading={true}
													size={50}
													aria-label="Loading Spinner"
												/>
											</div>
										)}
									</>
								}
								useWindow={false}
							>
								{attendeesList.map((each: any, index) => (
									<div
										role="button"
										className={`${index > 0 ? "mt-3" : ""}`}
										onClick={() => {
											if (
												selectedPeople.filter(
													(data: any) =>
														data?.attendee_id === each?.attendee_id
												).length === 0
											) {
												setSelectedPeople((data: number[]) => {
													return [...data, each];
												});
											}
											// if (selectedPeople.filter(data => data === each).length > 0) {
											// 	let _selectedPeople = [...selectedPeople];
											// 	_selectedPeople.splice(selectedPeople.indexOf(each), 1);
											// 	setSelectedPeople(() => [..._selectedPeople]);
											// }
										}}
									>
										<div
											className={`w-100 d-flex align-items-center meeting-people-box ${selectedPeople?.some((data: any) => data?.attendee_id === each?.attendee_id) ? "active" : ""}`}
										>
											{profileImage(each?.image_url, each?.att_name, 40, {
												width: "40px",
												height: "40px"
											})}
											<div className="ms-2 position-relative w-100 d-flex justify-content-between align-items-center">
												<div className="meeting-people-name-designation-wrapper d-flex flex-column justify-content-center">
													<div className="meeting-people-name">
														{each?.att_name || ""}
													</div>
													{(each?.designation || each?.organisation) && (
														<div className="meeting-people-designation-organization-wrapper">
															<span className="meeting-people-designation">
																{`${each?.designation ? each?.designation + ", " : ""}`}
															</span>
															<span className="meeting-people-organization">
																{`${each?.organisation || ""}`}
															</span>
														</div>
													)}
												</div>
												{selectedPeople.filter(
													(data: any) =>
														data?.attendee_id === each?.attendee_id
												).length > 0 && (
													<img
														role="button"
														className="meeting-people-select-close"
														src="/assets/close.svg"
														alt="Close"
														onClick={() => {
															let _selectedPeople = [
																...selectedPeople
															];
															_selectedPeople.splice(
																selectedPeople.indexOf(
																	selectedPeople.filter(
																		(data: any) =>
																			data?.attendee_id ===
																			each?.attendee_id
																	)[0]
																),
																1
															);
															setSelectedPeople([..._selectedPeople]);
														}}
													/>
												)}
											</div>
										</div>
									</div>
								))}
							</InfiniteScroll>
						</div>

						<div className="d-flex justify-content-center meeting-people-add-button-wrapper w-100">
							<CustomButton
								disabled={selectedPeople.length === 0}
								isBgFill={true}
								paddingLeftRight="152px"
								minWidth="0px"
								className="d-flex justify-content-center"
								onClick={() => {
									handleAddSelectedAttendee();
								}}
							>
								<div>Add</div>
							</CustomButton>
						</div>
					</>
				</div>
				<div></div>
			</div>
		</>
	);
});

export default AddPeople;
