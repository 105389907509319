import React, { useEffect } from "react";
import {
	Box,
	List,
	ListItemButton,
	ListItemText,
	Checkbox,
	Button,
	Typography,
	FormControlLabel,
	SwipeableDrawer
} from "@mui/material";
import "./FilterDrawer.css";
import { ClipLoader } from "react-spinners";
import _ from "lodash";
import { Icon } from "@iconify/react/dist/iconify.js";

interface FilterDrawerProps {
	isOpen: boolean;
	onClose: () => void;
	onOpen: () => void;
	filterData: any;
	setFilterTags: (selectedTags: { [key: string]: string[] }) => void;
	filterLoading: boolean;
	filterTags: any;
}

const FilterDrawer: React.FC<FilterDrawerProps> = ({
	setFilterTags,
	filterData,
	isOpen,
	onClose,
	onOpen,
	filterLoading,
	filterTags
}) => {
	const [isInitialLoaded, setIsInitialLoaded] = React.useState(false);
	const [selectedMenu, setSelectedMenu] = React.useState<string>(
		!_.isEmpty(filterTags) ? Object.keys(filterTags)?.[0] : filterData?.[0]?.name
	);
	const [tempSelectedFilters, setTempSelectedFilters] = React.useState<{
		[key: string]: string[];
	}>({});

	const spinnerStyle = {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		margin: "auto",
		height: "100%"
	};

	useEffect(() => {
		if (filterData?.length > 0 && !selectedMenu && !isInitialLoaded) {
			setSelectedMenu(!_.isEmpty(filterTags) ? Object.keys(filterTags)[0] : filterData?.[0]?.name);
			if(filterTags){
				setTempSelectedFilters(filterTags)
			}
			setIsInitialLoaded(true);
		}
	}, [filterData, selectedMenu, filterTags]);

	const toggleFilterItem = (menuName: string, itemName: string) => {
		setTempSelectedFilters(prev => {
			const currentItems = prev[menuName] || [];
			const newItems = currentItems.includes(itemName)
				? currentItems.filter(item => item !== itemName)
				: [...currentItems, itemName];

			return {
				...prev,
				[menuName]: newItems
			};
		});
	};

	const handleClear = () => {
		setTempSelectedFilters({});
	};

	const handleSelectAll = () => {
		const currentItems =
			filterData?.find((item: any) => item.name === selectedMenu)?.tags.map((tag: any) => tag.name) || [];
		setTempSelectedFilters(prev => ({
			...prev,
			[selectedMenu]:
				(tempSelectedFilters[selectedMenu] || []).length === currentItems.length
					? []
					: currentItems
		}));
	};

	// Apply filters only when "Apply" button is clicked
	const handleApplyFilters = () => {
		setFilterTags(tempSelectedFilters);
		onClose();
	};

	return (
		<SwipeableDrawer
			onOpen={onOpen}
			anchor="bottom"
			open={isOpen}
			onClose={onClose}
			className="main_drawer_agenda"
		>
			<div className="drawer-top-line"></div>
			<div className="d-flex justify-content-end mb-1 me-4">
				<Icon
					role="button"
					icon={`material-symbols-light:close`}
					width={"24px"}
					height={"24px"}
					onClick={() => {
						onClose();
					}}
				/>
			</div>
			<Typography variant="h6" className="headText_filter">
				Filters
			</Typography>
			<Box className="filter-container">
				{filterLoading ? (
					<div style={spinnerStyle}>
						<ClipLoader
							color="#4A90E2"
							loading={filterLoading}
							size={50}
							aria-label="Loading Filters"
						/>
					</div>
				) : filterData?.length > 0 ? (
					<>
						<Box className="filter-sidebar">
							<List>
								{filterData?.map((filterItem: any) => (
									<ListItemButton
										key={filterItem.id}
										onClick={() => setSelectedMenu(filterItem.name)}
										selected={selectedMenu === filterItem.name}
										className="menu-item_filter"
										style={{
											backgroundColor:
												selectedMenu === filterItem.name
													? "var(--primary-color) !important"
													: "",
											color: selectedMenu === filterItem.name ? "var(--comp-secondary-color) !important" : ""
										}}
									>
										<ListItemText
											className="menu-item_text"
											primary={filterItem.name}
										/>
									</ListItemButton>
								))}
							</List>
						</Box>

						<Box className="filter-content">
							{filterData?.map(
								(filterItem: any) =>
									selectedMenu === filterItem.name && (
										<List key={filterItem.id}>
											<ListItemButton>
												<FormControlLabel
													control={
														<Checkbox
															style={{
																color:
																	(
																		tempSelectedFilters[
																			selectedMenu
																		] || []
																	).length ===
																	filterItem.tags.length
																		? "var(--primary-color) !important"
																		: ""
															}}
															checked={
																(
																	tempSelectedFilters[
																		selectedMenu
																	] || []
																).length === filterItem.tags.length
															}
															onChange={handleSelectAll}
														/>
													}
													label={`All`}
												/>
											</ListItemButton>
											{filterItem.tags.map((tag: any) => (
												<ListItemButton key={tag.id}>
													<FormControlLabel
														control={
															<Checkbox
																style={{
																	color: (
																		tempSelectedFilters[
																			selectedMenu
																		] || []
																	).includes(tag.name)
																		? "var(--primary-color) !important"
																		: ""
																}}
																checked={(
																	tempSelectedFilters[
																		selectedMenu
																	] || []
																).includes(tag.name)}
																onChange={() =>
																	toggleFilterItem(
																		selectedMenu,
																		tag.name
																	)
																}
															/>
														}
														label={tag.name}
													/>
												</ListItemButton>
											))}
										</List>
									)
							)}
						</Box>
					</>
				) : (
					<div style={{ margin: "auto" }} className="no-results">
						Filter not found
					</div>
				)}
			</Box>
			<Box className="filter-actions">
				<Button
					variant="outlined"
					onClick={handleClear}
					className="btn_agenda clear-btn text-capitalize"
				>
					Clear all
				</Button>
				<Button
					variant="contained"
					onClick={handleApplyFilters}
					className="applybtn_agenda btn_agenda text-capitalize"
				>
					Apply
				</Button>
			</Box>
		</SwipeableDrawer>
	);
};

export default FilterDrawer;
