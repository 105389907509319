import React from 'react';
import { Icon } from "@iconify/react/dist/iconify.js";

interface DownloadSectionProps {
    handleDownloadClick: () => void;
    showDownloadOptions: boolean;
    setDownloadDrawerOpen: (open: boolean) => void;
    activeTab: string;
    toggleFilterModal: () => void;
    setFilterModal: boolean;
    attendeesList: any

}

const DownloadSection: React.FC<DownloadSectionProps> = ({
    handleDownloadClick,
    showDownloadOptions,
    setDownloadDrawerOpen,
    activeTab,
    setFilterModal,
    toggleFilterModal,
    attendeesList
}) => {
    const allowedDomains = [
        "future-sustainability-forum.dev-app.konfhub.com",
        "webapp.futuresustainabilityforum.com",
        "webapp.app.konfhub.com",
        "localhost"
    ];

    const isFilterEnabled = allowedDomains.includes(window.location.hostname);

    return (
        <div className='download-section'>
            {activeTab === "All" ? (
                isFilterEnabled && (
                    <button onClick={toggleFilterModal} className='download-button network_download_s'>
                        <Icon
                            icon={`material-symbols-light:filter-alt-outline`}
                            width={"25px"}
                            height={"25px"}
                            className=''
                        />
                    </button>
                )
            ) : (
                <button onClick={handleDownloadClick} disabled={!attendeesList} className='download-button network_download_s'>
                    <Icon
                        icon={`material-symbols-light:download`}
                        width={"25px"}
                        height={"25px"}
                        className=''
                    />
                </button>
            )}
        </div>
    );
};

export default DownloadSection;
