import React from 'react';
import './BusinessCard.css';



interface SocialLink {
  icon: string;
  url: string;
}

interface BusinessCardProps {
  name: string;
  title: string;
  avatarUrl: string;
  qrCodeUrl: string;
  socialLinks: SocialLink[];
}

const BusinessCard: React.FC<BusinessCardProps> = ({
  name,
  title,
  avatarUrl,
  qrCodeUrl,
  socialLinks
}) => {
  return (
    <>
      <div className="business-card">
        <div className="card-content">
          <div className="personal-info">
            <h2>{name}</h2>
            <p>{title}</p>
          </div>
          <img src={avatarUrl} alt={name} className="avatar" />
        </div>
        <div className="s-link-qr-bussiness_card">
          <div className="social-icons">
            {socialLinks.map((link, index) => (
              <a key={index} href={link.url} target="_blank" rel="noopener noreferrer">
                <img src={link.icon} alt={`Social icon ${index + 1}`} className="social-icon" />
              </a>
            ))}
          </div>
          <img src={qrCodeUrl} alt="QR Code" className="qr-code_bussinessc" />
        </div>
      </div>

     
    </>
  );
};

export default BusinessCard;