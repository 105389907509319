import React from "react";
import { ClipLoader } from "react-spinners";

interface CustomLoader {
	height?: string;
	isButtonLoader?: boolean;
    loaderSize?: number
}

const CustomLoader: React.FC<CustomLoader> = ({ height = "250px", isButtonLoader = false, loaderSize = 50 }) => {
	return (
		<>
			{!isButtonLoader ? (
				<div
					style={{ height }}
					className="d-flex justify-content-center align-items-center flex-column w-100"
				>
					<ClipLoader
						color="var(--primary-color)"
						loading={true}
						size={loaderSize}
						aria-label="Loading Spinner"
					/>
				</div>
			) : (
				<ClipLoader
					color="#ffffff"
					loading={true}
					size={loaderSize}
					aria-label="Loading Spinner"
				/>
			)}
		</>
	);
};

export default CustomLoader;
