import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './ChangePassword.css';
import { useNavigate, useParams } from "react-router-dom";
import { Bell } from 'lucide-react';

const ChangePassword: React.FC = () => {
  const navigate = useNavigate();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isValid, setIsValid] = useState(false); // Validation state
  const [isSubmitted, setIsSubmitted] = useState(false); // Submission state

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
   
    if (oldPassword && newPassword && confirmPassword && newPassword === confirmPassword) {
      setIsValid(true);
      setIsSubmitted(true);
    } else {
      setIsValid(false);
      alert('Please ensure all fields are filled and passwords match.');
    }
  };
  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="change-password-container">
      <header className="profile-header_pprofile">
      <img className='left_uparrow search_left_arrow ch'  src="/assets/uparrow.svg" alt="Go Back" onClick={goBack} />

        <div className="notification-icon_pprofile">
          <span> <Bell className="icons interest_bell right-icon1" /></span>
        </div>
      </header>
      <div><h1 className='heading_password'>Change Password</h1></div>
      <form onSubmit={handleSubmit}>
        <div className="input-group_password">
          <label htmlFor="oldPassword" className='pass'>Old Password</label>
        <div>  <input
            type="password"
            id="oldPassword"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            placeholder="Password"
            className='passoword' style={{padding: '15px'}}
          /></div>
        </div>
        <div className="input-group_password">
          <label htmlFor="newPassword" className='pass'>New Password</label>
          <input
            type="password"
            id="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            placeholder="Password" style={{padding: '15px'}}
          />
        </div>
        <div className="input-group_password">
          <label htmlFor="confirmPassword" className='pass'>Confirm Password</label>
          <input
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="Confirm password" style={{padding: '15px'}}
          />
        </div>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        
        <button
          type="submit"
          className={`verify-button_password ${isSubmitted ? 'submitted' : ''}`} // Conditionally change class
        >
          Verify
        </button>
      </form>
    </div>
  );
};

export default ChangePassword;
