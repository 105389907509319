import { ENVS, ENV_NAME } from "./envs";
import { centralLoginUrl } from "./app";
import { graphqlPublicApiKey } from "./app";
import { Auth } from "aws-amplify";

export const attenndeeAppId =
	ENVS?.[ENV_NAME]?.VITE_AWS_USER_POOLS_WEB_CLIENT_ID || "1cum79568pe0u434ek1d5kin9a";
export const userPoolId = ENVS?.[ENV_NAME]?.VITE_AWS_USER_POOLS_ID || "ap-southeast-1_f6tfrmIsS";
export const identityPoolId =
	ENVS?.[ENV_NAME]?.VITE_AWS_COGNITO_IDENTITY_POOL_ID ||
	"ap-southeast-1:9a5331da-329a-497d-be61-15668b42bf8f";
export const oAuthDomain = ENVS?.[ENV_NAME]?.VITE_OAUTH_DOMAIN || "dev-auth.konfhub.com";
export const graphqlUrl =
	ENVS?.[ENV_NAME]?.VITE_AWS_APPSYNC_GRAPHQLENDPOINT ||
	"https://dev-attendee-api.konfhub.com/graphql";
export const projectRegion = ENVS?.[ENV_NAME]?.VITE_AWS_PROJECT_REGION || "ap-southeast-1";
export const cognitoRegion = ENVS?.[ENV_NAME]?.VITE_AWS_COGNITO_REGION || "ap-southeast-1";
export const userPoolWebClientId =
	ENVS?.[ENV_NAME]?.VITE_AWS_USER_POOLS_WEB_CLIENT_ID || "1cum79568pe0u434ek1d5kin9a";

const defaultConfig = {
	aws_project_region: projectRegion,
	aws_cognito_identity_pool_id: identityPoolId,
	aws_cognito_region: cognitoRegion,
	aws_user_pools_id: userPoolId,
	aws_user_pools_web_client_id: userPoolWebClientId,
	authenticationFlowType: "USER_SRP_AUTH",
	oauth: {
		domain: oAuthDomain,
		scopes: ["phone", "email", "profile", "openid", "aws.cognito.signin.user.admin"],
		redirectSignIn: [centralLoginUrl, "http://localhost:5173/home", location.href],
		redirectSignOut: [centralLoginUrl, "http://localhost:5173/", location.href],
		responseType: "code"
	}
};

export const authConfig = {
	...defaultConfig,
	...(ENV_NAME !== "custom"
		? {
				cookieStorage: {
					domain: ENVS[ENV_NAME].VITE_HOST_NAME || "localhost",
					secure: false,
					path: "/",
					expires: 365
				}
			}
		: [])
};

export const defaultAwsConfig = {
	Auth: {
		region: cognitoRegion,
		// aws_appsync_apiKey: import.meta.env.VITE_AWS_APPSYNC_APIKEY ? import.meta.env.VITE_AWS_APPSYNC_APIKEY: "kyema7eor5aexjc6pjnr7yusd4",
		userPoolId: userPoolId,
		identityPoolId: identityPoolId,
		userPoolWebClientId: userPoolWebClientId,
		mandatorySignIn: false
	},
	aws_appsync_graphqlEndpoint: graphqlUrl,
	aws_appsync_region: cognitoRegion,
	aws_appsync_apiKey: graphqlPublicApiKey,
	aws_appsync_authenticationType: "API_KEY"
};

export const loggedinAwsConfig = {
	API: {
		graphql_headers: async () => {
			const session = await Auth.currentSession();
			return {
				Authorization: session.getIdToken().getJwtToken()
			};
		}
	},
	aws_appsync_graphqlEndpoint: graphqlUrl,
	aws_appsync_region: cognitoRegion,
	aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS"
};

// Note: Please do not remove this. This will come handy if we decide to upgrade the amplify version in future.
//PROD===========================
// const awsconfig = {
//   Auth: {
//     Cognito: {
//       userPoolId: "ap-south-1_acJo3XGbL",
//       userPoolClientId: "5jf10rk2c0muftotp1iu2ucup0",
//       identityPoolId: "ap-south-1:0bf02d86-5b7b-4665-964d-cb52f756903b",
//       loginWith: { // Optional
//         oauth: {
//           domain: "auth.konfhub.com",
//           scopes: [ "phone", "email", "profile", "openid", "aws.cognito.signin.user.admin"],
//           redirectSignIn: ["https://accounts.konfhub.com"],
//           redirectSignOut: ["https://accounts.konfhub.com"],
//           responseType: "code",
//         },
//         username: true,
//         email: false, // Optional
//         phone: false, // Optional
//       },
//       passwordFormat: {
//         minLength: 6,
//         requireLowercase: true,
//         requireUppercase: true,
//         requireNumbers: true,
//         requireSpecialCharacters: true,
//       },
//     },
//   },
// };
