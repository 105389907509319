import React, { useEffect, useState } from "react";
import "./ColorPicker.css";

interface ColorPickerProps {
	colors: string[];
	formik: any
}

const ColorPicker: React.FC<ColorPickerProps> = ({ colors, formik }) => {
	// State to keep track of the selected 
	const [isLoaded, setIsLoaded] = useState(false);
	const [selectedColor, setSelectedColor] = useState<string | null>();

	// Handle color selection
	const handleColorSelect = (color: string) => {
		setSelectedColor(color);
		formik.setFieldValue("default_color", color);
	};

	useEffect(()=>{
		if(!isLoaded && formik.values.default_color){
			setSelectedColor(formik.values.default_color)
			setIsLoaded(true);
		}
	}, [formik.values.default_color])

	return (
		<div className="">
			<div style={{ display: "flex", gap: "10px" }}>
				{colors.map(color => (
					<div
						key={color}
						onClick={() => handleColorSelect(color)}
						className="color-picker-default me-2"
						style={{
							backgroundColor: color,
							border: color === selectedColor ? "3px solid white" : "1px solid gray"
						}}
					></div>
				))}
			</div>
			{/* {selectedColor && (
				<p style={{ marginTop: "10px" }}>
					Selected Color: <span style={{ color: selectedColor }}>{selectedColor}</span>
				</p>
			)} */}
		</div>
	);
};

export default ColorPicker;
