
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { confirmSignUp, confirmSignIn, signIn } from 'aws-amplify/auth';
import './SigninWithEmail.css';

const SigninWithEmail: React.FC = () => {
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const navigate = useNavigate();
  


    const isFormValid =
  
    !emailError ;

    const validateEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      };
    
    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setEmail(value);
        if (!validateEmail(value)) {
          setEmailError("Please enter a valid email address.");
        } else {
          setEmailError("");
        }
      };


       const handleSignupWithOtp = async () => {
    if (isFormValid) {
    
    }
  };
  

   
    return (
        <> 
        <div className='phone-view'>
         
        </div>


        {/* desktop-view */}
        <div className="desktop-view">
        <div className="main-container">
          <div className="left-panel auth-screen-container"  style={{backgroundImage: `url('/assets/layesImage.svg')`}}>
            <div className="leftpanel-tag-container">
              <img src="assets/StarforLeft.svg" alt="this is star img" />
              <p className="tagline-for-leftpanel">
                Trusted by over 10,000 event organizers worldwide.
              </p>
            </div>
          </div>
          <div className="right-panel ">
            <div className="panel-desktop-otps">
            <div className='font '>
            <div><img src="assets/Logo.svg" alt="Center Logo" className="logo-desk-otp" /></div>
            <div className="email-otp-container">
                <div className='email-desks'>We will send you One Time Password (OTP) to your email. </div>
                  
                <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                  placeholder="Enter your email"
                  className="puts"
                  style={{
                    color: email ? "#002E6E" : "",
                    fontWeight: email ? "700" : "",
                    fontSize: email ? "17px" : "",
                  }}
                />
                {emailError && <span className="error-text error">{emailError}</span>}
              </div>
                
              <button
                className={`proceed-button doc ${isFormValid ? "enabled" : "disabled"}`}
                disabled={!isFormValid}
                onClick={handleSignupWithOtp}
              >
                Continue
              </button>
            </div>
        </div>  
            </div>
            <div className="powered-by">
                <span className="powered-text">Powered by</span>
                <img src="assets/Group.png" alt="Logo" className="logo1" />
            </div>
           
          </div>
        </div>
      </div>
        
        </>
    );
};

export default SigninWithEmail;