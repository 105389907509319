import React from 'react';
import { Box, Typography, Button, Grid, IconButton } from '@mui/material';
import './Vcard.css'; // Custom styles

const Vcard: React.FC = () => {

    const socialLinks = [
        { icon: 'assets/icons/linkedin.svg', url: 'https://in.linkedin.com' },
        { icon: 'assets/icons/twitter.svg', url: 'https:/x.com' },
        { icon: 'assets/icons/whatsapp.svg', url: 'https://whatsapp.com' },
        { icon: 'assets/icons/website.svg', url: 'mailto:angyl@example.com' },
    ];

    return (
        <Box className="main-vcard" >
            {/* Business Card */}
            <Box className="vcard" >


                {/* Name and Title */}
                <Typography variant="h6" sx={{ color: '#0d47a1', fontSize:18 }}>
                    Anoyki Soe
                </Typography>
                <Typography variant="subtitle2" sx={{ color: '#666', marginBottom: 1, fontSize: 14 }}>
                    Investor, Infy
                </Typography>

                {/* Contact Info */}
                <Grid container spacing={1} alignItems="center" sx={{ marginBottom: 1 }}>
                    <Grid item>
                        <IconButton size="small">
                            <img src="assets/icons/gphone.svg" alt="phone" />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <Typography variant="body2" sx={{ color: '#666', fontSize: 12 }}>
                            +91 7868325326
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={1} alignItems="center" sx={{ marginBottom: 1 }}>
                    <Grid item>
                        <IconButton size="small">
                            <img src="assets/icons/gmail.svg" alt="mail" />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <Typography variant="body2" sx={{ color: '#666', fontSize: 12 }}>
                            simonroobben@amdubai.com
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={1} alignItems="center" sx={{ marginBottom: 1 }}>
                    <Grid item>
                        <IconButton size="small">
                            <img src="assets/icons/gloc.svg" alt="location" />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <Typography variant="body2" sx={{ color: '#666', fontSize: 12 }}>
                            Mumbai, India
                        </Typography>
                    </Grid>
                </Grid>


                <div className="social-icons-vcard">
                    {socialLinks.map((link, index) => (
                        <a key={index} href={link.url} target="_blank" rel="noopener noreferrer">
                            <img src={link.icon} alt={`Social icon ${index + 1}`} className="social-icon" />
                        </a>
                    ))}
                </div>
            </Box>


            {/* Button */}
            <Button
                variant="contained"

                sx={{ marginTop: 4, width: 200 }}
                onClick={() => window.location.href = '/profile-view'}
            >
                Back to Profile
            </Button>
        </Box>
    );
};

export default Vcard;
