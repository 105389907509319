import axios from "./axios";
// import { errorToaster, successToaster } from '../helpers/utils'
import { apiUrl } from "../config/crud";

//Image Upload
const API_URL = apiUrl;
const UPLOAD_ENDPOINT = "/upload-file";
const CHECKIN = "/event/";

export const UploadImage = async (
	file,
	fileName,
	moduleNumber,
	mimeType,
	eventId,
	faceImage,
	formID,
	profile = false
) => {
	const request = {
		file_name: fileName,
		mime_type: mimeType
	};
	if (moduleNumber) request.module = moduleNumber;

	let uploadUrl, downloadUrl;

	const uploadToUrl = async (
		uploadUrl,
		key,
		AWSAccessKeyId,
		policy,
		signature,
		securityToken,
		fields
	) => {
		const formData = new FormData();
		Object.keys(fields).forEach(k => {
			if (typeof fields[k] !== "object") formData.append(k, fields[k]);
			else formData.append(k, JSON.stringify(fields[k]));
		});
		formData.append("file", file);

		try {
			const axiosWithoutAuthorization = axios.create();
			delete axiosWithoutAuthorization.defaults.headers.common["Authorization"];
			let res = await axiosWithoutAuthorization.post(uploadUrl, formData, {
				headers: {
					"Content-Type": "multipart/form-data",
					"Access-Control-Allow-Origin": "*"
				}
			});
			return res;
		} catch (err) {
			console.log("err: ", err);
			return;
		}
	};

	const uploadProfileToUrl = async uploadUrl => {
		try {
			const axiosWithoutAuthorization = axios.create();
			delete axiosWithoutAuthorization.defaults.headers.common["Authorization"];
			let request_config = {
				headers: {
					"Content-Type": mimeType,
					"Access-Control-Allow-Origin": "*"
				}
			};
			let res = await axiosWithoutAuthorization.put(uploadUrl, file, request_config);
			return res;
		} catch (err) {
			console.log("err: ", err);
			return;
		}
	};

	try {
		let apiUrl;
		if (!eventId) apiUrl = API_URL + "/upload-file";
		else {
			apiUrl = API_URL + CHECKIN + eventId + "/form-file/" + formID + "/upload";
			if (faceImage === true && eventId) {
				apiUrl = API_URL + CHECKIN + eventId + "/face-upload";
			}
		}
		let res = await axios.post(apiUrl, request);
		uploadUrl = res.data.upload.url;
		downloadUrl = res.data.download.url;
		const fields = res.data.upload?.fields || {};
		let key = res.data?.upload?.fields?.key;
		let AWSAccessKeyId = res.data?.upload?.fields?.AWSAccessKeyId;
		let policy = res.data?.upload?.fields?.policy;
		let signature = res.data?.upload?.fields?.signature;
		let securityToken = res.data?.upload?.fields?.["x-amz-security-token"];
		if (profile) await uploadProfileToUrl(uploadUrl);
		else
			await uploadToUrl(
				uploadUrl,
				key,
				AWSAccessKeyId,
				policy,
				signature,
				securityToken,
				fields
			);
	} catch (err) {
		let error = err.message || "Unable to upload file. Please try again!";
		console.log("error: ", error);
		return;
	}

	return downloadUrl;
};
