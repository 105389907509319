import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import FilterDrawerDesktop from "../../components/FilterDrawer/FilterDreawerDesktop";
import FilterDrawer from "../../components/FilterDrawer/FilterDrawer";
import "./SearchAndFilter.css";

interface FilterSectionProps {
	filterData: any;
	filterLoading: boolean;
	getFilters: () => void;
	setFilterTags: (value: object) => void;
	showFilter?: boolean;
	filterTags: any
}

const FilterSection: React.FC<FilterSectionProps> = ({
	filterData,
	filterLoading,
	getFilters,
	setFilterTags,
	showFilter = true,
	filterTags
}) => {
	const [filterModal, setFilterModal] = useState(false);
	const [isFilterDrawerOpen, setFilterDrawerOpen] = useState(false);

	const toggleFilterModal = () => setFilterModal(!filterModal);

	if (!showFilter) return null;

	return (
		<>
			{/* Desktop Filter */}
			<div className="hide-in-mobile ms-2">
				{filterData?.length > 0 && (
					<div
						className="searchbar-filter-btn"
						onClick={() => {
							toggleFilterModal();
							getFilters();
						}}
					>
						<Icon
							icon={`material-symbols-light:filter-alt-outline`}
							width={"20px"}
							height={"20px"}
							className="searchbar-filter-icon"
						/>
					</div>
				)}
				<div className={`filter-section ${filterModal ? "show" : ""}`}></div>
			</div>

			{/* Mobile Filter */}
			<div className="hide-in-desktop ms-2">
				{filterData?.length > 0 && (
					<div
						className="searchbar-filter-btn"
						onClick={() => {
							setFilterDrawerOpen(true);
							getFilters();
						}}
					>
						<Icon
							icon={`material-symbols-light:filter-alt-outline`}
							width={"20px"}
							height={"20px"}
							className="searchbar-filter-icon"
						/>
					</div>
				)}
				<div className={`filter-section ${filterModal ? "show" : ""}`}></div>
			</div>

			{/* Filter Drawers */}
			{filterModal && (
				<FilterDrawerDesktop
					filterLoading={filterLoading}
					filterData={filterData}
					setFilterTags={setFilterTags}
					onClose={() => {
						setFilterModal(false);
					}}
					filterTags={filterTags}
				/>
			)}
			<FilterDrawer
				filterLoading={filterLoading}
				filterData={filterData}
				setFilterTags={setFilterTags}
				isOpen={isFilterDrawerOpen}
				onClose={() => {
					setFilterDrawerOpen(false);
				}}
				filterTags={filterTags}
				onOpen={()=>{
					setFilterDrawerOpen(true);
				}}
			/>
		</>
	);
};

export default FilterSection;
