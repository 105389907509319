import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Amplify, Auth } from "aws-amplify";
import * as workerTimers from "worker-timers";
import { ToastContainer } from "react-toastify";
import AppRoutes from "./routes/AppRoutes";
import ErrorBoundary from "./utils/errorBoundary";
import AuthWrapper from "./components/wrappers/AuthWrapper";
import { jwtToken, setJwtToken } from "./crud/axios";
import { getSubdomain } from "./utils/core";
import { getEventDetails } from "./apis/app.crud";
import { getAllModules } from "./crud/module.crud";
import { useUser } from "./contexts/AuthContext";
import { useModule } from "./contexts/ModuleContext";
import { useNotificationContext } from "./contexts/NotificationContext";
import { useMessageContext } from "./contexts/MessageContext";
import { useEvent } from "./contexts/EventContext";
import { usePWAInstallPrompt } from "./hooks/installer";
import InstallPrompt from "./CommomComponents/InstallPrompt";
import DynamicMetaTags from "./CommomComponents/MetaTags";
import { requestNotificationPermission } from "./utils/notifications";
import { clickTrackingModules, defaultModuleUrls, spinnerStyle } from "./config/app";
import ClipLoader from "react-spinners/ClipLoader";
import { getAttendeeDetails } from "./crud/attendee.crud";
import {
	getComplementaryColor,
	useScreenWidth,
	addEmptyStringForNullValues
} from "./reusable/common";
import { getUserById } from "./crud/users.crud";
import { getNotification } from "./crud/notification.crud";
import { convertKeysToCamelCase } from "./reusable/strings";
import { loggedinAwsConfig } from "./config/aws-exports";
import { trackEntity } from "./crud/tracking.crud";

const App: React.FC = () => {
	const {
		user,
		setUser,
		setIsRegistered,
		setIsOrganizer,
		setLoading,
		loading,
		setCurrentUserAttendeeId
	} = useUser();
	const { eventDetails, setEventDetails, setEventLoading, setEventError, eventLoading } =
		useEvent();
	const {
		setModulesDetails,
		setModulesLoading,
		setModulesError,
		modulesLoading,
		setModulesNames,
		setMenuItems
	} = useModule();
	const { isInstallPromptSupported, showInstallPrompt, isAppInstalled } = usePWAInstallPrompt();
	const { subscribeToPushNotifications, subscribeToPushNotificationsPublic, showNotification } =
		useNotificationContext();
	const { subscribeToMessages } = useMessageContext();
	const navigate = useNavigate(); // Hook for navigation
	// Fetch query parameters from URL
	const queryParams = new URLSearchParams(window.location.search);
	const primaryColorQuery = queryParams.get("primaryColor")
		? `#${queryParams.get("primaryColor")}`
		: null;
	const secondaryColorQuery = queryParams.get("secondaryColor")
		? `#${queryParams.get("secondaryColor")}`
		: null;
	const appFontQuery = queryParams.get("appFont");
	const notificationId: string = queryParams.get("notificationId") || "";

	const isSmallScreen = useScreenWidth();

	const fetchEventDetails = async () => {
		try {
			setEventLoading(true);
			setModulesLoading(true);

			//Get the event details
			const eventResponse = await getEventDetails(getSubdomain());
			localStorage.setItem("eventId", eventResponse?.data?.event_id);
			//Get the list of modules configuredfor the app
			const modules = await getAllModules(eventResponse?.data?.event_id);
			//Set the event data to the event context
			setEventDetails({
				...eventResponse?.data,
				primaryColor:
					primaryColorQuery || eventResponse?.data?.att_app_pri_col || "#002E6E",
				secondryColor:
					secondaryColorQuery || eventResponse?.data?.att_app_sec_col || "#EFF2FA",
				appFont: appFontQuery || eventResponse?.data?.att_app_font || "'Figtree'",
				eventUrl: eventResponse?.data?.event_url,
				eventId: eventResponse?.data?.event_id
			});
			localStorage.setItem("eventId", eventResponse?.data?.event_id);
			localStorage.setItem("appLogo", eventResponse?.data?.att_app_logo);
			//Set the modules data to the event context
			setModulesDetails({
				...modules?.data
			});
			//Create the list of menu items and update them in modules context
			const updatedMenuItems: any = [];
			if (modules?.data?.length > 0) {
				const modulesNames: any = {};
				modules?.data?.sort((a: any, b: any) => a.att_app_order - b.att_app_order);
				modules?.data?.forEach((module: any) => {
					if (!module.att_app_visibility) return;
					modulesNames[module.module_id] = module.module_name;
					updatedMenuItems.push({
						id: module.module_id,
						icon: module.module_icon,
						label: module.module_name,
						number: module.module_type,
						url: module.is_default
							? defaultModuleUrls[module.module_type]
							: `/m/${module.module_id}`,
						onClick: (module: any) => {
							trackEntity(
								eventResponse?.data?.event_id,
								clickTrackingModules.MODULE,
								{
									id: module.id
								}
							);
						}
					});
				});
				setModulesNames(modulesNames || {});
				setMenuItems(updatedMenuItems);
			}
			setEventLoading(false);
			setEventError(false);
			setModulesLoading(false);
			setModulesError(false);
		} catch (error: any) {
			setEventLoading(false);
			setEventError(true);
			setModulesLoading(false);
			setModulesError(true);
			// Handle network/API failures and redirect to 500 page
			if (error.response?.status === 404) {
				navigate("/page-notfound"); // Navigate to 404 page on not found error
			} else {
				navigate("/internal-error"); // Navigate to 500 page on server error or other failure
			}
		}
	};

	const checkAuthStatus = async () => {
		try {
			const userResponse = await Auth.currentAuthenticatedUser();
			const sessionResponse = await Auth.currentSession();
			if (sessionResponse && userResponse) {
				setUser((user: any) => ({
					...user,
					session: sessionResponse,
					user: userResponse
				}));
				setJwtToken(sessionResponse?.getIdToken()?.getJwtToken());
				Amplify.configure({
					...loggedinAwsConfig
				});
			}
		} catch (error) {
			console.log("User is not authenticated");
			setUser(false);
			setLoading(false);
		}
	};

	const refreshToken = async () => {
		workerTimers.setInterval(
			async () => {
				try {
					const sessionResponse: any = await Auth.currentSession();
					const userResponse = await Auth.currentAuthenticatedUser();
					if (sessionResponse && userResponse) {
						userResponse.refreshSession(sessionResponse.refreshToken, (err: any, session: any) => {
							if (err) throw new Error(err);
							if(session?.idToken) {
								const { idToken } = session;
								setJwtToken(idToken.jwtToken);
							}
						});
					}
				} catch (error) {
					console.error("Error checking auth status:", error);
					console.log("User is not authenticated");
					setUser(false);
				}
			},
			60 * 45 * 1000
		);
	};

	const fetchAttendeeDetails = async () => {
		try {
			//Get the attendee details, if any
			if (eventDetails && user) {
				setLoading(true);
				const attendee = await getAttendeeDetails(eventDetails.event_id);
				setCurrentUserAttendeeId(attendee?.data?.attendee_id);
				localStorage.setItem("attendeeId", attendee.data.attendee_id);
				if (attendee.data.registrations.length > 0 && !attendee.data.is_organizer) {
					setIsRegistered(true);
					setIsOrganizer(false);
					localStorage.setItem(
						"registrations",
						JSON.stringify(attendee.data.registrations)
					);
				}
				if (attendee.data.is_organizer) {
					setIsRegistered(false);
					setIsOrganizer(true);
				}
				setLoading(false);
				//Subscribe to GraphQL subscription for push notifications
				subscribeToPushNotifications(attendee?.data);
				subscribeToMessages(attendee?.data?.attendee_id);
			}
		} catch (err) {
			console.log(err);
			setIsOrganizer(false);
			setIsRegistered(false);
			setLoading(false);
		}
	};

	const fetchNotification = async () => {
		try {
			const notificationResp = await getNotification(eventDetails?.event_id, notificationId);
			showNotification(convertKeysToCamelCase(notificationResp?.data[0]));
		} catch (err: any) {
			console.log("Could not load notification - ", notificationId, err);
		}
	};

	useEffect(() => {
		if (eventDetails) {
			// Set colors with precedence for query parameters
			document.documentElement.style.setProperty(
				"--primary-color",
				primaryColorQuery || eventDetails?.att_app_pri_col || "#002E6E"
			);
			document.documentElement.style.setProperty(
				"--secondary-color",
				secondaryColorQuery || eventDetails?.att_app_sec_col || "#EFF2FA"
			);
			const priColor = eventDetails.att_app_pri_col;
			const secColor = eventDetails.att_app_sec_col;

			const complementaryPrimaryColor = getComplementaryColor(priColor);
			const complementarySecondaryColor = getComplementaryColor(secColor);
			document.documentElement.style.setProperty(
				"--comp-primary-color",
				complementaryPrimaryColor
			);
			document.documentElement.style.setProperty(
				"--comp-secondary-color",
				complementarySecondaryColor
			);

			const font = `'${appFontQuery || eventDetails?.att_app_font || "Figtree"}', sans-serif`;
			document.documentElement.style.setProperty("--font-family", font);

			localStorage.setItem("eventUrl", eventDetails.eventUrl);
		}
	}, [eventDetails, primaryColorQuery, secondaryColorQuery]);

	async function getProfileAndRegistrations() {
		// setJwtToken(user.session?.tokens?.idToken?.toString());
		try {
			const res = await getUserById();
			const userData = addEmptyStringForNullValues(res.data);
			localStorage.setItem("user", JSON.stringify(userData));
		} catch (error) {
			console.log("error: ", error);
		}
	}

	useEffect(() => {
		requestNotificationPermission();
		getSubdomain();
		checkAuthStatus();
		fetchEventDetails();
	}, []);

	useEffect(() => {
		if (user) {
			const getCurrentUser = async () => {
				const sessionResponse: any = await Auth.currentSession();
				setJwtToken(sessionResponse?.getIdToken()?.getJwtToken());
				//Start refreshing token every 1 hour
				refreshToken();
				//Request notification permission
				requestNotificationPermission();
			};
			getCurrentUser();
			getProfileAndRegistrations();
		}
	}, [user]);

	useEffect(() => {
		if (eventDetails && user) {
			fetchAttendeeDetails();
		}
		if (!user && !loading && !jwtToken) {
			subscribeToPushNotificationsPublic();
		}
	}, [eventDetails, user]);

	useEffect(() => {
		if (notificationId && eventDetails) fetchNotification();
	}, [notificationId, eventDetails]);

	if (eventLoading || modulesLoading || loading)
		return (
			<div style={spinnerStyle}>
				<ClipLoader
					color="#4A90E2"
					loading={eventLoading || modulesLoading || loading}
					size={50}
					aria-label="Loading Spinner"
				/>
			</div>
		);

	return (
		<ErrorBoundary>
			<ToastContainer />
			{eventDetails && <DynamicMetaTags eventData={eventDetails} />}
			{!isAppInstalled && isInstallPromptSupported && (
				<InstallPrompt
					onInstall={showInstallPrompt}
					primaryColor={primaryColorQuery || eventDetails?.att_app_pri_col}
					secondaryColor={secondaryColorQuery || eventDetails?.att_app_sec_col}
				/>
			)}

			<div className="main-container h-100">
				<div className="right-panel h-100">
					{isSmallScreen ? (
						<div className="phone-container font h-100">
							<AuthWrapper>
								<AppRoutes />
							</AuthWrapper>
						</div>
					) : (
						<div className="desktop-container font h-100">
							<AuthWrapper>
								<AppRoutes desktop={true} />
							</AuthWrapper>
						</div>
					)}
				</div>
			</div>
		</ErrorBoundary>
	);
};

export default App;
