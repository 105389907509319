import { ENVS, ENV_NAME } from "./envs";

export const centralLoginUrl =
	ENVS?.[ENV_NAME]?.VITE_CENTRAL_LOGIN_URL || "https://dev-accounts.konfhub.com";

export const graphqlPublicApiKey =
	ENVS?.[ENV_NAME]?.VITE_GRAPHQL_PUBLIC_API_KEY || "da2-r3oju7njvrbhhed2egv3nel6jy";

export const timeZoneAbbreviations: { [key: string]: string } = {
	"Asia/Kolkata": "IST",
	"Asia/Dubai": "GST",
	"America/New_York": "EST",
	"America/Los_Angeles": "PST",
	"Europe/London": "GMT"
};

export const filterEntity = {
	AGENDA: 1,
	SPONSORS: 2,
	SPEAKERS: 3,
	FLOORPLAN: 4,
	PARTNERS: 5,
	EXHIBITOR: 6
};

export const apiEntity = {
	SPONSORS: 1,
	PARTNERS: 2,
	EXHIBITOR: 3
};

export const spinnerStyle = {
	position: "fixed" as const,
	top: 0,
	left: 0,
	right: 0,
	bottom: 0,
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	zIndex: 9999,
	color: "var(--primary-color)"
};

export const moduleTypes = {
	SPONSORS: 1,
	SPEAKERS: 2,
	EXHIBITORS: 3,
	CHAT: 4,
	ADDRESS: 5,
	NETWORK: 6,
	PARTNERS: 7,
	AGENDA: 8,
	DOCUMENT_LINKS: 9,
	PEOPLE: 11,
	COMPANIES: 12,
	SCAN: 13,
	MEETINGS: 14,
	AI_PHOTO_BOOTH: 15
};

export const actionButtons = {
	ACCEPT: "Accept",
	WITHDRAW: "Withdraw",
	CONNECT: "Connect",
	REJECT: "Reject",
	REMOVE: "Remove",
	DECLINE: "Decline"
};

export const clickTrackingModules = {
	NOTIFICATIONS: 1,
	CUSTOM_MODULE_ITEM: 2,
	MODULE: 3
};

export const notificationMetricEvents = {
	RECEIVED: 1,
	READ: 2,
	CLOSE: 3,
	CLICK: 4
};

export const networkEntity = {
	ACCEPT: 1,
	WITHDRAW: 3,
	CONNECT: 1,
	REJECT: 3,
	REMOVE: 4,
	SENT: 2,
	All: 0
};

export const TABS = {
	ALL: "All",
	MY_NETWORK: "My Network",
	SENT: "Sent",
	RECEIVED: "Received",
	BUSINESS_CARDS: "Business Cards"
};

export const CONNECTION_STATUS = {
	CONNECTED: 1,
	PENDING: 2
}

export const toasterMessageNetwork = {
	REQUEST_ACCEPTED: "Request accepted successfully",
	REQUEST_REJECTED: "Request rejected successfully",
	REQUEST_WITHDRAW: "Request withdraw successful",
	CONNECTION_REMOVED: "Connection removed successfully"
};

export const defaultModuleUrls = {
	[moduleTypes.SPONSORS]: "/sponsors",
	[moduleTypes.SPEAKERS]: "/speakers",
	[moduleTypes.EXHIBITORS]: "/exhibitors",
	[moduleTypes.CHAT]: "/chats",
	[moduleTypes.ADDRESS]: "/address-page",
	[moduleTypes.NETWORK]: "/network",
	[moduleTypes.PARTNERS]: "/partners",
	[moduleTypes.AGENDA]: "/agenda",
	[moduleTypes.PEOPLE]: "/people",
	[moduleTypes.COMPANIES]: "/companies",
	[moduleTypes.SCAN]: "/scanner",
	[moduleTypes.MEETINGS]: "/meetings",
	[moduleTypes.AI_PHOTO_BOOTH]: "/photo-booth"
};
