import React, { useEffect, useMemo, useState } from "react";
import "./Meetings.css";
import SideNavbar from "../SideNavbar/SideNavbar";
import ModuleHeading from "../../CommomComponents/ModuleHeading/ModuleHeading";
import DateSelectorContainer from "../Agenda/Datecard";
import "../../CommomComponents/SearchAndFilter/SearchAndFilter.css";
import CustomModal from "../../CommomComponents/CustomModal/CustomModal";
import MeetingDetails from "./MeetingDetails/MeetingDetails";
import MeetingsCard from "./MeetingsCard/MeetingsCard";
import CustomDrawer from "../../CommomComponents/CustomDrawer/CustomDrawer";
import {
	getModuleDescriptionByName,
	getModuleNameByType,
	useScreenWidth
} from "../../reusable/common";
import CreateMeeting from "./CreateMeeting/CreateMeeting";
import { useEvent } from "../../contexts/EventContext";
import { useNavigate } from "react-router-dom";
import DeleteMeeting from "./DeleteMeeting/DeleteMeeting";
import { deleteMeeting, getListOfMeetings } from "../../crud/meetings.crud";
import CustomLoader from "../../CommomComponents/CustomLoader/CustomLoader";
import { Icon } from "@iconify/react/dist/iconify.js";
import Footer from "../Footer/Footer";
import ModuleDescription from "../../CommomComponents/ModuleDescription/ModuleDescription";
import { useModule } from "../../contexts/ModuleContext";
import moment from "moment";

const Meetings: React.FC = () => {
	const { eventDetails }: any = useEvent();
	const attendeeId = localStorage.getItem("attendeeId");
	const navigate = useNavigate();
	const { modulesDetails } = useModule();
	const [activeTab, setActiveTab] = useState<any>("upcoming");
	const [activeRequestsTab, setActiveRequestsTab] = useState<any>("requests");
	const [searchTerm, setSearchTerm] = useState<string>("");
	const [openMeetingDetailsModal, setOpenMeetingDetailsModal] = useState(false);
	const [openMeetingsDetailsDrawer, setOpenMeetingDetailsDrawer] = useState(false);
	const [openMeetingDeleteModal, setOpenMeetingDeleteModal] = useState(false);
	const [openMeetingsDeleteDrawer, setOpenMeetingDeleteDrawer] = useState(false);

	const [dates, setDates] = useState([]);
	const [selectedDate, setSelectedDate] = useState<any>(null);
	const [loading, setLoading] = useState(true);
	const [containerClass, setContainerClass] = useState("");
	const [deleteMeetingLoading, setDeleteMeetingLoading] = useState(false);
	const [meetingList, setMeetingList] = useState([]);
	const [meetingDetails, setMeetingDetails] = useState<any>(null);
	const [editMeetingDetails, setEditMeetingDetails] = useState<any>(null);
	const [isCreateMeetingPage, setIsCreateMeetingPage] = useState(false);

	const moduleHeading = getModuleNameByType(modulesDetails, 14);

	const statuses: any = {
		upcoming: 2,
		completed: 5,
		requests: 1,
		ignored: 4,
		rejected: 3
	};

	const statusesRequests: any = {
		requests: 1,
		ignored: 4,
		rejected: 3
	};

	const isScreenSmall = useScreenWidth();

	const modalActions = {
		Edit: () => {
			setEditMeetingDetails(() => {
				return { ...meetingDetails };
			});
			setMeetingDetails(null);
			setIsCreateMeetingPage(true);
			setOpenMeetingDetailsDrawer(false);
			setOpenMeetingDetailsModal(false);
			navigate("/meetings/edit-meeting");
		},
		Delete: () => {
			if (isScreenSmall) {
				setOpenMeetingDeleteDrawer(true);
			} else {
				setOpenMeetingDeleteModal(true);
			}
		}
	};

	const fetchMeetings = async (date: any) => {
		setLoading(true);
		try {
			const res = await getListOfMeetings(
				eventDetails?.event_id,
				activeTab !== "requests" && activeTab !== "ignored" && activeTab !== "rejected"
					? statuses[activeTab]
					: statusesRequests[activeRequestsTab],
				activeTab !== "requests" && activeTab !== "ignored" && activeTab !== "rejected"
					? date
					: ""
			);

			setMeetingList(() => res.data);
			setOpenMeetingDetailsDrawer(false);
			setOpenMeetingDetailsModal(false);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	const handleDeleteMeeting = async () => {
		setDeleteMeetingLoading(true);
		try {
			await deleteMeeting(eventDetails?.event_id, meetingDetails?.id);
			setOpenMeetingDeleteModal(false);
			setOpenMeetingDeleteDrawer(false);
			setMeetingDetails(null);
			setOpenMeetingDetailsDrawer(false);
			setOpenMeetingDetailsModal(false);
			fetchMeetings(selectedDate);
		} catch (error) {
			console.log("error", error);
		} finally {
			setDeleteMeetingLoading(false);
		}
	};

	const fetchDates = (event: any) => {
		const startDate = new Date(event.start_date);
		const endDate = new Date(event.end_date);
		const dateArray = [];
		for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
			const year = date.getFullYear().toString();
			const month = (date.getMonth() + 1).toString().padStart(2, "0");
			const day = date.getDate().toString().padStart(2, "0");
			const formattedDate = `${year}-${month}-${day}`;
			dateArray.push({
				originalDate: new Date(date),
				formattedDate: formattedDate
			});
		}

		return dateArray;
	};

	const memoizedDates: any = useMemo(() => {
		if (eventDetails?.start_date && eventDetails?.end_date) {
			return fetchDates(eventDetails);
		}
		return [];
	}, [eventDetails?.start_date, eventDetails?.end_date]);

	useEffect(() => {
		if (memoizedDates.length) {
			setDates(memoizedDates);
			const today = new Date();
			const todayYear = today.getFullYear().toString();
			const todayMonth = (today.getMonth() + 1).toString().padStart(2, "0");
			const todayDay = today.getDate().toString().padStart(2, "0");
			const todayFormatted = `${todayYear}-${todayMonth}-${todayDay}`;
			if (moment.utc(new Date()).format("YYYY-MM-DD") >= eventDetails?.start_date) {
				setSelectedDate(todayFormatted);
			} else {
				if (
					memoizedDates.length > 0 &&
					!memoizedDates.some((dateObj: any) => dateObj.formattedDate === selectedDate)
				) {
					const firstDate = memoizedDates[0].formattedDate;
					setSelectedDate(firstDate);
				}
			}
		}
	}, [memoizedDates]);

	useEffect(() => {
		if (selectedDate) {
			fetchMeetings(selectedDate);
		}
	}, [selectedDate, activeTab, activeRequestsTab]);

	useEffect(() => {
		if (location.pathname.includes("edit-meeting") && !meetingDetails) {
			navigate("/meetings");
		}
	}, []);

	useEffect(() => {
		if (
			!location.pathname.includes("edit-meeting") &&
			!location.pathname.includes("create-meeting")
		) {
			setContainerClass("meetings-container default");
		}
		if (
			location.pathname.includes("edit-meeting") ||
			location.pathname.includes("create-meeting")
		) {
			setContainerClass("meetings-container create");
		}
	}, [location.pathname]);

	return (
		<>
			<div className="h-100">
				<div className="d-flex h-100">
					<div className="hide-in-mobile">
						<SideNavbar />
					</div>
					<div className="h-100 w-100">
						<div className={containerClass}>
							<ModuleHeading
								moduleHeading={
									isCreateMeetingPage ||
									location.pathname.includes("create-meeting") ||
									location.pathname.includes("edit-meeting")
										? location.pathname.includes("edit-meeting")
											? "Edit Meeting"
											: location.pathname.includes("create-meeting")
												? "Create Meeting"
												: moduleHeading
										: moduleHeading
								}
								moduleDetails={{}}
								goBack={() => {
									if (
										location.pathname.includes("/meetings") &&
										!location.pathname.includes("create-meeting") &&
										!location.pathname.includes("edit-meeting")
									) {
										navigate("/");
									} else if (
										location.pathname.includes("create-meeting") ||
										location.pathname.includes("edit-meeting")
									) {
										setIsCreateMeetingPage(false);
										setEditMeetingDetails(null);
										navigate("/meetings");
									}
								}}
							/>
							{getModuleDescriptionByName(modulesDetails, moduleHeading) && (
								<ModuleDescription
									description={getModuleDescriptionByName(
										modulesDetails,
										moduleHeading
									)}
								/>
							)}

							{!location.pathname.includes("create-meeting") &&
							!location.pathname.includes("edit-meeting") ? (
								<>
									<nav className="meeting-tabs">
										<button
											className={`meeting-section-tab ${activeTab === "upcoming" ? "active" : ""}`}
											onClick={() => setActiveTab("upcoming")}
										>
											Upcoming
										</button>
										<button
											className={`meeting-section-tab ${activeTab === "completed" ? "active" : ""}`}
											onClick={() => setActiveTab("completed")}
										>
											Completed
										</button>
										<button
											className={`meeting-section-tab ${activeTab === "requests" ? "active" : ""}`}
											onClick={() => setActiveTab("requests")}
										>
											Requests
										</button>
									</nav>

									<div className="row meeting-date-searchbar-wrapper mx-0">
										<div className="col-12 col-md-6 d-flex meetings-tabs-requests-wrapper flex-column align-items-end">
											{activeTab === "requests" ? (
												<>
													<div className="d-flex justify-content-between w-100">
														<div
															role="button"
															className={`meeting-requests-type ${activeRequestsTab === "requests" ? "active-tab" : ""} d-flex justify-content-center flex-column align-items-center mb-3 mb-md-0 mb-lg-0`}
															onClick={() => {
																setActiveRequestsTab("requests");
															}}
														>
															Received
														</div>
														{/* <div className="ms-3 meeting-requests-type d-flex justify-content-center flex-column align-items-center">
														Sent
													</div> */}
														<div
															role="button"
															className={`ms-3 meeting-requests-type ${activeRequestsTab === "ignored" ? "active-tab" : ""} d-flex justify-content-center flex-column align-items-center mb-3 mb-md-0 mb-lg-0`}
															onClick={() => {
																setActiveRequestsTab("ignored");
															}}
														>
															Ignored
														</div>
														<div
															role="button"
															className={`ms-3 meeting-requests-type ${activeRequestsTab === "rejected" ? "active-tab" : ""} d-flex justify-content-center flex-column align-items-center mb-3 mb-md-0 mb-lg-0`}
															onClick={() => {
																setActiveRequestsTab("rejected");
															}}
														>
															Rejected
														</div>
													</div>
												</>
											) : (
												<DateSelectorContainer
													dates={dates}
													selectedDate={selectedDate}
													setSelectedDate={date => {
														setSelectedDate(date);
													}}
												/>
											)}
										</div>
										<div className="col-12 col-md-6 d-flex justify-content-end">
											<div className="position-relative w-100 d-flex justify-content-end">
												<div className="meeting-searchbar-input-wrapper w-100">
													{/* <img
													className="meeting-search-icon"
													src="/assets/search-icon.svg"
													alt=""
												/> */}
													<input
														type="text"
														placeholder="Search"
														value={searchTerm}
														onChange={e =>
															setSearchTerm(e.target.value)
														}
														className="meeting-searchbar-input"
													/>

													<Icon
														icon={
															searchTerm
																? `oui:cross`
																: `material-symbols-light:search-rounded`
														}
														className="searchbar-search-icon"
														onClick={() => {
															setSearchTerm("");
														}}
														style={{ cursor: "pointer" }}
													/>
												</div>
											</div>
										</div>
									</div>

									<div className="px-3 meetings-listing-wrapper">
										{loading ? (
											<CustomLoader />
										) : meetingList.length > 0 ? (
											meetingList
												?.filter((data: any) => {
													return data?.title
														?.toLowerCase()
														.includes(searchTerm.toLowerCase());
												})
												.map((data: any, index) => (
													<MeetingsCard
														data={data}
														onClickMeetingCard={data => {
															setMeetingDetails(data);
															if (isScreenSmall) {
																setOpenMeetingDetailsDrawer(true);
															} else {
																setOpenMeetingDetailsModal(true);
															}
														}}
														position={index}
														isRequestsTab={
															(activeTab === "requests" &&
																(activeRequestsTab === "requests" ||
																	activeRequestsTab ===
																		"ignored" ||
																	activeRequestsTab ===
																		"rejected")) ||
															data?.attendees?.filter(
																(each: any) =>
																	each?.attendee_id ===
																		attendeeId &&
																	each?.status === 1
															)?.length > 0
														}
														activeRequestsTab={activeRequestsTab}
														fetchMeetings={fetchMeetings}
														activeTab={activeTab}
														selectedDate={selectedDate}
													/>
												))
										) : (
											<div className="d-flex justify-content-center align-items-center flex-column meetings-no-meetings add-people-loader">
												No meetings found
											</div>
										)}
									</div>

									<div
										role="button"
										className="create-meeting-button d-flex justify-content-center flex-column align-items-center"
										onClick={() => {
											setIsCreateMeetingPage(true);
											navigate("/meetings/create-meeting");
										}}
									>
										<Icon
											icon={`material-symbols-light:add-2-rounded`}
											width={"24px"}
											height={"24px"}
										/>
									</div>
								</>
							) : (
								<>
									<CreateMeeting
										meetingDetails={editMeetingDetails}
										onAddEditMeeting={() => {
											fetchMeetings(selectedDate);
										}}
									/>
								</>
							)}
						</div>
						<div className="hide-in-desktop">
							<Footer />
						</div>
					</div>
				</div>
			</div>

			<div>
				<CustomModal
					openCustomModal={openMeetingDetailsModal}
					onClose={() => {
						setOpenMeetingDetailsModal(false);
						navigate("/meetings");
					}}
					component={
						<MeetingDetails
							meetingData={meetingDetails}
							isRequestsTab={
								(activeTab === "requests" &&
									(activeRequestsTab === "requests" ||
										activeRequestsTab === "ignored" ||
										activeRequestsTab === "rejected")) ||
								meetingDetails?.attendees?.filter(
									(each: any) =>
										each?.attendee_id === attendeeId && each?.status === 1
								)?.length > 0
							}
							activeRequestsTab={activeRequestsTab}
							fetchMeetings={fetchMeetings}
							activeTab={activeTab}
							selectedDate={selectedDate}
						/>
					}
					modalActions={
						meetingDetails?.created_by === attendeeId && activeTab === "upcoming"
							? modalActions
							: {}
					}
					showModalAction={true}
				/>
				<CustomModal
					openCustomModal={openMeetingDeleteModal}
					onClose={() => {
						setOpenMeetingDeleteModal(false);
						setOpenMeetingDeleteDrawer(false);
					}}
					maxWidth={"388px"}
					component={
						<DeleteMeeting
							loading={deleteMeetingLoading}
							onCancel={() => {
								setOpenMeetingDeleteModal(false);
								setOpenMeetingDeleteDrawer(false);
							}}
							onDelete={() => {
								handleDeleteMeeting();
							}}
						/>
					}
				/>
			</div>
			<div>
				<CustomDrawer
					open={openMeetingsDetailsDrawer}
					onOpen={() => {
						setOpenMeetingDetailsDrawer(true);
					}}
					onClose={() => {
						setOpenMeetingDetailsDrawer(false);
						navigate("/meetings");
					}}
					minHeight="47vh"
					component={
						<MeetingDetails
							meetingData={meetingDetails}
							isRequestsTab={
								(activeTab === "requests" &&
									(activeRequestsTab === "requests" ||
										activeRequestsTab === "ignored" ||
										activeRequestsTab === "rejected")) ||
								meetingDetails?.attendees?.filter(
									(each: any) =>
										each?.attendee_id === attendeeId && each?.status === 1
								).length > 0
							}
							activeRequestsTab={activeRequestsTab}
							fetchMeetings={fetchMeetings}
							activeTab={activeTab}
							selectedDate={selectedDate}
						/>
					}
					modalActions={
						meetingDetails?.created_by === attendeeId && activeTab === "upcoming"
							? modalActions
							: {}
					}
					showModalAction={true}
				/>
				<CustomDrawer
					open={openMeetingsDeleteDrawer}
					onOpen={() => {
						setOpenMeetingDetailsDrawer(true);
					}}
					onClose={() => {
						setOpenMeetingDeleteModal(false);
						setOpenMeetingDeleteDrawer(false);
					}}
					component={
						<DeleteMeeting
							loading={deleteMeetingLoading}
							onCancel={() => {
								setOpenMeetingDeleteModal(false);
								setOpenMeetingDeleteDrawer(false);
							}}
							onDelete={() => {
								handleDeleteMeeting();
							}}
						/>
					}
				/>
			</div>
		</>
	);
};

export default Meetings;
