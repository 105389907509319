import axios from "./axios";
import { apiUrl } from "../config/crud";

const API_URL = apiUrl;
const USER = "user";
const REGISTRATIONS = "registrations";

// Get list of filters
export const getAttendeeDetails = (eventId: string) => {
	return axios.get(`${API_URL}/${USER}/${eventId}/${REGISTRATIONS}`);
};
