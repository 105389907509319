import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../Footer/Footer";
import { getModule } from "../../crud/module.crud";
import SideNavbar from "../SideNavbar/SideNavbar";
import { useEvent } from "../../contexts/EventContext";
import ModuleHeading from "../../CommomComponents/ModuleHeading/ModuleHeading";
import { useModule } from "../../contexts/ModuleContext";
import { spinnerStyle } from "../../config/app";
import { ClipLoader } from "react-spinners";
import ModuleItemCard from "./ModuleItemCard";
import { ModuleItem } from "./CustomModulesTypes";
import ModuleDescription from "../../CommomComponents/ModuleDescription/ModuleDescription";
import { getModuleDescriptionByName, getModuleNameByType } from "../../reusable/common";

const CustomModule: React.FC = () => {
	const { eventDetails } = useEvent();
	const navigate = useNavigate();
	const { moduleId } = useParams();
	const { modulesNames, modulesDetails } = useModule();

	const [searchTerm, setSearchTerm] = useState("");
	const [moduleData, setModuleData] = useState<ModuleItem[]>([]);
	const [loading, setLoading] = useState(false);
	const [filteredModuleData, setFilteredModuleData] = useState<ModuleItem[]>([]);

	const moduleHeading =getModuleNameByType(modulesDetails,9);


	useEffect(() => {
		fetchModuleData();
	}, []);

	const fetchModuleData = async () => {
		setLoading(true);
		try {
			if (moduleId) {
				const resp = await getModule(eventDetails?.event_id, moduleId);
				setModuleData(resp.data);
				setFilteredModuleData(resp.data);
			}
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	};

	const handleModuleItemSelect = (moduleItem: ModuleItem) => {
		if (moduleId) navigate(`/m/${moduleId}/i/${moduleItem.item_id}`, { state: moduleItem });
	};

	useEffect(() => {
		setFilteredModuleData([
			...moduleData.filter((moduleItem: ModuleItem) => {
				const term = searchTerm.toLowerCase();

				// Check name and item_url
				const nameMatches = moduleItem.name.toLowerCase().includes(term);
				const urlMatches = moduleItem.item_url.toLowerCase().includes(term);

				// Check tags
				const tagsMatch = moduleItem.tags.some((tag: any) =>
					tag.name.toLowerCase().includes(term)
				);

				// Return true if any match is found
				return nameMatches || urlMatches || tagsMatch;
			})
		]);
	}, [searchTerm]);
	const customModuleName = modulesNames?.[moduleId || ""]
	return (
		<>
			<div className="mobile-view">
				<div className="search-page-container">
					<div>
						<div className="en">
							<ModuleHeading moduleHeading={modulesNames?.[moduleId || ""]} />
						</div>
						<ModuleDescription description={getModuleDescriptionByName(modulesDetails, customModuleName)} />

						{loading ? (
							<div style={spinnerStyle}>
								<ClipLoader
									loading={loading}
									size={50}
									aria-label="Loading Spinner"
								/>
							</div>
						) : (
							<div className="module-items">
								{filteredModuleData.map((moduleItem: ModuleItem) => (
									<div key={moduleItem.item_id}>
										<ModuleItemCard
											data={moduleItem}
											onSelect={handleModuleItemSelect}
										/>
									</div>
								))}
							</div>
						)}
						<Footer />
					</div>
				</div>
			</div>

			<div className="desktop-view">
				<div className="desktop-view-side">
					<div className="desktop-left-slider">
						<SideNavbar />
					</div>
					<div className="home-screens desktop-left-container font">
						<div className="">
							<ModuleHeading moduleHeading={modulesNames?.[moduleId || ""]} />
							<ModuleDescription description={getModuleDescriptionByName(modulesDetails, customModuleName)} />
						</div>
						{loading ? (
							<div className="loader-container" style={spinnerStyle}>
								<ClipLoader
									color="#4A90E2"
									loading={loading}
									size={50}
									aria-label="Loading Spinner"
								/>
							</div>
						) : (
							<div className="module-items">
								{filteredModuleData.map((moduleItem: ModuleItem) => (
									<div key={moduleItem.item_id}>
										<ModuleItemCard
											data={moduleItem}
											onSelect={handleModuleItemSelect}
										/>
									</div>
								))}
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default CustomModule;
