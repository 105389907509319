import React, { useEffect, useState } from "react";

interface DocumentViewerProps {
	url: string;
	navigateToModuleItems: () => void;
}

const DocumentViewer: React.FC<DocumentViewerProps> = ({ url, navigateToModuleItems }) => {
	const [isDocument, setIsDocument] = useState(true);
	const [isPdf, setIsPdf] = useState(false);
	const [hasOpenedInNewTab, setHasOpenedInNewTab] = useState(false); // Flag to track if the URL has been opened

	useEffect(() => {
		// Check once if the URL is a PDF, an image, or something else
		if (!hasOpenedInNewTab) {
			if (url.endsWith(".pdf")) {
				setIsPdf(true);
			} else if (url?.match(/\.(jpg|jpeg|png|gif)$/i)) {
				setIsPdf(false);
			} else {
				setIsDocument(false);
			}
			setHasOpenedInNewTab(true); // Mark as checked
			if (!isDocument && navigateToModuleItems) navigateToModuleItems();
		}
	}, [url, hasOpenedInNewTab, navigateToModuleItems, isDocument]);

	const handleIframeError = () => {
		// If the document can't be loaded, open the URL in a new tab (only once)
		if (!hasOpenedInNewTab) {
			setHasOpenedInNewTab(true); // Mark as opened
			if (navigateToModuleItems) navigateToModuleItems();
			//window.open(url, "_blank");
		}
	};

	return (
		<>
			{isDocument ? (
				isPdf ? (
					<div
						style={{
							position: "relative",
							width: "100%",
							height: "0",
							paddingBottom: "75%"
						}}
					>
						<iframe
							src={url}
							width="100%"
							height="100%"
							onError={handleIframeError} // Handles inaccessible PDF case
							title="PDF Viewer"
							style={{ position: "absolute", top: 0, left: 0 }}
						/>
					</div>
				) : (
					<img
						src={url}
						alt="Document Preview"
						style={{ width: "100%", height: "auto" }}
						onError={handleIframeError} // Handles inaccessible image case
					/>
				)
			) : null}
		</>
	);
};

export default DocumentViewer;
