import React from "react";
import "./DeleteMeeting.css";
import CustomButton from "../../../CommomComponents/CustomButton/CustomButton";

interface DeleteMeetingProps {
	onCancel: () => void;
	onDelete: () => void;
	loading: boolean
}

const DeleteMeeting: React.FC<DeleteMeetingProps> = ({ onCancel, onDelete, loading }) => {
	return (
		<>
			<div className="p-4">
				<div className="meeting-delete-heading-text">Delete Meeting</div>
				<div className="mt-4 meeting-delete-subHeading-text">Are you sure that you want to delete this meeting?</div>
				<div className="d-flex mt-5">
					<CustomButton
						isBgFill={false}
						paddingLeftRight="55px"
						minWidth="160px"
						onClick={() => {
							onCancel();
						}}
					>
						Cancel
					</CustomButton>
					<CustomButton
						disabled={loading}
						btnType="danger"
						isBgFill={true}
						paddingLeftRight="55px"
						minWidth="160px"
						onClick={() => {
							onDelete();
						}}
						className="ms-3"
					>
						Yes
					</CustomButton>
				</div>
			</div>
		</>
	);
};

export default DeleteMeeting;
