import {
	onSendMessage,
	onPushNotificationPerTicket,
	onPushNotificationPerEvent,
	onPushNotificationOrganizer,
	onPushNotificationPublic
} from "../../crud/subscriptions.crud";
import { showNotification } from "../notifications";
import { SubscribeToServicesParams } from "./subscriptions.types";

let activeNotifications: Set<string> = new Set();

export const onSendMessageSubscription = (callback: any) => {
	return {
		name: "onSendMessage",
		subscriptionQuery: onSendMessage,
		subscriptionVariables: {
			eventId: localStorage.getItem("eventId") ?? "",
			receiverId: localStorage.getItem("attendeeId") ?? ""
		},
		onSubscribe: (notification: any) => {
			console.log("Received message for onSendMessage subscription:", notification);
			const data = notification?.value?.data?.onSendMessage;
			showNotification({
				title: `New Message from ${data?.senderName}`,
				body: data.message,
				data: {
					notificationId: data?.notificationId
				}
			});
			if (callback) callback(data);
		},
		onError: (err: any) => {
			console.log("Error with onSendMessage subscription:", err);
		},
		onComplete: () => {
			console.log("onSendMessage subscription completed!");
		}
	};
};

export const pushNotificationSubscriptionPerTicket = (
	registration: any,
	callback: any
): SubscribeToServicesParams | undefined => {
	if (registration) {
		return {
			name: "onPushNotificationPerTicket",
			subscriptionQuery: onPushNotificationPerTicket,
			subscriptionVariables: {
				eventId: localStorage.getItem("eventId") ?? "",
				ticketId: registration?.ticket_id || "",
				isOrganiser: false
			},
			onSubscribe: (notification: any) => {
				console.log(
					"Received message for onPushNotificationPerTicket subscription:",
					notification
				);
				const data = notification?.value?.data?.onPushNotification;
				if (activeNotifications.has(data?.notificationId)) return;
				activeNotifications.add(data?.notificationId);
				showNotification({
					title: data?.title || "New Notification!",
					body: data?.message,
					data: {
						notificationId: data?.notificationId
					}
				});
				if (callback) callback(data);
			},
			onError: (err: any) => {
				console.log("Error with onPushNotificationPerTicket subscription:", err);
			},
			onComplete: () => {
				console.log("onPushNotificationPerTicket subscription completed!");
			}
		};
	}
};

export const pushNotificationSubscriptionPerEvent = (callback: any): SubscribeToServicesParams => {
	return {
		name: "onPushNotificationOrganizerPerEvent",
		subscriptionQuery: onPushNotificationPerEvent,
		subscriptionVariables: {
			eventId: localStorage.getItem("eventId") ?? "",
			isOrganiser: false
		},
		onSubscribe: (notification: any) => {
			console.log(
				"Received message for onPushNotificationOrganizerPerEvent subscription:",
				notification
			);
			const data = notification?.value?.data?.onPushNotification;
			if (activeNotifications.has(data?.notificationId)) return;
			activeNotifications.add(data?.notificationId);
			showNotification({
				title: data?.title || "New Notification!",
				body: data?.message,
				data: {
					notificationId: data?.notificationId
				}
			});
			if (callback) callback(data);
		},
		onError: (err: any) => {
			console.log("Error with onPushNotificationOrganizerPerEvent subscription:", err);
		},
		onComplete: () => {
			console.log("onPushNotificationOrganizerPerEvent subscription completed!");
		}
	};
};

export const pushNotificationSubscriptionOrganizer = (callback: any): SubscribeToServicesParams => {
	return {
		name: "onPushNotificationOrganizer",
		subscriptionQuery: onPushNotificationOrganizer,
		subscriptionVariables: {
			eventId: localStorage.getItem("eventId") ?? "",
			isOrganiser: true
		},
		onSubscribe: (notification: any) => {
			console.log(
				"Received message for onPushNotificationOrganizer subscription:",
				notification
			);
			const data = notification?.value?.data?.onPushNotification;
			if (activeNotifications.has(data?.notificationId)) return;
			activeNotifications.add(data?.notificationId);
			showNotification({
				title: data?.title || "New Notification!",
				body: data?.message,
				data: {
					notificationId: data?.notificationId
				}
			});
			if (callback) callback(data);
		},
		onError: (err: any) => {
			console.log("Error with onPushNotificationOrganizer subscription:", err);
		},
		onComplete: () => {
			console.log("onPushNotificationOrganizer subscription completed!");
		}
	};
};

export const pushNotificationSubscriptionPublic = (callback: any): SubscribeToServicesParams => {
	return {
		name: "pushNotificationSubscriptionPublic",
		subscriptionQuery: onPushNotificationPublic,
		subscriptionVariables: {
			eventId: localStorage.getItem("eventId") ?? ""
		},
		onSubscribe: (notification: any) => {
			console.log(
				"Received message for onPushNotificationPublic subscription:",
				notification
			);
			const data = notification?.value?.data?.onPushNotificationPublic;
			if (activeNotifications.has(data?.notificationId)) return;
			activeNotifications.add(data?.notificationId);
			showNotification({
				title: data?.title || "New Notification!",
				body: data?.message,
				data: {
					notificationId: data?.notificationId
				}
			});
			if (callback) callback(data);
		},
		onError: (err: any) => {
			console.log("Error with pushNotificationSubscriptionPublic subscription:", err);
		},
		onComplete: () => {
			console.log("pushNotificationSubscriptionPublic subscription completed!");
		},
		isPublic: true
	};
};
