import React from "react";
import "./SpeakerDetailsComp.css";
import EventAgenda from "../../../CommomComponents/SessionDetailssDesktop";

interface SpeakerDetailsProps {
	speakerDetails?: any;
	setModalOpenDesktop?: (val: boolean) => void;
	setModalData?: (val: object) => void;
	speakers?: object[];
	setIsSessionDrawerOpen?: (val: boolean) => void;
	setSpeakerDetails?: (val: any) => void;
	eventDetails?: any;
	isSessionShow?: boolean;
}

const SpeakerDetailsComp: React.FC<SpeakerDetailsProps> = ({
	speakerDetails,
	setModalOpenDesktop,
	setModalData,
	speakers,
	setIsSessionDrawerOpen,
	eventDetails,
	isSessionShow = true,
	setSpeakerDetails
}) => {
	const loading = false;

	function removeTags(str: string) {
		return str?.replace(/<\/?[^>]+(>|$)/g, "");
	}

	return (
		<>
			<div className="row mx-0 speaker-details-root text-start">
				<div className="col-12 col-lg-4">
					<img
						className="speaker-details-speaker-image"
						src={speakerDetails?.image_url}
						alt="Speaker Image"
					/>
					<div className="mt-4 spaaker-details-speaker-name">{speakerDetails?.name}</div>
					<div className="mt-2 spaaker-details-organization">{`${speakerDetails?.designation ? speakerDetails?.designation + "," : ""} ${speakerDetails?.organisation || ""}`}</div>

					{speakerDetails?.location && (
						<div className="mt-2 d-flex align-items-center">
							<img src="/assets/speaker-location.svg" alt="location" />
							<div className="ms-2 speaker-details-location">
								{speakerDetails?.location || ""}
							</div>
						</div>
					)}

					{/* <div className="d-flex justify-content-between speaker-details-message-buttton-wrapper">
					<button className="speaker-details-message-buttton">Message</button>
					<div className="speaker-details-message-option d-flex flex-column justify-content-center align-items-center">
						<img src="assets/option-icon.svg" alt="option-icon" />
					</div>
				</div> */}
					<div className="speaker-details-about mt-4">
						{removeTags(speakerDetails?.about)}
					</div>

					{(speakerDetails?.linkedin_url || speakerDetails?.twitter_url) && (
						<div className="mt-4 pt-2">
							<div className="speaker-details-social">Social media</div>
							<div className="mt-3 speaker-details-social-share d-flex">
								{speakerDetails?.linkedin_url && (
									<div
										className="social-share d-flex align-items-center justify-content-center"
										onClick={() => {
											window.open(
												speakerDetails?.linkedin_url,
												"_blank",
												"noopener, noreferrer"
											);
										}}
									>
										<img src="/assets/speaker-linkedin.svg" alt="LinkedIn" />
									</div>
								)}

								{speakerDetails?.twitter_url && (
									<div
										className="ms-2 social-share d-flex align-items-center justify-content-center"
										onClick={() => {
											window.open(
												speakerDetails?.twitter_url,
												"_blank",
												"noopener, noreferrer"
											);
										}}
									>
										<img src="/assets/speaker-x.svg" alt="X" />
									</div>
								)}

								{/* <div className="ms-2 social-share d-flex align-items-center justify-content-center">
								<img src="/assets/speaker-whatsapp.svg" alt="Whatsapp" />
							</div> */}
							</div>
						</div>
					)}

					{speakerDetails?.tags?.length > 0 && (
						<div className="mt-3 pt-3 speaker-details-tags-wrapper">
							<div className="speaker-details-tags-heading">Tags</div>
							<div className="mt-2 speaker-details-tag-list-wrapper">
								{speakerDetails?.tags
									?.map((data: any) => data?.name)
									.map((each: any) => (
										<div
											className={`me-3 mt-1 speaker-details-tags-text-wrapper d-flex align-items-center justify-content-center`}
										>
											<div className="speaker-details-tags-text">{`#${each}`}</div>
										</div>
									))}
							</div>
						</div>
					)}
				</div>

				<div className="col-12 col-lg-8 mt-3 mt-lg-0 pe-0">
					{isSessionShow && (
						<div>
							<div className="mt-4 mb-4 speaker-details-sessions-heading">
								Sessions
							</div>
							<div>
								{speakerDetails?.sessions?.length > 0 ? (
									speakerDetails?.sessions?.map((each: any, index: number) => (
										<>
											<EventAgenda
												key={index}
												data={each}
												setIsSessionDrawerOpen={setIsSessionDrawerOpen}
												setModalOpenDesktop={setModalOpenDesktop}
												setModalData={setModalData}
												loading={loading}
												speakers={speakers}
												eventsData={eventDetails}
												sessionDesc={true}
												setSpeakerDetails={setSpeakerDetails}
											/>
										</>
									))
								) : (
									<div className="d-flex justify-content-center align-items-center flex-column speaker-loader">
										No Sessions found
									</div>
								)}
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default SpeakerDetailsComp;
