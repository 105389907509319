import React, { createContext, useContext, useState, useMemo } from "react";
import { subscribeToServices } from "../utils/subscriptions/subscriptions";
import { onSendMessageSubscription } from "../utils/subscriptions/subscriptions.list"; // Assuming you have a message subscription

interface MessageContextType {
	messages: any[];
	addMessage: (message: any) => void;
	subscribeToMessages: (attendeeDetails: any) => void;
	setMessages: (messages: any) => void;
}

const MessageContext = createContext<MessageContextType | undefined>(undefined);

export const MessageProvider = ({ children }: { children: React.ReactNode }) => {
	const [messages, setMessages] = useState<any[]>([]);

	const subscribeToMessages = () => {
		const subscription = onSendMessageSubscription(onNewMessage);
		if (subscription) subscribeToServices(subscription);
	};

	const onNewMessage = (data: any) => {
		if (messages.some(message => message.message_id === data.message_id)) return;
		addMessage(data);
	};

	const addMessage = (message: any) => {
		setMessages(prev => [...prev, message]);
	};

	const value = useMemo(
		() => ({
			messages,
			addMessage,
			setMessages,
			subscribeToMessages
		}),
		[messages, addMessage, subscribeToMessages]
	);

	return <MessageContext.Provider value={value}>{children}</MessageContext.Provider>;
};

export const useMessageContext = () => {
	const context = useContext(MessageContext);
	if (!context) {
		throw new Error("useMessageContext must be used within a MessageProvider");
	}
	return context;
};
