// import React, { useState } from 'react';
// import './FloorPlanDetail.css'
// import { useNavigate, useParams } from "react-router-dom";


// const FloorPlanDetail: React.FC = () => {
//   const [zoom, setZoom] = useState(1);
//   const navigate = useNavigate();

//   // Function to handle zoom in
//   const zoomIn = () => {
//     setZoom(prevZoom => prevZoom * 1.2);
//   };

//   // Function to handle zoom out
//   const zoomOut = () => {
//     setZoom(prevZoom => Math.max(prevZoom / 1.2, 1)); 
//   };
//   const goBack = () => {
//     navigate(-1);
//   };




//     return (
//       <div className='floorplan_detail_container'>
//         <div className='en enp'>
//           <div><img className='left_uparrow search_left_arrow' src="/assets/uparrow.svg" alt="Go Back" onClick={goBack} /></div>
//           <div><span className='un'>ELYSIAN - 2024</span></div>
//         </div>
//         <img className='floor_btn' src='assets/Actions.png' alt="Actions" />
//         <div className="floor-plan-detail">
//           <img 
//             src="assets/wheels.png" 
//             alt="Floor Plan" 
//             style={{ transform: `scale(${zoom})`, transition: 'transform 0.3s ease' }} 
//           />
//         </div>
//         <div className='zoom-controls'>
//           <button onClick={zoomIn} className='zoom-button'>+</button>
//           <button onClick={zoomOut} className='zoom-button'>-</button>
//         </div>
//       </div>
//     );
//   };


// export default FloorPlanDetail;

import React, { useState } from 'react';
import './FloorPlanDetail.css'

interface FloorPlanData {
  floor_plan_id: string;
  floor_plan_order: number;
  name: string;
  file_url: string;
}

interface FloorPlanDetailProps {
  floorPlan: FloorPlanData;
}

const FloorPlanDetail: React.FC<FloorPlanDetailProps> = ({ floorPlan }) => {
  const [zoom, setZoom] = useState(1);

  // Function to handle zoom in
  const zoomIn = () => {
    setZoom(prevZoom => prevZoom * 1.2);
  };

  // Function to handle zoom out
  const zoomOut = () => {
    setZoom(prevZoom => Math.max(prevZoom / 1.2, 1));
  };

  return (
    <>
      <div className='phone-view'>
        <div className='floorplan_detail_container'>
          <div className='en enp'>
          </div>
          <div className="floor-plan-detail">
            <div className="image-container">
              <img
                src={floorPlan.file_url}
                alt={floorPlan.name}
                style={{ transform: `scale(${zoom})`, transition: 'transform 0.3s ease' }}
              />
            </div>
          </div>
          <div className='zoom-controls'>
            <button onClick={zoomIn} className='zoom-button'>+</button>
            <button onClick={zoomOut} className='zoom-button'>-</button>
          </div>
        </div>
      </div>


      {/* desktop container */}
      <div className='desktop-view'>
        <div className='floorplan_detail_container'>
          {/* <img className='floor_btn' src='assets/street.png' alt="Actions" /> */}
          <div className='en enp'>
            {/* <div><span className='un'>{floorPlan.name}</span></div> */}
          </div>
          <div className="floor-plan-detail">
            <div className="image-container">
              <img
                src={floorPlan.file_url}
                alt={floorPlan.name}
                style={{ transform: `scale(${zoom})`, transition: 'transform 0.3s ease' }}
              />
            </div>
          </div>
          <div className='zoom-controls'>
            <button onClick={zoomIn} className='zoom-button'>+</button>
            <button onClick={zoomOut} className='zoom-button'>-</button>
          </div>
        </div>
      </div>

    </>
  );
};

export default FloorPlanDetail;