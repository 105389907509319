import React from 'react';
import './NotificationPage.css';
import { useNavigate, useParams } from "react-router-dom";

interface Notification {
  id: string;
  type: 'connection' | 'business-card' | 'event' | 'reminder';
  title: string;
  time: string;
  image: string;
  action?: 'Accept' | 'Decline' | 'View' | 'Join';
}

const notifications: Notification[] = [
  {
    id: '1',
    type: 'connection',
    title: 'Deepak',
    time: '2hr',
    image: 'assets/lisa.png',
    action: 'Accept',
  },
  {
    id: '2',
    type: 'business-card',
    title: 'Anoyki seo',
    time: '2hr',
    image: 'assets/lisa.png',
    action: 'View',
  },
  {
    id: '3',
    type: 'event',
    title: 'ELYSIAN - 2024',
    time: '21hr',
    image: 'assets/lisa.png',
    action: 'View',
  },
  {
    id: '4',
    type: 'reminder',
    title: 'Gentle reminder',
    time: '24hr',
    image: 'assets/lisa.png',
    action: 'View',
  },
  {
    id: '5',
    type: 'event',
    title: 'KonfHub - 2024',
    time: '24hr',
    image: 'assets/lisa.png',
    action: 'View',
  },
  {
    id: '6',
    type: 'reminder',
    title: 'Gentle reminder',
    time: '1d',
    image: 'assets/lisa.png',
    action: 'View',
  },
  {
    id: '7',
    type: 'event',
    title: 'KonfHub - 2024',
    time: '3d',
    image: 'assets/lisa.png',
    action: 'Join',
  },
];





const Notifications: React.FC = () => {
  const navigate = useNavigate();

const goBack = () => {
  navigate(-1);
};
  return (
    <div className="notifications-container_page">
      <header className="notifications-header_page">
        <div className='notifications-head'>
        <div><img className='left_uparrow search_left_arrow' src="/assets/uparrow.svg" alt="Go Back" onClick={goBack} /> </div>
        <h1 className='not'>Notifications</h1>
        </div>
        <button className="mark-read-button_page">Mark all as read</button> 
      </header>
      <br />
      <br />
      <br />
      <div className="notifications-list_page">
        <section>
          <div className='yes'>
            <div>
              <h2 className='days'>Today</h2>
            </div>
            <div className='re'>
              <div>Mark as read</div>
            </div>
          </div>

          {notifications.slice(0, 2).map((notification) => (
            <NotificationItem key={notification.id} notification={notification} />
          ))}
        </section>
        <section>
          <div className='yes'>
            <div>
              <h2 className='days'>Yesterday</h2>
            </div>
            <div className='re'>
              <div>Mark as read</div>
            </div>
          </div>

          {notifications.slice(2, 5).map((notification) => (
            <NotificationItem key={notification.id} notification={notification} />
          ))}
        </section>
        <section>
          <div className='yes'>
            <div>
              <h2 className='days'>Last 4 days</h2>
            </div>
            <div className='re'>
              <div>Mark as read</div>
            </div>
          </div>

          {notifications.slice(5).map((notification) => (
            <NotificationItem key={notification.id} notification={notification} />
          ))}
        </section>
      </div>
    </div>
  );
};

const NotificationItem: React.FC<{ notification: Notification }> = ({ notification }) => {
  return (
    <div className="notification-item_page">
      <img src={notification.image} alt={notification.title} className="notification-image_page" />
      <div className="notification-content_page">
        <h3>{notification.title}</h3>
        {notification.type === 'connection' && <p>wants to connect</p>}
        {notification.title === 'Anoyki seo' && <p>exchanged the business card</p>}
        {notification.title === 'ELYSIAN - 2024' && <p>event will start in 5 days</p>}
        {notification.title === 'Gentle reminder' && <p>event starting tomorrow at 9.00am</p>}
        {notification.title === 'KonfHub - 2024' && <p>event denied</p>}
        <div className="action-buttons_page">
          {notification.type === 'connection' && (
            <>
              <button className="action-button_page accept">Accept</button>
              <button className="action-button_page decline">Decline</button>
            </>
          )}
          {notification.type !== 'connection' && (
            <button className={`action-button_page ${notification.action?.toLowerCase()} ${notification.action === 'View' ? 'view-dark-blue' : ''}`}>
              {notification.action}
            </button>
          )}
        </div>
      </div>
      <p className="notification-time_page">{notification.time}</p>
    </div>
  );
};

export default Notifications;
