import { DateTimePicker, RainbowThemeContainer } from "react-rainbow-components";
import { useEvent } from "../../contexts/EventContext";
import React from "react";
import "./CustomDateTimePicker.css";

interface CustomDateTimePickerProps {
	minDate?: any;
	maxDate?: any;
	value?: any;
	onHandleDateTimePicker?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	handleOnBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	id?: string;
}

const CustomDateTimePicker: React.FC<CustomDateTimePickerProps> = ({
	minDate,
	maxDate,
	value,
	onHandleDateTimePicker,
	handleOnBlur,
	id
}) => {
	const { eventDetails } = useEvent();
	const primaryColor = eventDetails?.primaryColor;

	// Define the theme object
	const theme = {
		rainbow: {
			palette: {
				brand: primaryColor?.trim() // fallback color if the variable isn't available
			}
		}
	};

	const containerStyles = {
		alignSelf: "center"
	};

	return (
		<RainbowThemeContainer
			theme={theme}
			className="rainbow-align-content_center rainbow-m-vertical_large rainbow-p-horizontal_small rainbow-m_auto custom-date-time-picker-root"
			style={containerStyles}
		>
			<DateTimePicker
				locale="en-IN"
				id={id}
				name={id}
				formatStyle="medium"
				{...(minDate && { minDate })}
				{...(maxDate && { maxDate })}
				{...(value && { value })}
				className=""
				hideLabel={true}
				okLabel="Save"
				{...(typeof handleOnBlur === "function" && {
					onBlur: (e: any) => {
						handleOnBlur(e);
					}
				})}
				{...(typeof onHandleDateTimePicker === "function" && {
					onChange: (e: any) => {
						onHandleDateTimePicker(e);
					}
				})}
			/>
		</RainbowThemeContainer>
	);
};

export default CustomDateTimePicker;
