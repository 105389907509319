import React from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import "./SearchAndFilter.css";

interface SearchBarProps {
	searchTerm: string;
	setSearchTerm: (term: string) => void;
	searchPlaceholder: string;
}

const SearchBar: React.FC<SearchBarProps> = ({ searchTerm, setSearchTerm, searchPlaceholder = "Search" }) => (
	<div className="searchbar-input-wrapper">
		<input
			type="text"
			placeholder={searchPlaceholder}
			value={searchTerm}
			onChange={(e) => setSearchTerm(e.target.value)}
			className="searchbar-input"
		/>
		<Icon
			icon={searchTerm ? `oui:cross` : `material-symbols-light:search-rounded`}
			className="searchbar-search-icon"
			onClick={() => {
				setSearchTerm("");
			}}
			style={{ cursor: "pointer" }}
		/>
	</div>
);

export default SearchBar;
