import React from "react";
import "./Modal.css"; // Import styles for the modal

const Modal = ({ closeModal }) => {
  const handleExplore = () => {
    closeModal();
    // Store in localStorage to prevent modal from showing again
    localStorage.setItem('modalShown', 'true');
  };

  return (
    <div className="modal-overlay">
      <div className="">
        <div className="announcement-overlay">
          <div className="announcement-card">
            <div className="announcement-header">
              <h2 className="announce">Announcements</h2>
              <button onClick={handleExplore}>×</button>
            </div>
            <div className="announcement-body">
              <span className="megaphone-icon">📢</span>
              <h3 className="announce_new">New feature alert</h3>
              <p>
                Check out the new features we have added to improve your
                experience.
              </p>
            </div>

            <button className="explore-button" onClick={handleExplore}>Explore Now</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
