import "./Chats.css";
import "../Meetings/AddPeople/AddPeople.css";
import { profileImage } from "../../reusable/images";

export const Attendee = ({ image, name, designation, organisation, id, onClick }: any) => {
	return (
		<div className="chat-wrapper" onClick={() => onClick(id, name)}>
			<div>
				{profileImage(image, name, 40, {
					width: "40px",
					height: "40px"
				})}
			</div>
			<div className="chat-info-wrapper">
				<div className="chat-name">{name}</div>
				{/* <div className="chat-latest-message">{designation}</div> */}
				<div className="meeting-people-designation-organization-wrapper">
					<span className="meeting-people-designation">
						{`${designation ? designation + ", " : ""}`}
					</span>
					<div className="meeting-people-organization">{`${organisation || ""}`}</div>
				</div>
			</div>
		</div>
	);
};
