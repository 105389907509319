import React, { createContext, useContext, useState, ReactNode } from "react";

// Define the context type
interface UserContextType {
	user: object | null | boolean | any; // User can be any object or null
	setUser: (user: object | null | boolean | any) => void; // Function to set user
	isRegistered: boolean;
	setIsRegistered: (isRegistered: boolean) => void;
	isOrganizer: boolean;
	setIsOrganizer: (isOrganizer: boolean) => void;
	loading: boolean;
	setLoading: (isOrganizer: boolean) => void;
	currentUserAttendeeId: string | null;
	setCurrentUserAttendeeId: (attendeeId: string) => void;
}

// Create the UserContext
const UserContext = createContext<UserContextType>({
	user: null,
	setUser: () => {},
	isRegistered: false,
	setIsRegistered: () => {},
	isOrganizer: false,
	setIsOrganizer: () => {},
	loading: true,
	setLoading: () => {},
	currentUserAttendeeId: null,
	setCurrentUserAttendeeId: () => {}
});

// Define props that include children for UserProvider
interface UserProviderProps {
	children: ReactNode; // Prop to accept children
}

const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
	const [user, setUser] = useState<object | null | boolean | any>(null); // State can be any object or null
	const [isRegistered, setIsRegistered] = useState<boolean>(false);
	const [isOrganizer, setIsOrganizer] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(true);
	const [currentUserAttendeeId, setCurrentUserAttendeeId] = useState<string | null>(null);

	return (
		<UserContext.Provider
			value={{
				user,
				setUser,
				isRegistered,
				setIsRegistered,
				isOrganizer,
				setIsOrganizer,
				loading,
				setLoading,
				currentUserAttendeeId,
				setCurrentUserAttendeeId
			}}
		>
			{children}
		</UserContext.Provider>
	);
};

// Hook to use the User context
const useUser = () => useContext(UserContext);

export { UserProvider, useUser };
