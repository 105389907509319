import { ReactNode } from "react";
import "./CustomButton.css";

interface CustomButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	btnType?: string;
	isBgFill?: boolean;
	children?: ReactNode;
	paddingLeftRight?: string;
	paddingTopBottom?: string;
	minWidth?: string;
	className?: string;
}

const CustomButton: React.FC<CustomButtonProps> = ({
	btnType = "primary",
	isBgFill = false,
	children,
	paddingLeftRight = "30px",
	paddingTopBottom = "10px",
	minWidth = "160px",
	className = "",
	...props
}) => {
	return (
		<>
			<button
				style={{ padding: `${paddingTopBottom} ${paddingLeftRight}`, minWidth }}
				{...props}
				className={`btnType ${btnType} ${isBgFill ? "isBgFill" : ""} ${className}`}
			>
				{children}
			</button>
		</>
	);
};

export default CustomButton;
