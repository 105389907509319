import React, { createContext, useContext, useState, ReactNode } from "react";

// Define the context type
interface EventContextType {
	eventDetails: object | null | boolean | any;
	eventLoading: boolean;
	eventError: boolean;
	setEventDetails: (user: object | null | boolean | any) => void;
	setEventLoading: (loading: boolean) => void;
	setEventError: (error: boolean) => void;
}

// Create the UserContext
const EventContext = createContext<EventContextType>({
	eventDetails: null,
	eventLoading: false,
	eventError: false,
	setEventDetails: () => {},
	setEventLoading: () => {},
	setEventError: () => {}
});

// Define props that include children for UserProvider
interface EventProviderProps {
	children: ReactNode; // Prop to accept children
}

const EventProvider: React.FC<EventProviderProps> = ({ children }) => {
	const [eventDetails, setEventDetails] = useState<object | null | boolean | any>(null); // State can be any object or null
	const [eventLoading, setEventLoading] = useState<boolean>(true);
	const [eventError, setEventError] = useState<boolean>(false);

	return (
		<EventContext.Provider
			value={{
				eventDetails,
				eventLoading,
				eventError,
				setEventDetails,
				setEventLoading,
				setEventError
			}}
		>
			{children}
		</EventContext.Provider>
	);
};

// Hook to use the User context
const useEvent = () => useContext(EventContext);

export { EventProvider, useEvent };
