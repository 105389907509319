import React from "react";
import IconButton from "@mui/material/IconButton";
import "../components/Agenda/Agenda.css";
import { ClipLoader } from "react-spinners";
import { timeZoneAbbreviations } from "../config/app";
import { Icon } from "@iconify/react/dist/iconify.js";
import { spinnerStyle } from "../config/app";
import moment from "moment";
import "moment-timezone";
import { formatTimestamp } from "../reusable/common";

interface Speaker {
	name: string;
	image_url: string;
}

interface Event {
	start_timestamp: string;
	end_timestamp: string;
	session_title: string;
	session_speakers: Speaker[];
}

interface EventCardProps {
	data: any;
	loading: boolean;
	handleEventClick?: (val: any) => void;
	setModalData: (event: Event) => void;
	eventsData: [];
}

const EventCard: React.FC<EventCardProps> = ({
	setModalData,
	data,
	handleEventClick,
	loading,
	eventsData
}) => {
	return (
		<>
			{loading ? (
				<div style={spinnerStyle}>
					<ClipLoader loading={loading} size={50} aria-label="Loading Spinner" />
				</div>
			) : (
				data && (
					<div className="event-container">
						<div
							onClick={() => {
								if (handleEventClick) handleEventClick(data);
								setModalData(data);
							}}
						>
							<div className="event-details-wrapper">
								<div className="event-details_agenda">
									<div className="event-time">
										{formatTimestamp(
											data?.start_timestamp,
											data?.end_timestamp,
											eventsData.time_zone
										)}
									</div>
									<div className="event-icons">
										<IconButton aria-label="fullscreen">
											<Icon
												icon={`material-symbols-light:zoom-out-map`}
												width={"25px"}
												height={"25px"}
												className="primary-color-icons"
											/>
										</IconButton>
									</div>
								</div>

								<div className="event-description_agenda">
									<p className="session_title">{data.session_title}</p>
								</div>
								{data?.session_location && (
									<div
										className="d-flex align-items-center mt-2"
										style={{ marginLeft: "5px" }}
									>
										<Icon
											icon={`fluent:location-16-regular`}
											width={"18px"}
											height={"18px"}
											weight={20}
											className="primary-color-icons"
										/>
										<p className="venue-address" style={{ marginLeft: "7px" }}>
											{data?.session_location}
										</p>
									</div>
								)}
								<div className="speaker-event">
									<div className="speaker-grid-mobile">
										{data?.session_speakers?.length > 0 && (
											<>
												{data.session_speakers.map((speaker, index) => {
													return (
														<div className="speaker-avatar" key={index}>
															<img
																src={speaker.image_url}
																alt={speaker.name}
																className="avatar-img"
																style={{ borderRadius: "50%" }}
															/>
															<p className="eventdraw-name"></p>
														</div>
													);
												})}
											</>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				)
			)}
		</>
	);
};

export default EventCard;
