import React from 'react';
// import './QuizCard.css';

const QuizCard = () => {
  return (
    <div className="quiz-card">
      <div className="quiz-info">
      <div className="quiz-image_quiz" style={{backgroundImage: `url('assets/MaskGroup.png')`}}>Test Quiz</div>

        {/* <img src='assets/MaskGroup.png'/> */}
        <div className="quizzzzz_con">
          <div className="quiz-timer">🕒 30:00 mins</div>
          <div className="quiz-title">Quiz 1 - Machine <br></br>Learning</div>
          <div className="quiz-date-time">
            <div>📅 20 June, 2024</div>
            <div>⏰ 10:00 AM</div>
          </div>
          <div className="quiz-tags">
            <span className="quiz_tag">#quiz</span>
            <span className="quiz_tag">#machinelearning</span>
           
          </div>
          <div className="quiz-tags">
            
            <span className="quiz_tag">#machinelearning</span>
            <span className="quiz_tag">#quiz</span>
          
          </div>
        </div>


        <div>


        </div>
      </div>





     
    </div>
  );
};

export default QuizCard;
