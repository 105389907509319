import React, { useState } from "react";
import {
    Box,
    List,
    ListItemButton,
    ListItemText,
    Checkbox,
    Button,
    Typography,
    FormControlLabel,
    SwipeableDrawer
} from "@mui/material";
import "./NetworkFilter.css";
import { ClipLoader } from "react-spinners";
import { Icon } from "@iconify/react/dist/iconify.js";

interface FilterDrawerProps {
    isOpen: boolean;
    onClose: () => void;
    onOpen: () => void;
    selectedFilter: string;
    setFilterData: any;
}

const NetworkFilter: React.FC<FilterDrawerProps> = ({
    isOpen,
    onClose,
    onOpen,
    selectedFilter,
    setFilterData,
}) => {
    const [selectedMenu, setSelectedMenu] = useState<string>("Attendee Category");
    const [selectedTag, setSelectedTag] = useState(selectedFilter);

    const handleClear = () => {
        setSelectedTag('');
        setFilterData(false);
        onClose();
    };

    const handleApplyFilters = () => {
        setFilterData(selectedTag);
        onClose();
    };

    const filterData = [
        {
            id: "attendee-category",
            name: "Attendee Category",
            tags: [
                { id: "1", name: "Delegate" },
                { id: "2", name: "Sponsor" },
                { id: "3", name: "Media" },
                { id: "4", name: "Investor" },
                { id: "5", name: "Organizer" }
            ]
        }
    ];

    return (
        <SwipeableDrawer onOpen={onOpen} anchor="bottom" open={isOpen} onClose={onClose} className="main_drawer_agenda">
            <div className="drawer-top-line"></div>
            <div className="d-flex justify-content-end mb-1 me-4">
                <Icon
                    role="button"
                    icon={`material-symbols-light:close`}
                    width={"24px"}
                    height={"24px"}
                    onClick={() => {
                        onClose();
                    }}
                />
            </div>
            <Typography variant="h6" className="headText_filter">
                Filters
            </Typography>
            <Box className="filter-container">
                <Box className="filter-sidebar">
                    <List>
                        {filterData?.map(filterItem => (
                            <ListItemButton
                                key={filterItem.id}
                                onClick={() => setSelectedMenu(filterItem.name)}
                                selected={selectedMenu === filterItem.name}
                                className="menu-item_filter"
                                style={{
                                    backgroundColor:
                                        selectedMenu === filterItem.name
                                            ? "var(--secondary-color) !important"
                                            : "",
                                    color: 'var(--comp-secondary-color) !important',
                                }}
                            >
                                <ListItemText
                                    className="menu-item_text"
                                    primary={filterItem.name}
                                />
                            </ListItemButton>
                        ))}
                    </List>
                </Box>

                <Box className="filter-content">
                    {filterData?.map(
                        filterItem =>
                            selectedMenu === filterItem.name && (
                                <List key={filterItem.id}>
                                    {filterItem.tags.map((tag: any) => (
                                        <ListItemButton key={tag.id}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        style={{
                                                            color:
                                                                selectedTag === tag.name
                                                                    ? "var(--secondary-color)"
                                                                    : ""
                                                        }}
                                                        checked={selectedTag === tag.name}
                                                        onChange={() => { setSelectedTag(tag?.name) }}
                                                    />
                                                }
                                                label={tag.name}
                                            />
                                        </ListItemButton>
                                    ))}
                                </List>
                            )
                    )}
                </Box>
            </Box>
            <Box className="filter-actions">
                <Button variant="outlined" onClick={handleClear} className="btn_agenda clear-btn text-capitalize">
                    Clear all
                </Button>
                <Button
                    variant="contained"
                    onClick={handleApplyFilters}
                    className="applybtn_agenda btn_agenda text-capitalize"
                >
                    Apply
                </Button>
            </Box>
        </SwipeableDrawer>
    );
};

export default NetworkFilter;
