import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./FloorPlan.css";
import Footer from "../Footer/Footer";
import FloorPlanList from "./FloorPlanList/FloorPlanList";
import FloorPlanDetail from "./FloorPlanDetail/FloorPlanDetail";
import { getModule } from "../../crud/module.crud";
import SideNavbar from "../SideNavbar/SideNavbar";
import { useEvent } from "../../contexts/EventContext";
import SearchAndFilter from "../../CommomComponents/SearchAndFilter/SearchAndFilter";
import { getFilterDetails } from "../../crud/filter.crud";
import { filterEntity, spinnerStyle, moduleTypes } from "../../config/app";
import ModuleHeading from "../../CommomComponents/ModuleHeading/ModuleHeading";
import { ClipLoader } from "react-spinners";
import { useModule } from "../../contexts/ModuleContext";
import ModuleDescription from "../../CommomComponents/ModuleDescription/ModuleDescription";
import { getModuleDescriptionByName, getModuleNameByType } from "../../reusable/common";

interface FloorPlanData {
	floor_plan_id: string;
	floor_plan_order: number;
	name: string;
	file_url: string;
}

const FloorPlan: React.FC = () => {
	const { eventDetails } = useEvent();
	const { modulesIds, modulesDetails } = useModule();

	const [searchTerm, setSearchTerm] = useState("");
	const [floorPlans, setFloorPlans] = useState<FloorPlanData[]>([]);
	const [selectedFloorPlan, setSelectedFloorPlan] = useState<FloorPlanData | null>(null);
	const [loading, setLoading] = useState(false); // New loading state for fetchFloorPlans
	const [filterLoading, setFilterLoading] = useState(false); // Loader state for filters
	const [filterData, setFilterData] = useState([]);
	const [isFilterDrawerOpen, setFilterDrawerOpen] = useState(false);
	const [filterTags, setFilterTags] = useState([]);

	const navigate = useNavigate();
	const moduleHeading =getModuleNameByType(modulesDetails,10);


	useEffect(() => {
		fetchFloorPlans();
		getFilters();
	}, []);

	const handleFilterClick = () => {
		setFilterDrawerOpen(true);
	};

	const fetchFloorPlans = async () => {
		setLoading(true); // Set loading to true when fetching data
		try {
			if (!modulesIds?.[moduleTypes.FLOOR_PLAN])
				throw new Error("No moduleId found for floor-plan");
			const response = await getModule(
				eventDetails?.event_id,
				modulesIds?.[moduleTypes.FLOOR_PLAN]
			);
			setFloorPlans(response.data);
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	};

	const handleFloorPlanSelect = (floorPlan: FloorPlanData) => {
		setSelectedFloorPlan(floorPlan);
	};

	const goBack = () => {
		if (selectedFloorPlan) {
			setSelectedFloorPlan(null);
		} else {
			navigate("/");
		}
	};

	const getFilters = async () => {
		setFilterLoading(true);
		try {
			const res = await getFilterDetails(eventDetails.event_id, filterEntity.FLOORPLAN);
			if (res.data.length > 0) {
				setFilterData(res.data);
			} else {
				setFilterData([]);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setFilterLoading(false);
		}
	};

	const getSelectedTagsArray = tagsObject => {
		return Object.values(tagsObject).flat();
	};
	const selectedTagNames = getSelectedTagsArray(filterTags);

	const filteredFloorPlans = floorPlans.filter(floorPlan => {
		const matchesSearch = floorPlan.name.toLowerCase().includes(searchTerm.toLowerCase());

		const floorPlanTagNames = floorPlan.tags?.map(tag => tag.name);
		const matchesTags =
			selectedTagNames.length === 0 ||
			floorPlanTagNames?.some(tagName => selectedTagNames.includes(tagName));

		return matchesSearch && matchesTags;
	});

	return (
		<>
			<div className="mobile-view">
				<div className="search-page-container">
					<div>
						<div className="en">
							<ModuleHeading moduleHeading={moduleHeading} goBack={goBack} />
							<ModuleDescription description={getModuleDescriptionByName(modulesDetails, moduleHeading)} />
						</div>
						{loading ? (
							<div style={spinnerStyle}>
								<ClipLoader
									loading={loading}
									size={50}
									aria-label="Loading Spinner"
								/>
							</div>
						) : (
							<>
								{!selectedFloorPlan && (
									<>
										<div className="search-bars no-margin">
											<div className="search-input-wrapper-new">
												<SearchAndFilter
													filterLoading={filterLoading}
													filterData={filterData}
													getFilters={getFilters}
													eventId={eventDetails.event_id}
													searchTerm={searchTerm}
													setSearchTerm={setSearchTerm}
													handleFilterClick={handleFilterClick}
													setFilterTags={setFilterTags}
												/>
											</div>
										</div>
										<div className="floor_plannnn">
											<FloorPlanList
												floorPlans={filteredFloorPlans}
												onSelect={handleFloorPlanSelect}
											/>
										</div>
									</>
								)}
								{selectedFloorPlan && (
									<FloorPlanDetail floorPlan={selectedFloorPlan} />
								)}
							</>
						)}
						<Footer />
					</div>
				</div>
			</div>

			<div className="desktop-view">
				<div className="desktop-view-side">
					<div className="desktop-left-slider">
						<SideNavbar />
					</div>
					<div className="home-screens desktop-left-container font">
						<div className="">
							<ModuleHeading moduleHeading={moduleHeading} goBack={goBack} />
							<ModuleDescription description={getModuleDescriptionByName(modulesDetails, moduleHeading)} />

						</div>
						<div className="network-searchbar-container" id="searchbar-floorplan">
							<div className="search-bars no-margin search-filter">
								<SearchAndFilter
									filterLoading={filterLoading}
									filterData={filterData}
									getFilters={getFilters}
									eventId={eventDetails.event_id}
									searchTerm={searchTerm}
									setSearchTerm={setSearchTerm}
									handleFilterClick={handleFilterClick}
									setFilterTags={setFilterTags}
								/>
							</div>
						</div>
						{loading ? (
							<div className="loader-container" style={spinnerStyle}>
								<ClipLoader
									color="#4A90E2"
									loading={loading}
									size={50}
									aria-label="Loading Spinner"
								/>
							</div>
						) : (
							<>
								{!selectedFloorPlan && (
									<div className="floor_plannnn">
										<FloorPlanList
											floorPlans={filteredFloorPlans}
											onSelect={handleFloorPlanSelect}
										/>
									</div>
								)}
								{selectedFloorPlan && (
									<FloorPlanDetail floorPlan={selectedFloorPlan} />
								)}
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default FloorPlan;
