import React, { useState } from "react";
import { Button, SwipeableDrawer } from "@mui/material";
import "./LogoutDrawer.css";
import { Icon } from "@iconify/react/dist/iconify.js";

interface LogoutDrawerProps {
	isOpen: boolean;
	onClose: () => void;
	onLogout: () => void;
	setIsAddDrawerOpen: (arg0: boolean) => void;
}

const LogoutDrawer: React.FC<LogoutDrawerProps> = ({
	isOpen,
	onClose,
	setIsAddDrawerOpen,
	onLogout
}) => {
	return (
		<>
			<SwipeableDrawer
				open={isOpen}
				onOpen={() => setIsAddDrawerOpen(true)}
				onClose={() => setIsAddDrawerOpen(false)}
				className="swipeable-drawer-wrapper"
				anchor="bottom"
			>
				<div className="logout-drawer-content align-items-center d-flex flex-column">
					<div className="drawer-top-line" />
					<div className="w-100 d-flex justify-content-end mb-1">
						<Icon
							role="button"
							icon={`material-symbols-light:close`}
							width={"24px"}
							height={"24px"}
							onClick={() => {
								onClose();
							}}
						/>
					</div>
					<div className="mt-2">
						<p className="mt-0 text-center">Are you sure you want to logout?</p>
						<div className="logout-drawer-buttons">
							<Button
								className="logoutsocial_btn m-0 me-3"
								onClick={() => {
									onClose();
								}}
							>
								No
							</Button>
							<Button
								className="logoutsocial_btn primaryColor m-0 ms-3"
								onClick={() => {
									onLogout();
								}}
							>
								Yes
							</Button>
						</div>
					</div>
				</div>
			</SwipeableDrawer>
			<style>
				{`
						.hide-in-desktop{
							display: block!important;
						}
						.swipeable-drawer-wrapper .MuiPaper-root{
							height: auto!important;
						}
					`}
			</style>
		</>
	);
};

export default LogoutDrawer;
