import React from "react";
import "./ExchangeBusinessCard.css";
import CustomButton from "../../../CommomComponents/CustomButton/CustomButton";
import CustomBusinessCard from "../BusinessCard/CustomBusinessCard";
import { CONNECTION_STATUS } from "../../../config/app";

interface ExchangeBusinessCardProps {
	onExchange: () => void;
	onCancel: () => void;
	businessCardData: any;
	loading?: boolean;
}

const ExchangeBusinessCard: React.FC<ExchangeBusinessCardProps> = ({
	onExchange,
	onCancel,
	businessCardData,
	loading
}) => {
	return (
		<>
			<div className="p-4">
				<div className="exchange-business-card-title">
					{businessCardData?.connection_status === null ||
					businessCardData?.connection_status === CONNECTION_STATUS.PENDING
						? `Request to exchange business cards`
						: `Business Card`}
				</div>
				<div className="mt-3">
					<CustomBusinessCard cardDetails={businessCardData} />
				</div>
				{(businessCardData?.connection_status === null ||
					businessCardData?.connection_status === CONNECTION_STATUS.PENDING) && (
					<div className="d-flex justify-content-center mt-3">
						<CustomButton
							isBgFill={false}
							paddingLeftRight="53x"
							minWidth="90px"
							onClick={() => {
								onCancel();
							}}
						>
							Cancel
						</CustomButton>
						<CustomButton
							disabled={loading}
							isBgFill={true}
							paddingLeftRight="53px"
							minWidth="90px"
							onClick={() => {
								onExchange();
							}}
							className="ms-3"
						>
							Exchange Card
						</CustomButton>
					</div>
				)}
			</div>
		</>
	);
};

export default ExchangeBusinessCard;
