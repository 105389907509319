import React, { useState, useEffect } from "react";
import "../Sponsors/Sponsors.css";
import Footer from "../Footer/Footer";
import SideNavbar from "../SideNavbar/SideNavbar";
import { ClipLoader } from "react-spinners";
import ItemCard from "../../EntityPages/ItemCard";
import ItemDetails from "../../EntityPages/EntityPage/ItemDetails";
import { useEvent } from "../../contexts/EventContext";
import { filterEntity, apiEntity } from "../../config/app";
import { getEntityDetails } from "../../crud/entity.crud";
import { getFilterDetails } from "../../crud/filter.crud";
import { spinnerStyle } from "../../config/app";
import ModuleHeading from "../../CommomComponents/ModuleHeading/ModuleHeading";
import SearchAndFilter from "../../CommomComponents/SearchAndFilter/SearchAndFilter";
import SearchSection from "../../CommomComponents/SearchAndFilter/SearchSection";
import FilterSection from "../../CommomComponents/SearchAndFilter/FilterSection";
import { useNavigate } from "react-router-dom";
import ItemCardMobile from "../../EntityPages/ItemCardMobile";
import { getModuleDescriptionByName, getModuleNameByType } from "../../reusable/common";
import ModuleDescription from "../../CommomComponents/ModuleDescription/ModuleDescription";
import { useModule } from "../../contexts/ModuleContext";

const Partners = () => {
	const { eventDetails } = useEvent();
	const { modulesDetails } = useModule()
	const navigate = useNavigate();
	const [categories, setCategories] = useState([]);
	const [uncategorizedSponsors, setUncategorized] = useState([]);
	const [openCategories, setOpenCategories] = useState({});
	const [searchTerm, setSearchTerm] = useState("");
	const [events, setEvents] = useState({});
	const [loading, setLoading] = useState(true);
	const [isFilterDrawerOpen, setFilterDrawerOpen] = useState(false);
	const [filterData, setFilterData] = useState([]);
	const [filterTags, setFilterTags] = useState([]);
	const [filterLoading, setFilterLoading] = useState(false);
	const [itemDetailsPage, setItemDetailsPage] = useState(false);
	const [open, setOpen] = useState(false);
	const [selectedPartners, setSelectedpatners] = useState("");

	const moduleHeading =getModuleNameByType(modulesDetails,7);


	const handleCloseModal = () => {
		setOpen(false);
		setSelectedpatners(null);
	};

	const handleFilterClick = () => {
		setFilterDrawerOpen(true);
	};

	useEffect(() => {
		setEvents(eventDetails);
		getFilters(eventDetails);

		getPartners(eventDetails.event_url);
	}, []);

	const getPartners = async eventurl => {
		try {
			const res = await getEntityDetails(eventurl, apiEntity.PARTNERS);
			setUncategorized(res.data.uncategorized);
			setCategories(res.data.categorized);

			const initialOpenState = res.data.categorized.reduce((acc, category) => {
				acc[category.category_id] = false;
				return acc;
			}, {});
			setOpenCategories(initialOpenState);
		} catch (error) {
			console.error("Failed to fetch Partners:", error);
		} finally {
			setLoading(false);
		}
	};

	const goBack = () => {
		navigate("/");
	};

	const getSelectedTagsArray = tagsObject => {
		return Object.values(tagsObject).flat();
	};

	const selectedTagNames = getSelectedTagsArray(filterTags);
	const filteredCategories = categories
		.map(category => ({
			...category,
			entity: category.entity.filter(Partners => {
				const matchesSearch = Partners.entity_name
					.toLowerCase()
					.includes(searchTerm.toLowerCase());

				const PartnersTagNames = Partners.tags?.map(tag => tag.name) || [];
				const matchesTags =
					selectedTagNames.length === 0 ||
					PartnersTagNames.some(tagName => selectedTagNames.includes(tagName));

				return matchesSearch && matchesTags;
			})
		}))
		.filter(category => category.entity.length > 0);

	const filteredUncategorizedSponsors = uncategorizedSponsors.filter(Partners => {
		const matchesSearch = Partners.entity_name.toLowerCase().includes(searchTerm.toLowerCase());

		const PartnersTagNames = Partners.tags?.map(tag => tag.name) || [];
		const matchesTags =
			selectedTagNames.length === 0 ||
			PartnersTagNames.some(tagName => selectedTagNames.includes(tagName));

		return matchesSearch && matchesTags;
	});

	const getFilters = async fetchdatapatners => {
		setFilterLoading(true);
		try {
			const res = await getFilterDetails(eventDetails.event_id, filterEntity.SPONSORS);
			if (res.data.length > 0) {
				setFilterData(res.data);
			} else {
				setFilterData([]);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setFilterLoading(false);
		}
	};

	return (
		<>
			<div className="phone-view">
				<div className="sponsors-container">
					<div>
						<ModuleHeading goBack={goBack} moduleHeading={moduleHeading} />
						<ModuleDescription description={getModuleDescriptionByName(modulesDetails, moduleHeading)} />

						<div className="search-bar_network">
							<div className="search-bars no-margin w-100">
								<div className="search-input-wrapper" style={{ maxWidth: "100%" }}>
									<SearchSection
										searchTerm={searchTerm}
										setSearchTerm={setSearchTerm}
										handleFilterClick={handleFilterClick}
									/>
								</div>
							</div>
							{(
								<div className="download-section">
									<FilterSection
										filterLoading={filterLoading}
										filterData={filterData}
										filterTags={filterTags}
										getFilters={getFilters}
										setFilterTags={setFilterTags}
										handleFilterClick={handleFilterClick}
										showSearchbox={false}
									/>
								</div>
							)}
						</div>
					</div>

					{loading ? (
						<div style={spinnerStyle}>
							<ClipLoader loading={loading} size={50} aria-label="Loading Spinner" />
						</div>
					) : filteredCategories.length === 0 &&
						filteredUncategorizedSponsors.length === 0 ? (
						<div className="no-sponsors-message">No Partners available</div>
					) : (
						<ItemCardMobile
							filteredCategories={filteredCategories}
							filteredUncategorized={filteredUncategorizedSponsors}
							selectedPartners={selectedPartners}
							open={open}
							onClose={handleCloseModal}
							unCategrizedname={"Partners"}
							routeDetails={"/partners/details"}
							route={"/partners"}
						/>
					)}
					<Footer />
				</div>
			</div>

			{/* Desktop View */}
			<div className="desktop-view">
				<div className="menu-desktop">
					<SideNavbar />
					{itemDetailsPage ? (
						<ItemDetails
							open={open}
							onClose={handleCloseModal}
							selectedEntity={selectedPartners}
							setItemDetailsPage={setItemDetailsPage}
							setSelectedSponsor={setSelectedpatners}
							route={"/partners"}
						/>
					) : (
						<div className="sponsors-container-desktop">
							<ModuleHeading goBack={goBack} moduleHeading={moduleHeading} />
							<ModuleDescription description={getModuleDescriptionByName(modulesDetails, moduleHeading)} />

							<div className="search-bar_network-desktop">
							<div className="search-bars no-margin">
								<div className="search-input-wrapper">
									<SearchSection
										searchTerm={searchTerm}
										setSearchTerm={setSearchTerm}
										showSearchbox={"true"}
									/>
								</div>
							</div>

							{ (
								<div className="download-section">
									<FilterSection
										filterLoading={filterLoading}
										filterData={filterData}
										getFilters={getFilters}
										filterTags={filterTags}
										setFilterTags={setFilterTags}
										handleFilterClick={handleFilterClick}
										showSearchbox={false}
									/>
								</div>
							)}
						    </div>

							{loading ? (
								<div style={spinnerStyle}>
									<ClipLoader
										loading={loading}
										size={50}
										aria-label="Loading Spinner"
									/>
								</div>
							) : filteredCategories.length === 0 &&
								filteredUncategorizedSponsors.length === 0 ? (
								<div className="no-sponsors-message">No Partners available</div>
							) : (
								<div className="scrollable-div-other">
									<ItemCard
										filteredCategories={filteredCategories}
										filteredUncategorized={filteredUncategorizedSponsors}
										selectedPartners={selectedPartners}
										open={open}
										onClose={handleCloseModal}
										setItemDetailsPage={setItemDetailsPage}
										setSelectedSponsor={setSelectedpatners}
										unCategrizedname={"Partners"}
										routeDetails={"/partners/details"}
										route={"/partners"}
									/>
								</div>
							)}
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default Partners;
