import React, { useEffect, useState } from "react";
import "./Network.css";
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router-dom";
import { useEvent } from "../../contexts/EventContext";
import { useUser } from "../../contexts/AuthContext";
import SearchSection from "../../CommomComponents/SearchAndFilter/SearchSection";
import TabsNavigation from "./TabsNavigation/TabsNavigation";
import ConnectionList from "./ConnectionList/ConnectionList";
import DownloadSection from "./DownloadSection/DownloadSection";
import ProfileDrawer from "./ProfileDrawer/ProfileDrawer";
import NotificationBox from "./NotificationBox/NotificationBox";
import ConfirmationDialog from "./ConfirmationDialog/ConfirmationDialog";
import ConnectionListDesktop from "./ConnectionList/ConnectionListDesktop";
import { networkEntity, actionButtons, TABS, CONNECTION_STATUS } from "../../config/app";
import NotificationToaster from "./NotificationBox/NotificationToaster";
import SideNavbar from "../SideNavbar/SideNavbar";
import BusinessCard from "../../CommomComponents/BusinessCard/BussinessCard";
import ModuleHeading from "../../CommomComponents/ModuleHeading/ModuleHeading";
import { ClipLoader } from "react-spinners";
import { spinnerStyle } from "../../config/app";
import {
	fetchAllAttendees,
	updateConnectionStatus,
	createConnection,
	downloadConnections
} from "../../crud/network.crud";

import ModuleDescription from "../../CommomComponents/ModuleDescription/ModuleDescription";
import { getModuleDescriptionByName, getModuleNameByType } from "../../reusable/common";
import { useModule } from "../../contexts/ModuleContext";
import NetworkFilter from "./NetworkFilter/NetworkFilter";
import NetworkFilterDesktop from "./NetworkFilter/NetworkFilterDesktop";
import { toasterMessageNetwork } from "../../config/app";
import _ from "lodash";


interface Connection {
	id: string;
	name: string;
	title: string;
	company: string;
	avatar: string;
	status: "connected" | "pending" | "not_connected";
	isOnline: boolean;
}

const Network: React.FC = () => {
	const { eventDetails } = useEvent();
	const { modulesDetails } = useModule()
	const useDetails = useUser();
	const [activeTab, setActiveTab] = useState<string>("All");
	const [searchTerm, setSearchTerm] = useState("");
	const [showDownloadOptions, setShowDownloadOptions] = useState(false);
	const [selectedConnections, setSelectedConnections] = useState<string[]>([]);
	const [drawerOpen, setDrawerOpen] = useState(false);
	const [selectedConnection, setSelectedConnection] = useState<Connection | null>(null);
	const [downloadDrawerOpen, setDownloadDrawerOpen] = useState(false);
	const [attendees, setAttendees] = useState([]);
	const [attendeesData, setAttendeesAttendeesData] = useState([]);
	const [notificationPopup, setNotificationPopup] = useState(false);
	const [connectionButton, setConnectionButton] = useState("");
	const [withdrawModalOpen, setWithdrawModalOpen] = useState(false);
	const [connectionToWithdraw, setConnectionToWithdraw] = useState<string | null>(null);
	const [connectionStatus, setConnectionStatus] = useState("");
	const [removeConnectionId, setRemoveConnectionId] = useState(false);
	const [toasterOpen, setToasterOpen] = useState(false);
	const [toasterMessage, setToasterMessage] = useState("");
	const [toasterSeverity, setToasterSeverity] = useState<"success" | "error">("success");
	const [hasMore, setHasMore] = useState(true);
	const [infiniteLoader, setInfiniteLoader] = useState(false);
	const [actionFetch, setActionFetch] = useState(true);
	const [filterTags, setFilterTags] = useState("");
	const [countMetrics, setCountMetrics] = useState([])
	const [filterData, setFilterData] = useState(false);
	const [loading, setLoading] = useState(false);
	const [isFilterOpen, setIsFilterOpen] = useState(false);
	const [filterModal, setFilterModal] = useState(false);
	const [isFilterDrawerOpen, setFilterDrawerOpen] = useState(false);
	const [currentAttendeesCount, setCurrentAttendeesCount] = useState(0);
	const [disabledButton, setDisabledButton] = useState("")

	const [filterNumber, setFilterNumber] = useState({
		MY_NETWORK: 0,
		RECEIVED: 0,
	});

	const [searchedAttendees, setSearchedAttendees] = useState<any[]>([]);
	const userId = localStorage.getItem("attendeeId");

	const attendeesList = attendees;
	const navigate = useNavigate();
	const moduleHeading = getModuleNameByType(modulesDetails, 6);


	const handleWithdrawClick = (connection: string) => {
		setConnectionToWithdraw(connection);
		setWithdrawModalOpen(true);
		setSelectedConnection(connection);
	};

	const handleConfirmWithdraw = () => {
		setWithdrawModalOpen(false);
	};

	const handleCancelWithdraw = () => {
		setWithdrawModalOpen(false);
		setConnectionToWithdraw(null);
	};

	const toggleFilterModal = () => setFilterModal(!filterModal);

	useEffect(() => {
		if (notificationPopup) {
			const timer = setTimeout(() => {
				setNotificationPopup(true);
			}, 30000000000);
			// return () => clearTimeout(timer);
		}
	}, [notificationPopup]);

	const handleCloseToaster = () => {
		setToasterOpen(false);
	};

	const handleFilterClick = () => {
		setFilterDrawerOpen(true);
	};

	const goBack = () => {
		navigate("/");
	};

	const fetchAttendees = _.debounce(async (offset = 0) => {
		if (offset === 0) {
			setLoading(true);
			setCurrentAttendeesCount(0);
		} else {
			setInfiniteLoader(true);
		}

		const limit = 50;
		try {
			const category = activeTab === TABS.ALL ? filterData : null;
			const connectionStatus = (activeTab === TABS.SENT || activeTab === TABS.RECEIVED) ? CONNECTION_STATUS.PENDING : (activeTab === TABS.MY_NETWORK || activeTab === TABS.BUSINESS_CARDS) ? CONNECTION_STATUS.CONNECTED : null;
			const response = await fetchAllAttendees(category, true, eventDetails?.event_id, searchTerm, connectionStatus, limit, offset);
			if (offset === 0) {
				setAttendees([...filterConnections(response?.data?.attendees || [])]);
				setCurrentAttendeesCount(response?.data?.attendees?.length);
			} else {
				setAttendees([...attendees, ...filterConnections(response?.data?.attendees || [])]);
				setCurrentAttendeesCount([...attendees, ...response?.data?.attendees]?.length);
			}
			setHasMore(limit === response?.data?.attendees?.length);
			setCountMetrics(response?.data?.count_metrics);

		} catch (error) {
			console.error("Error fetching attendees:", error?.response || error);
			setAttendees([]);
			setCurrentAttendeesCount(0);
		} finally {
			setLoading(false);
			setInfiniteLoader(false);
		}
	}, 700);


	const actionUpdate = async (connection, network) => {
		let updatedStatus = '';
		let toasterMessage = "";

		if (network == networkEntity.ACCEPT) {
			var updateEntity = networkEntity.ACCEPT;
			toasterMessage = toasterMessageNetwork.REQUEST_ACCEPTED;

		} else if (network == networkEntity.REJECT) {
			var updateEntity = null;
			toasterMessage = toasterMessageNetwork.REQUEST_REJECTED;

		} else if (network == networkEntity.REMOVE) {
			var updateEntity = null;
			toasterMessage = toasterMessageNetwork.CONNECTION_REMOVED;
		}

		switch (connectionButton) {
			case actionButtons.WITHDRAW:
				updatedStatus = networkEntity.WITHDRAW;
				toasterMessage = toasterMessageNetwork.REQUEST_WITHDRAW;
				break;

			default:
				if (removeConnectionId) {
					updatedStatus = networkEntity.REMOVE;
					toasterMessage = toasterMessageNetwork.CONNECTION_REMOVED;
				}
				break;
		}

		const newUpdate = updatedStatus ? updatedStatus : network;

		try {
			const res = await updateConnectionStatus(
				newUpdate,
				connection?.connection_id,
				eventDetails?.event_id
			);

			if (res.status === 204 || res.status === 200) {
				setAttendees((prevAttendees) => {
					const updatedAttendees = prevAttendees.map((attendee) =>
						attendee.attendee_id === connection?.attendee_id
							? {
								...attendee,
								connection_status:
									connectionButton === actionButtons.WITHDRAW ? null : updateEntity
							}
							: attendee
					);

					if (activeTab === TABS.MY_NETWORK) {
						return updatedAttendees.filter(
							(c) => c?.connection_status === networkEntity.CONNECT
						);
					}

					return filterConnections(updatedAttendees);
				});

				setToasterMessage(toasterMessage);
				setToasterSeverity("success");
				setToasterOpen(true);
				setNotificationPopup(true);
			} else {
				setToasterMessage("Failed to update status");
				setToasterSeverity("error");
				setToasterOpen(true);
				setNotificationPopup(true);
			}
		} catch (error) {
			console.error("Error updating connection status:", error);

			setToasterMessage("Failed to update status");
			setToasterSeverity("error");
			setToasterOpen(true);
		}
	};


	const createUserConnection = async (connection) => {
		setDisabledButton(connection.attendee_id)

		try {
			const res = await createConnection(
				eventDetails?.event_id,
				connection?.attendee_id
			);


			if (res?.status === 201 && res?.data) {
				const { connection_id, connection_status } = res.data;

				setAttendees((prevAttendees) =>
					prevAttendees.map((attendee) =>
						attendee.attendee_id === connection?.attendee_id
							? {
								...attendee,
								connection_status: connection_status || networkEntity.SENT,
								connection_id: connection_id,
								created_by: userId,
							}
							: attendee
					)
				);


				const successMessage = "Connection request sent";
				setDisabledButton(null)
				setToasterMessage(successMessage);
				setToasterSeverity("success");
				setToasterOpen(true);
				setNotificationPopup(true);
			} else {
				console.error("Unexpected status code:", res?.status);
			}
		} catch (error) {
			console.error("Error in createUserConnection:", error.message || error);

			setToasterMessage("Failed to create connection.");
			setToasterSeverity("error");
			setToasterOpen(true);
		} finally {
		}
	};

	const filterConnections = (attendeesList: any[]) => {
		switch (activeTab) {
			case TABS.SENT:
				return attendeesList?.filter(
					(c) =>
						c?.connection_status === networkEntity.SENT &&
						c?.created_by === userId
				);
			case TABS.RECEIVED:
				return attendeesList?.filter(
					(c) =>
						c?.connection_status === networkEntity.SENT &&
						c?.created_by !== userId
				);
			default:
				return attendeesList;
		}
	};



	const downloadAttendee = async () => {
		try {
			const res = await downloadConnections(eventDetails?.event_id);
			const downloadUrl = res?.data?.url;

			if (downloadUrl) {
				const link = document.createElement("a");
				link.href = downloadUrl;
				link.download = "attendees.csv";
				link.target = "_self";
				link.rel = "noopener noreferrer";
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		} catch (error: any) {
			console.error("Error downloading file:", error?.message || error);
		}
	};

	const toggleConnectionSelection = (id: string) => {
		setSelectedConnections(prev =>
			prev?.includes(id) ? prev.filter(connId => connId !== id) : [...prev, id]
		);
	};

	const handleConnectionClick = connection => {
		setSelectedConnection(connection);
		setDrawerOpen(true);

	};
	useEffect(() => {
		if (notificationPopup) {
			const timer = setTimeout(() => {
				setNotificationPopup(false);
			}, 3000);

			return () => clearTimeout(timer);
		}
	}, [notificationPopup]);

	const openFilter = () => {
		setIsFilterOpen(true);
	};

	const closeFilter = () => {
		setIsFilterOpen(false);
	};

	useEffect(() => {
		fetchAttendees();
	}, []);

	useEffect(() => {
		setLoading(true);
		fetchAttendees();
	}, [activeTab, filterData]);

	useEffect(() => {
		setLoading(true);
		const debouncedFetchAttendees = _.debounce(() => {
			fetchAttendees();
		}, 500)
		debouncedFetchAttendees();
		return () => {
			debouncedFetchAttendees.cancel();
		}
	}, [searchTerm]);

	return (
		<>
			<div className="phone-view">
				<div className="connection-list">
					<div className="en">
						<ModuleHeading moduleHeading={moduleHeading} goBack={goBack} />
						<ModuleDescription description={getModuleDescriptionByName(modulesDetails, moduleHeading)} />
					</div>
					<NetworkFilter
						isOpen={isFilterOpen}
						onClose={closeFilter}
						onOpen={openFilter}
						setFilterTags={setFilterTags}
						filterLoading={false}
						setFilterData={setFilterData}
						setAttendees={setAttendees}
					/>
					<TabsNavigation
						activeTab={activeTab}
						setActiveTab={setActiveTab}
						countMetrics={countMetrics}
					/>
					<div>
						<div className="search-bar_network">
							<div className="search-bars no-margin w-100">
								<div className="search-input-wrapper" style={{ maxWidth: "100%" }}>
									<SearchSection
										searchTerm={searchTerm}
										setSearchTerm={setSearchTerm}
										handleFilterClick={handleFilterClick}
									/>
								</div>
							</div>
							{(activeTab === "My Network" || activeTab === "Business Cards" || activeTab === "All") && (
								<div className="download-section">
									<DownloadSection
										handleDownloadClick={downloadAttendee}
										showDownloadOptions={showDownloadOptions}
										setDownloadDrawerOpen={setDownloadDrawerOpen}
										activeTab={activeTab}
										toggleFilterModal={openFilter}
										setFilterModal={setFilterModal}
										attendeesList={attendeesList}
									/>
								</div>
							)}
						</div>
						{loading ? (
							<div style={spinnerStyle}>
								<ClipLoader
									loading={loading}
									size={50}
									aria-label="Loading Spinner"
								/>
							</div>
						) : (
							<>
								{activeTab === "Business Cards" ? (
									<div className="business-card-container scrollable-div-other" style={{ marginBottom: "15px" }}>
										{attendees?.length > 0 ? (
											attendees?.map(connection => (
												<BusinessCard
													key={connection?.id}
													connection={connection}
												/>
											))
										) : (
											<p style={{ textAlign: "center", width: "100%" }}>
												No connections found for this category.
											</p>
										)}
									</div>
								) : (
									<ConnectionList
										activeTab={activeTab}
										connections={[]}
										filterConnections={filterConnections}
										attendees={attendees}
										handleConnectionClick={handleConnectionClick}
										userId={userId}
										setAttendeesAttendeesData={setSelectedConnection}
										setConnectionButton={setConnectionButton}
										connectionButton={connectionButton}
										setSelectedConnection={setSelectedConnection}
										setRemoveConnectionId={setRemoveConnectionId}
										hasMore={hasMore}
										loading={infiniteLoader}
										actionFetch={actionFetch}
										setSelectedConnections={setSelectedConnections}
										createUserConnection={createUserConnection}
										actionUpdate={actionUpdate}
										filterTags={filterTags}
										searchedAttendees={searchedAttendees}
										fetchAttendees={fetchAttendees}
										currentAttendeesCount={currentAttendeesCount}
										disabledButton={disabledButton}

									/>
								)}
							</>
						)}
					</div>
					{notificationPopup && (
						<div className="notification-wrapper">
							<NotificationBox message="Request accepted successfully" />
						</div>
					)}

					<Footer />

					<ProfileDrawer
						drawerOpen={drawerOpen}
						setDrawerOpen={setDrawerOpen}
						selectedConnection={selectedConnection}
						setNotificationPopup={setNotificationPopup}
						connectionButton={connectionButton}
						actionUpdate={
							actionUpdate
						}
						setSelectedConnection={setSelectedConnection}
						createUserConnection={() => {
							createUserConnection();
						}}
						removeConnectionId={removeConnectionId}
						setRemoveConnectionId={setRemoveConnectionId}
					/>
				</div>
			</div>
			<ConfirmationDialog
				open={withdrawModalOpen}
				title="Confirm Withdraw"
				message="Are you sure you want to withdraw this connection request?"
				onConfirm={handleConfirmWithdraw}
				onCancel={handleCancelWithdraw}
				connectionButton={connectionButton}
				selectedConnection={selectedConnection}
				setConnectionStatus={setConnectionStatus}
				actionUpdate={actionUpdate}

				setSelectedConnection={setSelectedConnection}
				createUserConnection={() => {
					createUserConnection();
				}}
				removeConnectionId={removeConnectionId}
			/>
			{/* Notification Box */}
			{notificationPopup && (
				<div className="notification-wrapper">
					<NotificationBox message={toasterMessage} />
				</div>
			)}

			{/* desktop container */}
			<div className="desktop-view">
				<div className="menu-desktop">
					<div>
						<SideNavbar />
					</div>
					<div className="connection-list-desktop">
						<ModuleHeading moduleHeading={moduleHeading} goBack={goBack} />
						<ModuleDescription description={getModuleDescriptionByName(modulesDetails, moduleHeading)} />
						<div className="search-bar_network-desktop">
							<div className="search-bars no-margin">
								<div className="search-input-wrapper">
									<SearchSection
										searchTerm={searchTerm}
										setSearchTerm={setSearchTerm}
										downloadNetwork={true}
									/>
								</div>
							</div>

							{(activeTab === "My Network" || activeTab === "Business Cards" || activeTab === "All") && (
								<div className="download-section">
									<DownloadSection
										handleDownloadClick={downloadAttendee}
										showDownloadOptions={showDownloadOptions}
										setDownloadDrawerOpen={setDownloadDrawerOpen}
										activeTab={activeTab}
										toggleFilterModal={toggleFilterModal}
										setFilterModal={setFilterModal}
										attendeesList={attendeesList}
									/>
								</div>
							)}
						</div>
						{filterModal &&
							<NetworkFilterDesktop
								isOpen={isFilterOpen}
								onClose={closeFilter}
								onOpen={openFilter}
								setFilterTags={setFilterTags}
								filterLoading={false}
								setFilterData={setFilterData}
								setAttendees={setAttendees}
							/>}

						<TabsNavigation
							activeTab={activeTab}
							setActiveTab={setActiveTab}
							fetchAttendees={fetchAttendees}
							setConnectionStatus={setConnectionStatus}
							actionFetch={actionFetch}
							setLoading={setLoading}
							filterNumber={filterNumber}
							countMetrics={countMetrics}
							setFilterData={setFilterData}
							filterTags={filterTags}
							setAttendees={setAttendees}
						/>

						{loading ? (
							<div style={spinnerStyle}>
								<ClipLoader
									loading={loading}
									size={50}
									aria-label="Loading Spinner"
								/>
							</div>
						) : (
							<>
								{activeTab === "Business Cards" ? (
									<div className="business-card-container scrollable-div-other">
										{attendees?.length > 0 ? (
											attendees?.map(connection => (
												<BusinessCard
													key={connection?.id}
													connection={connection}
												/>
											))
										) : (
											<p style={{ textAlign: "center", width: "100%" }}>
												No connections found for this category.
											</p>
										)}
									</div>
								) : (
									<ConnectionListDesktop
										activeTab={activeTab}
										connections={[]}
										filterConnections={filterConnections}
										attendees={attendees}
										handleConnectionClick={handleWithdrawClick}
										userId={userId}
										setAttendeesAttendeesData={setSelectedConnection}
										setConnectionButton={setConnectionButton}
										connectionButton={connectionButton}
										setSelectedConnection={setSelectedConnection}
										setRemoveConnectionId={setRemoveConnectionId}
										hasMore={hasMore}
										loading={infiniteLoader}
										actionFetch={actionFetch}
										setSelectedConnections={setSelectedConnections}
										createUserConnection={createUserConnection}
										actionUpdate={actionUpdate}
										filterTags={filterTags}
										fetchAttendees={fetchAttendees}
										currentAttendeesCount={currentAttendeesCount}
										handleWithdrawClick={handleWithdrawClick}
										disabledButton={disabledButton}
									/>
								)}
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default Network;
