const TopBackground = ({ color }) => {
	return (
		<>
			<svg
				width="100%"
				height="140"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 390 140"
				preserveAspectRatio="none"
				style={{ opacity: "0.1" }}
			>
				<path
					d="M0 0H390V109.085C390 109.085 271.719 140.122 194 140C117.05 139.878 0 109.085 0 109.085V0Z"
					fill={color}
				/>
			</svg>
		</>
	);
};
export default TopBackground;
