import axios from "./axios";
import { apiBase, apiUrl, eventBase } from "./../config/crud";

const API_URL = apiUrl;
const API_BASE = apiBase;
const EVENT = eventBase;
const PUSH_NOTIFICATION = "push-notification";

// Get list of speakers
export const getNotification = (eventId: string, notificationId: string) => {
	return axios.get(
		`${API_URL}/${API_BASE}/${EVENT}/${eventId}/${PUSH_NOTIFICATION}/${notificationId}`
	);
};
