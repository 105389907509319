import { useNavigate } from "react-router-dom";
import "./ModuleHeading.css";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { Icon } from "@iconify/react/dist/iconify.js";
import { profileImage } from "../../reusable/images";
import { useUser } from "../../contexts/AuthContext";

interface ModuleHeadingProps {
	moduleDetails?: object | null;
	goBack?: () => void;
	moduleHeading: string;
	hideProfileFromHeader?: boolean;
}

const ModuleHeading: React.FC<ModuleHeadingProps> = ({
	moduleDetails,
	goBack,
	moduleHeading,
	hideProfileFromHeader = false
}) => {
	const navigate = useNavigate();
	const selectedModules = ["/meetings/create-meeting", "/meetings/edit-meeting"];
	const userDataFromLocalStorage = localStorage.getItem("user");
	const userData =
		userDataFromLocalStorage?.length > 0 ? JSON.parse(userDataFromLocalStorage) : {};
	const { user } = useUser();

	return (
		<div className="module-heading-wrapper multi-menu-desktop d-flex justify-content-between">
			<div className="d-flex align-items-center">
				{moduleDetails ? (
					<Icon
						icon={
							selectedModules.includes(location.pathname)
								? `material-symbols-light:close`
								: `material-symbols-light:arrow-back-ios-rounded`
						}
						width={"25px"}
						height={"25px"}
						onClick={goBack ? goBack : () => navigate(-1)}
						className="primary-color-icons module-heading-back-icon"
					/>
				) : (
					<Icon
						icon={
							selectedModules.includes(location.pathname)
								? `material-symbols-light:close`
								: `material-symbols-light:arrow-back-ios-rounded`
						}
						width={"25px"}
						height={"25px"}
						onClick={goBack ? goBack : () => navigate(-1)}
						className="primary-color-icons module-heading-back-icon cursor-pointer"
					/>
				)}

				<div className="text-left ms-1">
					<div className="module-heading">{moduleHeading}</div>
				</div>
			</div>
			{user && !hideProfileFromHeader && (
				<div className="hide-in-mobile">
					<div className="d-flex cursor-pointer" onClick={() => navigate("/my-profile")}>
						{profileImage(userData.image_url, userData.name, 50, {
							width: "50px",
							height: "50px"
						})}
					</div>
				</div>
			)}
			<style>{`
				.sb-avatar{
				width:50px;
				height:50px}
			`}</style>
		</div>
	);
};

export default ModuleHeading;
