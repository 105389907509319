import React, { useState } from 'react';
import {
  Tabs, Tab, Box, Button, TextField, Avatar, Typography, IconButton, Paper, Grid,
  SwipeableDrawer
} from '@mui/material';
import About from './About/About';
import Badge from './Badge/Badge';
import BusinessCard from './BusinessCard/BusinessCard';
import './ProfileView.css'; // Assuming you have specific CSS for this page
import { Call } from '@mui/icons-material';
import { Bell } from "lucide-react";
import { Link, useNavigate, useParams } from "react-router-dom";
import SideNavbar from '../SideNavbar/SideNavbar';
import VCardPage from './VCardPage/VCardPage';
import { Header } from '../Header/Header';

const ProfileView: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false); // State to manage drawer visibility
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [feedbackText, setFeedbackText] = useState('');
  const [showDownloadOptions, setShowDownloadOptions] = useState(false);


  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const handleFeedbackChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFeedbackText(event.target.value);
  };

  const handleClear = () => {
    setFeedbackText(''); // Clear the feedback input
    handleDrawerClose(); // Close the drawer on Cancel button click
  };



  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  const handleDownloadVCard = () => {
    navigate('/vCard-page');
  };

  const [message, setMessage] = useState('');
  const [charCount, setCharCount] = useState(300);

  const handleInputChange = (e) => {
    const inputText = e.target.value;
    setMessage(inputText);
    setCharCount(300 - inputText.length);
  };

  const handleSend = () => {
    // Add your send logic here
  };

  const handleSendClick = () => {

    navigate('/vcard');

  };

  const goBack = () => {
    navigate(-1);
  };

  const [showOptions, setShowOptions] = useState(false);
  const [clickedButton, setClickedButton] = useState('');

  const handleButtonClick = (button) => {
    setClickedButton(button);
  };




  const socialLinks = [
    { icon: 'assets/icons/linkdin.svg', url: 'https://in.linkedin.com' },
    { icon: 'assets/icons/tweet.svg', url: 'https:/x.com' },
    { icon: 'assets/icons/whatsapp1.svg', url: 'https://whatsapp.com' },
    { icon: 'assets/icons/glob.svg', url: 'mailto:angyl@example.com' },
  ];

  return (
    <>

      <div className='phone-view'>
        <div>
          <Paper sx={{ maxWidth: 400, mx: 'auto', height: '100%', borderRadius: 0, boxShadow: 'none', }} >
            <Box sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <IconButton>
                <img src="assets/Vector.png" alt="Back" />
              </IconButton>
              <Box>
                <IconButton>
                  <img src="assets/Agenda donwload.png" alt="Center Logo" className="Down_profile" />
                </IconButton>
                <IconButton>
                  <img src="assets/27 notification bell.svg" alt="Center Logo" className="Down_profile" />
                </IconButton>
              </Box>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
              <Avatar
                src="assets/lisa.png"
                sx={{ width: 100, height: 100 }}
              />
              <Typography variant="h6" sx={{ mt: 2 }}>
                Lisa Soe
              </Typography>
              <Typography variant="subtitle2" color="text.secondary">
                Investor, Ultrh
              </Typography>
            </Box>

            <Grid container spacing={1} justifyContent="center" sx={{ mt: 2 }} className='profile_icon-img'>
              <img src="assets/icons/linkedin.svg" alt="Center Logo" className="Down_profile" />
              <img src="assets/icons/twitter.svg" alt="Center Logo" className="Down_profile" />
              <img src="assets/icons/whatsapp.svg" alt="Center Logo" className="Down_profile" />
              <img src="assets/icons/website.svg" alt="Center Logo" className="Down_profile" />
              <img src="assets/icons/message.svg" alt="Center Logo" className="Down_profile" />
              <img src="assets/icons/calendar1.svg" alt="Center Logo" className="Down_profile" />
            </Grid>

            {/* Tab navigation */}
            <Box>
              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                centered
                indicatorColor="primary"
              >
                <Tab label="About" />
                <Tab label="Badge" />
                <Tab label="Business Card" />
              </Tabs>
            </Box>

            {/* Content Sections */}
            <Box mt={4}>
              {selectedTab === 0 && <About />}
              {selectedTab === 1 && (
                <Badge
                  eventName="ELYSIAN - 2024"
                  eventLogo="assets/photos/logo-2.png"
                  location="HSR Layout, Bangalore, India"
                  organizer="HSR Layout, Bangalore, India"
                  date="20 June, 2024"
                  time="10:00 AM"
                  qrCode="assets/photos/QR.png"
                />
              )}
              {selectedTab === 2 && (
                <>
                  <div className='myprofile_businesscard'>
                    <BusinessCard
                      name="Anoyiki Soe"
                      title="Investor, Infy"
                      avatarUrl="assets/lisa.png"
                      qrCodeUrl="assets/photos/QR.png"
                      socialLinks={socialLinks}
                    />
                  </div>
                  <Button
                    variant="contained"
                    className=".btn_bussiness-card"
                    onClick={handleDrawerOpen} // Open drawer when button is clicked
                  >
                    <img src="assets/icons/arrows-exchange1.svg" alt="bookmark" className='icons-size-loc' />
                    Exchange business card
                  </Button>
                </>
              )}
            </Box>
          </Paper >
        </div>
      </div>

      {/* desktop conatiner */}


      <div className='desktop-view'>
        <div className="menu-desktop">
          <div>
            <SideNavbar />
          </div>

          <div className="view-container">
            <Header />
            <div className="view-profile-section">
              <div className='llp'>
                <div>
                  <img src="assets/lisa.png" alt="Lisa Soe" className="view-profile-avatar" />
                </div>

                <div>

                  <h2 className="view-profile-name">Lisa Soe</h2>
                  <p className="view-profile-title">Investor, Ultrh</p>

                  <div className="view-profile-icons">
                    <img src="assets/icons/linkedin.svg" alt="LinkedIn" className="view-icon" />
                    <img src="assets/icons/twitter.svg" alt="Twitter" className="view-icon" />
                    <img src="assets/icons/whatsapp.svg" alt="WhatsApp" className="view-icon" />
                    <img src="assets/icons/website.svg" alt="Website" className="view-icon" />
                    <img src="assets/icons/message.svg" alt="Message" className="view-icon" />
                    <img src="assets/icons/calendar1.svg" alt="Calendar" className="view-icon" />
                  </div>
                </div>
              </div>
              <div>
                <button className="options-button" onClick={() => setShowOptions(!showOptions)}>⋮</button>

                {/* Dropdown Menu */}
                {showOptions && (
                  <div className="options-dropdown">
                    <button
                      className={`dropdown-button ${clickedButton === 'download' ? 'active' : ''}`}
                      onClick={handleDownloadVCard}
                    >

                      Download vCard
                    </button>
                    <button
                      className={`dropdown-button ${clickedButton === 'remove' ? 'active' : ''}`}
                      onClick={() => handleButtonClick('remove')}
                    >
                      Remove Connection
                    </button>
                  </div>

                )}



              </div>

            </div>



            {/* <div className="view-tab-navigation">
              <button className="view-tab" onClick={() => setSelectedTab(0)}>About</button>
              <button className="view-tab" onClick={() => setSelectedTab(1)}>Badge</button>
              <button className="view-tab" onClick={() => setSelectedTab(2)}>Business Card</button>
            </div> */}
            <div className="view-tab-navigation">
              <button
                className={`view-tab ${selectedTab === 0 ? 'active-tab' : ''}`}
                onClick={() => setSelectedTab(0)}
              >
                About
              </button>
              <button
                className={`view-tab ${selectedTab === 1 ? 'active-tab' : ''}`}
                onClick={() => setSelectedTab(1)}
              >
                Badge
              </button>
              <button
                className={`view-tab ${selectedTab === 2 ? 'active-tab' : ''}`}
                onClick={() => setSelectedTab(2)}
              >
                Business Card
              </button>
            </div>

            <div className="view-tab-content">
              {selectedTab === 0 && <About />}
              {selectedTab === 1 && (
                <Badge
                  eventName="ELYSIAN - 2024"
                  eventLogo="assets/photos/logo-2.png"
                  location="HSR Layout, Bangalore, India"
                  organizer="HSR Layout, Bangalore, India"
                  date="20 June, 2024"
                  time="10:00 AM"
                  qrCode="assets/photos/QR.png"
                />
              )}
              {selectedTab === 2 && (
                <>
                  <div className='myprofile_businesscard card_b'>
                    <BusinessCard
                      name="Anoyiki Soe"
                      title="Investor, Infy"
                      avatarUrl="assets/lisa.png"
                      qrCodeUrl="assets/photos/QR.png"
                      socialLinks={socialLinks}
                    />
                  </div>
                  <button className="view-exchange-card-btn" onClick={openModal}>
                    <img src="assets/icons/arrows-exchange1.svg" alt="bookmark" className="view-exchange-icon" />
                    Exchange business card
                  </button>

                  {isOpen && (
                    <div className="modal-overlay" onClick={closeModal}>
                      <div className="modal-content" onClick={e => e.stopPropagation()}>
                        <h2 className="modal-title">Request To Exchange Business Cards </h2>
                        <div className="modal-body">
                          <BusinessCard
                            name="Anoyiki Soe"
                            title="Investor, Infy"
                            avatarUrl="assets/lisa.png"
                            qrCodeUrl="assets/photos/QR.png"
                            socialLinks={socialLinks}
                          />

                          <div className="message-input-container">
                            <div className='char1'>
                              <div className='char-count1'>Message</div>
                              <div className="char-count">{charCount} characters remaining</div>
                            </div>
                            <textarea
                              className="message-textarea"
                              placeholder="Write a message for them"
                              value={message}
                              onChange={handleInputChange}
                              maxLength={300}
                            />
                            <div className="toolbar">
                              <button className="toolbar-btn">B</button>
                              <button className="toolbar-btn"><i>I</i></button>
                              <button className="toolbar-btn"><u>U</u></button>
                              <button className="toolbar-btn">S</button>
                              <button className="toolbar-btn">A</button>
                              <button className="toolbar-btn">
                                <img src="/api/placeholder/16/16" alt="Color picker" />
                              </button>
                              <button className="toolbar-btn">
                                <img src="/api/placeholder/16/16" alt="Emoji" />
                              </button>
                              <button className="toolbar-btn">¶</button>
                              <button className="toolbar-btn">
                                <img src="/api/placeholder/16/16" alt="Align left" />
                              </button>
                              <button className="toolbar-btn">
                                <img src="/api/placeholder/16/16" alt="List" />
                              </button>
                              <button className="toolbar-btn">
                                <img src="/api/placeholder/16/16" alt="Numbered list" />
                              </button>
                              <button className="toolbar-btn">
                                <img src="/api/placeholder/16/16" alt="Indent" />
                              </button>
                              <button className="toolbar-btn">
                                <img src="/api/placeholder/16/16" alt="Image" />
                              </button>
                              <button className="toolbar-btn">
                                <img src="/api/placeholder/16/16" alt="Link" />
                              </button>
                              <button className="toolbar-btn">
                                <img src="/api/placeholder/16/16" alt="Document" />
                              </button>
                              <button className="toolbar-btn">
                                <img src="/api/placeholder/16/16" alt="Video" />
                              </button>
                              <button className="toolbar-btn">
                                <img src="/api/placeholder/16/16" alt="Undo" />
                              </button>
                              <button className="toolbar-btn">
                                <img src="/api/placeholder/16/16" alt="Redo" />
                              </button>
                            </div>
                            <div className="button-container">
                              <button className="cancel-btn">Cancel</button>
                              <button className="send-btn" onClick={handleSend}>Send</button>
                            </div>
                          </div>
                        </div>
                        <button className="close-button" onClick={closeModal}>Close</button>
                      </div>
                    </div>
                  )}



                </>
              )}
            </div>
          </div>

        </div>

      </div>





      {/* Drawer component */}
      <SwipeableDrawer onOpen={()=>{
        setIsDrawerOpen(true);
      }} anchor="bottom" open={isDrawerOpen} onClose={handleDrawerClose} className='main_drawer_agenda'>
        <div className="main_exchange_drawer">
          <h3 className='headText_exchange-Bcard'>Request to exchange business cards</h3>
          <div className='myprofile_businesscard'>
            <BusinessCard
              name="Anoyiki Soe"
              title="Investor, Infy"
              avatarUrl="assets/lisa.png"
              qrCodeUrl="assets/photos/QR.png"
              socialLinks={socialLinks}
            />
          </div>
          {/* <BusinessCard
            name="Anoyiki Soe"
            title="Investor, Infy"
            avatarUrl="assets/lisa.png"
            qrCodeUrl="assets/photos/QR.png"
            socialLinks={socialLinks}
          /> */}

          <Typography variant="h6" className='headText_filter'>Message</Typography>
          <div className="ex_mess_div">
            <TextField
              fullWidth
              multiline
              rows={3}
              variant="outlined"
              placeholder="Write message for them"
              className="feedback-textbox"
              value={feedbackText}
              onChange={handleFeedbackChange}
            />
          </div>
          <Box className="filter-actions">
            <Button variant="outlined" onClick={handleClear} className='btn_agenda'>
              Cancel
            </Button>
            <Button
              variant="contained"
              className=" btn_agenda"
              disabled={!feedbackText.trim()} // Disable if feedbackText is empty or only whitespace
              onClick={handleSendClick}
            >
              Send
            </Button>
          </Box>
        </div>
      </SwipeableDrawer>
    </>
  );
};

export default ProfileView;
