import React from 'react';
import { X, Bell } from 'lucide-react';
import './UnregisteredEmail.css';

const UnregisteredEmail: React.FC = () => {
    return (
        <> 
        <div className='phone-view'> 
        <div className='phone-container font'>
            <div className="icon-container">
                <X className="icon left-icon" />
                <Bell className="icon right-icon" />
            </div>
            <div>
                <img
                    src="assets/unknown-error.png"
                    alt="Welcome"
                    className="unregisteredemail-image"
                />
                <h3 className="unregisteredemail-text1 font">Page Not Found</h3>
                <p className='unregisteredemail-text'>
                    We apologize, but the page you are <br /> trying to access cannot be found
                </p>
            </div>
        </div>
        </div>

        <div className='desktop-view'> 
        <div className=' font'>
           
            <div className='errorpage'>
                <div> 
                <img
                    src="assets/unknown-error.png"
                    alt="Welcome"
                    className="unregisteredemail-image"
                />
                </div>
                <div className='errorpage1'> 
                <div className="error-headings"> Unregistered Email Address</div>
                <p className='error-textts'>
                The email id from which you have signed <br></br> up is not part of the registration list.<br></br> Please login using your registered email id <br></br> for accessing the event in this app.
                </p> 
                </div>
            </div>
        </div>
        </div>



      
        </>
    );
};

export default UnregisteredEmail;