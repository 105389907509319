import { Box, Modal } from "@mui/material";
import React, { ReactNode, useState } from "react";
import "./CustomModal.css";
import { Icon } from "@iconify/react/dist/iconify.js";

interface CustomModalProps {
	openCustomModal: boolean;
	onClose: () => void;
	component: ReactNode;
	modalActions?: object | any;
	maxWidth?: string;
	showModalAction?: boolean;
}

const CustomModal: React.FC<CustomModalProps> = ({
	openCustomModal,
	onClose,
	component,
	modalActions,
	maxWidth,
	showModalAction = false
}) => {
	const [openModalActionItems, setOpenModalActionItems] = useState(false);
	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		maxWidth: maxWidth || "620px",
		maxHeight: "700px",
		overflow: "hidden",
		overflowY: "auto",
		background: "#FFFFFF",
		borderRadius: "16px",
		width: "100%",
		outline: "none"
	};

	return (
		<Modal
			open={openCustomModal}
			onClose={() => {
				onClose();
				setOpenModalActionItems(false);
			}}
		>
			<Box
				sx={style}
				onClick={() => {
					setOpenModalActionItems(false);
				}}
			>
				{showModalAction && (
					<div
						className={`d-flex justify-content-${modalActions && Object.keys(modalActions)?.length > 0 ? "between" : "end"} custom-modal-close`}
					>
						<Icon
							role="button"
							icon={`material-symbols-light:close`}
							width={"24px"}
							height={"24px"}
							onClick={() => {
								onClose();
								setOpenModalActionItems(false);
							}}
						/>

						{modalActions && Object.keys(modalActions)?.length > 0 && (
							<Icon
								role="button"
								icon={`material-symbols-light:more-vert`}
								width={"24px"}
								height={"24px"}
								onClick={e => {
									e.stopPropagation();
									setOpenModalActionItems(true);
								}}
							/>
						)}

						{openModalActionItems && (
							<>
								<div className="custom-modal-action-items">
									{Object.keys(modalActions)?.map((each: any, index: number) => (
										<div
											role="button"
											key={index}
											className="custom-modal-action-text"
											onClick={() => {
												modalActions[each]();
											}}
										>
											<span>{each}</span>
										</div>
									))}
								</div>
							</>
						)}
					</div>
				)}

				{component}
			</Box>
		</Modal>
	);
};

export default CustomModal;
