import axios from "./axios";
import { apiBase, apiUrl, eventBase, publicBase } from "./../config/crud";

const API_URL = apiUrl;
const API_BASE = apiBase;
const EVENT = eventBase;
const TRACKING = "tracker";

// Get list of speakers
export const trackEntity = (
	eventId: string,
	urlType: number,
	payload: any,
	notificationMetricEvents: number = 0
) => {
	let QUERY = "";
	if (notificationMetricEvents) QUERY = `?notification_metric=${notificationMetricEvents}`;
	return axios.post(
		`${API_URL}/${API_BASE}/${EVENT}/${eventId}/${TRACKING}/${urlType}${QUERY}`,
		payload
	);
};
