import React from 'react';
import { Modal, Box, Button } from '@mui/material';
import './DeleteSocialIconModal.css';

interface DeleteSocialIconModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  socialPlatform: string;
}

const DeleteSocialIconModal: React.FC<DeleteSocialIconModalProps> = ({ isOpen, onClose, onConfirm, socialPlatform }) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{
        '& .MuiModal-paper': {
          border: 'none',  
          backgroundColor: 'rgb(0 0 0 / 18%);', 
          justifyContent:'center'
        },
      }}
      aria-labelledby="delete-social-icon-title"
      aria-describedby="delete-social-icon-description"
      BackdropProps={{
        style: { backgroundColor: 'rgba(0, 0, 0, 0.2)' }, // Semi-transparent black background
      }}
    >
      <Box className="delete-modal-content">
        <h2 id="delete-social-icon-title" className="edit-modal-titles">Delete {socialPlatform}</h2>
        <p id="delete-social-icon-description" className="te">
          Are you sure you want to delete the URL of {socialPlatform}?
        </p>
        <div className="delete-modal-buttons">
          <Button variant="outlined" onClick={onClose} className="cancel-button_deletee">
            Cancel
          </Button>
          <Button variant="contained" color="error" onClick={onConfirm} className="confirm-button_delete">
            Yes
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default DeleteSocialIconModal;
