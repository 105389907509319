import { currentEnv } from "./env.ts";

export const ENVS = {
	localhost: {
		VITE_ENV: "dev",
		VITE_APP_URL: "*.dev-app.konfhub.com",
		VITE_API_URL: " https://dev-api.konfhub.com",
		VITE_APP_BUCKET_NAME: "kh-attendee-app-dev",
		VITE_CENTRAL_LOGIN_URL: "https://dev-accounts.konfhub.com",
		VITE_AWS_PROJECT_REGION: "ap-southeast-1",
		VITE_AWS_COGNITO_IDENTITY_POOL_ID: "ap-southeast-1:9a5331da-329a-497d-be61-15668b42bf8f",
		VITE_AWS_COGNITO_REGION: "ap-southeast-1",
		VITE_AWS_USER_POOLS_ID: "ap-southeast-1_f6tfrmIsS",
		VITE_OAUTH_DOMAIN: "dev-auth.konfhub.com",
		VITE_AWS_USER_POOLS_WEB_CLIENT_ID: "1cum79568pe0u434ek1d5kin9a",
		VITE_AWS_APPSYNC_GRAPHQLENDPOINT: "https://dev-attendee-api.konfhub.com/graphql",
		VITE_HOST_NAME: "localhost",
		VITE_SENTRY_AUTH_TOKEN:
			"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",
		VITE_GRAPHQL_PUBLIC_API_KEY: "da2-r3oju7njvrbhhed2egv3nel6jy"
	},
	dev: {
		VITE_ENV: "dev",
		VITE_APP_URL: "*.dev-app.konfhub.com",
		VITE_API_URL: "https://dev-api.konfhub.com",
		VITE_APP_BUCKET_NAME: "kh-attendee-app-dev",
		VITE_CENTRAL_LOGIN_URL: "https://dev-accounts.konfhub.com",
		VITE_AWS_PROJECT_REGION: "ap-southeast-1",
		VITE_AWS_COGNITO_IDENTITY_POOL_ID: "ap-southeast-1:9a5331da-329a-497d-be61-15668b42bf8f",
		VITE_AWS_COGNITO_REGION: "ap-southeast-1",
		VITE_AWS_USER_POOLS_ID: "ap-southeast-1_f6tfrmIsS",
		VITE_OAUTH_DOMAIN: "dev-auth.konfhub.com",
		VITE_AWS_USER_POOLS_WEB_CLIENT_ID: "1cum79568pe0u434ek1d5kin9a",
		VITE_AWS_APPSYNC_GRAPHQLENDPOINT: "https://dev-attendee-api.konfhub.com/graphql",
		VITE_HOST_NAME: "konfhub.com",
		VITE_SENTRY_AUTH_TOKEN:
			"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",
		VITE_GRAPHQL_PUBLIC_API_KEY: "da2-r3oju7njvrbhhed2egv3nel6jy"
	},
	staging: {
		VITE_ENV: "staging",
		VITE_APP_URL: "*.staging-app.konfhub.com",
		VITE_API_URL: "https://staging-api.konfhub.com",
		VITE_APP_BUCKET_NAME: "kh-attendee-app-staging",
		VITE_CENTRAL_LOGIN_URL: "https://staging-accounts.konfhub.com",
		VITE_AWS_PROJECT_REGION: "us-east-1",
		VITE_AWS_COGNITO_IDENTITY_POOL_ID: "us-east-1:337d5ba7-28cf-4e5a-b991-6c66fdfa215d",
		VITE_AWS_COGNITO_REGION: "us-east-1",
		VITE_AWS_USER_POOLS_ID: "us-east-1_Qt5m915Di",
		VITE_OAUTH_DOMAIN: "kh-staging-auth.konfhub.com",
		VITE_AWS_USER_POOLS_WEB_CLIENT_ID: "11h7864bll3sgmtb93qb5coeg3",
		VITE_AWS_APPSYNC_GRAPHQLENDPOINT: "https://staging-attendee-api.konfhub.com/graphql",
		VITE_HOST_NAME: "konfhub.com",
		VITE_SENTRY_AUTH_TOKEN:
			"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",
		VITE_GRAPHQL_PUBLIC_API_KEY: "da2-np4ggmvkvvaqpapn73xvm2lchq"
	},
	prod: {
		VITE_ENV: "prod",
		VITE_APP_URL: "*.app.konfhub.com",
		VITE_API_URL: "https://api.konfhub.com",
		VITE_APP_BUCKET_NAME: "kh-attendee-app-prod",
		VITE_CENTRAL_LOGIN_URL: "https://accounts.konfhub.com",
		VITE_AWS_PROJECT_REGION: "ap-south-1",
		VITE_AWS_COGNITO_IDENTITY_POOL_ID: "ap-south-1:0bf02d86-5b7b-4665-964d-cb52f756903b",
		VITE_AWS_COGNITO_REGION: "ap-south-1",
		VITE_AWS_USER_POOLS_ID: "ap-south-1_acJo3XGbL",
		VITE_OAUTH_DOMAIN: "auth.konfhub.com",
		VITE_AWS_USER_POOLS_WEB_CLIENT_ID: "5jf10rk2c0muftotp1iu2ucup0",
		VITE_AWS_APPSYNC_GRAPHQLENDPOINT: "https://attendee-api.konfhub.com/graphql",
		VITE_HOST_NAME: "konfhub.com",
		VITE_SENTRY_AUTH_TOKEN:
			"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",
		VITE_GRAPHQL_PUBLIC_API_KEY: "da2-5uz4kjc3z5gj7gspqffvtcwuyq"
	},
	custom: {
		VITE_ENV: "prod",
		VITE_APP_URL: "*.app.konfhub.com",
		VITE_API_URL: "https://api.konfhub.com",
		VITE_APP_BUCKET_NAME: "kh-attendee-app-prod",
		VITE_CENTRAL_LOGIN_URL: "https://accounts.konfhub.com",
		VITE_AWS_PROJECT_REGION: "ap-south-1",
		VITE_AWS_COGNITO_IDENTITY_POOL_ID: "ap-south-1:0bf02d86-5b7b-4665-964d-cb52f756903b",
		VITE_AWS_COGNITO_REGION: "ap-south-1",
		VITE_AWS_USER_POOLS_ID: "ap-south-1_acJo3XGbL",
		VITE_OAUTH_DOMAIN: "auth.konfhub.com",
		VITE_AWS_USER_POOLS_WEB_CLIENT_ID: "5jf10rk2c0muftotp1iu2ucup0",
		VITE_AWS_APPSYNC_GRAPHQLENDPOINT: "https://attendee-api.konfhub.com/graphql",
		VITE_HOST_NAME: "futuresustainabilityforum.com",
		VITE_SENTRY_AUTH_TOKEN:
			"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",
		VITE_GRAPHQL_PUBLIC_API_KEY: "da2-5uz4kjc3z5gj7gspqffvtcwuyq"
	}
};

const getEnvName = () => {
	if (typeof window !== "undefined") {
		const currentUrl = window?.location?.hostname || "localhost";
		if (
			currentUrl?.includes(".dev-app.konfhub.com") ||
			currentUrl?.includes("fsf.codeops.tech")
		) {
			return "dev";
		}
		if (currentUrl?.includes(".staging-app.konfhub.com")) {
			return "staging";
		}
		if (currentUrl?.includes(".app.konfhub.com")) {
			return "prod";
		}
		if (
			currentUrl?.includes("localhost") ||
			currentUrl?.includes("127.0.0.1") ||
			currentUrl?.includes("192.168.1.11")
		) {
			return "localhost";
		}
		return "custom";
	} else {
		return currentEnv;
	}
};

export const ENV_NAME = getEnvName();
export const isKHDomain = getEnvName() !== "custom";
