import React from "react";
import QRCode from "react-qr-code";
import { useEvent } from "../../contexts/EventContext";
import "./Ticket.css";

const TicketPreview = ({ ticketDetails }) => {
	const { eventDetails } = useEvent();

	const qrCodeFormat = `id:${ticketDetails.booking_id}|n:${ticketDetails.name}|eid:${eventDetails.event_id}`;

	return (
		<>
			<div className="conf-ticket col-md-4 my-2 mx-md-2 position-relative">
				<div className="col-11 pb-2 mx-auto d-flex border-bottom">
					<div className="col-12 d-flex flex-column pt-2 ps-md-3 ps-2 mt-sm-0 mt-2">
						<p className="conf-ticket-name text-center">{ticketDetails.ticket_name}</p>
					</div>
				</div>
				<div className="col-12 my-3 text-center">
					<QRCode value={qrCodeFormat} style={{ width: "100px", height: "100px" }} />
				</div>
			</div>
		</>
	);
};

export default TicketPreview;
