import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import "./SignupPage.css";
import { useUser } from "../../contexts/AuthContext";
import { setJwtToken } from "../../crud/axios";
import { toast } from "react-toastify";
import NotificationBox from "../Network/NotificationBox/NotificationBox";

const SignupPage: React.FC = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { setUser } = useUser();
	const [loading, setLoading] = useState(false);
	const [email, setEmail] = useState(location.state?.email || "");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [emailError, setEmailError] = useState("");
	const [passwordError, setPasswordError] = useState("");
	const [confirmPasswordError, setConfirmPasswordError] = useState("");
	const [passwordVisible, setPasswordVisible] = useState(false);
	const [otp, setOtp] = useState(Array(6).fill(""));
	const [timer, setTimer] = useState(30);
	const [verificationError, setVerificationError] = useState("");
	const [confirmPasswordVisible, setConfirmsetPasswordVisible] = useState(false);
	const [newPassword, setNewPassword] = useState("");
	const [confirmNewPassword, setConfirmNewPassword] = useState("");
	const [currentUser, setCurrentUser] = useState(null);

	const searchParams = new URLSearchParams(location.search);
	const requestOtpEmail: any = searchParams.get("email");
	const redirectUrl = searchParams.get("redirect") || "/";
	const signUp = searchParams.get("signUp") ? true : false;
	const signIn = searchParams.get("signIn") ? true : false;
	const [showTimer, setShowTimer] = useState(false);
	const [isResendDisabled, setIsResendDisabled] = useState(false);
	const [signUpError, setSignUpError] = useState("");
	const [notificationPopup, setNotificationPopup] = useState(false);
	const [toasterMessage, setToasterMessage] = useState("");

	// const isFormValid = email;

	//   const handleSignupPageWithOtp = async () => {
	//   try {
	//     const otpString = otp.join(''); // Convert array to string
	//     console.log(otpString, 'entered OTP');

	//     // Pass the OTP string as challengeResponse
	//     await confirmSignIn({ challengeResponse: otp });
	//     navigate('/');
	//   } catch (error) {
	//     console.error('Error during OTP confirmation:', error);
	//   }
	// };

	const handleSignupWithOtp = async () => {
		try {
			const result = await Auth.signUp({
				username: email,
				password,
				attributes: { email }
			});
			handleVerifyOtpLogin();
		} catch (error) {
			console.error("Error during sign-up:", error);
		}
	};

	// const handleVerifyOtp = async () => {

	//     try {
	//         const otpString = otp.join(''); // Convert OTP array to a string
	//         await confirmSignUp(email, otpString);
	//         console.log('OTP verified successfully. User sign-up complete.');
	//     } catch (error) {
	//         if (error.code === 'CodeMismatchException') {
	//             console.error('Incorrect OTP. Please try again.');
	//         } else if (error.code === 'ExpiredCodeException') {
	//             console.error('OTP expired. Please request a new one.');
	//         } else {
	//             console.error('Error verifying OTP:', error);
	//         }
	//     }
	// };
	useEffect(() => {
		if (notificationPopup) {
			const timer = setTimeout(() => {
				setNotificationPopup(false);
			}, 3000);

			return () => clearTimeout(timer);
		}
	}, [notificationPopup]);

	const handleVerifyOtpSignUp = async () => {
		if (!requestOtpEmail) {
			navigate(`/signup-account?redirect=${redirectUrl}&email=${requestOtpEmail}`);
			setToasterMessage("Please enter a valid email");
			setNotificationPopup(true);
			return;
		}

		try {
			const cognitoUser = await Auth.confirmSignUp(
				requestOtpEmail.toLowerCase(),
				otp.join("")
			);
			if (cognitoUser) {
				setToasterMessage(
					"Account verification successful. You can login to your account now"
				);
				setNotificationPopup(true);
				setTimeout(() => {
					navigate(`/login-account?redirect=${redirectUrl}&email=${requestOtpEmail}`, {
						replace: true
					});
				}, 2000);
			}
		} catch (err: any) {
			setToasterMessage(err?.message || "Something went wrong");
			setNotificationPopup(true);
			console.error("Error confirming OTP:", err);
		}
	};

	const handleVerifyOtpLogin = async () => {
		if (!requestOtpEmail) {
			navigate(`/login-account?redirect=${redirectUrl}&email=${requestOtpEmail}`);
			setToasterMessage("Please enter a valid email");
			setNotificationPopup(true);
			return;
		}
		setLoading(true);
		try {
			const confirmedUser = await Auth.sendCustomChallengeAnswer(currentUser, otp.join(""));
			const sessionResponse = await Auth.currentSession();
			if (confirmedUser && sessionResponse) {
				setUser((user: any) => ({
					...user,
					session: sessionResponse,
					user: confirmedUser
				}));
				setJwtToken(sessionResponse?.getIdToken()?.getJwtToken());
				setLoading(false);
				window.location.href = redirectUrl;
				// navigate(redirectUrl,{ replace: true }); // Redirect to the desired page
			}
		} catch (err: any) {
			setToasterMessage(err?.message || "Something went wrong");
			setNotificationPopup(true);
			setLoading(false);
			console.error("Error confirming OTP:", err);
		}
	};

	// const handleTermsandCondition = () => {
	//     navigate('/terms-condition');
	// };

	const validateEmail = (email: string) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	};

	const togglePasswordVisibility = () => {
		setPasswordVisible(!passwordVisible);
	};
	const togglePasswordVisibilityconfirm = () => {
		setConfirmsetPasswordVisible(!confirmpasswordVisible);
	};

	const validatePassword = (password: string) => {
		const strongPasswordRegex =
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&!,.<>':;|_~=`+\-/=]).{6,}$/;
		return strongPasswordRegex.test(password);
	};

	const isFormValid =
		email &&
		password &&
		confirmPassword &&
		password === confirmPassword &&
		!emailError &&
		!passwordError &&
		!confirmPasswordError;

	// const handleSignupWithOtp = async () => {
	//     if (isFormValid) {
	//         try {
	//             const { isSignUpComplete, userId, nextStep } = await signUp({
	//                 username: email,
	//                 password,
	//                 options: {
	//                     autoSignIn: true
	//                 }
	//             });

	//             // if (nextStep.signUpStep === "CONFIRM_SIGN_UP") {
	//             //     navigate("/verify-otp", { state: { email, password } });
	//             // } else {
	//             //     console.error("Unexpected sign-up step:", nextStep.signUpStep);
	//             //     setSignUpError("An unexpected error occurred. Please try again.");
	//             // }
	//         } catch (error) {
	//             console.error("Error initiating sign up:", error);
	//             setSignUpError("An error occurred during sign up initiation. Please try again.");
	//         }
	//     }
	// };

	const handleSignupsWithOtp = () => {
		navigate("/signup-page");
	};
	const userResult = async () => {
		try {
			const userDetails = await Auth.signIn(requestOtpEmail || ""); // No password required for OTP
			setCurrentUser(userDetails);
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		userResult();
	}, [requestOtpEmail]);

	const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
		const paste = e.clipboardData.getData("text");
		if (/^\d{6}$/.test(paste)) {
			const newOtp = paste.split("");
			setOtp(newOtp);

			// Focus the last input field after pasting
			const lastInput = document.getElementById(`otp-input-5`);
			if (lastInput) lastInput.focus();
		}
		e.preventDefault();
	};

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
		if (e.key === "Backspace" && !otp[index] && index > 0) {
			const newOtp = [...otp];
			newOtp[index - 1] = "";
			setOtp(newOtp);
			const prevInput = document.getElementById(`otp-input-${index - 1}`);
			if (prevInput) prevInput.focus();
		}
	};

	// const handleResendOtp = () => {
	// 	// TODO: Implement resend OTP functionality with AWS Amplify
	// 	setOtp(Array(6).fill(""));
	// 	setTimer(30);
	// 	setVerificationError("");
	// };

	const isOtpValid = otp.every(num => num !== "");

	const handleFocus = (index: number) => {
		const input = document.getElementById(`otp-input-${index}`);
		input?.focus();
	};
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
		const value = e.target.value;

		// Only allow numeric values
		if (!/^\d*$/.test(value)) return;

		const newOtp = [...otp];
		newOtp[index] = value;
		setOtp(newOtp);

		// Focus the next input box after the current one
		if (value && index < otp.length - 1) {
			setTimeout(() => {
				const nextInput = document.getElementById(`otp-input-${index + 1}`);
				if (nextInput) nextInput.focus();
			}, 50); // Small delay to ensure the state is updated
		}
	};

	// const handleResetPassword = async () => {
	//     if (!newPassword) {
	//         setResetPasswordError("New password is required.");
	//         return;
	//     }

	//     try {
	//         await confirmResetPassword({
	//             username: email,
	//             newPassword: newPassword,
	//             confirmationCode: otp.join('')
	//         });
	//         // Handle successful password reset
	//         navigate("/login-account");
	//     } catch (error) {
	//         navigate("/login-account");
	//         console.error("Error resetting password:", error);
	//         setResetPasswordError("An error occurred. Please try again.");
	//     }
	// };
	const handleResendSignUpOtp = async () => {
		setOtp(Array(6).fill(""));
		setTimer(30);
		setIsResendDisabled(true);
		setShowTimer(true);
		try {
			if (requestOtpEmail) {
				await await Auth.resendSignUp(requestOtpEmail?.toLowerCase() || "");
			} else {
				setVerificationError("Invalid email. Please try again.");
			}
		} catch (error) {
			console.error("Error during OTP resend:", error);
			setSignUpError("Failed to resend OTP. Please try again.");
		}
		setVerificationError("");
	};
	const handleResendSignInOtp = async () => {
		setOtp(Array(6).fill(""));
		setTimer(30);
		setIsResendDisabled(true);
		setShowTimer(true);
		try {
			if (requestOtpEmail) {
				await Auth.signIn(requestOtpEmail || "");
			} else {
				setVerificationError("Invalid email. Please try again.");
			}
		} catch (error) {
			console.error("Error during OTP resend:", error);
			setSignUpError("Failed to resend OTP. Please try again.");
		}
		setVerificationError("");
	};
	useEffect(() => {
		if (timer > 0) {
			const interval = setInterval(() => {
				setTimer(prevTimer => prevTimer - 1);
			}, 1000);

			return () => clearInterval(interval); // Cleanup interval on unmount
		} else {
			setShowTimer(false); // Hide the timer when it reaches 0
		}
	}, [timer]);
	return (
		<div className="singup_page_container">
			{notificationPopup && (
				<div className="notification-wrapper">
					<NotificationBox message={toasterMessage} />
				</div>
			)}
			<div className="phone-view d-flex d-md-none w-100 h-100 flex-column justify-content-center flex-grow-1 forgot-ph-cont">
				<div className="signup-form">
					{/* <div>
                                    {" "}
                                    <img src="assets/Logo.svg" alt="Center Logo" className="logo-desktop" />
                                </div> */}
					{/* otp section */}
					<div
						onClick={() => navigate("/create-account")}
						className="d-flex gap-2 text-black cursor-pointer"
					>
						<img
							src="assets/back-btn.svg"
							alt="Center Logo"
							className="logo-mr-0 ml-auto d-flex"
						/>{" "}
						<span>Back</span>
					</div>

					<div className="verify-otp-containers">
						<div className="otp_sent_info">
							Please enter the OTP we have sent you on {requestOtpEmail}
						</div>
						<div className="mb-2 text-black text-start">OTP</div>

						<div className="otp-inputssr">
							{otp.map((value, index) => (
								<React.Fragment key={index}>
									<input
										id={`otp-input-${index}`}
										type="tel"
										autoFocus={index === 0}
										maxLength={1}
										value={value}
										onChange={e => handleChange(e, index)}
										onKeyDown={e => handleKeyDown(e, index)}
										onPaste={handlePaste}
										inputMode="numeric"
										onClick={() => handleFocus(index)}
									/>
									{(index + 1) % 3 === 0 && index < otp.length - 1 && (
										<span className="hyphen"></span>
									)}
								</React.Fragment>
							))}
						</div>
						{verificationError && (
							<div className="error-message">{verificationError}</div>
						)}

						<div className="receive-timer-container d-flex align-items-center">
							<div className="receive text-nowrap">
								Didn't receive OTP?
								{signUp && (
									<button
										style={{ border: "0", backgroundColor: "transparent" }}
										className="resend-text"
										disabled={timer > 0}
										onClick={handleResendSignUpOtp}
									>
										Resend OTP{" "}
									</button>
								)}
								{signIn && (
									<button
										style={{ border: "0", backgroundColor: "transparent" }}
										className="resend-text"
										disabled={timer > 0}
										onClick={handleResendSignInOtp}
									>
										Resend OTP{" "}
									</button>
								)}
							</div>
							{showTimer && (
								<div className="timer-text text-nowrap ">
									in{" "}
									{timer > 0 ? `00:${timer < 10 ? `0${timer}` : timer}` : "00:00"}{" "}
									seconds
								</div>
							)}
						</div>
					</div>

					<div className="sign_btn">
						<button
							className={`proceed-button ${isOtpValid && newPassword === confirmNewPassword ? "enabled" : "disabled"}`}
							disabled={!isOtpValid || newPassword !== confirmNewPassword}
							onClick={signUp ? handleVerifyOtpSignUp : handleVerifyOtpLogin}
						>
							{signUp ? "Sign Up" : "Sign In"}{" "}
							{loading && (
								<div
									className=" ms-2 spinner-border text-white spinner-border-sm"
									role="status"
								>
									<span className="visually-hidden">Loading...</span>
								</div>
							)}
						</button>
					</div>
				</div>
			</div>

			<div className="desktop-view">
				<div className="main-container">
					<div className="right-panel m-auto">
						<div className="signup-otp-panel-desktop">
							<div className="signup-form">
								{/* <div>
                                    {" "}
                                    <img src="assets/Logo.svg" alt="Center Logo" className="logo-desktop" />
                                </div> */}
								{/* otp section */}
								<div
									onClick={() => navigate("/create-account")}
									className="d-flex gap-2 text-black cursor-pointer"
								>
									<img
										src="assets/back-btn.svg"
										alt="Center Logo"
										className="logo-mr-0 ml-auto d-flex"
									/>{" "}
									<span>Back</span>
								</div>

								<div className="verify-otp-containers">
									<div className="otp_sent_info">
										Please enter the OTP we have sent you on {requestOtpEmail}
									</div>
									<div className="mb-2 text-black text-start">OTP</div>

									<div className="otp-inputssr">
										{otp.map((value, index) => (
											<React.Fragment key={index}>
												<input
													id={`otp-input-${index}`}
													type="tel"
													autoFocus={index === 0}
													maxLength={1}
													value={value}
													onChange={e => handleChange(e, index)}
													onKeyDown={e => handleKeyDown(e, index)}
													onPaste={handlePaste}
													inputMode="numeric"
													onClick={() => handleFocus(index)}
												/>
												{(index + 1) % 3 === 0 &&
													index < otp.length - 1 && (
														<span className="hyphen"></span>
													)}
											</React.Fragment>
										))}
									</div>
									{verificationError && (
										<div className="error-message">{verificationError}</div>
									)}

									<div className="receive-timer-container d-flex align-items-center ">
										<div className="receive text-nowrap">
											Didn't receive OTP?
											{signUp && (
												<button
													style={{
														border: "0",
														backgroundColor: "transparent"
													}}
													className="resend-text"
													disabled={timer > 0}
													onClick={handleResendSignUpOtp}
												>
													Resend OTP{" "}
												</button>
											)}
											{signIn && (
												<button
													style={{
														border: "0",
														backgroundColor: "transparent"
													}}
													className="resend-text"
													disabled={timer > 0}
													onClick={handleResendSignInOtp}
												>
													Resend OTP{" "}
												</button>
											)}
										</div>
										{showTimer && (
											<div className="timer-text text-nowrap ">
												in{" "}
												{timer > 0
													? `00:${timer < 10 ? `0${timer}` : timer}`
													: "00:00"}{" "}
												seconds
											</div>
										)}
									</div>
								</div>

								<div className="sign_btn">
									<button
										className={`proceed-button ${isOtpValid && newPassword === confirmNewPassword ? "enabled" : "disabled"}`}
										disabled={!isOtpValid || newPassword !== confirmNewPassword}
										onClick={
											signUp ? handleVerifyOtpSignUp : handleVerifyOtpLogin
										}
									>
										{signUp ? "Sign Up" : "Sign In"}{" "}
										{loading && (
											<div
												className=" ms-2 spinner-border text-white spinner-border-sm"
												role="status"
											>
												<span className="visually-hidden">Loading...</span>
											</div>
										)}
									</button>
								</div>
							</div>
						</div>
					</div>
					<div
						className="left-panel auth-screen-container"
						style={{ backgroundImage: `url('/assets/layesImage.svg')` }}
					>
						<div className="leftpanel-tag-container">
							{/* <img src="assets/StarforLeft.svg" alt="this is star img" />
                            <p className="tagline-for-leftpanel">
                                Trusted by over 10,000 event organizers worldwide.
                            </p> */}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SignupPage;
