import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Bell } from 'lucide-react';
import './InterestsPage.css';

interface Interest {
  name: string;
  selected: boolean;
}

function InterestsPage() {
  const [interests, setInterests] = useState<Interest[]>([
    { name: 'Media Partner', selected: true },
    { name: 'Cloud Provider', selected: true },
    { name: 'Media', selected: false },
    { name: 'Cloud', selected: true },
    { name: 'AI', selected: true },
    { name: 'JS', selected: false },
    { name: 'Tech Leader', selected: true },
    { name: 'PAAS', selected: true },
    { name: 'ML', selected: false },
    { name: 'Technologies', selected: false },
    { name: 'Business', selected: false },
    { name: 'Azure', selected: false },
    { name: 'Python', selected: false },
    { name: 'Tech Leader', selected: true },
    { name: 'JS', selected: false },
    { name: 'SAAS', selected: false },
    { name: 'Business', selected: false },
    { name: 'Azure', selected: false },
    { name: 'Python', selected: false },
    { name: 'AI', selected: true },
    { name: 'JS', selected: false },
    { name: 'SAAS', selected: false },
    { name: 'Business', selected: false },
    { name: 'Azure', selected: false },
    { name: 'Python', selected: false },
    { name: 'AI', selected: true },
    { name: 'JS', selected: false },
    { name: 'SAAS', selected: false },
    { name: 'Cloud', selected: true },




    { name: 'SAAS', selected: false },
    { name: 'Business', selected: false },
    { name: 'Azure', selected: false },
    { name: 'Python', selected: false },
    { name: 'AI', selected: true },
    { name: 'JS', selected: false },
    { name: 'SAAS', selected: false },
    { name: 'Business', selected: false },
    { name: 'Azure', selected: false },
    { name: 'Python', selected: false },
    { name: 'AI', selected: true },
    { name: 'JS', selected: false },
    { name: 'SAAS', selected: false },
    { name: 'Cloud', selected: true },
  ]);
  const navigate = useNavigate();
  const handleSaveChanges = () => {
    navigate('/home-screen');
  };


  // const isFormValid = ;
  const [searchTerm, setSearchTerm] = useState('');

  const toggleInterest = (index: number) => {
    const newInterests = [...interests];
    newInterests[index].selected = !newInterests[index].selected;
    setInterests(newInterests);
  };

  const filteredInterests = interests.filter(interest => interest.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <div className='phone-view'>
        <div className="interests-page font">
          <div className="header-interest-page">
            <div>  <img src="assets/logoo.svg" alt="Center Logo" /></div>
            <Bell className="icon interest_bell right-icon1" />
            <div>  <img className='interest_users' src="assets/user.svg" alt="Center Logo" /></div>
          </div>
          <h1 className='rest'>Your Interests</h1>
          {/* search bar */}
          <div className="search-bars no-margin">
            <div className="search-input-wrapper">
              <img className="search-icon" src="assets/search-1.svg" alt="" />
              <input
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="search-input"
              />
            </div>
            <div className="interest_filter_btns">
              <img
                src="assets/filter.svg"
                alt="Center Logo"
                className="filter_image"
              />
            </div>
          </div>

          <div className="interests-container">
            {filteredInterests.map((interest, index) => (
              <button
                key={index}
                className={`interest-button ${interest.selected ? 'selected' : ''}`}
                onClick={() => toggleInterest(index)}
              >
                {interest.name}
                {/* Conditionally render the plus icon for unselected interests */}
                {!interest.selected && (
                  <span >
                    <img className="plus-icon" src="assets/plusadd.svg" alt="Add" />
                  </span>
                )}
                {interest.selected && <span className="remove-icon">×</span>}
              </button>
            ))}
          </div>
          <button className="save-button" onClick={handleSaveChanges}>Save Changes</button>


        </div>
      </div>



      {/* second section*/}
      <div className='desktop-view'>
        <div className="interests-page-desktop font">
          <div className="header-interest-page">
            <div>  <img src="assets/logoo.svg" alt="Center Logo" /></div>
          </div>
           <div className='skip-button-div'>
          <button className="skip-image-desktop">Skip</button>
          </div>
          {/* search bar */}
          <div className='desktop-interest-container'>
          <div className='desktop-skip'>
            <h1 className='rest'>Your Interests</h1>

            <div className="search-bars-desktop ">
              <div className="search-input-wrapper">
                <img className="search-icon" src="assets/search-1.svg" alt="" />
                <input
                  type="text"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="search-input"
                />
              </div>
              <div className="interest_filter_btns">
                <img
                  src="assets/filter.svg"
                  alt="Center Logo"
                  className="filter_image"
                />
              </div>
            </div>
          </div>

          <div className="interests-container">
            {filteredInterests.map((interest, index) => (
              <button
                key={index}
                className={`interest-button ${interest.selected ? 'selected' : ''}`}
                onClick={() => toggleInterest(index)}
              >
                {interest.name}
                {/* Conditionally render the plus icon for unselected interests */}
                {!interest.selected && (
                  <span >
                    <img className="plus-icon" src="assets/plusadd.svg" alt="Add" />
                  </span>
                )}
                {interest.selected && <span className="remove-icon">×</span>}
              </button>
            ))}
          </div>
          <div className='savedesk'>
          <button className="save-button-desktop" onClick={handleSaveChanges}>Save Changes</button>

          </div>
          </div>

        </div>
      </div>
    </>
  );
}

export default InterestsPage;