import axios from "./axios";
import { apiUrl, eventBase, publicBase } from "../config/crud";

const API_URL = apiUrl;
const EVENT = eventBase;
const PUBLIC = publicBase;
const FILTERS = "filters";

// Get list of filters
export const getFilterDetails = (eventId: string, moduleNo: number) => {
	const QUERY = moduleNo ? "?module=" + moduleNo : "";
	return axios.get(`${API_URL}/${EVENT}/${eventId}/${PUBLIC}/${FILTERS}${QUERY}`);
};
