import axios from "./axios";
import { apiBase, apiUrl, eventBase } from "./../config/crud";

const API_URL = apiUrl;
const API_BASE = apiBase;
const EVENT = eventBase;
const MEETING = "meeting";

// Get list of speakers
export const getListOfMeetings = (eventId: string, status: number, date: string) => {
	const QUERY = `?status=${status}${date ? `&date=${date}` : ""}`;
	return axios.get(`${API_URL}/${API_BASE}/${EVENT}/${eventId}/${MEETING}${QUERY}`);
};

// Upload file
export const UploadFile = (file: any) => {
	return axios.post(`${API_URL}/upload-file`, file);
};

// Get list of attendees
export const getListOfAttendees = ({ eventId, limit, offset, searchTerm }: any) => {
	return axios.get(
		`${API_URL}/${API_BASE}/${EVENT}/${eventId}/att-app/attendees${limit ? `?limit=${limit}` : ""}${offset ? `&offset=${offset}` : ""}${searchTerm ? `&search_param=${searchTerm}` : ""}`
	);
};

// Create a meeting
export const createMeeting = (eventId: string, body: any) => {
	return axios.post(`${API_URL}/${API_BASE}/${EVENT}/${eventId}/meeting`, body);
};

// Edit a meeting
export const editMeeting = (eventId: string, meetingId: string, body: any) => {
	return axios.put(`${API_URL}/${API_BASE}/${EVENT}/${eventId}/meeting/${meetingId}`, body);
};

// Update the status of meeting attendee
export const updateMeetingAttendeeStatus = (eventId: string, meetingId: string, status: number) => {
	return axios.put(`${API_URL}/${API_BASE}/${EVENT}/${eventId}/meeting/${meetingId}/status?status=${status}`);
};

// Delete a meeting
export const deleteMeeting = (eventId: string, meetingId: string) => {
	return axios.delete(`${API_URL}/${API_BASE}/${EVENT}/${eventId}/meeting/${meetingId}`);
};