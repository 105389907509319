import React, { useState, useEffect } from 'react';
import { Drawer, TextField, Button, Typography, SwipeableDrawer } from '@mui/material';
import './EditSocialMedia.css';

interface EditSocialMediaDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  platform: string;
  initialUrl: string;
  onSave: (url: string) => void;
}

const EditSocialMediaDrawer: React.FC<EditSocialMediaDrawerProps> = ({
  isOpen,
  onClose,
  onOpen,
  platform,
  initialUrl,
  onSave,
}) => {
  const [url, setUrl] = useState(initialUrl);
  const [isUrlChanged, setIsUrlChanged] = useState(false);

  useEffect(() => {
    setUrl(initialUrl);
    setIsUrlChanged(false);
  }, [initialUrl]);

  const handleUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(event.target.value);
    setIsUrlChanged(true);
  };

  const handleSave = () => {
    onSave(url);
    onClose();
  };

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      classes={{ paper: 'edit-drawer' }}
    >
      <div className="edit-drawer-content">
        <Typography variant="h6" className="edit-drawer-title">
          Edit {platform}
        </Typography>
        <Typography variant="body1" className="edit-drawer-subtitle">
          URL 
        </Typography>
        <TextField
          fullWidth
          value={url}
          onChange={handleUrlChange}
          className="edit-drawer-input"
        />
        <div className="edit-drawer-actions">
          <Button onClick={onClose} className="edit-drawer-button cancel">
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            className={`edit-drawer-button save ${isUrlChanged ? 'active' : ''}`} sx={{color: "white"}}
            // disabled={!isUrlChanged} 
          >
            Save
          </Button>
        </div>
      </div>
    </SwipeableDrawer>
  );
};

export default EditSocialMediaDrawer;