import React, { useEffect, useRef, useState } from "react";
import "./MobileChatInterface.css";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import chatApiService from "../../crud/chat.crud";
import { useEvent } from "../../contexts/EventContext";
import { Message } from "./Message";
import { useUser } from "../../contexts/AuthContext";
import { Icon } from "@iconify/react/dist/iconify.js";
import moment from "moment";
import { useMessageContext } from "../../contexts/MessageContext";
import InfiniteScroll from "react-infinite-scroller";
import { ClipLoader } from "react-spinners";
import _ from "lodash";
import { profileImage } from "../../reusable/images";

interface MessageProps {
	username: string;
	subtitle?: string;
	image?: string;
	eventTitle?: string;
	eventLink?: string;
	eventTime?: string;
	joinTime?: string;
	userResponse?: string;
	venueRequest?: string;
}

const ChatRoom: React.FC<MessageProps> = () => {
	const [params, setParams] = useState(useParams());
	const { messages: contextMessages, setMessages: contextSetMessages } = useMessageContext();
	const [queryParams] = useSearchParams();
	const [isNewChat, setIsNewChat] = useState(queryParams.get("isNew") === "true");
	const navigate = useNavigate();
	const { eventDetails } = useEvent();
	const { currentUserAttendeeId } = useUser();
	const fpDetails = JSON.parse(localStorage.getItem("fpDetails") ?? "{}");
	const [messages, setMessages] = useState([]);
	const [inputValue, setInputValue] = useState("");
	const [hasMore, setHasMore] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [initialLoadDone, setInitialLoadDone] = useState(false);
	const [showScrollToBottom, setShowScrollToBottom] = useState(false);
	const [scrollToAppropriateWindow, setScrollToAppropriateWindow] = useState(0);
	const inputRef = useRef<HTMLInputElement | null>(null);
	const scrollParentRef = useRef(null);

	const getMessages = async (limit = 50, offset = 0) => {
		if (isLoading || !hasMore) return;

		setIsLoading(true);
		setInitialLoadDone(true);

		if (!isNewChat) {
			const response = await chatApiService.listMessages({
				eventId: eventDetails?.event_id,
				roomId: params?.roomId,
				limit,
				offset: offset * limit
			});

			if (response.length < limit) {
				setHasMore(false);
			}

			if (initialLoadDone) {
				setScrollToAppropriateWindow([...messages, ...response]?.length);
				setMessages(prevMessages => [...prevMessages, ...response]);
			} else {
				setMessages(response);
			}

			setIsLoading(false);
			return response?.length < limit;
		}
	};

	const prependToMessages = (receivedData: any) => {
		setMessages([...receivedData, ...messages]);
		chatApiService.updateMessage(eventDetails?.event_id, params?.roomId);
	};

	useEffect(() => {
		if (!Object.keys(fpDetails)?.length) {
			navigate("/chats");
		}
		if (fpDetails?.unreadCount) {
			chatApiService.updateMessage(eventDetails?.event_id, params?.roomId);
		}
	}, []);

	useEffect(() => {
		if (
			currentUserAttendeeId &&
			eventDetails?.event_id &&
			params?.roomId &&
			!isNewChat &&
			!initialLoadDone
		) {
			(async () => {
				const noMoreItems = await getMessages();
				if (noMoreItems) {
					setHasMore(false);
				}
			})();
		}
	}, [currentUserAttendeeId, eventDetails?.event_id, params?.roomId, isNewChat, initialLoadDone]);

	const scrollToBottom = () => {
		inputRef.current?.focus();
		if (scrollParentRef.current) {
			setTimeout(() => {
				const scrollHeight = scrollParentRef.current.scrollHeight ?? 0;
				const clientHeight = scrollParentRef.current.clientHeight ?? 0;
				scrollParentRef.current.scrollTop = scrollHeight - clientHeight;
			}, 0);
		}
		setShowScrollToBottom(false);
	};

	useEffect(() => {
		if (initialLoadDone && messages.length > 50) {
			const difference =
				scrollParentRef.current?.scrollHeight - scrollParentRef?.current?.clientHeight;
			if (scrollParentRef.current) {
				scrollParentRef.current.scrollTop = scrollParentRef.current?.scrollHeight / 3;
			}
		}
	}, [scrollToAppropriateWindow]);

	useEffect(() => {
		const currentContextMessages = contextMessages;
		if (currentContextMessages?.length) {
			const chatRoomIds = [...new Set(currentContextMessages?.map(c => c?.roomId))];
			if (chatRoomIds?.includes(params?.roomId)) {
				prependToMessages(
					currentContextMessages?.filter(message => message?.roomId === params?.roomId)
				);
				contextSetMessages(prevMessages =>
					prevMessages.filter(msg => msg.roomId !== params?.roomId)
				);
				setShowScrollToBottom(true);
			}
		}
	}, [contextMessages]);

	const handleSendMessage = async () => {
		if (!inputValue) return;
		inputRef.current?.focus();
		try {
			setInputValue("");
			setMessages([
				{ sender_id: currentUserAttendeeId, message: inputValue, sent_at: new Date() },
				...messages
			]);
			const response = await chatApiService.sendMessage({
				eventId: eventDetails?.event_id,
				receiverId: fpDetails?.attendeeId,
				receiverName: fpDetails?.name,
				message: inputValue,
				roomId: !isNewChat ? params?.roomId : null,
				receiverImageUrl: fpDetails?.profileImage
			});
			scrollToBottom();
			if (isNewChat) {
				setIsNewChat(false);
				window.history?.replaceState(null, "", `/chats/${response?.roomId}`);
				setParams({ roomId: response?.roomId });
			}
		} catch (e) {
			console.log(e);
		}
	};

	const handleInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
		setInputValue(e.target.value);
	};

	const debouncedLoadMore = _.debounce(async page => {
		const scrollPosition = scrollParentRef?.current?.scrollTop ?? 0;

		if (scrollPosition > 50) {
			return;
		}
		const noMoreItems = await getMessages(50, page);
		if (noMoreItems) setHasMore(false);
	}, 500);

	useEffect(() => {
		if (initialLoadDone) {
			debouncedLoadMore(0);
		}
	}, [initialLoadDone]);

	useEffect(() => {
		if (initialLoadDone && messages.length > 0 && messages.length < 51) {
			const scrollHeight = scrollParentRef?.current?.scrollHeight ?? 0;
			const clientHeight = scrollParentRef?.current?.clientHeight ?? 0;
			scrollParentRef.current.scrollTop = scrollHeight - clientHeight;
		}
	}, [messages]);

	const isAtTop = () => {
		if (!scrollParentRef.current) return false;
		return scrollParentRef.current.scrollTop <= 0;
	};

	useEffect(() => {
		const handleScroll = () => {
			if (!scrollParentRef.current || isLoading) return;

			const scrollPosition = scrollParentRef.current.scrollTop;
			if (
				scrollParentRef?.current?.scrollHeight - scrollParentRef?.current?.clientHeight ===
				scrollPosition
			) {
				setShowScrollToBottom(false);
			}

			// Check if the user is at the very top
			if (isAtTop()) {
				debouncedLoadMore(Math.ceil(messages.length / 50));
			}
		};

		const scrollElement = scrollParentRef.current;
		if (scrollElement) {
			scrollElement.addEventListener("scroll", handleScroll);
		}

		return () => {
			if (scrollElement) {
				scrollElement.removeEventListener("scroll", handleScroll);
			}
		};
	}, [messages, isLoading]);

	// useEffect(() => {
	//     if (initialLoadDone && !isLoading) {
	//         const currentScrollHeight = scrollParentRef.current.scrollHeight;
	//         setTimeout(() => {
	//             scrollParentRef.current.scrollTop = currentScrollHeight;
	//         }, 0);
	//     }
	// }, [messages]);

	const groupMessagesByDate = (messages: any[]) => {
		return messages?.reduce((groupedMessages: any, message: any) => {
			const messageDate = moment.utc(message?.sent_at).local().format("YYYY-MM-DD");
			if (!groupedMessages[messageDate]) {
				groupedMessages[messageDate] = [];
			}
			groupedMessages[messageDate].push(message);
			return groupedMessages;
		}, {});
	};

	const groupedMessages = groupMessagesByDate(messages);

	return (
		<div className="messages-wrapper">
			<div className="phone-header">
				<div className="phone-header-left">
					<Icon
						icon={`material-symbols-light:arrow-back-ios-rounded`}
						width={25}
						height={25}
						onClick={() => navigate(-1)}
						className="primary-color-icons chat-window-back-button"
					/>
					<div className="phone-avatar">
						{profileImage(fpDetails.profileImage, fpDetails?.name, 40, {
							width: "40px",
							height: "40px"
						})}
					</div>
					<div className="phone-user-info">
						<div className="phone-username">{fpDetails?.name}</div>
						<div className="phone-subtitle">{fpDetails?.designation}</div>
					</div>
				</div>
			</div>
			<div className="all-messages-wrapper" ref={scrollParentRef}>
				{/* {messages?.length}
                {JSON.stringify(initialLoadDone)} */}
				{(initialLoadDone || !!messages?.length) && (
					<InfiniteScroll
						pageStart={0}
						hasMore={hasMore && !isLoading}
						useWindow={false}
						isReverse={true}
					>
						{isLoading && messages?.length !== 1 && (
							<div
								style={{
									width: "100vw",
									textAlign: "center",
									padding: "10px",
									marginTop: "30px",
									height: "50px"
								}}
							>
								<ClipLoader loading={true} size={30} />
							</div>
						)}
						{Object.keys(groupedMessages)
							?.reverse()
							?.map(date => (
								<div key={date} className="message-group">
									<div className="date-heading">
										{moment.utc(date).local().format("MMMM Do, YYYY")}
									</div>
									{groupedMessages[date].reverse().map(message => (
										<Message
											key={message?.id}
											message={message?.message}
											isSent={message?.sender_id === currentUserAttendeeId}
											dateTime={message?.sent_at}
										/>
									))}
								</div>
							))}
					</InfiniteScroll>
				)}
			</div>
			<div className="input-container-phone">
				<textarea
					value={inputValue}
					onChange={handleInputValue}
					onKeyDown={e => {
						if (e.key === "Enter" && !e.shiftKey) {
							e.preventDefault();
							if (inputValue.trim() !== "") {
								handleSendMessage();
							}
						}
					}}
					className="response-input-phone"
					placeholder="Type your message..."
					ref={inputRef}
				/>

				<div className="action-buttons_chat">
					<div className="send-icon-button" onClick={handleSendMessage}>
						<Icon
							icon="material-symbols-light:send-outline"
							width={25}
							height={25}
							className="primary-color-icons"
						/>
					</div>
				</div>
				{showScrollToBottom && (
					<div className="scroll-to-bottom" onClick={() => scrollToBottom()}>
						New messages
						<Icon
							icon="material-symbols-light:expand-circle-down-outline"
							height={24}
							width={24}
							style={{ marginLeft: "5px" }}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default ChatRoom;
