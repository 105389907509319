import _ from "lodash";
import moment from "moment";
import { getISTDateNTime } from "../../reusable/common";

export const validateMeetingData = ({ values, eventDetails, formik }: any) => {
	let errors: any = {};

	// Convert event start and end into "midnight-to-midnight" range
	const eventStartDate = moment(eventDetails?.start_date + "T00:00:00");
	const eventEndDate = moment(eventDetails?.end_date + "T23:59:59");

	// Title validation
	const regex = new RegExp(
		"^[-:()\\[\\]|,`~#/<>.&@+'\\w\\s!$%^*={}\\[\\];?\\\\]+$"
	);

	if (_.isEmpty(values?.title)) {
		errors.title = "Please fill up the title";
	} else if (!regex.test(values?.title)) {
		errors.title = "Title contains invalid characters.";
	} else if (values?.title.length > 200) {
		errors.title = "Title cannot exceed 200 characters.";
	}

	// Description validation
	if (!_.isEmpty(values?.description)) {
		if (!regex.test(values?.description)) {
			errors.description = "Description contains invalid characters.";
		} else if (values?.description.length > 2000) {
			errors.description = "Description cannot exceed 2000 characters.";
		}
	}

	// Start and End time validation
	if (moment(values.start_timestamp).isSameOrAfter(moment(values.end_timestamp))) {
		errors.start_timestamp = "Please enter Start date and time before End date and time";
	} else if (moment(values.end_timestamp).isSameOrBefore(moment(values.start_timestamp))) {	
		errors.end_timestamp = "Please enter End date and time greater than Start date and time";
	}

	// Start timestamp within event date range
	else if (!moment(values.start_timestamp).isBetween(eventStartDate, eventEndDate, null, "[]")) {
		errors.start_timestamp = "Please enter Start date and time on or after the Event's start date";
	}

	// End timestamp within event date range
	else if (!moment(values.end_timestamp).isBetween(eventStartDate, eventEndDate, null, "[]")) {
		errors.end_timestamp = "Please enter End date and time on or before the Event's end date";
	}

	// Attendees validation
	if (values.attendees.length === 0) {
		errors.attendees = "At least one attendee is required to create a meeting.";
	}

	return errors;
};
