import React from 'react';
import { useState } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import './SearchPage.css';
import Footer from '../Footer/Footer';
import { Bell } from "lucide-react";
import SideNavbar from '../SideNavbar/SideNavbar';
import { Header } from '../Header/Header';

const SearchPage: React.FC = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const goBack = () => {
    navigate(-1);
  };


  const handleLoginClick = () => {
    navigate('/login-account');
  };


  return (
    <>
      <div className='phone-view'>
        <div className='search-page-container'>
          <div>
            <div className='en'>

              <div><img className='left_uparrow search_left_arrow' src="/assets/uparrow.svg" alt="Go Back" onClick={goBack} /> </div>
              <div><span className='un'>ELYSIAN - 2024</span></div></div>

            <div className="search-bars no-margin">
              <div className="search-input-wrapper">
                {/* <i className="fa fa-search search-icon"></i> */}
                <img className="search-icon" src="assets/search-1.svg" alt="" />
                <input
                  type="text"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="search-input"
                />
              </div>
              <div className="interest_filter_btns">
                <img
                  src="assets/filter.svg"
                  alt="Center Logo"
                  className="filter_image"
                />
              </div>
            </div>
            <br />
            {/* <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br /> */}

            <Footer />


          </div>
        </div>
      </div>

      <div className='desktop-view'>
        <div className="menu-desktop">
          <div>
            <SideNavbar />
          </div>
          <div className='search-page-container-desktop'>
            <Header />
            <div>

              <div className="search-bars no-margin">
                <div className="search-input-wrapper">
                  {/* <i className="fa fa-search search-icon"></i> */}
                  <img className="search-icon" src="assets/search-1.svg" alt="" />
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="search-input"
                  />
                </div>
                <div className="interest_filter_btns">
                  <img
                    src="assets/filter.svg"
                    alt="Center Logo"
                    className="filter_image"
                  />
                </div>
              </div>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />

              {/* <Footer /> */}


            </div>
          </div>
        </div>
      </div>

    </>

  );
};

export default SearchPage;
