import axios from "./axios";
import { apiUrl, eventBase } from "../config/crud";

const API_URL = apiUrl;
const EVENT = eventBase;
const SESSIONS = "sessions";

// Get list of tracks
export const getTrackDetails = (eventId: string, sessionsToReturn: string = "all") => {
	const QUERY = `?sessions_to_return=${sessionsToReturn}`;
	return axios.get(`${API_URL}/${EVENT}/${eventId}/${SESSIONS}${QUERY}`);
};
export const getSessionDetails = (eventId: string, speakerId: string) => {
	const speakers ='speakers';
	return axios.get(`${API_URL}/${EVENT}/${eventId}/${speakers}/${speakerId}/${SESSIONS}`);
};

