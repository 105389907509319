import { useNavigate } from "react-router-dom";
import "./Header.css";

interface HeaderProps {
	setModalOpenDesktop?: (val: boolean) => void;
}

export const Header: React.FC<HeaderProps> = ({ setModalOpenDesktop }) => {
	const navigate = useNavigate();
	return (
		<div className="header-wrapper">
			{/* <img
				className="left_uparrow search_left_arrow cursor_pointer"
				src="/assets/uparrow.svg"
				alt="Go Back"
				onClick={() => {
					if(typeof setModalOpenDesktop === "function"){
						setModalOpenDesktop(false);
					}
				}}
			/> */}
			{/* <div>
			</div>
			<div className="display-flex justify-content-between">
				<span onClick={() => {}}><Bell className="icon interest_bell right-icon1" /></span>
				<div>
					<img
						className="h_image"
						src="https://media.konfhub.com/default-profile.png"
						alt="Center Logo"
						onClick={() => navigate("/profile-page")}
					/>
				</div>
			</div> */}
		</div>
	);
};
