import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './TermsAndConditions.css';

const TermsAndConditions: React.FC = () => {
  const handleSaveChanges = () => {
    navigate('/signup-page');
  };

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
    <div className='phone-view'>
    <div className="terms-and-condition font">
      <div className='terms-and-condition-header'>
        <Link to="/"> <span><img className='left_uparrow' src='/assets/uparrow.svg' onClick={goBack}/>  </span></Link>
        <span><h1 className='term'>Terms & conditions</h1></span>
      </div>
      <main className="contentt">

        <section className="clause">
          <h2 className='cla1'>Clause 1</h2>
          <p className='pp'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra condimentum eget purus in. Consectetur eget id morbi amet amet urna sed. Lobortis lacus nibh tellus nec. Ultrices parturient ac.</p>
          <p className='pp'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra condimentum eget purus in. Consectetur eget id morbi amet amet urna sed.</p>
        </section>
        <section className="clause">
          <h2 className='cla1'>Clause 2</h2>
          <p className='pp'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra condimentum eget purus in. Consectetur eget id morbi amet amet urna sed. Lobortis lacus nibh tellus nec. Ultrices parturient ac.</p>
          <p className='pp'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra condimentum eget purus in. Consectetur eget id morbi amet amet urna sed.</p>

          <div className="button-container">
            <button className="button decline" onClick={goBack}>Decline</button>
            <button className="button accept" onClick={goBack}>Accept</button>
          </div>

        </section>
        
      </main>

    </div>
    </div>

    {/* desktop container */}
    <div className='desktop-view'>
    <div className="terms-and-condition font">
      <div className='terms-and-condition-header'>
        <Link to="/"> <span><img className='left_uparrow' src='/assets/uparrow.svg' onClick={goBack} />  </span></Link>
       
      </div>
      <span><h1 className='term-desktop'>Terms & conditions</h1></span>
      <main className="contentt">

        <section className="clause">
          <h2 className='cla1'>Clause 1</h2>
          <p className='pp'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra condimentum eget purus in. Consectetur eget id morbi amet amet urna sed. Lobortis lacus nibh tellus nec. Ultrices parturient ac.</p>
          <p className='pp'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra condimentum eget purus in. Consectetur eget id morbi amet amet urna sed.</p>
        </section>
        <section className="clause">
          <h2 className='cla1'>Clause 2</h2>
          <p className='pp'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra condimentum eget purus in. Consectetur eget id morbi amet amet urna sed. Lobortis lacus nibh tellus nec. Ultrices parturient ac.</p>
          <p className='pp'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra condimentum eget purus in. Consectetur eget id morbi amet amet urna sed.</p>

          <div className="button-container-desktop">
            <button className="button-term decline" onClick={goBack}>Decline</button>
            <button className="button-term accept" onClick={goBack}>Accept</button>
          </div>

        </section>
        
      </main>

    </div>
    </div>
   
    </>
  );
};

export default TermsAndConditions;