import React, { useState } from "react";
import FilterDrawerDesktop from "../../components/FilterDrawer/FilterDreawerDesktop";
import FilterDrawer from "../../components/FilterDrawer/FilterDrawer";
import "./SearchAndFilter.css";
import { Icon } from "@iconify/react/dist/iconify.js";

interface SearchAndFilterProps {
	searchTerm: string;
	setSearchTerm: (term: string) => void;
	setFilterTags: (val: object) => void;
	getFilters: () => void;
	filterData: any;
	filterLoading: boolean;
	showFilter: boolean;
}

const SearchAndFilter: React.FC<SearchAndFilterProps> = ({
	searchTerm,
	setSearchTerm,
	filterLoading,
	filterData,
	getFilters: getFilters,
	setFilterTags,
	searchPlaceHolder = 'Search',
	showFilter = true,
}) => {
	const [filterModal, setFilterModal] = useState(false);
	const [isFilterDrawerOpen, setFilterDrawerOpen] = useState(false);

	const toggleFilterModal = () => setFilterModal(!filterModal);

	return (
		<>
			<div className="searchbar-input-wrapper">

				<input
					type="text"
					placeholder={searchPlaceHolder}
					value={searchTerm}
					onChange={e => setSearchTerm(e.target.value)}
					className="searchbar-input"
				/>
				<Icon
					icon={searchTerm ? `oui:cross` : `material-symbols-light:search-rounded`}
					className="searchbar-search-icon"
					onClick={() => { setSearchTerm("") }}
					style={{ cursor: "pointer" }}
				/>
			</div>

			{showFilter && <div className="hide-in-mobile ms-2">
				{filterData?.length > 0 &&
					<div
						className="searchbar-filter-btn"
						onClick={() => {
							toggleFilterModal();
							getFilters();
						}}
					>
						<Icon
							icon={`material-symbols-light:filter-alt-outline`}
							width={"20px"}
							height={"20px"}
							className="searchbar-filter-icon"
						/>
					</div>}
				<div className={`filter-section ${filterModal ? "show" : ""}`}></div>
			</div>}

			{showFilter && <div className="hide-in-desktop ms-2">
				{filterData?.length > 0 &&
					<div
						className="searchbar-filter-btn"
						onClick={() => {
							setFilterDrawerOpen(true);
							getFilters();
						}}
					>
						<Icon
							icon={`material-symbols-light:filter-alt-outline`}
							width={"20px"}
							height={"20px"}
							className="searchbar-filter-icon"
						/>
					</div>
				}
				<div className={`filter-section ${filterModal ? "show" : ""}`}></div>
			</div>}

			{filterModal && (
				<FilterDrawerDesktop
					filterLoading={filterLoading}
					filterData={filterData}
					setFilterTags={setFilterTags}
					onClose={() => {
						setFilterModal(false);
					}}
				/>
			)}

			{isFilterDrawerOpen && <FilterDrawer
				filterLoading={filterLoading}
				filterData={filterData}
				setFilterTags={setFilterTags}
				isOpen={isFilterDrawerOpen}
				onClose={() => {
					setFilterDrawerOpen(false);
				}}
			/>}
		</>
	);
};

export default SearchAndFilter;
