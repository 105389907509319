import React from "react";
import IconButton from "@mui/material/IconButton";
import "../components/Agenda/Agenda.css";
import { Icon } from "@iconify/react/dist/iconify.js";
import "moment-timezone";
import { formatTimestamp } from "../reusable/common";
import { useNavigate } from "react-router-dom";

interface Event {
	start_timestamp: string;
	end_timestamp: string;
	session_title: string;
	session_description: string;
	session_speakers: { name: string; image_url: string; designation: string }[];
	time_zone: string;
}

interface EventAgendaProps {
	data: any;
	setModalOpenDesktop: (open: boolean) => void;
	setIsSessionDrawerOpen?: (val: boolean) => void;
	setModalData: (event: Event) => void;
	setSpeakerDetails?: (val: any) => void;
	loading: boolean;
	speakers?: object[];
	eventsData: any;
	sessionDesc: boolean;
}

const EventAgenda: React.FC<EventAgendaProps> = ({
	loading,
	setModalData,
	setModalOpenDesktop,
	setIsSessionDrawerOpen,
	data,
	speakers,
	eventsData,
	sessionDesc,
	setSpeakerDetails
}) => {
	const navigate = useNavigate();
	const searchParams = new URLSearchParams(location.search); // Parse the search string
	const sessionId = searchParams.get("sessionId"); 
	const speakerId = searchParams.get("speakerId");

	const spinnerStyle = {
		position: "fixed" as const,
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		zIndex: 9999
	};

	return (
		<div className="desktop-eventCard-container_agenda">
			<div
				className="desktop-event-container"
				onClick={() => {
					if (typeof setIsSessionDrawerOpen === "function") {
						setIsSessionDrawerOpen(true);
					}
					if (typeof setSpeakerDetails === "function") {
						setSpeakerDetails(false);
					}
					setModalOpenDesktop(true);
					setModalData(data);
				}}
			>
				<div className="event-details_agenda">
					<div className="event-time">
						{formatTimestamp(
							data?.start_timestamp,
							data?.end_timestamp,
							eventsData?.time_zone
						)}
					</div>
					<div className="event-icons">
						<IconButton aria-label="fullscreen">
							<Icon
								icon={`material-symbols-light:zoom-out-map`}
								width={"25px"}
								height={"25px"}
								className="primary-color-icons"
							/>
						</IconButton>
					</div>
				</div>

				<div className="event-description_agenda">
					<p className="session_title">{data?.session_title}</p>
					{data?.session_location && (
						<div
							className="d-flex align-items-center mt-2"
							style={{ marginLeft: "0px" }}
						>
							<Icon
								icon={`fluent:location-16-regular`}
								width={"18px"}
								height={"18px"}
								className="primary-color-icons"
							/>
							<p className="venue-address" style={{ marginLeft: "7px" }}>
								{data?.session_location}
							</p>
						</div>
					)}
					{!sessionDesc && (
						<p
							className="session_title_desc"
							dangerouslySetInnerHTML={{
								__html: data?.session_description
							}}
						></p>
					)}
				</div>
				<div className="speaker-grid-desktop margin-left-speaker">
					{data?.session_speakers?.length > 0 && (
						<>
							{data?.session_speakers[0]?.name ? (
								<>
									{data?.session_speakers?.map((speaker: any, idx: number) => (
										<div className="speaker-avatar" key={idx}>
											<img
												src={speaker?.image_url}
												style={{ borderRadius: "50%" }}
												alt={speaker?.name}
												className="avatar-img"
											/>
											{/* <p className="eventdraw-name">
												{speaker?.name?.length > 10
													? `${speaker?.name.slice(0, 10)}...`
													: speaker?.name}
											</p> */}
											{/* <p className="eventdraw-dec-desktop">
													{speaker?.designation}
												</p> */}
										</div>
									))}
								</>
							) : (
								<>
									{data?.session_speakers
										?.map((data: object) => {
											const _speaker = speakers?.filter(
												(each: any) => each?.speaker_id === data
											)[0];
											data = { ..._speaker };
											return data;
										})
										.map((speaker: any, index: number) => (
											<div className="speaker-avatar-with-text" key={index}>
												<img
													src={speaker?.image_url}
													style={{ borderRadius: "50%" }}
													alt={speaker?.name}
													className="avatar-img"
												/>
												<p className="eventdraw-name">{speaker?.name}</p>
												{/* <p className="eventdraw-dec-desktop">
														{speaker?.designation}
													</p> */}
											</div>
										))}
								</>
							)}
						</>
					)}
				</div>
			</div>
			{/* // )} */}
		</div>
	);
};

export default EventAgenda;
