import React, { useState } from 'react';
import './ItemDetails.css';
import { Modal, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Header } from '../../components/Header/Header';
import { Icon } from '@iconify/react/dist/iconify.js';
import { useNavigate } from 'react-router-dom';



interface SponsorModalProps {
  open: boolean;
  onClose: () => void;
  selectedEntity: null;
  setItemDetailsPage: (value: boolean) => void; // Fix this type
  route?: any;
}

const ItemDetails: React.FC<SponsorModalProps> = ({ open, onClose, selectedEntity, setItemDetailsPage, route }) => {
  const navigate = useNavigate();
  if (!selectedEntity) return null;



  return (
    <>

      <div style={{ width: "100%" }}>
        <div className="flex-container-desktops menuuu-desktop item-arrow">
          <Icon
            icon={`material-symbols-light:arrow-back-ios-rounded`}
            width={"25px"}
            height={"25px"}
            onClick={() => { 
              setItemDetailsPage(false); 
            }}
            className="primary-color-icons"
          />
          <Header />
        </div>
        <div className='sponsors-header-modal'>

          <div className='sponsors-header-section1'>
            <div className="sponsors-header">
              <img src={selectedEntity.image_url} alt={selectedEntity.name} className="sponsors-logo" />
              <div className='sps_head-desk'>
                <div className='sps-head1'>
                  <div>
                    <h2 className='sps_name'>{selectedEntity.entity_name}</h2>
                  </div>
                  {selectedEntity.website_url && <div >
                    <a href={selectedEntity.website_url} target="_blank" rel="noopener noreferrer">
                      <img className="sps-head-external1" src="assets/external-link.png" alt="External Link" />
                    </a>
                  </div>}
                </div>
                <p className='sps_lead-desktop'><span className='sps_lead1'>{selectedEntity.location}</span></p>


              </div>
            </div>

            <p className="sponsors-description" dangerouslySetInnerHTML={{ __html: selectedEntity.description }}
            ></p>
            {selectedEntity?.booth_number && <div className="sponsors-details-desktop">
              <div className="detail-value-desktop">
                <span style={{ fontSize: "16px" }} className="detail-label">Booth Number : </span>

                <span className=""> {selectedEntity.booth_number}</span>
              </div>
              {/* <div className="sps_detail-item">
                <span className="detail-value-desktop">{sponsor.category}</span>
                <span className="detail-label">Category</span>
              </div> */}
            </div>}

            {selectedEntity?.representatives && <div className="representatives-section" style={{ marginBottom: "15px" }}>
              <h3 className='sps_industry'>Representative(s)</h3>
              <div className="representatives-list-desktop">
                {selectedEntity?.representatives?.map((Representative, index) => (
                  <div key={index} className="representative">
                    {Representative.image_url ? (<img src={Representative.image_url} alt={Representative.name} className="rep-image-desktop" />) : (<Icon icon={"proicons:person-circle"} style={{ marginRight: "0px" }} className="contact-imagee" />)}
                    <div className="rep-details">
                      <h4 className='sps_del'>{Representative.name}</h4>
                      <p className='sps_in'>
                          {/* {Representative.designation}
                          {Representative.organization && `, `}
                          <span className='sps_ins'>{Representative.organization}</span> */}
                        {[Representative.designation, Representative.organization]?.filter(item => item).length > 0 && (
                          <span className='sps_ins'>
                            {[Representative.designation, Representative.organization]?.filter(item => item).join(", ")}
                          </span>
                        )}
                        </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>}



            {selectedEntity?.tags.length > 0 && <div className="">
              <h3 className='sps_industry'>Tags</h3>
              <div style={{ display: "flex", gap: "8px" }}>

                {selectedEntity?.tags?.map((tags, index) => (
                  <div key={index}>
                    <div className="tags" style={{ display: "flex" }}>
                      <span className="tag-desktop">{tags.name}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>}
          </div>

          <div className='sponsors-header-section2'>

            {selectedEntity?.poc && <div className="contact-section" style={{ padding: "16px 12px", width: "100%", marginBottom: "15px", borderRadius: "5px" }}>
              <h3 className='sps_con'>Contact us</h3>
              <div className="contact-person">
                {selectedEntity?.poc?.image_url ? (
                  <img
                    src={selectedEntity.poc.image_url}
                    alt="Simon Robben"
                    className="contact-image"
                  />
                ) : (
                  <Icon icon={"proicons:person-circle"} className="contact-imagee" />
                )}
                <div className="contact-details">
                  <h4 className='sps_names'>{selectedEntity?.poc?.name}</h4>
                  {selectedEntity?.poc?.designation && (
                      <p className='sps_in'>
                        {/* {selectedEntity?.poc?.designation}
                        {selectedEntity?.poc?.organization && ', '}
                        <span className='sps_lin'>{selectedEntity?.poc?.organization}</span> */}
                      {[selectedEntity?.poc?.designation, selectedEntity?.poc?.organization]?.filter(item => item).length > 0 && (
                        <p className='sps_lin'>
                          {[selectedEntity?.poc?.designation, selectedEntity?.poc?.organization]?.filter(item => item).join(", ")}
                        </p>
                      )}
                      </p>
                    )}
                </div>
              </div>

              <div>
                {selectedEntity?.poc?.contact_number && <div className="contact-info">
                  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="assets/sam.png">
                    <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  <span>{selectedEntity?.poc?.contact_number}</span>
                </div>}
                {selectedEntity?.poc?.email && <div className="contact-info">
                  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M22 6l-10 7L2 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  <span>{selectedEntity?.poc?.email}</span>
                </div>}
              </div>
            </div>}
          </div>


        </div></div>
    </>
  );
};



export default ItemDetails;
