// Footer.tsx
import React from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import "./Footer.css";
import { Icon } from "@iconify/react";
import { useUser } from "../../contexts/AuthContext";
import { useModule } from "../../contexts/ModuleContext";

const Footer: React.FC = () => {
	const location = useLocation();
	const { user, isRegistered, loading } = useUser();
	const { modulesDetails, modulesNames } = useModule();
	const isModuleVisible = moduleName => {
		return Object.values(modulesDetails).some(
			module => module.module_name === moduleName && module.att_app_visibility
		);
	};
	return (
		<>
			<div className="footer">
				<button
					className={`footer-button home ${location.pathname === "/" ? "active" : ""}`}
				>
					<Link
						className="llnk"
						to={{
							pathname: "/"
						}}
					>
						<div>
							<Icon
								icon="material-symbols-light:home-outline"
								width={24}
								height={24}
								className="primary-color-icons"
							/>
						</div>
					</Link>
					<Link
						to={{
							pathname: "/"
						}}
					>
						<span className="net__">Home</span>
					</Link>
				</button>
				{isModuleVisible("Agenda") && (
					<button
						className={`footer-button agenda ${location.pathname === "/agenda" ? "active_nav" : ""}`}
					>
						<Link
							to={{
								pathname: "/agenda"
							}}
						>
							<div>
								<Icon
									icon="material-symbols-light:calendar-month-outline"
									width={24}
									height={24}
									className="primary-color-icons"
								/>
							</div>
						</Link>
						<Link
							to={{
								pathname: "/agenda"
							}}
						>
							<span className="net__">Agenda</span>
						</Link>
					</button>
				)}

				{isModuleVisible("Scan") && (
					<button
						className={`footer-button scanner ${location.pathname === "/scanner" ? "active_nav" : ""}`}
					>
						<Link
							to={{
								pathname: "/scanner"
							}}
						>
							<div>
								<Icon
									icon="material-symbols-light:qr-code-scanner"
									width={24}
									height={24}
									className="primary-color-icons"
								/>
							</div>
						</Link>
						<Link
							to={{
								pathname: "/scanner"
							}}
						>
							<span className="net__">Scan</span>
						</Link>
					</button>
				)}
				{user && (
					<button
						className={`footer-button scanner ${location.pathname === "/my-profile" ? "active_nav" : ""}`}
					>
						<Link
							to={{
								pathname: "/my-profile"
							}}
						>
							<div>
								<Icon
									icon="material-symbols-light:person-outline"
									width={24}
									height={24}
									className="primary-color-icons"
								/>
							</div>
						</Link>
						<Link
							to={{
								pathname: "/my-profile"
							}}
						>
							<span className="net__">Profile</span>
						</Link>
					</button>
				)}
				{/* <button
          className={`footer-button network ${location.pathname === '/network' ? 'active' : ''}`}
        >
          <Link
            to={{
              pathname: '/network'
            }}
          >
            <div>
              <Icon icon="material-symbols-light:network-node" width={20} height={20} className='primary-color-icons' />
            </div>
          </Link>
          <Link
            to={{
              pathname: '/network'
            }}
          >
            <span className='net__'>Network</span>
          </Link>
        </button> */}
			</div>
		</>
	);
};

export default Footer;
