import axios from "./axios";
import { apiBase, apiUrl, eventBase } from "./../config/crud";

const API_URL = apiUrl;
const API_BASE = apiBase;
const EVENT = eventBase;

// Scan Badge of an Attendee to Exchange Business Cards
export const fetchExchangeBusinessCard = (eventId: string, attendeeCode: string) => {
	return axios.post(
		`${API_URL}/${API_BASE}/${EVENT}/${eventId}/att-app/scan-badge/${attendeeCode}`
	);
};

// Exchange card
export const exchangeBusinessCardApi = (eventId: string, attendee_id: string) => {
	return axios.post(
		`${API_URL}/${API_BASE}/${EVENT}/${eventId}/att-app/exchange-cards/${attendee_id}`
	);
};
