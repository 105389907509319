import React, { ReactNode, useState } from "react";
import { SwipeableDrawer } from "@mui/material";
import "./CustomDrawer.css";
import { Icon } from "@iconify/react/dist/iconify.js";

interface CustomDrawerProps {
	open: boolean;
	onClose: () => void;
	onOpen: () => void;
	component?: ReactNode;
	modalActions?: object | any;
	showModalAction?: boolean;
	maxHeight?: string;
	minHeight?: string
}

const CustomDrawer: React.FC<CustomDrawerProps> = ({
	open,
	onClose,
	onOpen,
	component,
	modalActions,
	showModalAction = false,
	maxHeight = "80vh",
	minHeight 
}) => {
	const [openModalActionItems, setOpenModalActionItems] = useState(false);
	return (
		<SwipeableDrawer
			anchor="bottom"
			open={open}
			onOpen={onOpen}
			onClose={() => {
				setOpenModalActionItems(false);
				onClose();
			}}
			className="eventDrawer hide-in-desktop"
		>
			<div
				onClick={() => {
					setOpenModalActionItems(false);
				}}
			>
				<div className="drawer-top-line"></div>
				<div className="main-customDrawer" style={{ maxHeight, ...(minHeight && {minHeight}) }}>
					{showModalAction && (
						<div
							className={`d-flex justify-content-${modalActions && Object.keys(modalActions)?.length > 0 ? "between" : "end"} custom-drawer-close`}
						>
							<Icon
								role="button"
								icon={`material-symbols-light:close`}
								width={"24px"}
								height={"24px"}
								onClick={() => {
									onClose();
									setOpenModalActionItems(false);
								}}
							/>

							{modalActions && Object.keys(modalActions)?.length > 0 && (
								<Icon
									role="button"
									icon={`material-symbols-light:more-vert`}
									width={"24px"}
									height={"24px"}
									onClick={e => {
										e.stopPropagation();
										setOpenModalActionItems(true);
									}}
								/>
							)}

							{openModalActionItems && (
								<>
									<div className="custom-drawer-action-items">
										{Object.keys(modalActions)?.map(
											(each: any, index: number) => (
												<div
													key={index}
													className="custom-drawer-action-text"
													onClick={() => {
														modalActions[each]();
													}}
												>
													<span role="button">{each}</span>
												</div>
											)
										)}
									</div>
								</>
							)}
						</div>
					)}

					{component}
				</div>
			</div>
		</SwipeableDrawer>
	);
};

export default CustomDrawer;
