import React, { useState } from "react";
import "./ModuleDescription.css";
import DOMPurify from "dompurify";
interface ModuleDescriptionProps {
    description: string;
}

const ModuleDescription: React.FC<ModuleDescriptionProps> = ({ description }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleViewMore = () => {
        setIsExpanded(!isExpanded);
    };

    const isTruncated = description?.length > 50;
    const isTruncatedDesktop = description?.length > 400;

    const sanitizedDescription = DOMPurify.sanitize(description, {
        ALLOWED_TAGS: [
            'a', 'b', 'strong', 'i', 'em', 'u', 'span', 'p', 'br', 'div', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'blockquote',
            'ul', 'ol', 'li', 'hr', 'mark', 'del', 'ins', 'code', 'small', 'sup', 'sub', 'img', 'audio', 'video', 'source',
            'iframe', 'table', 'tr', 'th', 'td', 'thead', 'tbody', 'tfoot', 'caption', 'form', 'input', 'button', 'select',
            'option', 'textarea', 'label', 'colgroup', 'col'
        ],
        ALLOWED_ATTR: ['href', 'target', 'rel', 'class', 'id', 'style', 'title', 'lang', 'role', 'data-*','type', 'name', 'value', 'disabled','src', 'width', 'height', 'frameborder', 'allowfullscreen']
    });

    const addTargetToLinks = (html: string) => {
        return html.replace(/<a /g, '<a target="_blank" rel="noopener noreferrer" ');
    };

    // Add target="_blank" to all <a> tags in the sanitized description
    const finalDescription = addTargetToLinks(sanitizedDescription);

    return (
        <>
            {description && <div className="module-description">
                <div className="d-none d-md-block mb-4 text-start">
                    <p className="description-text text-start">
                        {isExpanded || !isTruncated
                            ? (<p
                                dangerouslySetInnerHTML={{ __html: finalDescription }}
                            ></p>

                            ) : (

                                <p
                                    className="description-text"
                                    dangerouslySetInnerHTML={{ __html: `${finalDescription?.slice(0, 600)}...` }}
                                />
                            )

                        }
                        {isTruncatedDesktop && (
                            <span className="view-more-btn cursor-pointer ml-2" onClick={toggleViewMore}>
                                {isExpanded ? "View Less" : "View More"}
                            </span>
                        )}
                    </p>
                </div>

                <div className="d-block d-md-none mb-4 text-start">
                    <p className="description-text text-start">
                        {isExpanded || !isTruncated
                            ? (<p
                                dangerouslySetInnerHTML={{ __html: finalDescription }}
                            ></p>

                            ) : (

                                <p
                                    className="description-text"
                                    dangerouslySetInnerHTML={{ __html: `${finalDescription?.slice(0, 250)}...` }}
                                />
                            )

                        }
                        {isTruncated && (
                            <span className="view-more-btn cursor-pointer ml-2" onClick={toggleViewMore}>
                                {isExpanded ? "View Less" : "View More"}
                            </span>
                        )}
                    </p>
                </div>
            </div>
            }
        </>
    );
};

export default ModuleDescription;
