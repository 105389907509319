// BottomDrawer.tsx

import React, { useState } from 'react';
import './LiveEventsFilter.css';

interface Location {
  id: string;
  name: string;
}

const locations: Location[] = [
  { id: '1', name: 'Bengalore' },
  { id: '2', name: 'Hyderabad' },
  { id: '3', name: 'Mumbai' },
  { id: '4', name: 'Chennai' },
  { id: '6', name: 'Delhi' },
  { id: '7', name: 'Pune' },
  { id: '8', name: 'Dubai' },
  { id: '9', name: 'Kochi' },
  { id: '10', name: 'Nagpur' },
];

const BottomDrawer: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);

  const toggleDrawer = () => setIsOpen(!isOpen);

  const toggleLocation = (id: string) => {
    setSelectedLocations(prev =>
      prev.includes(id) ? prev.filter(locId => locId !== id) : [...prev, id]
    );
  };

  const clearAll = () => setSelectedLocations([]);

  const applyFilter = () => {
    setIsOpen(false);
  };

  return (
    <>
      <button className="open-drawer-btn" onClick={toggleDrawer}>Open Filter</button>
      <div className={`bottom-drawer ${isOpen ? 'open' : ''}`}>
        <div className="drawer-content">
          <div className="drawer-header">
            <h2>Filter</h2>
            <button className="close-btn" onClick={toggleDrawer}>×</button>
          </div>
          <div className="filter-section">
            <h3>Locations</h3>
            <div className="location-list">
              {locations.map(location => (
                <label key={location.id} className="location-item">
                  <input
                    type="checkbox"
                    checked={selectedLocations.includes(location.id)}
                    onChange={() => toggleLocation(location.id)}
                  />
                  {location.name}
                </label>
              ))}
            </div>
          </div>
          <div className="drawer-footer">
            <button className="clear-btn" onClick={clearAll}>Clear all</button>
            <button className="apply-btn" onClick={applyFilter}>Apply</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default BottomDrawer;

