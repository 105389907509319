import React, { useState, useEffect } from "react";
import "./ProfileForm.css";
import { Bell, Link } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";
import SideNavbar from "../../SideNavbar/SideNavbar";
import * as Yup from "yup";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import { useFormik } from "formik";
import { getUserById, updateUser } from "../../../crud/users.crud";
import {
	addEmptyStringForNullValues,
	addNullForEmptyString,
	returnObjDiff
} from "../../../reusable/common";
import { profileImage } from "../../../reusable/images";
import { useUser } from "../../../contexts/AuthContext";
import { setJwtToken } from "../../../api";
import { ClipLoader } from "react-spinners";
import CropImageModal from "../../../CommomComponents/CropImage/CropImageModal";
import { Crop } from "react-image-crop";
import NotificationBox from "../../Network/NotificationBox/NotificationBox";
import Loading from "../../../CommomComponents/ButtonLoader";
import ModuleHeading from "../../../CommomComponents/ModuleHeading/ModuleHeading";
import { SwipeableDrawer } from "@mui/material";
import TopBackground from "./TopBackground";
import { useEvent } from "../../../contexts/EventContext";
import { Button } from "@mui/material";
import { spinnerStyle } from "../../../config/app";
import { Icon } from "@iconify/react/dist/iconify.js";

const ProfileForm: React.FC = () => {
	const [toasterMessage, setToasterMessage] = useState("");
	const [notificationPopup, setNotificationPopup] = useState(false);

	const [data, setData] = useState({});
	const [imageSettingsModal, setImageSettingsModal] = useState(false);
	const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);

	//upload image states
	const [imageInput, setImageInput] = useState("");
	const [imageModal, setImageModal] = useState(false);
	const [imageRef, setImageRef] = useState("");
	const [imageType, setImageType] = useState("");
	const [imageName, setImageName] = useState("");
	const [imageSrc, setImageSrc] = useState("");
	const [croppedImageUrl, setCroppedImageUrl] = useState("");
	const [imageCrop, setImageCrop] = useState<Crop>();
	const [pageLoad, setPageLoad] = useState(false);
	const [saveDetailsModal, setSaveDetailsModal] = useState(false);

	// const { user, isRegistered, loading } = useUser();
	const { eventDetails } = useEvent();

	const navigate = useNavigate();
	const goBack = () => {
		if (formik.dirty) {
			setSaveDetailsModal(true);
		} else {
			navigate(-1);
		}
	};

	useEffect(() => {
		if (notificationPopup) {
			const timer = setTimeout(() => {
				setNotificationPopup(false);
			}, 3000);
			return () => clearTimeout(timer);
		}
	}, [notificationPopup]);

	useEffect(() => {
		getProfileAndRegistrations();
	}, []);

	const getUserDetails = async () => {
		const res = await getUserById();
		const userData = addEmptyStringForNullValues(res.data);
		setData(addEmptyStringForNullValues(userData));
	};

	async function getProfileAndRegistrations() {
		// setJwtToken(user.session?.tokens?.idToken?.toString());
		setPageLoad(true);
		try {
			getUserDetails();
			setPageLoad(false);
		} catch (error) {
			console.log("error: ", error);
			setPageLoad(false);
		}
	}

	const openImageModal = async e => {
		setImageRef(e.target);
		setImageInput(e.target.name);
		setImageSettingsModal(false);
		const files = e.target.files; // Get the first selected file, if any
		if (files && files.length > 0) {
			// setImageCrop(undefined);
			const reader = new FileReader();
			setImageType(files[0].type);
			setImageName(files[0].name);
			reader.addEventListener("load", () => {
				setImageSrc(reader.result);
			});
			reader.readAsDataURL(files[0]);
		} else {
			setImageSrc("");
			setImageRef("");
			setCroppedImageUrl("");
			setImageName("");
			setImageType("");
			setImageCrop({
				x: 25,
				y: 25,
				width: 50,
				height: 50,
				unit: "%"
			});
		}
		setImageModal(true);
	};

	let formik = useFormik({
		enableReinitialize: true,
		initialValues: data || {
			email_id: "",
			name: "",
			image_url: "",
			city: "",
			phone_number: "",
			country_code: "in",
			dial_code: "91",
			// 'newsletter': false,
			created_at: "",
			interests: "",
			updated_at: "",
			linkedin_url: "",
			facebook_url: "",
			twitter_url: "",
			website: "",
			designation: "",
			organisation: "",
			t_shirt: "",
			profile_config: ["name", "image_url", "organisation", "designation"],
			// is_subscriber: false,
			email_subscription: false,
			whatsapp_subscription: false,
			sms_subscription: false
		},
		validationSchema: Yup.object({
			name: Yup.string()
				.required("Name is required")
				.matches(/^[a-zA-Z0-9.`\s-]+$/, "Please enter a valid name"),
			email_id: Yup.string().required("Email is required"),
			website: Yup.string().nullable().url("Invalid website link"),
			designation: Yup.string().max(72, "Designation should not exceed 72 characters"),
			// .matches(/^[a-zA-Z0-9.\-\s]{1,512}$/, "Please enter a valid designation"),
			organisation: Yup.string().max(72, "Organisation should not exceed 72 characters"),
			// .matches(/^[a-zA-Z0-9.\|\[\]`\- ]{1,1024}$/, "Please enter a valid organisation"),
			linkedin_url: Yup.string()
				.nullable()
				.url("Please enter a valid URL")
				.test("linkedin", "Enter a valid linkedin url", val => {
					if (val == undefined || val == "") return true;
					else if (val.includes("linkedin.com")) return true;
					else return false;
				})
				.max(160, "linkedin url should not exceed 160 characters"),
			facebook_url: Yup.string()
				.nullable()
				.url("Please enter a valid URL")
				.test("facebook", "Enter a valid facebook url", val => {
					if (val == undefined || val == "") return true;
					else if (val.includes("facebook.com")) return true;
					else return false;
				})
				.max(160, "facebook url should not exceed 160 characters"),
			twitter_url: Yup.string()
				.nullable()
				.url("Please enter a valid URL")
				.test("twitter", "Enter a valid X URL", val => {
					if (val == undefined || val == "") return true;
					else if (val.includes("x.com") || val.includes("twitter.com")) return true;
					else return false;
				})
				.max(160, "X URL should not exceed 160 characters"),
			phone_number: Yup.number()
				.nullable()
				.notRequired()
				.typeError("Phone number should only contain numbers")
				.positive("Enter a valid phone number")
				.integer("Enter a valid phone number")
				.test("min-len", "Phone number should contain atleast 5 digits", val => {
					if (val === undefined || val === "" || val === null) return true;
					else if (val && val.toString().length >= 5) return true;
				})
				.test("max-len", "Phone number should not exceed 20 digits", val => {
					if (val === undefined || val === "" || val === null) return true;
					else if (val && val.toString().length <= 20) return true;
				}),
			city: Yup.string().matches(/^[a-zA-Z0-9.\-\s]{1,512}$/, "Please enter a valid city")
		}),

		onSubmit: async values => {
			let updatedValues = returnObjDiff(formik.initialValues, values);

			// const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
			try {
				// const response = await updateUser(
				//  addNullForEmptyString(updatedValues),
				//  user.session?.tokens?.idToken?.toString()
				// );
				const response = await updateUser(addNullForEmptyString(updatedValues));
				const userData = await getUserById();
				setNotificationPopup(true);
				setToasterMessage("Changes saved successfully");
				formik.resetForm({ values: addEmptyStringForNullValues(userData.data) });
				setData(addEmptyStringForNullValues(userData.data));
			} catch (error) {
				console.log("error: ", error);
				setToasterMessage("Failed to save details");
				setNotificationPopup(true);
			}
		}
	});

	const toggleChangeVisibilitySet = (e, form_name) => {
		const profile_config_dup = [...formik.values.profile_config];
		if (e.target.checked && !profile_config_dup.includes(form_name)) {
			formik.setFieldValue("profile_config", [...profile_config_dup, form_name]);
		} else {
			const fitered_data = profile_config_dup.filter(field => field !== form_name);
			formik.setFieldValue("profile_config", fitered_data);
		}
	};

	const showHideToggleLabel = (htmlFor, input_field) => {
		if (Object.keys(formik.values).length > 0) {
			if (formik.values?.profile_config?.includes(input_field)) {
				return (
					<label htmlFor={htmlFor} className="toggle-label">
						{" "}
						<p className="toggle-text">Show</p>
					</label>
				);
			} else {
				return (
					<label htmlFor={htmlFor} className="toggle-label">
						<p className="toggle-hide-text">Hide</p>
					</label>
				);
			}
		}
	};

	//image functions
	const imageClickHandler = () => {
		setImageSettingsModal(true);
	};
	const imageHandler = (imageUrl, previewUrl) => {
		formik.setFieldValue("image_url", imageUrl);
	};

	const closeImageModal = () => {
		setImageRef("");
		setImageInput("");
		setImageModal(false);
	};

	const deleteImageFunction = async () => {
		setDeleteConfirmationModal(false);
		formik.setFieldValue("image_url", null);
		const updatedDetails = {
			image_url: null
		};
		try {
			await updateUser(updatedDetails);
			getUserDetails();
			setNotificationPopup(true);
			setToasterMessage("Image Deleted successfully");
		} catch (error) {
			console.log("error: ", error);
			setToasterMessage("Failed to Delete Image");
			setNotificationPopup(true);
		}
	};

	return (
		<>
			{!pageLoad ? (
				<>
					<div className="d-flex">
						<div>
							<div className="desktop-view-side desktop-view">
								<div className="desktop-left-slider">
									<SideNavbar />
								</div>
							</div>
						</div>
						<div className="d-flex w-100 flex-column">
							<div>
								<div className="profile-container_bs">
									<div className="profile-background_bt">
										<TopBackground
											color={eventDetails.primaryColor}
										></TopBackground>
									</div>
									<div className="profile-header_b">
										<header className="profile-header_pprofile">
											<div className="edit-profile-header">
												{/* <Link to="/event-app"> <span><img className='vv' src='/assets/Vector.png' />  </span></Link> */}

												<ModuleHeading
													moduleHeading={"Edit Profile"}
													goBack={goBack}
													hideProfileFromHeader={true}
												/>
												{/* <span>
                {" "}
                <Bell className="icon interest_bell right-icon1 notification-icon_b" />
              </span> */}
											</div>
										</header>
									</div>
								</div>
								<div className="profile-section_pprofiles">
									<div className="profile-info_pprofiles">
										<div className="profile-photo-container">
											{profileImage(
												formik.values.image_url,
												formik.values.name
											)}
											{!formik.values.image_url ||
											formik.values.image_url == "" ||
											formik.values.image_url.includes("default-profile") ? (
												<>
													<input
														type="file"
														id="imageUpload"
														style={{ display: "none" }}
														// onChange={openImageModal}
														onChange={openImageModal}
														accept=".png, .jpg, .jpeg"
													/>
													<label htmlFor="imageUpload">
														<img
															htmlFor="imageUpload"
															src="assets/edit.svg"
															alt="edit"
															className="edit-icon"
														/>
													</label>
												</>
											) : (
												<img
													src="assets/edit.svg"
													alt="edit"
													className="edit-icon"
													onClick={imageClickHandler}
												/>
											)}
										</div>
									</div>
								</div>
							</div>
							<div className="profile-form_f">
								<div className="form-group col-12 col-md-6 d-flex flex-wrap px-3 flex-column">
									<div className="gg">
										<div>
											<label className="label-font">Email</label>
										</div>
									</div>
									<input
										value={formik.values.email_id ?? ""}
										disabled
										type="text"
										className="form-control "
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										placeholder="E-Mail"
										name="email_id"
									/>
								</div>

								<div className="form-group col-12 col-md-6 d-flex flex-wrap px-3 flex-column">
									<div className="gg">
										<div className="d-flex">
											{" "}
											<label className="label-font">Name</label>
											<span className="d-flex align-items-end ps-1">*</span>
										</div>
									</div>
									<input
										value={formik.values.name ?? ""}
										type="text"
										className="form-control"
										onChange={formik.handleChange}
										name="name"
										onBlur={formik.handleBlur}
										placeholder="Name"
									/>
									{formik.errors.name && (
										<small className="text-danger text-start">
											{formik.errors.name}
										</small>
									)}
								</div>

								<div className="form-group col-12 col-md-6 d-flex flex-wrap px-3 flex-column">
									<div className="gg">
										<div>
											{" "}
											<label className="label-font">Organization</label>
										</div>
										<div className="it">
											<div className="toggle-switch">
												<input
													type="checkbox"
													id="org-toggle"
													className="toggle-input"
													onChange={e => {
														toggleChangeVisibilitySet(
															e,
															"organisation"
														);
													}}
													checked={formik.values?.profile_config?.includes(
														"organisation"
													)}
												/>
												{showHideToggleLabel("org-toggle", "organisation")}
											</div>
										</div>
									</div>
									<input
										value={formik.values.organisation ?? ""}
										type="text"
										className="form-control"
										onChange={formik.handleChange}
										name="organisation"
										onBlur={formik.handleBlur}
										placeholder="Organisation"
									/>
									{formik.errors.organisation && (
										<small className="text-danger text-start">
											{formik.errors.organisation}
										</small>
									)}
								</div>

								<div className="form-group col-12 col-md-6 d-flex flex-wrap px-3 flex-column">
									<div className="gg">
										<div>
											{" "}
											<label className="label-font">Designation</label>
										</div>
										<div className="it">
											<div className="toggle-switch">
												<input
													type="checkbox"
													id="designation-toggle"
													className="toggle-input"
													onChange={e => {
														toggleChangeVisibilitySet(e, "designation");
													}}
													checked={formik.values?.profile_config?.includes(
														"designation"
													)}
												/>
												{showHideToggleLabel(
													"designation-toggle",
													"designation"
												)}
											</div>
										</div>
									</div>

									<input
										value={formik.values.designation ?? ""}
										type="text"
										className="form-control"
										onChange={formik.handleChange}
										name="designation"
										onBlur={formik.handleBlur}
										placeholder="Designation"
									/>
									{formik.errors.designation && (
										<small className="text-danger text-start">
											{formik.errors.designation}
										</small>
									)}
								</div>

								<div className="form-group col-12 col-md-6 d-flex flex-wrap px-3 flex-column">
									<div className="gg">
										<div>
											{" "}
											<label className="label-font">Phone Number</label>
										</div>
										<div className="it">
											<div className="toggle-switch">
												<input
													type="checkbox"
													id="phone-toggle"
													className="toggle-input"
													onChange={e => {
														toggleChangeVisibilitySet(
															e,
															"phone_number"
														);
													}}
													checked={formik.values?.profile_config?.includes(
														"phone_number"
													)}
												/>
												{showHideToggleLabel(
													"phone-toggle",
													"phone_number"
												)}
											</div>
										</div>
									</div>

									<IntlTelInput
										inputClassName="form-control col-md-12"
										defaultCountry={formik.values.country_code || "in"}
										// defaultValue={formik.values.phone_number}
										fieldName="phone_number"
										fieldId="phone_number"
										value={formik.values.phone_number || ""}
										formatOnInit={false}
										separateDialCode={true}
										placeholder=""
										onPhoneNumberBlur={(
											isvalid,
											value,
											country,
											fullNumber,
											ext,
											event
										) => {
											formik.setFieldValue("phone_number", value, true);
											formik.setFieldValue("country_code", country.iso2);
											formik.setFieldValue("dial_code", country.dialCode);
										}}
										onPhoneNumberChange={async (isvalid, value, country) => {
											await formik.setFieldValue("phone_number", value, true);
											await formik.setFieldValue(
												"country_code",
												country.iso2
											);
											await formik.setFieldValue(
												"dial_code",
												country.dialCode
											);
											// if (formik.initialValues.phone_number !== formik.values.phone_number) {
											//     validatePhone(value, country.dialCode, formik, setValidating)
											// }
										}}
									/>
									{formik.touched.phone_number && formik.errors.phone_number && (
										<small className="text-danger text-start">
											{formik.errors.phone_number}
										</small>
									)}
								</div>

								<div className="form-group col-12 col-md-6 d-flex flex-wrap px-3 flex-column">
									<div className="gg">
										<div>
											{" "}
											<label className="label-font">LinkedIn URL</label>
										</div>
										<div className="it">
											<div className="toggle-switch">
												<input
													type="checkbox"
													id="linkedin-toggle"
													className="toggle-input"
													onChange={e => {
														toggleChangeVisibilitySet(
															e,
															"linkedin_url"
														);
													}}
													checked={formik.values?.profile_config?.includes(
														"linkedin_url"
													)}
												/>
												{showHideToggleLabel(
													"linkedin-toggle",
													"linkedin_url"
												)}
											</div>
										</div>
									</div>

									<input
										type="text"
										className="form-control "
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										placeholder="LinkedIn"
										name="linkedin_url"
										value={formik.values.linkedin_url ?? ""}
									/>
									{formik.touched.linkedin_url && formik.errors.linkedin_url && (
										<small className="text-danger text-start">
											{formik.errors.linkedin_url}
										</small>
									)}
								</div>

								<div className="form-group col-12 col-md-6 d-flex flex-wrap px-3 flex-column">
									<div className="gg">
										<div>
											{" "}
											<label className="label-font">X URL</label>
										</div>
										<div className="it">
											<div className="toggle-switch">
												<input
													type="checkbox"
													id="twitter-toggle"
													className="toggle-input"
													onChange={e => {
														toggleChangeVisibilitySet(e, "twitter_url");
													}}
													checked={formik.values?.profile_config?.includes(
														"twitter_url"
													)}
												/>
												{showHideToggleLabel(
													"twitter-toggle",
													"twitter_url"
												)}
											</div>
										</div>
									</div>

									<input
										type="text"
										className="form-control "
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										placeholder="X"
										name="twitter_url"
										value={formik.values.twitter_url ?? ""}
									/>
									{formik.touched.twitter_url && formik.errors.twitter_url && (
										<small className="text-danger text-start">
											{formik.errors.twitter_url}
										</small>
									)}
								</div>

								<div className="form-group col-12 col-md-6 d-flex flex-wrap px-3 flex-column">
									<div className="gg">
										<div>
											{" "}
											<label className="label-font">Website</label>
										</div>
										<div className="it">
											<div className="toggle-switch">
												<input
													type="checkbox"
													id="website-toggle"
													className="toggle-input"
													onChange={e => {
														toggleChangeVisibilitySet(e, "website");
													}}
													checked={formik.values?.profile_config?.includes(
														"website"
													)}
												/>
												{showHideToggleLabel("website-toggle", "website")}
											</div>
										</div>
									</div>

									<input
										type="text"
										className="form-control"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										placeholder="Website"
										name="website"
										value={formik.values.website ?? ""}
									/>
									{formik.touched.website && formik.errors.website && (
										<small className="text-danger text-start">
											{formik.errors.website}
										</small>
									)}
								</div>

								<div className="d-flex justify-content-center m-auto flex-column">
									<p className="note-text">
										<span>
											Details marked as{" "}
											<span style={{ fontWeight: "bold" }}>Show</span> will be
											visible and accessible by your connections.
										</span>
									</p>
									<button
										className="save-btn_f"
										onClick={() => formik.handleSubmit()}
										// disabled={!formik.dirty}
										disabled={
											!formik.dirty ||
											formik.isSubmitting ||
											Object.keys(formik.errors).length > 0
										}
									>
										{formik.isSubmitting ? (
											<>
												<Loading size={"1.5rem"} color={"white"} />
											</>
										) : (
											"Save"
										)}
									</button>
								</div>
							</div>
						</div>
						{imageSettingsModal && (
							<SwipeableDrawer
								onClose={() => {
									setImageSettingsModal(false);
								}}
								open={imageSettingsModal}
								onOpen={() => setImageSettingsModal(true)}
								className="swipeable-drawer-wrapper"
								anchor="bottom"
							>
								<div className="d-flex flex-column align-items-center my-3">
									<div className="drawer-top-line" />
									<div className="w-100 d-flex justify-content-end mb-1">
										<Icon
											role="button"
											icon={`material-symbols-light:close`}
											width={"24px"}
											height={"24px"}
											onClick={() => {
												setImageSettingsModal(false);
											}}
										/>
									</div>
									<div className="py-2 d-flex w-100">
										<input
											type="file"
											id="imageUpload"
											style={{ display: "none" }}
											onChange={openImageModal}
											accept=".png, .jpg, .jpeg"
										/>
										<label
											htmlFor="imageUpload"
											style={{ color: "#62636C" }}
											className="modal-text text-center"
										>
											Upload Photo
										</label>
									</div>
									<div
										className="py-3 modal-text text-danger d-flex w-100 justify-content-center"
										onClick={() => {
											setImageSettingsModal(false);
											setDeleteConfirmationModal(true);
											// formik.setFieldValue("image_url", "");
										}}
									>
										Delete Photo
									</div>
								</div>
							</SwipeableDrawer>
						)}
						{deleteConfirmationModal && (
							<SwipeableDrawer
								onClose={() => {
									setDeleteConfirmationModal(false);
								}}
								open={deleteConfirmationModal}
								onOpen={() => setDeleteConfirmationModal(true)}
								className="swipeable-drawer-wrapper"
								anchor="bottom"
							>
								<div className="d-flex flex-column align-items-center my-3">
									<div className="drawer-top-line" />
									<div className="m-2 py-2 px-4">
										<div>Delete Photo</div>
										<div className="modal-text pt-2">
											Are you sure that you want to delete your image?
										</div>
										<div className="crop-drawer-buttons justify-content-between">
											<Button
												className="cropsocial_btn ms-0 px-0 delete-profile-btn"
												onClick={() => {
													setDeleteConfirmationModal(false);
												}}
											>
												No
											</Button>
											<Button
												className="cropsocial_btn delete-btn ms-0 px-0 delete-profile-btn"
												onClick={deleteImageFunction}
											>
												Yes, delete
											</Button>
										</div>
									</div>
								</div>
							</SwipeableDrawer>
						)}
					</div>
					<CropImageModal
						show={imageModal}
						setImageModal={setImageModal}
						imageHandler={imageHandler}
						closeModal={closeImageModal}
						moduleNo={2}
						setImageRef={setImageRef}
						imageRef={imageRef}
						profile={true}
						imageType={imageType}
						setImageType={setImageType}
						imageName={imageName}
						setImageName={setImageName}
						imageSrc={imageSrc}
						setImageSrc={setImageSrc}
						croppedImageUrl={croppedImageUrl}
						setCroppedImageUrl={setCroppedImageUrl}
						imageCrop={imageCrop}
						setImageCrop={setImageCrop}
						circularCrop={true}
						formik={formik}
					/>
					{saveDetailsModal && (
						<SwipeableDrawer
							onClose={() => {
								setSaveDetailsModal(false);
							}}
							open={saveDetailsModal}
							onOpen={() => setSaveDetailsModal(true)}
							className="swipeable-drawer-wrapper"
							anchor="bottom"
						>
							<div className="d-flex flex-column align-items-center my-3">
								<div className="drawer-top-line" />
								<div className="m-2 py-2 px-4">
									{/* <div>Delete Photo</div> */}
									<div className="modal-text pt-2">
										Are you sure that you want to go back without saving
										details?
									</div>
									<div className="crop-drawer-buttons justify-content-between">
										<Button
											className="cropsocial_btn ms-0 px-0 delete-profile-btn"
											onClick={() => {
												setSaveDetailsModal(false);
											}}
										>
											No
										</Button>
										<Button
											className="cropsocial_btn delete-btn ms-0 px-0 delete-profile-btn"
											onClick={() => {
												navigate(-1);
												setSaveDetailsModal(false);
											}}
										>
											Yes
										</Button>
									</div>
								</div>
							</div>
						</SwipeableDrawer>
					)}

					{notificationPopup && (
						<div className="notification-wrapper">
							<NotificationBox message={toasterMessage} />
						</div>
					)}
				</>
			) : (
				<div style={spinnerStyle}>
					<ClipLoader loading={pageLoad} size={50} aria-label="Loading Spinner" />
				</div>
			)}
		</>
	);
};

export default ProfileForm;
