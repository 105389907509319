import React from 'react';
import { X, Bell } from 'lucide-react';
import './BadRequest.css';

const BadRequest: React.FC = () => {
    return (
        <> 
        <div className='phone-view'>
        <div className='phone-container font'>
            <div className="badrequest_icon-container">
                <X className="badrequest_icon left-icon" />
                <Bell className="badrequest_icon right-icon" />
            </div>
            <div>
                <img
                    src="assets/nothing-found.png"
                    alt="Welcome"
                    className="badrequest-image"
                />
                <h3 className="badrequest-text1 font">Page Not Found</h3>
                <p className='badrequest-text'>
                   The server cannot process your request <br />due to invalid syntax. 
                </p>
            </div>
        </div>
        </div>


        <div className='desktop-view'>
        <div className='font'>
           
            <div >
                <div className='errorpage'>
                <div>
                <img
                    src="assets/nothing-found.png"
                    alt="Welcome"
                    className="badrequest-image"
                />
                </div>

                <div className='errorpage1'>
                <div className="error-headings">Page Not Found</div>
                <p className='error-textts'>
                   The server cannot process your request <br />due to invalid syntax. 
                </p>
                </div>

                </div>
               
               
            </div>
        </div>
        </div>
       
        </>
    );
};

export default BadRequest;