import React from "react";
import { domPurify } from "../reusable/common";
import { trackEntity } from "../crud/tracking.crud";
import { useEvent } from "../contexts/EventContext";
import { clickTrackingModules, notificationMetricEvents } from "../config/app";

interface NotificationToastProps {
	message: any;
	title: any;
	ctaName: any;
	ctaTarget: any;
	notificationId: any;
}

const NotificationToast: React.FC<NotificationToastProps> = ({
	message,
	title,
	ctaName,
	ctaTarget,
	notificationId
}) => {
	const { eventDetails } = useEvent();
	return (
		<>
			<div>
				<div className="notification-title">{title}</div>
				{message && (
					<p
						className="notification-message"
						dangerouslySetInnerHTML={{ __html: domPurify(message) }}
					></p>
				)}
				{ctaTarget && (
					<button
						className="notification-cta"
						onClick={e => {
							e.preventDefault();
							trackEntity(
								eventDetails?.event_id,
								clickTrackingModules.NOTIFICATIONS,
								{ id: notificationId },
								notificationMetricEvents.CLICK
							);
							window.open(ctaTarget, '_blank');
						}}
					>
						{ctaName || "Explore Now"}
					</button>
				)}
			</div>
			<style>{`
                .notification-title {
                    font-family: var(--font-family);
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 20px;
                    margin-bottom: 5px;
                    color: #000;
                }

                .notification-message {
                    font-family: var(--font-family);
                    font-size: 14px !important;
                    font-weight: 400;
                    line-height: 20px;
                    margin-bottom: 5px;
                    color: #62636C;
                }

                .notification-cta {
                    width: 100%;
                    padding: 10px 0;
                    background-color: var(--secondary-color);
                    color: var(----comp-secondary-color);
                    border-radius: 5px;
                    text-align: center;
                    font-size: 12px;
                    margin: 10px 0;
                    border: none;
                    outline: none;
                    font-family: var(--font-family);
                    font-weight: 600;
                    line-height: 24px;
                }

                .ReactModal__Overlay {
                    background-color: rgba(0, 0, 0, 0.65) !important;
                    z-index: 1000;
                }

                .ReactModal__Overlay .notification-modal {
                    top: 50%;
                    left: 50%;
                    right: auto;
                    bottom: auto;
                    margin-right: -50%;
                    transform: translate(-50%, -50%);
                    position: relative;
                    width: 60%;
                    background: #FFF;
                    border-radius: 10px;
                    padding: 20px;
                    max-height: 90%;
                    overflow: auto;
                }

                .notification-modal .notification-close-button {
                    position: absolute;
                    top: 0px;
                    right: 5px;
                    background: none;
                    border: none;
                    font-size: 1.5rem;
                    cursor: pointer;
                    color: #333;
                }

                .notification-modal .close-button:hover {
                    color: #000;
                }

                @media only screen and (max-width: 480px) {
                    .Toastify__toast-container {
                        padding: 13px;
                    }

                    .Toastify__toast-container .Toastify__toast {
                        border-radius: 10px;
                        flex-direction: column-reverse;
                    }

                    .Toastify__toast-container .Toastify__close-button {   
                        display: flex;
                        width: 100%;
                        justify-content: end;
                    }

                    .ReactModal__Overlay .notification-modal {
                        top: 50%;
                        left: 50%;
                        right: auto;
                        bottom: auto;
                        margin-right: -50%;
                        transform: translate(-50%, -50%);
                        position: relative;
                        width: 90%;
                    }
                    
                }
            `}</style>
		</>
	);
};

export default NotificationToast;
