import moment from "moment";
import "./Chats.css";
import { profileImage } from "../../reusable/images";

export const Chat = ({
	image,
	name,
	latestMessage,
	unreadCount,
	lastMessageAt,
	onClick,
	chat
}: {
	image: string;
	name: string;
	latestMessage: string;
	unreadCount: number;
	lastMessageAt: string;
	onClick: (roomId: string, attendeeId: string) => {};
	chat: any;
}) => {
	return (
		<div className="chat-wrapper" onClick={() => onClick(chat)}>
			<div className="chat-inner-wrapper">
				<div className="left-side-wrapper">
					{profileImage(image, name, 40, {
						width: "40px",
						height: "40px"
					})}
					<div className="chat-info-wrapper">
						<div className="chat-name">{name}</div>
						<div className="chat-latest-message">{latestMessage}</div>
					</div>
				</div>
				<div className="meta-wrapper">
					{unreadCount > 0 && <div className="unread-count">{unreadCount}</div>}
					<div>
						<div className="last-message-at">
							{moment.utc(lastMessageAt).local().format("DD MMM YY")}
						</div>
						<div className="last-message-at">
							{moment.utc(lastMessageAt).local().format("hh:mm a")}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
