import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { logout } from "../../reusable/common";
import { useUser } from "../../contexts/AuthContext";

const Logout = () => {
	const { setUser } = useUser();

	const navigate = useNavigate();

	useEffect(() => {
		// Call the logout action
		logout();
		setUser(false);
		window.location.href = "/";
	}, [logout, navigate]);

	return null; // No UI needed since it's a redirect route
};

export default Logout;
