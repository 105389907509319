import Avatar from "react-avatar";
import axios from "axios";
import { UploadFile } from "../crud/meetings.crud";

//This fucntion is used to return profile image based on the image url sent
export const profileImage = (imgUrl, user_name, size, style) => {
	if (!imgUrl || imgUrl == "" || imgUrl.includes("default-profile")) {
		return <Avatar name={user_name} round={true} size={size} color="#bdbdbd" maxInitials={1} />;
	} else {
		return <img src={imgUrl} alt="profile" className="profile-photo" style={style} />;
	}
};

export const uploadImage = async (
	file: any,
	fileName: string,
	moduleNumber: number,
	mimeType: string
) => {
	const request = {
		file_name: `${fileName}`,
		module: moduleNumber,
		mime_type: mimeType
	};

	let uploadUrl, downloadUrl;

	const uploadToUrl = async (uploadUrl: string) => {
		try {
			const axiosWithoutAuthorization = axios.create();
			delete axiosWithoutAuthorization.defaults.headers.common["Authorization"];
			let request_config = {
				headers: {
					"Content-Type": mimeType,
					"Access-Control-Allow-Origin": "*"
				}
			};
			let res = await axiosWithoutAuthorization.put(uploadUrl, file, request_config);
			return res;
		} catch (error) {
			console.log("error", error);
			return;
		}
	};

	try {
		let fileUrlResponse = await UploadFile(request);
		uploadUrl = fileUrlResponse.data.upload.url;
		downloadUrl = fileUrlResponse.data.download.url;
		await uploadToUrl(uploadUrl);
		return fileUrlResponse;
	} catch (error) {
		console.log("error", error);
		return;
	}
};
