import SideNavbar from "../../SideNavbar/SideNavbar";
import { useNavigate } from "react-router-dom";
import TicketPreview from "../../../CommomComponents/Ticket/TicketPreview";
import ModuleHeading from "../../../CommomComponents/ModuleHeading/ModuleHeading";
import ticket from "../../../assets/ticket.png";
import "./MyTickets.css";

const MyTickets = () => {
	const registrations = localStorage.getItem("registrations");
	const registrationData = registrations?.length ? JSON.parse(registrations) : [];

	const navigate = useNavigate();

	const goBack = () => {
		navigate(-1);
	};

	return (
		<>
			<div className="d-flex">
				<div>
					<div className="desktop-view-side desktop-view">
						<div className="desktop-left-slider">
							<SideNavbar />
						</div>
					</div>
				</div>
				<div className="d-flex w-100 flex-column">
					<div>
						<div className="profile-container_bs">
							<div className="profile-header_b">
								<header className="profile-header_pprofile" style={{ marginLeft: '20px' }}>
									<ModuleHeading
										moduleHeading={"My Tickets"}
										goBack={goBack}
										hideProfileFromHeader={true}
									/>
								</header>
							</div>
						</div>
						{registrationData.length > 0 ? (
							<div
								style={{ marginTop: "84px" }}
								className="d-flex justify-content-center flex-wrap"
							>
								{registrationData.map(ticketData => {
									return (
										<TicketPreview ticketDetails={ticketData}></TicketPreview>
									);
								})}
							</div>
						) : (
							<div className="d-flex justify-content-center flex-column align-items-center text-center margin-set">
								<img src={ticket} style={{ width: "50px" }}></img>
								<div className="tickets-text">No tickets available</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default MyTickets;
