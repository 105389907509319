import axios from "./axios";
import { apiUrl, eventBase } from "../config/crud";

const API_URL = apiUrl;
const EVENT = eventBase;
const PHOTO_BOOTH = "photo-booth";
const PHOTO_BOOTH_TEMPLATE = "photo-booth/template";

// Get list of floor plans
export const getAvatars = (eventId: string) => {
	return axios.get(`${API_URL}/${EVENT}/${eventId}/${PHOTO_BOOTH_TEMPLATE}`);
};

export const uploadImage = (eventId: string, fileName: string) => {
	return axios.post(`${API_URL}/${EVENT}/${eventId}/${PHOTO_BOOTH}/upload`, {
		mime_type: "image/jpeg",
		file_name: `${fileName}`
	});
};

export const genAvatar = (eventId: string, target_url: string, attendee_image_key: string) => {
	return axios.post(`${API_URL}/${EVENT}/${eventId}/${PHOTO_BOOTH}/avatar`, {
		target_url,
		attendee_image_key
	});
};
