import React from "react";
import "./QrScanner.css";
import { Scanner, TrackFunction } from "@yudiel/react-qr-scanner";

export interface IScannerComponents {
	tracker?: TrackFunction;
	audio?: boolean;
	onOff?: boolean;
	torch?: boolean;
	zoom?: boolean;
	finder?: boolean;
}

interface QrScannerProps {
	setScanResponse: (val: any) => void;
	isScanningPaused: boolean;
	setIsScanningPaused: (val: any) => void;
}

const QrScanner: React.FC<QrScannerProps> = ({
	setScanResponse,
	isScanningPaused,
	setIsScanningPaused
}) => {
	return (
		<>
			<div className="qr-scanner-container">
				{!isScanningPaused && (
					<Scanner
						onScan={(result: any) => {
							if (result && !isScanningPaused) {
								setIsScanningPaused(() => true);
								setScanResponse(result[0]);
							}
						}}
						components={{
							zoom: true,
							torch: true
						}}
						classNames={{ container: "qr-scanner-style" }}
					/>
				)}
			</div>
		</>
	);
};

export default QrScanner;
