import React from "react";
import { ClipLoader } from "react-spinners";
import CardComponent from "./SpeakerCardsComponent/SpeakerCardsComponent";

interface SpeakerListProps {
	searchTerm: string;
	loading: boolean;
	speakers: object[];
	setSpeakerDetails: (speakerDetails: object | null) => void;
	setOpenDrawer: (val: boolean) => void;
	appliedTags: any[];
}

const SpeakerList: React.FC<SpeakerListProps> = ({
	searchTerm,
	loading,
	speakers,
	setSpeakerDetails,
	setOpenDrawer,
	appliedTags
}) => {
	const filteredSpeakers = speakers.filter((each: any) => {
		const matchesName = searchTerm
			? `${each?.name?.toLowerCase()} ${each?.designation?.toLowerCase()} ${each?.organisation?.toLowerCase()}`.includes(
					searchTerm.toLowerCase()
				)
			: true;

		const matchesTags =
			appliedTags.length > 0
				? each?.tags?.map((each: any)=>each?.name).some((tag: string) => appliedTags.includes(tag))
				: true;

		return matchesName && matchesTags;
	});

	return (
		<>
			<div>
				<div className="all-speakers-card">
					{loading ? (
						<div className="d-flex justify-content-center align-items-center flex-column speaker-loader">
							<ClipLoader
								color="#4A90E2"
								loading={true}
								size={50}
								aria-label="Loading Spinner"
							/>
						</div>
					) : speakers.length > 0 ? (
						<div className="card-desk row w-100">
							{filteredSpeakers.map((speaker: any, index: number) => (
								<div className="col-md-6 col-lg-4 px-0 px-sm-2">
									<CardComponent
										key={index}
										image={speaker?.image_url}
										name={speaker?.name}
										designationAndOrganization={[
											speaker?.designation,
											speaker?.organisation
										]}
										organisation={speaker?.organisation}
										designation={speaker?.designation}
										location={speaker?.location}
										tags={speaker?.tags.map((each: any) => each.name)}
										actionType="connect"
										speakerDetails={speaker}
										setSpeakerDetails={setSpeakerDetails}
										setOpenDrawer={setOpenDrawer}
									/>
								</div>
							))}
						</div>
					) : (
						<div className="d-flex justify-content-center align-items-center flex-column speaker-loader">
							No speakers found
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default SpeakerList;
