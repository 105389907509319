import React, { useState, useEffect } from "react";
import "./Exhibitors.css";
import Footer from "../Footer/Footer";
import { getFilterDetails } from "../../crud/filter.crud";
import { getEntityDetails } from "../../crud/entity.crud";
import SideNavbar from "../SideNavbar/SideNavbar";
import { Link, useNavigate } from "react-router-dom";
import ItemDetails from "../../EntityPages/EntityPage/ItemDetails";
import ItemCard from "../../EntityPages/ItemCard";
import { ClipLoader } from "react-spinners";
import { useEvent } from "../../contexts/EventContext";
import { apiEntity, filterEntity } from "../../config/app";
import { spinnerStyle } from "../../config/app";
import SearchAndFilter from "../../CommomComponents/SearchAndFilter/SearchAndFilter";
import SearchSection from "../../CommomComponents/SearchAndFilter/SearchSection";
import FilterSection from "../../CommomComponents/SearchAndFilter/FilterSection";
import ModuleHeading from "../../CommomComponents/ModuleHeading/ModuleHeading";
import ItemCardMobile from "../../EntityPages/ItemCardMobile";
import ModuleDescription from "../../CommomComponents/ModuleDescription/ModuleDescription";
import { getModuleDescriptionByName, getModuleNameByType } from "../../reusable/common";
import { useModule } from "../../contexts/ModuleContext";

const Exhibitors = () => {
	const { eventDetails } = useEvent();
	const navigate = useNavigate();
	const { modulesDetails } = useModule()
	const [categories, setCategories] = useState([]);
	const [uncategorizedExhibitor, setUncategorized] = useState([]);
	const [openCategories, setOpenCategories] = useState({});
	const [searchTerm, setSearchTerm] = useState("");
	const [events, setEvents] = useState({});
	const [loading, setLoading] = useState(true);
	const [isFilterDrawerOpen, setFilterDrawerOpen] = useState(false);
	const [filterData, setFilterData] = useState([]);
	const [filterTags, setFilterTags] = useState([]);
	const [filterLoading, setFilterLoading] = useState(false);
	const [itemDetailsPage, setItemDetailsPage] = useState(false);
	const [open, setOpen] = useState(false);
	const [selectedExhibitor, setSelectedExhibitor] = useState("");

	const moduleHeading =getModuleNameByType(modulesDetails,3);
	const handleCloseModal = () => {
		setOpen(false);
		setSelectedExhibitor(null);
	};

	const handleFilterClick = () => {
		setFilterDrawerOpen(true);
	};

	useEffect(() => {
		getExhibitor();
		setEvents(eventDetails);
		getFilters();
	}, []);

	const getExhibitor = async () => {
		try {
			const res = await getEntityDetails(eventDetails.event_url, apiEntity.EXHIBITOR);
			setUncategorized(res.data.uncategorized);
			setCategories(res.data.categorized);

			const initialOpenState = res.data.categorized.reduce((acc, category) => {
				acc[category.category_id] = false;
				return acc;
			}, {});
			setOpenCategories(initialOpenState);
		} catch (error) {
			console.error("Failed to fetch sponsor:", error);
		} finally {
			setLoading(false);
		}
	};

	const goBack = () => {
		navigate("/");
	};

	const getSelectedTagsArray = tagsObject => {
		return Object.values(tagsObject).flat();
	};

	const selectedTagNames = getSelectedTagsArray(filterTags);

	const filteredCategories = categories
		.map(category => ({
			...category,
			entity: category.entity.filter(exhibitor => {
				const matchesSearch = exhibitor.entity_name
					.toLowerCase()
					.includes(searchTerm.toLowerCase());

				const exhibitorTagNames = exhibitor.tags?.map(tag => tag.name) || [];
				const matchesTags =
					selectedTagNames.length === 0 ||
					exhibitorTagNames.some(tagName => selectedTagNames.includes(tagName));

				return matchesSearch && matchesTags;
			})
		}))
		.filter(category => category.entity.length > 0);

	const filteredUncategorizedExhibitor = uncategorizedExhibitor.filter(exhibitor => {
		const matchesSearch = exhibitor.entity_name
			.toLowerCase()
			.includes(searchTerm.toLowerCase());

		const exhibitorTagNames = exhibitor.tags?.map(tag => tag.name) || [];
		const matchesTags =
			selectedTagNames.length === 0 ||
			exhibitorTagNames.some(tagName => selectedTagNames.includes(tagName));

		return matchesSearch && matchesTags;
	});

	const getFilters = async () => {
		setFilterLoading(true);
		try {
			const res = await getFilterDetails(eventDetails.event_id, filterEntity.EXHIBITOR);
			if (res.data.length > 0) {
				setFilterData(res.data);
			} else {
				setFilterData([]);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setFilterLoading(false);
		}
	};

	return (
		<>
			<div className="phone-view">
				<div className="sponsors-container">
					<div>
						<ModuleHeading goBack={goBack} moduleHeading={moduleHeading} />
						<ModuleDescription description={getModuleDescriptionByName(modulesDetails, moduleHeading)} />


						<div className="search-bar_network">
							<div className="search-bars no-margin w-100">
								<div className="search-input-wrapper" style={{ maxWidth: "100%" }}>
									<SearchSection
										searchTerm={searchTerm}
										setSearchTerm={setSearchTerm}
										handleFilterClick={handleFilterClick}
									/>
								</div>
							</div>
							{(
								<div className="download-section">
									<FilterSection
										filterLoading={filterLoading}
										filterData={filterData}
										filterTags={filterTags}
										getFilters={getFilters}
										setFilterTags={setFilterTags}
										handleFilterClick={handleFilterClick}
										showSearchbox={false}
									/>
								</div>
							)}
						</div>
					</div>

					{loading ? (
						<div style={spinnerStyle}>
							<ClipLoader loading={loading} size={50} aria-label="Loading Spinner" />
						</div>
					) : filteredCategories.length === 0 &&
						filteredUncategorizedExhibitor.length === 0 ? (
						<div className="no-sponsors-message">No Exhibitors available</div>
					) : (
						<ItemCardMobile
							filteredCategories={filteredCategories}
							filteredUncategorized={filteredUncategorizedExhibitor}
							selectedExhibitor={selectedExhibitor}
							open={open}
							onClose={handleCloseModal}
							unCategrizedname={"Exhibitor"}
							routeDetails={"/exhibitors/details"}
							route={"/exhibitors"}
						/>
					)}
					<Footer />
				</div>
			</div>

			{/* Desktop View */}
			<div className="desktop-view">
				<div className="menu-desktop">
					<SideNavbar />
					{itemDetailsPage ? (
						<ItemDetails
							open={open}
							onClose={handleCloseModal}
							selectedEntity={selectedExhibitor}
							setItemDetailsPage={setItemDetailsPage}
							setSelectedSponsor={setSelectedExhibitor}
							route={"/exhibitors"}
						/>
					) : (
						<div className="sponsors-container-desktop">
							<ModuleHeading goBack={goBack} moduleHeading={moduleHeading} />
							<ModuleDescription description={getModuleDescriptionByName(modulesDetails, moduleHeading)} />

							<div className="search-bar_network-desktop">
							<div className="search-bars no-margin">
								<div className="search-input-wrapper">
									<SearchSection
										searchTerm={searchTerm}
										setSearchTerm={setSearchTerm}
										showSearchbox={"true"}
									/>
								</div>
							</div>

							{ (
								<div className="download-section">
									<FilterSection
										filterLoading={filterLoading}
										filterData={filterData}
										getFilters={getFilters}
										filterTags={filterTags}
										setFilterTags={setFilterTags}
										handleFilterClick={handleFilterClick}
										showSearchbox={false}
									/>
								</div>
							)}
						    </div>

							{loading ? (
								<div style={spinnerStyle}>
									<ClipLoader
										loading={loading}
										size={50}
										aria-label="Loading Spinner"
									/>
								</div>
							) : filteredCategories.length === 0 &&
								filteredUncategorizedExhibitor.length === 0 ? (
								<div className="no-sponsors-message">No Exhibitor available</div>
							) : (
								<div className="scrollable-div-other">
									<ItemCard
										filteredCategories={filteredCategories}
										filteredUncategorized={filteredUncategorizedExhibitor}
										selectedExhibitor={selectedExhibitor}
										open={open}
										onClose={handleCloseModal}
										setItemDetailsPage={setItemDetailsPage}
										setSelectedSponsor={setSelectedExhibitor}
										unCategrizedname={"Exhibitor"}
										routeDetails={"/exhibitors/details"}
										route={"/exhibitors"}
									/>
								</div>
							)}
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default Exhibitors;
