// in ./graphql/subscriptions.ts (or similar file)
export const onSendMessage = /* GraphQL */ `
	subscription OnSendMessage($eventId: String!, $receiverId: String!) {
		onSendMessage(eventId: $eventId, receiverId: $receiverId) {
			statusCode
			eventId
			message
			attachment
			roomId
			receiverId
			unreadCount
			error
			senderName
		}
	}
`;

export const onPushNotificationPerTicket = /* GraphQL */ `
	subscription onPushNotificationPerTicket(
		$eventId: String!
		$ticketId: Int!
		$isOrganiser: Boolean!
	) {
		onPushNotification(eventId: $eventId, ticketId: $ticketId, isOrganiser: $isOrganiser) {
			message
			ticketId
			title
			ctaName
			ctaTarget
			eventId
			isOrganiser
			notificationId
			notificationType
		}
	}
`;

export const onPushNotificationPerEvent = /* GraphQL */ `
	subscription onPushNotificationPerEvent($eventId: String!, $isOrganiser: Boolean!) {
		onPushNotification(eventId: $eventId, isOrganiser: $isOrganiser) {
			message
			ticketId
			title
			ctaName
			ctaTarget
			eventId
			isOrganiser
			notificationId
			notificationType
		}
	}
`;

export const onPushNotificationOrganizer = /* GraphQL */ `
	subscription onPushNotificationOrganizer($eventId: String!, $isOrganiser: Boolean!) {
		onPushNotification(eventId: $eventId, isOrganiser: $isOrganiser) {
			message
			ticketId
			title
			ctaName
			ctaTarget
			eventId
			isOrganiser
			notificationId
			notificationType
		}
	}
`;

export const onPushNotificationPublic = /* GraphQL */ `
	subscription onPushNotificationPublic($eventId: String!) {
		onPushNotificationPublic(eventId: $eventId) {
			eventId
			notificationId
			notificationType
			title
			message
			isOrganiser
			ticketId
			ctaName
			ctaTarget
		}
	}
`;

onPushNotificationPublic;
