import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { Amplify, Auth } from "aws-amplify";
// import { cognitoUserPoolsTokenProvider } from "aws-amplify/auth/cognito";
import { authConfig, defaultAwsConfig } from "./config/aws-exports";
// import { CookieStorage } from "aws-amplify/utils";
import { UserProvider } from "./contexts/AuthContext";
import "./App.css";
import { EventProvider } from "./contexts/EventContext";
import { ModuleProvider } from "./contexts/ModuleContext";
import { NotificationProvider } from "./contexts/NotificationContext";
import { MessageProvider } from "./contexts/MessageContext";
import * as Sentry from "@sentry/react";
import { ENV_NAME } from "./config/envs";
import { SubscriptionProvider } from "./contexts/SubscriptionContext";

Sentry.init({
	dsn: "https://5fe0b921e9adc7499359d80fb31f1518@o4508326426181632.ingest.de.sentry.io/4508326430638160",
	integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
	// Tracing
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: ["localhost"],
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	environment: ENV_NAME
});

const PWA = true;

Amplify.configure(defaultAwsConfig);
Auth.configure(authConfig as any);

// cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage());

ReactDOM.createRoot(document.getElementById("root")!).render(
	<UserProvider>
		<EventProvider>
			<ModuleProvider>
				<SubscriptionProvider>
					<NotificationProvider>
						<MessageProvider>
							<BrowserRouter>
								<App />
							</BrowserRouter>
						</MessageProvider>
					</NotificationProvider>
				</SubscriptionProvider>
			</ModuleProvider>
		</EventProvider>
	</UserProvider>
);

// Register Service Worker
if ("serviceWorker" in navigator) {
	window.addEventListener("load", async () => {
		if (PWA) {
			try {
				const registration = await navigator.serviceWorker.register("/sw.js");
				console.log("Service Worker registered:", registration);
			} catch (error) {
				console.error("Service Worker registration failed:", error);
			}
		}

		// Optional: Register push notification functionality if required
		try {
			const pushRegistration = await navigator.serviceWorker.register("/push.js");
			console.log("Push Notification Service Worker registered:", pushRegistration);
		} catch (error) {
			console.error("Push Notification Service Worker registration failed:", error);
		}
	});
}

// Ensure the event data type is specified for message event
interface ServiceWorkerMessageEvent {
	data: {
		type: string;
	};
}

if (navigator.serviceWorker) {
	navigator.serviceWorker.addEventListener("message", (event: MessageEvent) => {
		const typedEvent = event as ServiceWorkerMessageEvent; // Type assertion

		if (typedEvent.data && typedEvent.data.type === "updateManifestLink") {
			let manifestLink = document.querySelector('link[rel="manifest"]') as HTMLLinkElement;

			// If the <link rel="manifest"> does not exist, create it
			if (!manifestLink) {
				manifestLink = document.createElement("link");
				manifestLink.rel = "manifest";
				document.head.appendChild(manifestLink);
			}

			// Set the href to point to the dynamically cached manifest
			if (manifestLink) {
				manifestLink.href = "/manifest.json"; // Point to the dynamically cached manifest
			} else {
				console.error("Manifest link element could not be created.");
			}
		}
	});
}
