export const getSubdomain = () => {
    const hostnameParts = window.location.hostname.split('.');

    if (window?.location?.hostname === 'fsf.codeops.tech') 
        return 'future-sustainability-forum';
    
    if (hostnameParts.length > 2) {
        localStorage.setItem('eventUrl', hostnameParts[0]);
        return hostnameParts[0];
    }
    
    return 'future-sustainability-forum';
};