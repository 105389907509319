import React, { useState, useEffect, ReactNode } from "react";
import * as Sentry from "@sentry/react";


// Define types for props
interface ErrorBoundaryProps {
	children: ReactNode;
}

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children }) => {
	const [hasError, setHasError] = useState(false);
	const [error, setError] = useState<Error | null>(null);
	const [errorInfo, setErrorInfo] = useState<React.ErrorInfo | null>(null);

	// This hook will catch errors in the component tree
	const onError = (error: Error, errorInfo: React.ErrorInfo) => {
		setHasError(true);
		setError(error);
		setErrorInfo(errorInfo);

		// Optionally log error details to a service
		console.error("Error Boundary caught an error:", error, errorInfo);
	};

	// Use effect to add error event listeners
	useEffect(() => {
		const handleError = (event: ErrorEvent) => {
			Sentry.captureException(event);
			onError(event.error, {
				componentStack: "Component stack info not available in ErrorEvent"
			});
		};

		window.addEventListener("error", handleError);

		return () => {
			window.removeEventListener("error", handleError);
		};
	}, []);

	// Custom hook for catching React errors
	const onUnhandledRejection = (event: PromiseRejectionEvent) => {
		console.error("Unhandled Promise Rejection:", event.reason);
	};

	useEffect(() => {
		window.addEventListener("unhandledrejection", onUnhandledRejection);

		return () => {
			window.removeEventListener("unhandledrejection", onUnhandledRejection);
		};
	}, []);

	if (hasError) {
		return (
			<div>
				<h2>Something went wrong.</h2>
				<details style={{ whiteSpace: "pre-wrap" }}>
					{error && error.toString()}
					<br />
					{errorInfo?.componentStack}
				</details>
			</div>
		);
	}

	return <>{children}</>;
};

export default ErrorBoundary;
