import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from 'react-router-dom';
import { Auth } from "aws-amplify";
import "./ForgotPasswordDesktopOtp.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import NotificationBox from "../../Network/NotificationBox/NotificationBox";


const ForgotPasswordDesktopOtp: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState(location.state?.email || '');
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [signUpError, setSignUpError] = useState("");
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [otp, setOtp] = useState(Array(6).fill(''));
    const [timer, setTimer] = useState(0);
    const [verificationError, setVerificationError] = useState('');
    const [confirmpasswordVisible, confirmsetPasswordVisible] = useState(false);
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [resetPasswordError, setResetPasswordError] = useState("");
    const [showTimer, setShowTimer] = useState(false)
    const [isResendDisabled, setIsResendDisabled] = useState(false);
    const [notificationPopup, setNotificationPopup] = useState(false);
	const [toasterMessage, setToasterMessage] = useState("");
    const [validationStates, setValidationStates] = useState({
        length: false,
        uppercase: false,
        lowercase: false,
        number: false,
        specialChar: false
    });
    const [focused, setFocused] = useState(false);

    const validateEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };




    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };
    const togglePasswordVisibilityconfirm = () => {
        confirmsetPasswordVisible(!confirmpasswordVisible);
    };

    const validatePassword = (password: string) => {
        const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&!,.<>':;|_~=`+\-/=]).{6,}$/;
        return strongPasswordRegex.test(password);
    };

    const isFormValid =
        email &&
        password &&
        confirmPassword &&
        password === confirmPassword &&
        !emailError &&
        !passwordError &&
        !confirmPasswordError;



    // const handleSignupWithOtp = async () => {
    //     if (isFormValid) {
    //         try {
    //             const { isSignUpComplete, userId, nextStep } = await signUp({
    //                 username: email,
    //                 password,
    //                 options: {
    //                     autoSignIn: false
    //                 }
    //             });

    //             // if (nextStep.signUpStep === "CONFIRM_SIGN_UP") {
    //             //     navigate("/verify-otp", { state: { email, password } });
    //             // } else {
    //             //     console.error("Unexpected sign-up step:", nextStep.signUpStep);
    //             //     setSignUpError("An unexpected error occurred. Please try again.");
    //             // }
    //         } catch (error) {
    //             console.error("Error initiating sign up:", error);
    //             setSignUpError("An error occurred during sign up initiation. Please try again.");
    //         }
    //     }
    // };

    const handleSignupsWithOtp = () => {
        navigate("/signup-page");
    };

    const handleTermsandCondition = () => {
        navigate("/terms-condition");
    };

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setEmail(value);
        if (!validateEmail(value)) {
            setEmailError("Please enter a valid email address.");
        } else {
            setEmailError("");
        }
    };

    const getColor = (condition: boolean) => {
        return condition ? "green" : focused ? "#dc3545" : "black";
    };
    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setNewPassword(value);

        // Update validation states
        setValidationStates({
            length: value.length >= 6,
            uppercase: /[A-Z]/.test(value),
            lowercase: /[a-z]/.test(value),
            number: /\d/.test(value),
            specialChar: /[@#$%^&!,.<>':;|_~=`+\-/=]/.test(value),
        });

        // Clear error when user is typing
        if (passwordError) {
            setPasswordError("");
        }
    };

    const handlePasswordBlur = () => {
        if (!newPassword.trim()) {
            setPasswordError("Please enter your password.");
        } else if (
            !validationStates.length ||
            !validationStates.uppercase ||
            !validationStates.lowercase ||
            !validationStates.number ||
            !validationStates.specialChar
        ) {
            setPasswordError("");
        } else {
            setPasswordError("");
        }
    };

    const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setConfirmNewPassword(value);

        if (value !== newPassword) {
            setConfirmPasswordError("Passwords do not match.");
        } else {
            setConfirmPasswordError("");
        }
    };

    const handleConfirmPasswordBlur = () => {
        if (!confirmNewPassword.trim()) {
            setConfirmPasswordError("Please confirm your password.");
        } else if (confirmNewPassword !== newPassword) {
            setConfirmPasswordError("Passwords do not match.");
        } else {
            setConfirmPasswordError("");
        }
    };

    // const handleConfirmPasswordChange = (
    //     e: React.ChangeEvent<HTMLInputElement>
    // ) => {
    //     const value = e.target.value;
    //     setConfirmPassword(value);
    //     if (value !== password) {
    //         setConfirmPasswordError("Passwords do not match.");
    //     } else {
    //         setConfirmPasswordError("");
    //     }
    // };

    // const handleGoogleSignIn = async () => {
    //     try {
    //         await signInWithRedirect({ provider: "Google" });
    //     } catch (error) {
    //         console.error("Error signing in with Google:", error);
    //         setSignUpError(
    //             "An error occurred during Google sign in. Please try again."
    //         );
    //     }
    // };

    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
		const paste = e.clipboardData.getData("text");
		if (/^\d{6}$/.test(paste)) {
			const newOtp = paste.split("");
			setOtp(newOtp);
			
			// Focus the last input field after pasting
			const lastInput = document.getElementById(`otp-input-5`);
			if (lastInput) lastInput.focus();
		}
		e.preventDefault();
	};
	
    const handleFocus = (index: number) => {
        const input = document.getElementById(`otp-input-${index}`);
        input?.focus();
    };

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
		if (e.key === "Backspace" && !otp[index] && index > 0) {
			const newOtp = [...otp];
			newOtp[index - 1] = "";
			setOtp(newOtp);
			const prevInput = document.getElementById(`otp-input-${index - 1}`);
			if (prevInput) prevInput.focus();
		}
	};

    useEffect(() => {
		if (notificationPopup) {
			const timer = setTimeout(() => {
				setNotificationPopup(false);
			}, 3000);

			return () => clearTimeout(timer);
		}
	}, [notificationPopup]);

    const handleResendOtp = async () => {
        setOtp(Array(6).fill(''));
        setTimer(30);
        setIsResendDisabled(true);
        setShowTimer(true)
        try {
            if (email && !emailError) {
                await Auth.forgotPassword(email); // Call reset password function
                setToasterMessage("OTP has been resent successfully.")
                setNotificationPopup(true);
            } else {
                setVerificationError("Invalid email. Please try again.");
            }
        } catch (error) {
            console.error("Error during OTP resend:", error);
            setSignUpError("Failed to resend OTP. Please try again.");
        }
        setVerificationError('');

    };

    useEffect(() => {
        if (timer > 0) {
            const interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);

            return () => clearInterval(interval); // Cleanup interval on unmount
        } else {
            setShowTimer(false); // Hide the timer when it reaches 0
        }
    }, [timer]);
    const isOtpValid = otp.every(num => num !== '');
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
		const value = e.target.value;
	
		// Only allow numeric values
		if (!/^\d*$/.test(value)) return;
	
		const newOtp = [...otp];
		newOtp[index] = value;
		setOtp(newOtp);
	
		// Focus the next input box after the current one
		if (value && index < otp.length - 1) {
			setTimeout(() => {
				const nextInput = document.getElementById(`otp-input-${index + 1}`);
				if (nextInput) nextInput.focus();
			}, 50); // Small delay to ensure the state is updated
		}
	};


    const handleResetPassword = async () => {
        if (!newPassword) {
            setResetPasswordError("New password is required.");
            return;
        }
        setLoading(true)

        try {
            await Auth.forgotPasswordSubmit(
                email,
                otp.join(''),
                newPassword

            );
            // Handle successful password reset
            setToasterMessage('Password changed successfully')
            setNotificationPopup(true);
            setTimeout(() => {
                navigate("/login-account");
            }, 2000);
            // navigate("/login-account");
        } catch (error) {
            // navigate("/login-account");
            console.error("Error resetting password:", error);
            setResetPasswordError(error);
            setLoading(false)
            setToasterMessage('An error occurred. Please try again.')
            setNotificationPopup(true);
            console.log("error")

        }
    };

    return (
        <>
        {notificationPopup && (
				<div className="notification-wrapper">
					<NotificationBox message={toasterMessage} />
				</div>
			)}
            <div className="phone-view">
                <div className="signup-form forgot-ph-cont">
                    {/* <div>
                                    {" "}
                                    <img src="assets/Logo.svg" alt="Center Logo" className="logo-desktop" />
                                </div> */}
                    {/* otp section */}
                    <div onClick={() => navigate('/forgot-password')} className="d-flex gap-2 text-black cursor-pointer">
                        <img  src="assets/back-btn.svg" alt="Center Logo" className="logo-mr-0 ml-auto d-flex" />
                        <span>Back</span>
                    </div>

                    <div className="verify-otp-containers">
                        <div className='otp_sent_info'>
                            Please enter the OTP we have sent you on{' '}
                            {email}
                        </div>
                        <div className="mb-2 text-black text-start">OTP</div>
                        <div className="otp-inputssr">
                            {otp.map((value, index) => (
                                <React.Fragment key={index}>
                                    <input
                                        id={`otp-input-${index}`}
                                        maxLength={1}
                                        type="tel"
										autoFocus={index === 0}
										value={value}
										onChange={e => handleChange(e, index)}
										onKeyDown={e => handleKeyDown(e, index)}
										onPaste={handlePaste}
                                        inputMode="numeric" 
                                        onClick={() => handleFocus(index)}
 

                                    />
                                    {(index + 1) % 3 === 0 && index < otp.length - 1 && (
                                        <span className="hyphen"></span>
                                    )}
                                </React.Fragment>
                            ))}
                        </div>
                        {verificationError && <div className="error-message">{verificationError}</div>}

                        <div className="receive-timer-container d-flex align-items-center ">
                            <div className='receive text-nowrap'>
                                Didn't receive OTP? <button style={{ border: "0", backgroundColor: "transparent" }} className='resend-text' disabled={timer > 0} onClick={handleResendOtp}>Resend OTP  </button>
                            </div>
                            {showTimer && <div className="timer-text text-nowrap ">
                                in{' '} {' '}{timer > 0 ? `00:${timer < 10 ? `0${timer}` : timer}` : '00:00'} seconds
                            </div>}
                        </div>



                    </div>
                    {/* password */}
                    <div className="form-group mt-3">
                        <label className="ltext">New Password</label>

                        <div className="password-input-container">
                            <input
                                type={confirmpasswordVisible ? "text" : "password"}
                                value={newPassword}
                                onChange={handlePasswordChange}
                                onBlur={handlePasswordBlur}
                                onFocus={() => setFocused(true)}
                                placeholder="Enter new password"
                                className="input-field"
                                style={{
                                    fontWeight: newPassword ? "400" : "",
                                    fontSize: newPassword ? "16px" : "",
                                }}
                            />

                            <span
                                onClick={togglePasswordVisibilityconfirm}
                                className="eyeicon"

                            >
                                {confirmpasswordVisible ? <FaEye /> : <FaEyeSlash />}
                            </span>
                        </div>
                        {passwordError && (
                            <span className="error-text error">{passwordError}</span>
                        )}
                        <div className="text-start mt-2">
                            Password must contain a{" "}
                            <span style={{ color: getColor(validationStates.length) }}>
                                minimum of 6 characters
                            </span>
                            ,{" "}
                            <span style={{ color: getColor(validationStates.uppercase) }}>
                                1 uppercase letter
                            </span>
                            ,{" "}
                            <span style={{ color: getColor(validationStates.lowercase) }}>
                                1 lowercase letter
                            </span>
                            ,{" "}
                            <span style={{ color: getColor(validationStates.number) }}>
                                1 number
                            </span>{" "}
                            and{" "}
                            <span
                                style={{
                                    color: getColor(validationStates.specialChar)
                                }}
                            >
                                1 special character
                            </span>
                            .
                        </div>
                    </div>
                    {/* confirm password */}
                    <div className="form-group confirm_password">
                        <label className="ltext">Confirm New Password</label>
                        <div className="password-input-container">
                            <input
                                type={passwordVisible ? "text" : "password"}
                                value={confirmNewPassword}
                                onChange={handleConfirmPasswordChange}
                                onBlur={handleConfirmPasswordBlur}
                                placeholder="Confirm new password"
                                className="input-field"
                                style={{
                                    fontWeight: confirmNewPassword ? "400" : "",
                                    fontSize: confirmNewPassword ? "16px" : "",
                                }}

                            />
                            <span
                                onClick={togglePasswordVisibility}
                                className="eyeicon"

                            >

                                {passwordVisible ? <FaEye /> : <FaEyeSlash />}
                            </span>
                        </div>
                        {confirmPasswordError && (
                            <span className="error-text error">{confirmPasswordError}</span>
                        )}
                    </div>


                    <div className="sign_btn">
                        <button
                            className={`proceed-button ${isOtpValid && newPassword === confirmNewPassword ? "enabled" : "disabled"}`}
                            disabled={!isOtpValid || newPassword !== confirmNewPassword}
                            onClick={handleResetPassword}
                        >
                            Change Password {loading && <div className=" ms-2 spinner-border text-white spinner-border-sm" role="status">
							<span className="visually-hidden">Loading...</span>
						</div>}
                        </button>
                    </div>

                    <p className="new_user_signup_text mt-4">
                        Try signing in again? {''}
                        <Link className="login_create" to="/login-account">
                            Sign In
                        </Link>
                    </p>

                </div>
            </div>
            {/* second container */}

            <div className="desktop-view">
                <div className="main-container">
                    <div className="right-panel ">
                        <div className="forget-password-panel-desktop">
                            <div className="singup-form">

                                {/* <div>
                                    {" "}
                                    <img src="assets/Logo.svg" alt="Center Logo" className="logo-desktop" />
                                </div> */}
                                {/* otp section */}
                                <div onClick={() => navigate('/forgot-password')} className="d-flex gap-2 text-black cursor-pointer">
                                    <img onClick={() => navigate('/forgot-password')} src="assets/back-btn.svg" alt="Center Logo" className="logo-mr-0 ml-auto d-flex" />
                                    <span>Back</span>
                                </div>

                                <div className="verify-otp-containers">
                                    <div className='otp_sent_info'>
                                        Please enter the OTP we have sent you on{' '}
                                        {email}
                                    </div>
                                    <div className="mb-2 text-black text-start">OTP</div>

                                    <div className="otp-inputssr">
                                        {otp.map((value, index) => (
                                            <React.Fragment key={index}>
                                                <input
                                                    id={`otp-input-${index}`}
                                                    maxLength={1}
                                                    type="tel"
                                                    autoFocus={index === 0}
                                                    value={value}
                                                    onChange={e => handleChange(e, index)}
                                                    onKeyDown={e => handleKeyDown(e, index)}
                                                    onPaste={handlePaste}
                                                    inputMode="numeric" 
                                                    onClick={() => handleFocus(index)}
                                                />
                                                {(index + 1) % 3 === 0 && index < otp.length - 1 && (
                                                    <span className="hyphen"></span>
                                                )}
                                            </React.Fragment>
                                        ))}
                                    </div>
                                    {verificationError && <div className="error-message">{verificationError}</div>}

                                    <div className="receive-timer-container d-flex align-items-center ">
                                        <div className='receive text-nowrap'>
                                            Didn't receive OTP? <button style={{ border: "0", backgroundColor: "transparent" }} className='resend-text' disabled={timer > 0} onClick={handleResendOtp}>Resend OTP  </button>
                                        </div>
                                        {showTimer && <div className="timer-text text-nowrap ">
                                            in{' '} {' '}{timer > 0 ? `00:${timer < 10 ? `0${timer}` : timer}` : '00:00'} seconds
                                        </div>}
                                    </div>



                                </div>
                                {/* password */}
                                <div className="form-group">
                                    <label className="ltext">New Password</label>

                                    <div className="password-input-container">
                                        <input
                                            type={confirmpasswordVisible ? "text" : "password"}
                                            value={newPassword}
                                            onChange={handlePasswordChange}
                                            onBlur={handlePasswordBlur}
                                            onFocus={() => setFocused(true)}
                                            placeholder="Enter new password"
                                            className="input-field"
                                            style={{
                                                fontWeight: newPassword ? "400" : "",
                                                fontSize: newPassword ? "16px" : "",
                                            }}
                                        />

                                        <span
                                            onClick={togglePasswordVisibilityconfirm}
                                            className="eyeicon"

                                        >
                                            {confirmpasswordVisible ? <FaEye /> : <FaEyeSlash />}
                                        </span>
                                    </div>
                                    {passwordError && (
                                        <span className="error-text error">{passwordError}</span>
                                    )}
                                    <div className="text-start mt-2">
                                        Password must contain a{" "}
                                        <span style={{ color: getColor(validationStates.length) }}>
                                            minimum of 6 characters
                                        </span>
                                        ,{" "}
                                        <span
                                            style={{ color: getColor(validationStates.uppercase) }}
                                        >
                                            1 uppercase letter
                                        </span>
                                        ,{" "}
                                        <span
                                            style={{ color: getColor(validationStates.lowercase) }}
                                        >
                                            1 lowercase letter
                                        </span>
                                        ,{" "}
                                        <span style={{ color: getColor(validationStates.number) }}>
                                            1 number
                                        </span>{" "}
                                        and{" "}
                                        <span
                                            style={{
                                                color: getColor(validationStates.specialChar)
                                            }}
                                        >
                                            1 special character
                                        </span>
                                        .
                                    </div>
                                </div>
                                {/* confirm password */}
                                <div className="form-group confirm_password">
                                    <label className="ltext">Confirm New Password</label>
                                    <div className="password-input-container">
                                        <input
                                            type={passwordVisible ? "text" : "password"}
                                            value={confirmNewPassword}
                                            onChange={handleConfirmPasswordChange}
                                            onBlur={handleConfirmPasswordBlur}
                                            placeholder="Confirm new password"
                                            className="input-field"
                                            style={{
                                                fontWeight: confirmNewPassword ? "400" : "",
                                                fontSize: confirmNewPassword ? "16px" : "",
                                            }}

                                        />
                                        <span
                                            onClick={togglePasswordVisibility}
                                            className="eyeicon"

                                        >

                                            {passwordVisible ? <FaEye /> : <FaEyeSlash />}
                                        </span>
                                    </div>
                                    {confirmPasswordError && (
                                        <span className="error-text error">{confirmPasswordError}</span>
                                    )}
                                </div>


                                <div className="sign_btn">
                                    <button
                                        className={`proceed-button ${isOtpValid && newPassword === confirmNewPassword ? "enabled" : "disabled"}`}
                                        disabled={!isOtpValid || newPassword !== confirmNewPassword}
                                        onClick={handleResetPassword}
                                    >
                                        Change Password {loading && <div className=" ms-2 spinner-border text-white spinner-border-sm" role="status">
							<span className="visually-hidden">Loading...</span>
						</div>}
                                    </button>
                                </div>

                                <p className="new_user_signup_text mt-4">
                                    Try signing in again? {''}
                                    <Link className="login_create" to="/login-account">
                                        Sign In
                                    </Link>
                                </p>

                            </div>
                        </div>
                        <br></br>
                        <br></br>
                        {/* <div className="footers-container">
                            <div className='footers-text'>  <span >
                                © Konfhub 2024
                            </span>
                            </div>

                            <div>Privacy Policy</div>
                        </div> */}


                    </div>
                    <div className="left-panel auth-screen-container" style={{ backgroundImage: `url('/assets/layesImage.svg')` }}>
                        <div className="leftpanel-tag-container">
                            {/* <img src="assets/StarforLeft.svg" alt="this is star img" />
                            <p className="tagline-for-leftpanel">
                                Trusted by over 10,000 event organizers worldwide.
                            </p> */}
                        </div>
                    </div>
                </div>



            </div>
        </>
    );

};

export default ForgotPasswordDesktopOtp;
