import { graphqlUrl } from '../config/aws-exports';
import { apiUrl } from '../config/crud';
import axios from './axios';

const CHAT_API_URL = graphqlUrl;

export const getListOfAttendees = ({ eventId, limit, offset, searchTerm }: any) => {
    return axios.get(
        `${apiUrl}/attendee-app/event/${eventId}/att-app/attendees${limit ? `?limit=${limit}` : ""}${offset ? `&offset=${offset}` : ""}${searchTerm ? `&search_param=${searchTerm}` : ""}`
    );
};

const apiService = {
    async listChats(eventId: string) {
        try {
            const response = await axios.post(CHAT_API_URL, {
                query: `
          query ListChats($eventId: String!) {
            listChats(eventId: $eventId) {
              room_id
              sender_id
              sender_name
              sender_image_url
              last_msg
              unread_count
              last_msg_at
              receiver_id
              receiver_name
              receiver_image_url
            }
          }
        `,
                variables: { eventId },
            });
            return response.data.data.listChats;
        } catch (error) {
            console.error('Error fetching chats:', error);
            throw error;
        }
    },

    async listMessages({ eventId, roomId, receiverId, limit, offset }: any) {
        try {
            const response = await axios.post(CHAT_API_URL, {
                query: `
          query ListMessages($eventId: String!, $roomId: String, $receiverId: String, $limit: Int, $offset: Int) {
            listMessages(eventId: $eventId, roomId: $roomId, receiverId: $receiverId, limit: $limit, offset: $offset) {
              room_id
              msg_id
              message
              sent_at
              sender_id
              receiver_id
              status
              attachment
              read_at
            }
          }
        `,
                variables: { eventId, roomId, receiverId, limit, offset },
            });
            return response.data.data.listMessages;
        } catch (error) {
            console.error('Error fetching messages:', error);
            throw error;
        }
    },

    async sendMessage({ eventId, receiverId, receiverName, receiverImageUrl, roomId = null, message = 'Hi', attachment = null }: any) {
        try {
            const response = await axios.post(CHAT_API_URL, {
                query: `
                    mutation SendMessage($eventId: String!, $roomId: String, $receiverId: String!, $receiverName: String!, $receiverImageUrl: String, $message: String, $attachment: String) {
                        sendMessage(eventId: $eventId, roomId: $roomId, receiverId: $receiverId, receiverName: $receiverName, receiverImageUrl: $receiverImageUrl, message: $message, attachment: $attachment) {
                        statusCode
                        eventId
                        message
                        senderName
                        senderImageUrl
                        attachment
                        roomId
                        receiverId
                        unreadCount
                        error
                        }
                    }
                `,
                variables: { eventId, roomId, receiverId, receiverName, receiverImageUrl, message, attachment },
            });
            return response.data.data.sendMessage;
        } catch (error) {
            console.error('Error sending message:', error);
            throw error;
        }
    },

    async updateMessage(eventId: string, roomId: string) {
        try {
            const response = await axios.post(CHAT_API_URL, {
                query: `
          mutation UpdateMessage($eventId: String!, $roomId: String!) {
            updateMessage(eventId: $eventId, roomId: $roomId)
          }
        `,
                variables: { eventId, roomId },
            });
            return response.data.data.updateMessage;
        } catch (error) {
            console.error('Error updating message:', error);
            throw error;
        }
    },
};

export default apiService;
