import { useState, useRef, useEffect } from "react";
import ModuleHeading from "../../CommomComponents/ModuleHeading/ModuleHeading";
import { useEvent } from "../../contexts/EventContext";
import Footer from "../Footer/Footer";
import SideNavbar from "../SideNavbar/SideNavbar";
import { useNavigate } from "react-router-dom";
import { Slide } from "react-slideshow-image";
import { saveAs } from "file-saver";

import "./PhotoBooth.css";
import "react-slideshow-image/dist/styles.css";
import { ClipLoader } from "react-spinners";
import { Button, Skeleton } from "@mui/material";
import axios from "axios";
import { genAvatar, getAvatars, uploadImage } from "../../crud/photoBooth.crud";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ModuleDescription from "../../CommomComponents/ModuleDescription/ModuleDescription";
import { getModuleDescriptionByName, getModuleNameByType } from "../../reusable/common";
import { useModule } from "../../contexts/ModuleContext";
// import { ClipLoader } from "react-spinners";
// import { spinnerStyle } from "../../config/app";

const PhotoBooth = () => {
	const [attendeeImageKey, setAttendeeImageKey] = useState<string | null>(null);
	const { modulesDetails } = useModule();
	const [image, setImage] = useState<string | null>(null);
	const [avatars, setAvatars] = useState<string[]>([]);
	const [targetImage, setTargetImage] = useState<string | null>(null);
	const videoRefDesktop = useRef<HTMLVideoElement>();
	const videoRefMobile = useRef<HTMLVideoElement>();
	const navigate = useNavigate();
	const { eventDetails } = useEvent();
	const [showAvatars, setShowAvatars] = useState(true);
	const [loading, setLoading] = useState(false);
	const [imageCapturing, setImageCapturing] = useState(false);
	const [consent, setConsent] = useState(false);
	const currentUserAttendeeId = localStorage.getItem("attendeeId");
	const [isMobile, setIsMobile] = useState(false);
	const [isCameraAccessible, setCameraAccessible] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [isDeskOpen, setIsDeskOpen] = useState(false);

	const [copySuccess, setCopySuccess] = useState(false);
	const modalRefMobile = useRef(null);
	const modalRef = useRef(null);

	const moduleHeading = getModuleNameByType(modulesDetails, 15);

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-unused-expressions
		window.screen.width <= 760 ? setIsMobile(true) : setIsMobile(false);
		if (window.screen.width <= 760) {
			navigator.mediaDevices
				.getUserMedia({ video: true })
				.then(stream => {
					if (videoRefMobile.current) {
						videoRefMobile.current.srcObject = stream;
						setCameraAccessible(true);
					}
				})
				.catch(error => {
					if (
						error.name === "ConstraintNotSatisfiedError" ||
						error.name === "OverconstrainedError"
					) {
						showToast("error", "Video  is not supported by your device.");
					} else if (
						error.name === "PermissionDeniedError" ||
						error.name === "NotAllowedError"
					) {
						showToast(
							"error",
							"Permissions have not been granted to use your microphone, you need to allow the page access to your devices"
						);
					} else if (
						error.name === "DevicesNotFoundError" ||
						error.name === "NotFoundError"
					) {
						showToast("error", "No cameras found on this device");
					} else {
						showToast("error", "getUserMedia error: " + error.name, error);
					}
				});
		} else {
			navigator.mediaDevices
				.getUserMedia({ video: true })
				.then(stream => {
					if (videoRefDesktop.current) {
						videoRefDesktop.current.srcObject = stream;
						setCameraAccessible(true);
					}
				})
				.catch(error => {
					if (
						error.name === "ConstraintNotSatisfiedError" ||
						error.name === "OverconstrainedError"
					) {
						showToast("error", "Video  is not supported by your device.");
					} else if (
						error.name === "PermissionDeniedError" ||
						error.name === "NotAllowedError"
					) {
						console.log("inside");
						showToast(
							"error",
							"Permissions have not been granted to use your camera, you need to allow the page access to your devices"
						);
					} else if (
						error.name === "DevicesNotFoundError" ||
						error.name === "NotFoundError"
					) {
						showToast("error", "No cameras found on this device");
					} else {
						showToast("error", "getUserMedia error: " + error.name, error);
					}
				});
		}
	}, [window.screen.width]);

	function detectWindowSize() {
		// eslint-disable-next-line @typescript-eslint/no-unused-expressions
		window.innerWidth <= 760 ? setIsMobile(true) : setIsMobile(false);
	}

	window.onresize = detectWindowSize;

	console.log(isMobile);

	useEffect(() => {
		console.log("inside effect");
		populateAvatars();
	}, []);

	const populateAvatars = async () => {
		const response: any = await getAvatars(eventDetails.event_id);
		setAvatars([...response.data]);
	};

	const toggleModal = () => {
		setIsOpen(prevState => !prevState);
	};
	const toggleModalDesk = () => {
		setIsDeskOpen(prevState => !prevState);
	};

	const handleClickOutside = (event: MouseEvent | TouchEvent) => {
		if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
			setIsOpen(false);
			setIsDeskOpen(false);
		}
	};

	useEffect(() => {
		// getSubdomain();
		// const handleClickOutside = event => {
		// 	if (modalRef.current && !modalRef.current.contains(event.target)) {
		// 		setIsOpen(false);
		// 	}
		// };
		if (isOpen) {
			document.addEventListener("mousedown", handleClickOutside);
			document.addEventListener("touchstart", handleClickOutside);
		} else {
			document.removeEventListener("mousedown", handleClickOutside);
			document.removeEventListener("touchstart", handleClickOutside);
		}
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
			document.removeEventListener("touchstart", handleClickOutside);
		};
	}, [isOpen, isDeskOpen]);
	const copyToClipboard = () => {
		const url = window.location.href;
		navigator.clipboard.writeText(url).then(
			() => {
				setCopySuccess(true);
				setTimeout(() => setCopySuccess(false), 2000);
			},
			err => {
				console.error("Failed to copy: ", err);
			}
		);
	};

	const uploadToUrl = async (uploadUrl: string, image: any) => {
		try {
			const s3Axios = axios.create();
			const response = await s3Axios.put(uploadUrl, image, {
				headers: {
					"Content-Type": "image/jpeg"
				}
			});
			return response;
		} catch (error) {
			console.error("error", error);
			return;
		}
	};

	const dataURItoBlob = (dataURI: string) => {
		const binary = atob(dataURI.split(",")[1]);
		const array = [];
		for (let i = 0; i < binary.length; i++) {
			array.push(binary.charCodeAt(i));
		}
		return new Blob([new Uint8Array(array)], { type: "image/jpeg" });
	};

	const captureImage = async () => {
		if (image) {
			if (isMobile) {
				navigator.mediaDevices.getUserMedia({ video: true }).then(stream => {
					if (videoRefMobile.current) {
						videoRefMobile.current.srcObject = stream;
					}
				});
			} else {
				navigator.mediaDevices.getUserMedia({ video: true }).then(stream => {
					if (videoRefDesktop.current) {
						videoRefDesktop.current.srcObject = stream;
					}
				});
			}
			setImage(null);
			return;
		}
		setImageCapturing(true);
		const canvas = document.createElement("canvas");
		if (videoRefDesktop.current) {
			canvas.width = isMobile
				? (videoRefMobile.current as HTMLVideoElement).videoWidth
				: videoRefDesktop.current.videoWidth;
			canvas.height = isMobile
				? (videoRefMobile.current as HTMLVideoElement).videoHeight
				: videoRefDesktop.current.videoHeight;
			canvas
				.getContext("2d")
				?.drawImage(
					isMobile
						? (videoRefMobile.current as HTMLVideoElement)
						: videoRefDesktop.current,
					0,
					0
				);
			const imageDataUrl = canvas.toDataURL("image/jpeg", 1.0);
			const fileUrlResponse = await uploadImage(
				eventDetails.event_id,
				`${currentUserAttendeeId}_${Date.now()}.jpeg`
			);
			setAttendeeImageKey(fileUrlResponse.data?.attendee_image_key);
			uploadToUrl(fileUrlResponse.data.upload.url, dataURItoBlob(imageDataUrl));
			setImage(imageDataUrl);

			if (isMobile) {
				(videoRefMobile.current as HTMLVideoElement).srcObject
					?.getTracks()
					.forEach((track: any) => {
						track.stop();
					});
			} else {
				videoRefDesktop.current.srcObject?.getTracks().forEach((track: any) => {
					track.stop();
				});
			}
			setImageCapturing(false);
		}
	};

	const selectTargetImage = (image: string) => {
		setTargetImage(image);
	};

	const CustomIcon = ({ type }: { type: string }) => {
		switch (type) {
			case "info":
				return <>ℹ️</>;
			case "success":
				return <>✅</>;
			case "error":
				return <>❌</>;
			case "warning":
				return <>⚠️</>;
			default:
				return null;
		}
	};

	const showToast = (type: "info" | "success" | "error" | "warning", message: string) => {
		setTimeout(() => {
			toast[type](message, {
				icon: <CustomIcon type={type} />,
				position: "bottom-center",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true
			});
		}, 0);
	};

	const generateAvatar = async () => {
		setLoading(true);
		try {
			const imageURL = await genAvatar(
				eventDetails.event_id,
				targetImage as string,
				attendeeImageKey as string
			);
			setImage(imageURL.data.generated_image_url);
			setShowAvatars(false);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			showToast("warning", error.response.data.error);
		}
	};

	const toBlob = async (url: string) => {
		return await fetch(url, {
			method: "GET",
			mode: "cors"
		}).then(res => res.blob());
	};

	const downloadAvatar = async () => {
		const blob = await toBlob(image as string);
		saveAs(blob, "image.jpeg"); // Put your image URL here.
	};

	const goBack = () => {
		navigate("/");
	};

	return (
		<>
			<div className="mobile-view">
				<div className="photo-booth-container">
					{/* <div> */}
					<div className="en">
						<ModuleHeading moduleHeading={moduleHeading} goBack={goBack} />
					</div>
					<ModuleDescription
						description={getModuleDescriptionByName(modulesDetails, moduleHeading)}
					/>
					{!showAvatars && !image && (
						<Skeleton
							sx={{ bgcolor: "grey.900" }}
							variant="rectangular"
							width={"92vw"}
							height={"74vh"}
						/>
					)}
					{/* Share Modal */}
					{isOpen && (
						<div
							className="share-modal-overlay"
							onClick={e => {
								e.stopPropagation();
								setIsOpen(false);
							}}
						>
							<div
								className="share-modal share-modal-mob"
								ref={modalRefMobile}
								onClick={e => e.stopPropagation()}
							>
								<div className="share-modal-header">
									<h2 className="share-title">Share</h2>
								</div>

								<div className="share-options">
									{[
										{
											name: "WhatsApp",
											icon: "assets/whatsappgroup.svg",
											url: `https://api.whatsapp.com/send?text=${encodeURIComponent(
												image as string
											)}`
										},
										{
											name: "Facebook",
											icon: "assets/facebookgroup.svg",
											url: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
												image as string
											)}`
										},
										{
											name: "Email",
											icon: "assets/email-1.svg",
											url: `mailto:?subject=Check this out&body=${encodeURIComponent(
												image as string
											)}`
										},
										{
											name: "X",
											icon: "assets/twittergroup.svg",
											url: `https://twitter.com/intent/tweet?url=${encodeURIComponent(
												image as string
											)}`
										}
									].map(app => (
										<button
											key={app.name}
											className="share-option"
											onClick={e => {
												e.stopPropagation();
												window.open(app.url, "_blank");
											}}
										>
											<img
												src={app.icon}
												alt={app.name}
												className="share-option-icon"
											/>
											<span className="share-option-name">{app.name}</span>
										</button>
									))}
								</div>

								<div className="share-copy-link">
									<p className="share-copy-link-title">Copy Link</p>
									<div className="share-copy-link-input">
										<input
											type="text"
											value={image as string}
											readOnly
											className="share-copy-link-field"
										/>
										<button
											className="share-copy-button"
											onClick={e => {
												e.stopPropagation();
												copyToClipboard();
											}}
										>
											{copySuccess ? "Copied!" : "Copy"}
										</button>
									</div>
								</div>
							</div>
						</div>
					)}
					<div className="wrapper">
						{showAvatars && (
							<>
								<div style={{ position: "relative", display: "inline-block" }}>
									{image ? (
										<div className="capturedImage">
											<img src={image} className="camMobile" />
										</div>
									) : (
										<video
											ref={videoRefMobile}
											autoPlay={true}
											muted={true}
											playsInline={true}
											className="camMobile"
										/>
									)}
								</div>
								<Button
									variant="contained"
									onClick={captureImage}
									className="capture"
									disabled={consent || !isCameraAccessible}
								>
									{!imageCapturing ? (
										!image ? (
											"Capture"
										) : (
											"Retake"
										)
									) : (
										// <div style={spinnerStyle}>
										<ClipLoader
											loading={imageCapturing}
											size={20}
											color="#FFF"
											aria-label="Loading Spinner"
										/>
										// </div>
									)}
								</Button>
								{/* </div> */}
								<div className="test">
									<span className="alignLeft">Select Style</span>
									{avatars.length && (
										<Slide slidesToScroll={2} slidesToShow={3} autoplay={false}>
											{avatars.map((avatar, index) => (
												<div
													key={index}
													className="each-slide-effect"
													onClick={() => selectTargetImage(avatar)}
												>
													<div
														className={
															targetImage === avatar
																? "imgContainer highlight"
																: "imgContainer"
														}
													>
														<img src={avatar} />
													</div>
												</div>
											))}
										</Slide>
									)}
									<div className="consentMobile">
										<input
											type="checkbox"
											id="consent"
											checked={consent}
											onChange={() => setConsent(!consent)}
											className="consent-checkbox"
										></input>
										<label htmlFor="consent">
											I consent to my photo being uploaded to KonfHub and for
											using a third-party API for processing.
										</label>
									</div>
								</div>
								<div className="camera-action-buttons-wrapper">
									<Button
										variant="contained"
										onClick={generateAvatar}
										disabled={!(image && targetImage && consent)}
										className="fullWidth"
									>
										{!loading ? (
											"Generate Avatar"
										) : (
											// <div style={spinnerStyle}>
											<ClipLoader
												loading={loading}
												size={20}
												color="#FFF"
												aria-label="Loading Spinner"
											/>
											// </div>
										)}
									</Button>
								</div>
							</>
						)}
						{!showAvatars && (
							<>
								{image && (
									<div className="generatedImage">
										<img src={image} />
									</div>
								)}
								<div className="camera-action-buttons-wrapper">
									<Button
										variant="contained"
										onClick={downloadAvatar}
										className="custom-buttons-filled"
									>
										Download
									</Button>
									<Button
										variant="outlined"
										onClick={toggleModal}
										className="custom-buttons-filled"
									>
										Share
									</Button>
								</div>
							</>
						)}
					</div>
					<Footer />
				</div>
				{/* </div> */}
			</div>

			<div className="desktop-view">
				<div className="desktop-view-side">
					<div className="desktop-left-slider">
						<SideNavbar />
					</div>
					<div className="home-screens desktop-left-container font">
						<div className="">
							<ModuleHeading moduleHeading={moduleHeading} goBack={goBack} />
							<ModuleDescription
								description={getModuleDescriptionByName(
									modulesDetails,
									moduleHeading
								)}
							/>
						</div>
						{isDeskOpen && (
							<div
								className="share-modal-overlay"
								onClick={e => {
									e.stopPropagation();
									setIsDeskOpen(false);
								}}
							>
								<div
									className="share-modal"
									ref={modalRef}
									onClick={e => e.stopPropagation()}
								>
									<div className="share-modal-header">
										<h2 className="share-title">Share</h2>
									</div>

									<div className="share-options">
										{[
											{
												name: "WhatsApp",
												icon: "assets/whatsappgroup.svg",
												url: `https://api.whatsapp.com/send?text=${encodeURIComponent(
													image as string
												)}`
											},
											{
												name: "Facebook",
												icon: "assets/facebookgroup.svg",
												url: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
													image as string
												)}`
											},
											{
												name: "Email",
												icon: "assets/email-1.svg",
												url: `mailto:?subject=Check this out&body=${encodeURIComponent(
													image as string
												)}`
											},
											{
												name: "X",
												icon: "assets/twittergroup.svg",
												url: `https://twitter.com/intent/tweet?url=${encodeURIComponent(
													image as string
												)}`
											}
										].map(app => (
											<button
												key={app.name}
												className="share-option"
												onClick={e => {
													e.stopPropagation();
													window.open(app.url, "_blank");
												}}
											>
												<img
													src={app.icon}
													alt={app.name}
													className="share-option-icon"
												/>
												<span className="share-option-name">
													{app.name}
												</span>
											</button>
										))}
									</div>

									<div className="share-copy-link">
										<p className="share-copy-link-title">Copy Link</p>
										<div className="share-copy-link-input">
											<input
												type="text"
												value={image as string}
												readOnly
												className="share-copy-link-field"
											/>
											<button
												className="share-copy-button"
												onClick={e => {
													e.stopPropagation();
													copyToClipboard();
												}}
											>
												{copySuccess ? "Copied!" : "Copy"}
											</button>
										</div>
									</div>
								</div>
							</div>
						)}
						<div className="wrapper">
							{showAvatars && (
								<>
									<div
										style={{
											position: "relative",
											display: "inline-block",
											width: "342px",
											height: "342px"
										}}
									>
										{image ? (
											<div className="capturedImage">
												<img src={image} className="camDesktop" />
											</div>
										) : (
											<video
												ref={videoRefDesktop}
												autoPlay
												muted
												className="camDesktop"
											/>
										)}
									</div>
									<Button
										variant="contained"
										onClick={captureImage}
										className="capture"
										disabled={!isCameraAccessible}
									>
										{!imageCapturing ? (
											!image ? (
												"Capture"
											) : (
												"Retake"
											)
										) : (
											// <div style={spinnerStyle}>
											<ClipLoader
												loading={imageCapturing}
												size={20}
												color="#FFF"
												aria-label="Loading Spinner"
											/>
											// </div>
										)}
									</Button>

									<div className="test">
										<span className="alignLeft">Select Style</span>
										{avatars.length && (
											<Slide
												slidesToScroll={2}
												slidesToShow={5}
												autoplay={false}
											>
												{avatars.map((avatar, index) => (
													<div
														key={index}
														className="each-slide-effect"
														onClick={() => selectTargetImage(avatar)}
													>
														<div
															className={
																targetImage === avatar
																	? "imgContainer highlight"
																	: "imgContainer"
															}
														>
															<img src={avatar} />
														</div>
													</div>
												))}
											</Slide>
										)}
										<div className="consent">
											<input
												type="checkbox"
												id="consent"
												checked={consent}
												onChange={() => setConsent(!consent)}
												className="consent-checkbox"
											></input>
											<label htmlFor="consent">
												I consent to my photo being uploaded to KonfHub and
												for using a third-party API for processing.
											</label>
										</div>
									</div>
									<div className="camera-action-buttons-desktop-wrapper">
										<Button
											variant="contained"
											onClick={generateAvatar}
											disabled={!(image && targetImage && consent)}
											className="fullWidth"
										>
											{!loading ? (
												"Generate Avatar"
											) : (
												// <div style={spinnerStyle}>
												<ClipLoader
													loading={loading}
													size={20}
													color="#FFF"
													aria-label="Loading Spinner"
												/>
												// </div>
											)}
										</Button>
									</div>
								</>
							)}
							{!showAvatars && (
								<>
									{image && (
										<div className="generatedImage">
											<img src={image} />
										</div>
									)}
									<div className="camera-action-buttons-desktop-wrapper">
										<Button
											variant="contained"
											onClick={downloadAvatar}
											className="custom-buttons-filled"
										>
											Download
										</Button>
										<Button
											variant="outlined"
											onClick={toggleModalDesk}
											className="custom-buttons-filled"
										>
											Share
										</Button>
									</div>
								</>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default PhotoBooth;
