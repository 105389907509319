// import React from 'react';
// import { useNavigate } from 'react-router-dom';
// import './FloorPlanList.css'; // Add your styles here

// const FloorPlanList: React.FC = () => {
//   const navigate = useNavigate();

//   const handleFloorPlanClick = () => {
//     navigate(`/floor-plan-detail`);
//   };

//   return (
//     <div className="floor-plan-list">
//       <div className="floor-plan-item" onClick={() => handleFloorPlanClick()}>
//         <span>Floor Plan 1</span>
//         {/* <span className="arrow">{'>'}</span> */}
//         <img src="assets/uparrow.svg" className='floor_plan_arrow' alt="" />
//       </div>

//       <div className="floor-plan-item" onClick={() => handleFloorPlanClick()}>
//         <span>Floor Plan 2</span>
//         <img src="assets/uparrow.svg" className='floor_plan_arrow' alt="" />
//        </div>

//       <div className="floor-plan-item" onClick={() => handleFloorPlanClick()}>
//         <span>Floor Plan 3</span>
//         <img src="assets/uparrow.svg" className='floor_plan_arrow' alt="" />
//       </div>

//       <div className="floor-plan-item" onClick={() => handleFloorPlanClick()}>
//         <span>Floor Plan 4</span>
//         <img src="assets/uparrow.svg" className='floor_plan_arrow' alt="" />
 
//       </div>

//       <div className="floor-plan-item" onClick={() => handleFloorPlanClick()}>
//         <span>Floor Plan 5</span>
//         <img src="assets/uparrow.svg" className='floor_plan_arrow' alt="" />
//       </div>
//     </div>
//   );
// };

// export default FloorPlanList;

import React from 'react';
import './FloorPlanList.css';

interface FloorPlanData {
  floor_plan_id: string;
  floor_plan_order: number;
  name: string;
  file_url: string;
}

interface FloorPlanListProps {
  floorPlans: FloorPlanData[];
  onSelect: (floorPlan: FloorPlanData) => void;
}

const FloorPlanList: React.FC<FloorPlanListProps> = ({ floorPlans, onSelect }) => {
  return (
    <> 
    <div className='phone-view'>
    <div className="floor-plan-list">
      {floorPlans.map((floorPlan) => (
        <div
          key={floorPlan.floor_plan_id}
          className="floor-plan-item"
          onClick={() => onSelect(floorPlan)}
        >
          <span>{floorPlan.name}</span>
          <img src="assets/uparrow.svg" className='floor_plan_arrow' alt="" />
        </div>
      ))}
    </div>
    </div>
    <div className='desktop-view'>
    <div className="floor-plan-list">
      {floorPlans.map((floorPlan) => (
        <div
          key={floorPlan.floor_plan_id}
          className="floor-plan-item"
          onClick={() => onSelect(floorPlan)}
        >
          <span>{floorPlan.name}</span>
          <img src="assets/uparrow.svg" className='floor_plan_arrow' alt="" />
        </div>
      ))}
    </div>
    </div>

   
    </>
  );
};

export default FloorPlanList;