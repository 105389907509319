import React from 'react';
import './Gallery.css';
import Footer from '../Footer/Footer';
import { Bell } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import SideNavbar from '../SideNavbar/SideNavbar';
import { Header } from '../Header/Header';

const EventDetails: React.FC = () => {
    const navigate = useNavigate();


    const goBack = () => {

        navigate(-1);

    };

    return (
        <>
            <div className='phone-view'>
                <div className="gallery_container">
                    <div className='gallery_head'>
                        <div><span className='uns gall'>ELYSIAN - 2024</span></div>
                        <Bell className="icon interest_bell right-icon1" />
                    </div>


                    <div className='gallery-images'>
                        {/* First Line */}
                        <div >
                            <img src="assets/one.png" alt="" />
                        </div>

                        {/* Second Line */}
                        <div className='gal_3'>
                            <div className='gal_2'>
                                <img src="assets/two.png" alt="" />
                                <img src="assets/four.png" alt="" />
                            </div>
                            <div>
                                <img src="assets/three.png" alt="" />
                            </div>
                        </div>

                        {/* Third Line */}
                        <div >
                            <img src="assets/one.png" alt="" />
                        </div>
                        {/* Fourth Line */}
                        <div className='gal_4'>
                            <div>
                                <img src="assets/five.png" alt="" />
                            </div>
                            <div>
                                <img src="assets/six.png" alt="" />
                            </div>
                        </div>
                    </div>

                    <Footer />
                </div>
            </div>

            {/* desktop */}
            <div className='desktop-view'>
                <div className='desktop-view-side'>
                    <div>
                        <SideNavbar />
                    </div>
                    <div className="gallery_container-desktop">
                        <Header />
                        <div className='gallery-images'>
                            {/* First Line */}
                            <div >
                                <img src="assets/one.png" alt="" />
                            </div>

                            {/* Second Line */}
                            <div className='gal_3'>
                                <div className='gal_2'>
                                    <img src="assets/two.png" alt="" />
                                    <img src="assets/four.png" alt="" />
                                </div>
                                <div>
                                    <img src="assets/three.png" alt="" />
                                </div>
                            </div>

                            {/* Third Line */}
                            <div >
                                <img src="assets/one.png" alt="" />
                            </div>
                            {/* Fourth Line */}
                            <div className='gal_4'>
                                <div>
                                    <img src="assets/five.png" alt="" />
                                </div>
                                <div>
                                    <img src="assets/six.png" alt="" />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>

    );

};

export default EventDetails;
