import { useEffect, useState } from "react";
import SideNavbar from "../SideNavbar/SideNavbar";
import ModuleHeading from "../../CommomComponents/ModuleHeading/ModuleHeading";
import Footer from "../Footer/Footer";
import CustomDrawer from "../../CommomComponents/CustomDrawer/CustomDrawer";
import ExchangeBusinessCard from "./ExchangeBusinessCard/ExchangeBusinessCard";
import CustomModal from "../../CommomComponents/CustomModal/CustomModal";
import { getModuleDescriptionByName, getModuleNameByType, useScreenWidth } from "../../reusable/common";
import { exchangeBusinessCardApi, fetchExchangeBusinessCard } from "../../crud/scanner.crud";
import { useEvent } from "../../contexts/EventContext";
import QrScanner from "./QrScanner/QrScanner";
import "./ScanBadge.css";
import ScanBadgeNotificationBox from "./ScanBadgeNotificationBox";
import TicketPreview from "../../CommomComponents/Ticket/TicketPreview";
import ticket from "../../assets/ticket.png";
import ModuleDescription from "../../CommomComponents/ModuleDescription/ModuleDescription";
import { useModule } from "../../contexts/ModuleContext";
import CustomLoader from "../../CommomComponents/CustomLoader/CustomLoader";
import { CONNECTION_STATUS } from "../../config/app";

const ScanBadge = () => {
	const isSmallScreen = useScreenWidth();
	const { eventDetails } = useEvent();
	const { modulesDetails } = useModule();
	const [loading, setLoading] = useState(false);
	const [exchangeLoading, setExchangeLoading] = useState(false);
	const [activeTab, setActiveTab] = useState<any>("scan-qr-code");
	const [openBusinessCardDrawer, setOpenBusinessCardDrawer] = useState(false);
	const [openBusinessCardModal, setOpenBusinessCardModal] = useState(false);
	const [scanResponse, setScanResponse] = useState<any>(null);
	const [scanErrorMessage, setScanErrorMessage] = useState("");
	const [scanSuccessMessage, setScanSuccessMessage] = useState("");
	const [isScanningPaused, setIsScanningPaused] = useState(false);
	const [exchangeResponse, setExchangeResponse] = useState<any>(null);
	const [notificationPopup, setNotificationPopup] = useState(false);
	const moduleHeading = getModuleNameByType(modulesDetails,13);
	const registrations = localStorage.getItem("registrations");
	const registrationData = registrations?.length ? JSON.parse(registrations) : [];

	const extractValues = (rawValue: string) => {
		const idMatch = rawValue.match(/id:([^|]+)/); // Matches "id:" followed by anything until "|"
		const eidMatch = rawValue.match(/eid:([^|]+)/); // Matches "eid:" followed by anything until "|"
		return {
			id: idMatch ? idMatch[1] : null,
			eid: eidMatch ? eidMatch[1] : null
		};
	};

	const ifExchangeBusinessCard = async (scanResponse: any) => {
		setExchangeLoading(true);
		let rawValue = "";
		let id, eid;
		let otherId = "";
		if (scanResponse?.rawValue) {
			rawValue = scanResponse?.rawValue;
			if (rawValue?.includes("eid")) {
				({ id, eid } = extractValues(rawValue));
			} else {
				otherId = rawValue;
			}
		} else {
			setScanErrorMessage("Unable to fetch the details! Please scan properly");
			setIsScanningPaused(false);
			setNotificationPopup(true);
			setExchangeLoading(false);
		}

		if (id && eid) {
			if (eid !== eventDetails?.event_id) {
				setScanErrorMessage("You are not an attendee of this event");
				setIsScanningPaused(false);
				setNotificationPopup(true);
				setExchangeLoading(false);
			} else {
				//only attendee id
				try {
					const response = await fetchExchangeBusinessCard(eventDetails?.event_id, id);
					if (response?.status) {
						setExchangeResponse(response?.data);
						if(response?.data?.connection_status === CONNECTION_STATUS.CONNECTED){
							setScanSuccessMessage("Already connected");
							setNotificationPopup(true);
						}
					}
				} catch (error: any) {
					console.log("error", error);
					setScanErrorMessage(error?.message || "Something went wrong");
					setNotificationPopup(true);
					setIsScanningPaused(false);
				} finally {
					setExchangeLoading(false);
				}
			}
		} else if (otherId) {
			//det code or external_id
			try {
				const response = await fetchExchangeBusinessCard(eventDetails?.event_id, otherId);
				if (response?.status) {
					setExchangeResponse(response?.data);
					if(response?.data?.connection_status === CONNECTION_STATUS.CONNECTED){
						setScanSuccessMessage("Already connected");
						setNotificationPopup(true);
					}
				}
			} catch (error: any) {
				console.log("error", error);
				setScanErrorMessage(error?.message || "Something went wrong");
				setNotificationPopup(true);
				setIsScanningPaused(false);
			} finally {
				setExchangeLoading(false);
			}
		}
	};

	const handleExchangeCard = async (exchangeResponse: any) => {
		setLoading(true);
		try {
			const response: any = await exchangeBusinessCardApi(
				eventDetails?.event_id,
				exchangeResponse?.attendee_id
			);
			if (response?.status) {
				setScanErrorMessage("");
				setScanSuccessMessage("Card added to network");
				setNotificationPopup(true);
				setExchangeResponse(null);
				setIsScanningPaused(false);
				setOpenBusinessCardDrawer(false);
				setOpenBusinessCardModal(false);
			}
		} catch (error: any) {
			console.log("error", error);
			setNotificationPopup(true);
			setScanErrorMessage(error?.message || "Something went wrong");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (isScanningPaused) {
			ifExchangeBusinessCard(scanResponse);
		}
	}, [scanResponse, isScanningPaused]);

	useEffect(() => {
		if (exchangeResponse) {
			if (isSmallScreen) {
				setOpenBusinessCardDrawer(true);
			} else {
				setOpenBusinessCardModal(true);
			}
		}
	}, [exchangeResponse, isSmallScreen]);

	useEffect(() => {
		if (notificationPopup) {
			const timer = setTimeout(() => {
				setNotificationPopup(false);
				setScanErrorMessage("");
				setScanSuccessMessage("");
			}, 3000);
			return () => clearTimeout(timer);
		}
	}, [notificationPopup]);
	
	return (
		<>
			<div className="">
				<div className="d-flex">
					<div className="hide-in-mobile">
						<SideNavbar />
					</div>
					<div className="scanner-container">
						<ModuleHeading moduleHeading={moduleHeading} />
						<ModuleDescription
							description={getModuleDescriptionByName(modulesDetails, moduleHeading)}
						/>
						{!location.pathname.includes("scan-qr-code") && (
							<>
								<nav className="scanner-tabs">
									<button
										className={`scanner-section-tab ${activeTab === "scan-qr-code" ? "active" : ""}`}
										onClick={() => setActiveTab("scan-qr-code")}
									>
										Scan QR Code
									</button>
									<button
										className={`scanner-section-tab ${activeTab === "my-qr-code" ? "active" : ""}`}
										onClick={() => setActiveTab("my-qr-code")}
									>
										My QR Code
									</button>
								</nav>
							</>
						)}

						{activeTab === "scan-qr-code" && (
							<div>
								<QrScanner
									isScanningPaused={isScanningPaused}
									setScanResponse={setScanResponse}
									setIsScanningPaused={setIsScanningPaused}
								/>
								{exchangeLoading ? (
									<CustomLoader />
								) : (
									<>
										<CustomDrawer
											open={openBusinessCardDrawer}
											onOpen={() => {
												setOpenBusinessCardDrawer(true);
											}}
											onClose={() => {
												setExchangeResponse(null);
												setIsScanningPaused(false);
												setScanResponse(null);
												setOpenBusinessCardDrawer(false);
											}}
											component={
												<ExchangeBusinessCard
													loading={loading}
													businessCardData={exchangeResponse}
													onExchange={() => {
														handleExchangeCard(exchangeResponse);
													}}
													onCancel={() => {
														setExchangeResponse(null);
														setIsScanningPaused(false);
														setScanResponse(null);
														setOpenBusinessCardDrawer(false);
													}}
												/>
											}
										/>
										<CustomModal
											openCustomModal={openBusinessCardModal}
											onClose={() => {
												setOpenBusinessCardModal(false);
												setIsScanningPaused(false);
												setScanResponse(null);
												setOpenBusinessCardModal(false);
											}}
											maxWidth={"388px"}
											component={
												<ExchangeBusinessCard
													loading={loading}
													businessCardData={exchangeResponse}
													onExchange={() => {
														handleExchangeCard(exchangeResponse);
													}}
													onCancel={() => {
														setExchangeResponse(null);
														setIsScanningPaused(false);
														setScanResponse(null);
														setOpenBusinessCardModal(false);
													}}
												/>
											}
										/>
									</>
								)}
							</div>
						)}

						{activeTab === "my-qr-code" && (
							<div>
								{registrationData.length > 0 ? (
									<div className="d-flex justify-content-center flex-wrap mt-4 mb-5">
										{registrationData.map(ticketData => {
											return (
												<TicketPreview
													ticketDetails={ticketData}
												></TicketPreview>
											);
										})}
									</div>
								) : (
									<div className="d-flex justify-content-center flex-column align-items-center text-center margin-set">
										<img src={ticket} style={{ width: "50px" }}></img>
										<div className="tickets-text">No tickets available</div>
									</div>
								)}
							</div>
						)}
						{notificationPopup && (
							<div className="notification-wrapper">
								<ScanBadgeNotificationBox
									isError={scanErrorMessage !== ""}
									message={scanErrorMessage || scanSuccessMessage}
								/>
							</div>
						)}

						<div className="hide-in-desktop">
							<Footer />
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ScanBadge;
