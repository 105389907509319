import React, { createContext, useContext, useState, ReactNode, useEffect } from "react";

// Define the context type
interface ModuleContextType {
	modulesDetails: object | null | boolean | any; // User can be any object or null
	setModulesDetails: (user: object | null | boolean | any) => void; // Function to set user
	modulesNames: object | null | boolean | any; // User can be any object or null
	modulesLoading: boolean;
	modulesError: boolean;
	menuItems: object | null | boolean | any;
	setModulesLoading: (loading: boolean) => void;
	setModulesError: (error: boolean) => void;
	setModulesNames: (user: object | null | boolean | any) => void; // Function to set user
	setMenuItems: (menuItems: any) => void;
}

// Create the UserContext
const ModuleContext = createContext<ModuleContextType>({
	modulesDetails: null,
	setModulesDetails: () => {},
	modulesNames: {},
	modulesLoading: false,
	modulesError: false,
	menuItems: [],
	setModulesLoading: () => {},
	setModulesError: () => {},
	setModulesNames: () => {},
	setMenuItems: () => {}
});

// Define props that include children for UserProvider
interface ModuleProviderProps {
	children: ReactNode; // Prop to accept children
}

const ModuleProvider: React.FC<ModuleProviderProps> = ({ children }) => {
	const [modulesDetails, setModulesDetails] = useState(null);
	const [modulesNames, setModulesNames] = useState(null);
	const [modulesLoading, setModulesLoading] = useState(true);
	const [modulesError, setModulesError] = useState(false);
	const [menuItems, setMenuItems] = useState([]);

	return (
		<ModuleContext.Provider
			value={{
				modulesDetails,
				setModulesDetails,
				modulesNames,
				modulesLoading,
				modulesError,
				menuItems,
				setModulesLoading,
				setModulesError,
				setModulesNames,
				setMenuItems
			}}
		>
			{children}
		</ModuleContext.Provider>
	);
};

// Hook to use the User context
const useModule = () => useContext(ModuleContext);

export { ModuleProvider, useModule };
