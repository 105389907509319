import React, { useState, useEffect } from "react";
import "./SideNavbar.css";
import { Link, useLocation } from "react-router-dom";
import { useModule } from "../../contexts/ModuleContext";
import { Icon } from "@iconify/react";

interface NavItem {
	label: string;
	icon: string;
	hasSubMenu?: boolean;
	subMenuItems?: SubNavItem[];
	url: string;
}

interface SubNavItem {
	label: string;
	icon: string;
	url: string;
}

// const navItems: NavItem[] = [
//   { label: 'HOME', icon: 'home-outline', url: '/' },
//   { label: 'ADDRESS', icon: 'location-on-outline', url: '/address-page' },
//   { label: 'AGENDA', icon: 'calendar-month-outline', url: '/agenda' },
//   { label: "FLOOR PLAN", icon: "map-outline", url: "/floor-plan" },
//   { label: 'SPEAKERS', icon: 'frame-person-mic-outline', url: '/speakers' },
//   { label: 'PARTNERS', icon: 'handshake-outline', url: '/partners' },
//   // { label: 'NETWORKS', icon: 'network-node', url: '/network' },
//   { label: 'SPONSORS', icon: 'handshake-outline', url: '/sponsors' },
//   { label: 'EXHIBITOR', icon: 'handshake-outline', url: '/exhibitors' },
//   // { label: 'SCAN BADGE', icon: 'qr-code-scanner', url: '/scanner' },
// ];

/*const navItems: NavItem[] = [
	{ label: "SPONSORS", icon: "handshake-outline", url: "/sponsors", number: 1 },
	{ label: "SPEAKERS", icon: "frame-person-mic-outline", url: "/speakers", number: 2 },
	{ label: "EXHIBITORS", icon: "handshake-outline", url: "/exhibitors", number: 3 },
	{ label: "ADDRESS", icon: "location-on-outline", url: "/address-page", number: 5 },
	{ label: "PARTNERS", icon: "handshake-outline", url: "/partners", number: 7 },
	{ label: "AGENDA", icon: "calendar-month-outline", url: "/agenda", number: 8 },
	{ label: "FLOOR PLAN", icon: "map-outline", url: "/floor-plan", number: 10 }
	// { label: 'SCAN BADGE', icon: 'qr-code-scanner', url: '/scanner' },
	// { label: 'NETWORKS', icon: 'network-node', url: '/network' },
];*/

const SideNavbar: React.FC = () => {
	const location = useLocation();
	const { menuItems } = useModule();
	const navItems = menuItems;

	const getLabelByRoute = (route: string) => {
		const navItem = navItems.find(item => item.url === route);
		return navItem ? navItem.label : null;
	};

	const [activeItem, setActiveItem] = useState<string>(getLabelByRoute(location.pathname));
	const [isSubMenuOpen, setSubMenuOpen] = useState<boolean>(false);
	const [sortedNavItems, setSortedNavItems] = useState<NavItem[]>([]);

	const handleItemClick = (label: string, hasSubMenu?: boolean) => {
		setActiveItem(label);
		if (hasSubMenu) {
			setSubMenuOpen(prev => !prev);
		} else {
			setSubMenuOpen(false);
		}
	};

	useEffect(() => {
		const sortedItems = [...navItems].sort((a, b) => a.number - b.number);
		setSortedNavItems(sortedItems);
	}, []);

	return (
		<nav className="sidenavbar">
			<ul className="sidenavbar-nav-items" style={{ width: "250px" }}>
				<Link to="/" onClick={() => handleItemClick("HOME")}>
					<li
						className={`sidenavbar-nav-item ${activeItem === "HOME" ? "sidenavbar-active" : ""}`}
					>
						<Icon icon="material-symbols-light:home-outline" width={25} height={25} />
						<span className="sidenavbar-label">HOME</span>
					</li>
				</Link>

				{/* Render the rest of the sorted nav items */}
				{navItems.map(item => (
					<div key={item.label} onClick={() => item.onClick && item.onClick(item)}>
						<Link to={item.url} onClick={() => handleItemClick(item.label)}>
							<li
								className={`sidenavbar-nav-item ${activeItem === item.label ? "sidenavbar-active" : ""}`}
							>
								<Icon
									icon={`material-symbols-light:${item.icon}`}
									width={25}
									className="flex-shrink-0"
									height={25}
								/>
								<span className="sidenavbar-label">{item.label}</span>
							</li>
						</Link>
					</div>
				))}
			</ul>
		</nav>
	);
};

export default SideNavbar;
