import axios from "./axios";
import { apiBase, apiUrl, eventBase } from "../config/crud";

const API_URL = apiUrl;
const API_BASE = apiBase;
const EVENT = eventBase;

export const updateConnectionStatus = async (
	connectionStatus: number,
	connectionId: string,
	eventId: string
): Promise<void> => {
	try {
		return await axios.put(
			`${API_URL}/${API_BASE}/${EVENT}/${eventId}/att-app/connections/${connectionId}`,
			{
				connection_status: connectionStatus
			}
		);
	} catch (error: any) {
		console.error("Error updating connection status:", error?.response?.data || error.message);
	}
};

export const createConnection = async (eventId: string, attendeeId: string): Promise<void> => {
	try {
		return await axios.post(
			`${API_URL}/${API_BASE}/${EVENT}/${eventId}/att-app/connections`,
			{
				attendee_id: attendeeId
			}
		);
	} catch (error: any) {
		console.error("Error creating connection:", error.response || error.message);
	}
};

export const downloadConnections = async (eventId: string): Promise<void> => {
	try {
		return await axios.post(
			`${API_URL}/${API_BASE}/${EVENT}/${eventId}/att-app/connections/download`,
			{
				event_id: eventId
			}
		);
	} catch (error: any) {
		console.error("Error downloading connections:", error?.response?.data || error.message);
	}
};

export const fetchAllAttendees = async (
	category,
	include_count_metrics,
	eventId: string,
	searchParam,
	connectionStatus,
	limit,
	offset,

): Promise<void> => {
	try {

		const params: any = { limit, offset };
		if (searchParam) params.search_param = searchParam;
		if (connectionStatus) params.connection_status = connectionStatus;
		if (include_count_metrics) params.include_count_metrics = include_count_metrics
		if (category) params.category = category


		return await axios.get(`${API_URL}/${API_BASE}/${EVENT}/${eventId}/att-app/attendees`, {
			params
		});

	} catch (error: any) {
		console.error("Error fetching attendees:", error?.response?.data || error.message);
	}
};
