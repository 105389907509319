import { domPurify } from "./common";

export function toCamelCase(str: string): string {
	return str.replace(/[_-](\w)/g, (_, letter: string) => letter.toUpperCase());
}

export function convertKeysToCamelCase<T>(obj: T): T {
	if (Array.isArray(obj)) {
		return obj.map(item => convertKeysToCamelCase(item)) as unknown as T;
	} else if (obj !== null && typeof obj === "object") {
		return Object.entries(obj).reduce(
			(acc, [key, value]) => {
				const camelKey = toCamelCase(key);
				acc[camelKey] = convertKeysToCamelCase(value);
				return acc;
			},
			{} as Record<string, any>
		) as T;
	}
	return obj;
}

export function stripHtmlTags(input: string): string {
	if (!input) return "";

	// First, sanitize the HTML to remove harmful content
	const sanitized = domPurify(input);

	// Then, remove any remaining HTML tags (if any) manually
	const stripped = sanitized.replace(/<\/?[^>]+(>|$)/g, "");

	// Escape special characters to prevent issues in the manifest or other contexts
	return stripped
		.replace(/&/g, "&amp;") // Escape &
		.replace(/'/g, "&#39;") // Escape single quote
		.replace(/"/g, "&quot;") // Escape double quote
		.replace(/</g, "&lt;") // Escape <
		.replace(/>/g, "&gt;"); // Escape >
}
