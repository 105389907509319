import React, { useState, useEffect } from "react";
import { requestNotificationPermission, showNotification } from "../utils/notifications"; // Adjust import paths as necessary
import api from "./../api"; // Adjust import paths as necessary

const NotificationPOC: React.FC = () => {
	const [title, setTitle] = useState("Custom Notification");
	const [body, setBody] = useState("This is a custom notification body!");
	const [icon, setIcon] = useState("https://konfhub.com/logos/konfhub-icon-purple.svg");
	const [badge, setBadge] = useState("https://konfhub.com/logos/konfhub-icon-purple.svg");
	const [loading, setLoading] = useState(false);
	const [requestPermission, setRequestPermission] = useState(true);
	const [countdown, setCountdown] = useState<number | null>(null);
	const eventUrl = localStorage.getItem("eventUrl");

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		try {
			setLoading(true);
			const permission = await Notification.requestPermission();
			if (permission === "granted") setRequestPermission(false);
			const resp = await api.get(`/event/public/${eventUrl}`);
			if (resp.data) {
				setTitle(resp.data.name || "Notification Title");
				setBody(
					`This notification is from ${resp.data.name}. The event URL is ${resp.data.url}`
				);
			}
		} catch (err) {
			console.error(err);
		} finally {
			setLoading(false);
		}
	};

	const handleShowNotification = () => {
		showNotification({
			title,
			body,
			icon,
			badge,
			tag: "custom-notification",
			requireInteraction: true,
			silent: false,
			lang: "en-US",
			dir: "auto"
		});
	};

	const handleDelayedNotification = async () => {
		setCountdown(30);
		const countdownInterval = setInterval(() => {
			setCountdown((prev: any) => {
				if (prev === 1) {
					clearInterval(countdownInterval);
					sendDelayedNotification(); // Send notification to service worker
					return null; // Reset countdown
				}
				return prev! - 1;
			});
		}, 1000);
	};

	const sendDelayedNotification = async () => {
		const registration = await navigator.serviceWorker.getRegistration();
		if (registration) {
			registration.showNotification(title, {
				body: body,
				icon: icon,
				badge: badge
			});
		} else {
			console.error("Service Worker not registered.");
		}
	};

	return (
		<div className="notification-app">
			<h1 className="app-title">Browser Notification POC</h1>

			<div className="input-group">
				<label htmlFor="title">Title:</label>
				<input
					type="text"
					id="title"
					value={title}
					onChange={e => setTitle(e.target.value || "This is a custom title!")}
					className="input-field"
				/>
			</div>

			<div className="input-group">
				<label htmlFor="body">Body:</label>
				<input
					type="text"
					id="body"
					value={body}
					onChange={e => setBody(e.target.value || "This is a custom body!")}
					className="input-field"
				/>
			</div>

			<div className="input-group">
				<label htmlFor="icon">Icon URL:</label>
				<input
					type="text"
					id="icon"
					value={icon}
					onChange={e =>
						setIcon(
							e.target.value || "https://konfhub.com/logos/konfhub-icon-purple.svg"
						)
					}
					className="input-field"
				/>
			</div>

			<div className="input-group">
				<label htmlFor="badge">Badge URL:</label>
				<input
					type="text"
					id="badge"
					value={badge}
					onChange={e =>
						setBadge(
							e.target.value || "https://konfhub.com/logos/konfhub-icon-purple.svg"
						)
					}
					className="input-field"
				/>
			</div>

			<div className="button-group">
				<button
					className="request-permission-button"
					onClick={requestNotificationPermission}
					disabled={loading || !requestPermission}
				>
					{requestPermission ? "Request Permission" : "Already Granted"}
				</button>
				<button
					className="show-notification-button"
					onClick={handleShowNotification}
					disabled={loading || requestPermission}
				>
					{loading ? "Loading..." : "Show Notification"}
				</button>
				<button
					className="delayed-notification-button"
					onClick={handleDelayedNotification}
					disabled={loading || requestPermission || countdown !== null}
				>
					{countdown !== null
						? `Delayed Notification (${countdown}s)`
						: "Delayed Notification"}
				</button>
			</div>

			<style>{`
                .notification-app {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    background-color: #f0f4f8;
                    margin: 0;
                    padding: 0;
                    font-family: Arial, sans-serif;
                    padding: 20px;
                    box-sizing: border-box;
                }
                .app-title {
                    color: #333;
                    margin-bottom: 20px;
                    text-align: center;
                }
                .input-group {
                    margin: 10px 0;
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    max-width: 400px;
                }
                label {
                    margin-bottom: 5px;
                    font-weight: bold;
                    color: #333;
                }
                .input-field {
                    padding: 10px;
                    border: 1px solid #ccc;
                    border-radius: 5px;
                    font-size: 16px;
                }
                .input-field:focus {
                    border-color: #007bff;
                    outline: none;
                }
                .button-group {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    max-width: 400px;
                }
                .request-permission-button,
                .show-notification-button,
                .delayed-notification-button {
                    background-color: #007bff;
                    color: white;
                    border: none;
                    border-radius: 5px;
                    padding: 10px 20px;
                    margin: 5px 0;
                    cursor: pointer;
                    font-size: 16px;
                    transition: background-color 0.3s;
                }
                .request-permission-button:hover,
                .show-notification-button:hover,
                .delayed-notification-button:hover {
                    background-color: #0056b3;
                }
                .request-permission-button:disabled,
                .show-notification-button:disabled,
                .delayed-notification-button:disabled {
                    background-color: #cccccc;
                    cursor: not-allowed;
                }
                .notification-explanation {
                    text-align: center;
                    margin-top: 20px;
                }

                /* Responsive Styles */
                @media (max-width: 600px) {
                    .input-field {
                        font-size: 14px;
                    }
                    .request-permission-button,
                    .show-notification-button,
                    .delayed-notification-button {
                        font-size: 14px;
                    }
                }
            `}</style>
		</div>
	);
};

export default NotificationPOC;
