import React, { useState } from 'react';
import { Button, Checkbox, FormControlLabel, Typography, Box, SwipeableDrawer } from '@mui/material';
import BusinessCard from '../../ProfileView/BusinessCard/BusinessCard';
import './EditBussinessCard.css'; // Import the CSS file

interface EditDrawerProps {
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
}

const socialLinks = [
  { icon: 'assets/icons/linkdin.svg', url: 'https://in.linkedin.com' },
  { icon: 'assets/icons/tweet.svg', url: 'https:/x.com' },
  { icon: 'assets/icons/whatsapp1.svg', url: 'https://whatsapp.com' },
  { icon: 'assets/icons/glob.svg', url: 'mailto:angyl@example.com' },
];

const EditDrawer: React.FC<EditDrawerProps> = ({ open, onOpen, onClose }) => {
  // Array of checkbox states for each form item
  const [checkboxes, setCheckboxes] = useState({
    firstName: false,
    lastName: false,
    designation: false,
    company: false,
    whatsapp: false,
    linkedin: false,
    twitter: false,
    website: false,
    email: false,
    phoneNumber: false,
    photo: false,
  });

  // Check if any checkbox is checked
  const isAnyCheckboxChecked = Object.values(checkboxes).some(checked => checked);

  // Handle checkbox change
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, name: string) => {
    setCheckboxes({ ...checkboxes, [name]: event.target.checked });
  };

  return (
    
    <> 
    <div className=''> 
    <SwipeableDrawer 
      anchor="bottom" 
      open={open} 
      onOpen={onOpen}
      onClose={onClose} 
      sx={{
        '& .MuiDrawer-paper': { 
          height: '80%', 
        //   maxHeight: '400px',
          padding: '16px', 
        }
      }}
    >
      <Box role="presentation">
        <Typography variant="h6">Edit</Typography>

        <BusinessCard
          name="Anoyiki Soe"
          title="Investor, Infy"
          avatarUrl="assets/lisa.png"
          qrCodeUrl="assets/photos/QR.png"
          socialLinks={socialLinks}
        />

        {/* Form with Checkboxes */}
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {Object.keys(checkboxes).map((key) => (
            <FormControlLabel
              key={key}
              control={
                <Checkbox
                  checked={checkboxes[key as keyof typeof checkboxes]}
                  onChange={(e) => handleCheckboxChange(e, key)}
                />
              }
              label={key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())} // Formats label
              sx={{ alignItems: 'center' }}
            />
          ))}
        </Box>

        {/* Save Button */}
        <Button 
          variant="contained" 
          fullWidth 
          className={isAnyCheckboxChecked ? 'save-button-active_dd' : 'save-button_dd'}
          sx={{ marginTop: 2,backgroundColor: '#002E6E' }}
        >
          Save
        </Button>


        
      </Box>
    </SwipeableDrawer>
    </div>

    
    </>
  );
};

export default EditDrawer;
