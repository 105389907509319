import React, { useEffect, useState } from "react";
import "./EventDetails.css";
import Footer from "../Footer/Footer";
import { Bell } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import SideNavbar from "../SideNavbar/SideNavbar";
import { Header } from "../Header/Header";

const EventDetails: React.FC = () => {
	const navigate = useNavigate();

	const productId = localStorage.getItem("eventId");
	const producturl = localStorage.getItem("eventUrl");

	const eventHome = () => {
		navigate("/event-app");
	};
	const eventSponcers = () => {
		navigate("/sponsors");
	};
	const eventSpeakers = () => {
		navigate("/speakers");
	};

	const [searchTerm, setSearchTerm] = useState("");

	const goBack = () => {
		// navigate(-1);
		navigate("/home-screen");
	};

	return (
		<>
			<div className="phone-view">
				<div className="container-event_deatils">
					<div className="headerss">
						<img
							className="left_uparrow search_left_arrow"
							src="/assets/uparrow.svg"
							alt="Go Back"
							onClick={goBack}
						/>
					</div>

					{/* First Line */}
					<div className="line_event_details">
						<div className="image-container_event_details" onClick={eventHome}>
							<img
								src="/assets/Rectangle.png"
								alt="First Line Image"
								onClick={eventHome}
								className="image"
							/>
							<h1 className="heading_event_details">ELYSIAN - 2024</h1>
						</div>
					</div>
					{/* Second Line */}
					<div className="line_event_details" onClick={eventSpeakers}>
						<div className="image-container_event_details">
							<img
								src="/assets/Rectangle-1.png"
								alt="Second Line Image 1"
								className="image"
							/>
							<div className="text-overlay_event_details">SPEAKER</div>
						</div>
						<div className="image-container_event_details" onClick={eventSponcers}>
							<img
								src="/assets/Rectangle-2.png"
								alt="Second Line Image 2"
								className="image"
							/>
							<div className="text-overlay_event_details">SPONSERS</div>
						</div>
					</div>
					{/* Third Line */}
					<div className="line_event_details">
						<div className="image-container_event_details">
							<img
								src="/assets/Rectangle-3.png"
								alt="Third Line Image"
								className="image"
							/>
							<div className="text-overlay_event_details">SCAN A BADGE</div>
						</div>
					</div>
					{/* Fourth Line */}
					<div className="line_event_details">
						<div className="image-container_event_details">
							<img
								src="/assets/Rectangle-3.png"
								alt="Third Line Image"
								className="image"
							/>
							<div className="text-overlay_event_details">BUILD CONNECTIONS</div>
						</div>
					</div>
					<br />
					<br />
					<br />
					<br />
				</div>
				<Footer />
			</div>

			<div className="desktop-view">
				<div className="menu-desktop">
					<div>
						<SideNavbar />
					</div>

					<div className="desktop-container-event_deatils">
						<Header />
						{/* First Line */}
						<div className="line_event_details">
							<div
								className="desktop-image-container_event_details"
								onClick={eventHome}
							>
								<img
									src="/assets/info.svg"
									alt="First Line Image"
									onClick={eventHome}
									className="top-image"
								/>
								{/* <h1 className="heading_event_details">ELYSIAN - 2024</h1> */}
							</div>
						</div>
						{/* Second Line */}
						<div className="line_event_details" onClick={eventSpeakers}>
							<div className="desktop-image-container_event_details">
								<img
									src="/assets/info (1).svg"
									alt="Second Line Image 1"
									className="image"
								/>
								{/* <div className="text-overlay_event_details">SPEAKER</div> */}
							</div>
							<div
								className="desktop-image-container_event_details"
								onClick={eventSponcers}
							>
								<img
									src="/assets/info (2).svg"
									alt="Second Line Image 2"
									className="image2"
								/>
								{/* <div className="text-overlay_event_details">SPONSERS</div> */}
							</div>
						</div>
						{/* Third Line */}
						<div className="line_event_details" onClick={eventSpeakers}>
							<div className="desktop-image-container_event_details">
								<img
									src="/assets/info (3).svg"
									alt="Second Line Image 1"
									className="image"
								/>
								{/* <div className="text-overlay_event_details">SPEAKER</div> */}
							</div>
							<div
								className="desktop-image-container_event_details"
								onClick={eventSponcers}
							>
								<img
									src="/assets/info (4).svg"
									alt="Second Line Image 2"
									className="image2"
								/>
								{/* <div className="text-overlay_event_details">SPONSERS</div> */}
							</div>
						</div>
						{/* <div className="line_event_details">
              <div className="desktop-image-container_event_details">
                <img
                  src="/assets/Rectangle-3.png"
                  alt="Third Line Image"
                  className="image"
                />
                <div className="text-overlay_event_details">SCAN A BADGE</div>
              </div>
            </div> */}
						{/* Fourth Line */}
						{/* <div className="line_event_details">
              <div className="desktop-image-container_event_details">
                <img
                  src="/assets/Rectangle-3.png"
                  alt="Third Line Image"
                  className="image"
                />
                <div className="text-overlay_event_details">
                  BUILD CONNECTIONS
                </div>
              </div>
            </div> */}
						<br />
						<br />
						<br />
						<br />
						{/* <Footer /> */}
					</div>
				</div>
			</div>
		</>
	);
};

export default EventDetails;
