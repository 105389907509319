import axios from "./axios";
import { apiUrl } from "../config/crud";

const API_URL = apiUrl;
const USERS = "/user";
const USERS_AUTH = "/auth/user";

export function getUserById() {
	return axios.get(API_URL + USERS + "/");
}

export function createUser(user) {
	return axios.post(API_URL + USERS_AUTH, user);
}

export function updateUser(data, token) {
	return axios.put(API_URL + USERS + "/", data);
}

export const getBadgePerModule = (eventId: string) => {
	return axios.get(`${API_URL}${USERS}/${eventId}/attendee-app/badge-count`);
};

export const trackUserSignup = (eventId: string, source = 2) => {
	return axios.post(`${API_URL}${USERS}/sign-up/${source}`, {
		"event-id": eventId
	});
};
