import React, { useState, useRef, useEffect } from "react";
import "./EventApp.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../Footer/Footer";
import Modal from "../Modal/Modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SideNavbar from "../SideNavbar/SideNavbar";
import NotificationModal from "../Notification/NotificationModal/NotificationModal";
import { useEvent } from "./../../contexts/EventContext";
import { ClipLoader } from "react-spinners";
import { getSubdomain } from "../../utils/core";
import { Header } from "../Header/Header";
import { Icon } from "@iconify/react";
import { useModule } from "../../contexts/ModuleContext";
import { profileImage } from "../../reusable/images";
import { useUser } from "../../contexts/AuthContext";
import { getBadgePerModule } from "../../crud/users.crud";

const EventApp: React.FC = () => {
	const navigate = useNavigate();
	const { menuItems } = useModule();
	const [isExpanded, setIsExpanded] = useState(false);
	const [isReadMore, setIsReadMore] = useState(false);
	const [isOpen, setIsOpen] = useState(false);

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isDeskOpen, setIsDeskOpen] = useState(false);

	const [showModal, setShowModal] = useState(false);
	const [copySuccess, setCopySuccess] = useState(false);
	const [sortedNavItems, setSortedNavItems] = useState([]);
	const [badgeCounts, setBadgeCounts] = useState({});

	const { eventDetails, eventLoading, eventError } = useEvent();
	const loading = eventLoading;
	const error = eventError;
	const events = eventDetails;

	const userDataFromLocalStorage = localStorage.getItem("user");
	const userData =
		userDataFromLocalStorage?.length > 0 ? JSON.parse(userDataFromLocalStorage) : {};
	const { user } = useUser();

	const getEventStatus = (startDate: string, endDate: string) => {
		const currentDate = new Date();
		const eventStartDate = new Date(startDate);
		const eventEndDate = new Date(endDate);

		// Reset time portion for accurate date comparison
		currentDate.setHours(0, 0, 0, 0);
		eventStartDate.setHours(0, 0, 0, 0);
		eventEndDate.setHours(0, 0, 0, 0);

		if (currentDate < eventStartDate) {
			return "Upcoming";
		} else if (currentDate >= eventStartDate && currentDate <= eventEndDate) {
			return "Live";
		} else {
			return "Past";
		}
	};

	const copyToClipboard = () => {
		const url = window.location.href;
		navigator.clipboard.writeText(url).then(
			() => {
				setCopySuccess(true);
				setTimeout(() => setCopySuccess(false), 2000);
			},
			err => {
				console.error("Failed to copy: ", err);
			}
		);
	};

	const getBadges = async () => {
		if (user) {
			try {
				const badgesResponse = await getBadgePerModule(eventDetails?.event_id);
				setBadgeCounts(badgesResponse?.data);
			} catch (e) {
				console.log(e);
			}
		}
	};

	useEffect(() => {
		const sortedItems: any = [...menuItems].sort((a, b) => a.number - b.number);
		setSortedNavItems(sortedItems);
		getBadges();
	}, []);

	const getBadgeCount = (menuItemName: string) => {
		const keys = Object.keys(badgeCounts).map(b => b?.split("_")?.[0]);
		if (keys?.includes(menuItemName?.toLowerCase())) {
			const count = badgeCounts[`${menuItemName?.toLowerCase()}_badge_count`];
			if (count > 0) {
				return (
					<div className="badge-count">
						{badgeCounts[`${menuItemName?.toLowerCase()}_badge_count`]}
					</div>
				);
			}
		}
	};

	const CustomIcon = ({ type }: { type: string }) => {
		switch (type) {
			case "info":
				return <>ℹ️</>;
			case "success":
				return <>✅</>;
			case "error":
				return <>❌</>;
			case "warning":
				return <>⚠️</>;
			default:
				return null;
		}
	};

	const showToast = (type: "info" | "success" | "error" | "warning", message: string) => {
		setTimeout(() => {
			toast[type](message, {
				icon: <CustomIcon type={type} />,
				position: "bottom-center",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true
			});
		}, 0);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	const handleToggle = () => {
		setIsExpanded(!isExpanded);
	};

	const toggleModal = () => {
		setIsOpen(prevState => !prevState);
	};
	const toggleModalDesk = () => {
		setIsDeskOpen(prevState => !prevState);
	};

	const Agenda = () => {
		navigate("/agenda");
	};

	const toggleReadMore = () => {
		setIsReadMore(!isReadMore);
	};

	const modalRef = useRef(null);
	const modalRefMobile = useRef(null);

	const goBack = () => {
		navigate(-1);
	};
	const handleClickOutside = (event: MouseEvent | TouchEvent) => {
		if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
			setIsOpen(false);
			setIsDeskOpen(false);
		}
	};
	useEffect(() => {
		getSubdomain();
		// const handleClickOutside = event => {
		// 	if (modalRef.current && !modalRef.current.contains(event.target)) {
		// 		setIsOpen(false);
		// 	}
		// };
		if (isOpen) {
			document.addEventListener("mousedown", handleClickOutside);
			document.addEventListener("touchstart", handleClickOutside);
		} else {
			document.removeEventListener("mousedown", handleClickOutside);
			document.removeEventListener("touchstart", handleClickOutside);
		}
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
			document.removeEventListener("touchstart", handleClickOutside);
		};
	}, [isOpen, isDeskOpen]);

	const handleOpen = () => {
		setShowModal(true);
	};

	const handleClose = (e: React.MouseEvent<HTMLDivElement>) => {
		if (e.target === e.currentTarget) {
			setShowModal(false);
			e.stopPropagation();
		}
	};

	const toastContainerStyle = {
		bottom: "80px",
		left: "50%",
		transform: "translateX(-50%)",
		width: "100%",
		maxWidth: "400px"
	};

	const formatTimeWithZone = (timeStr?: string, timezone?: string) => {
		if (!timeStr || !timezone) {
			return "";
		}

		try {
			const today = new Date();
			const [hours, minutes] = timeStr.split(":");

			if (!hours || !minutes) {
				return "";
			}

			// Create a new date object and set the time
			today.setHours(parseInt(hours));
			today.setMinutes(parseInt(minutes));
			today.setSeconds(0);

			// Configure the formatter with specific options for 12-hour format
			const formatter = new Intl.DateTimeFormat("en-US", {
				hour: "numeric",
				minute: "2-digit",
				hour12: true, // This ensures 12-hour format
				timeZone: timezone
			});

			// Format and ensure AM/PM is capitalized
			const formattedTime = formatter.format(today);

			// If you want to ensure AM/PM is always capitalized and has consistent spacing
			return formattedTime.replace(/\s*(am|pm)/i, match => " " + match.trim().toUpperCase());
		} catch (error) {
			console.error("Error formatting time:", error);
			return "";
		}
	};

	const spinnerStyle = {
		position: "fixed" as const,
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: "rgba(255, 255, 255, 0.8)",
		zIndex: 9999
	};

	const formatDate = (dateString: string | null | undefined) => {
		if (!dateString) return "";
		try {
			const date = new Date(dateString);
			if (isNaN(date.getTime())) return "";
			return new Intl.DateTimeFormat("en-GB", {
				day: "2-digit",
				month: "short",
				year: "numeric"
			}).format(date);
		} catch (error) {
			console.error("Error formatting date:", error);
			return "";
		}
	};

	return (
		<>
			{loading && (
				<div style={spinnerStyle}>
					<ClipLoader
						color="#4A90E2"
						loading={loading}
						size={50}
						aria-label="Loading Spinner"
					/>
				</div>
			)}

			<div className="phone-view">
				<div className="phone-container-event">
					<div className="carousel-containers">
						<img src={events?.event_poster_url} alt="Slide 3" />
						<div className="carousel-slide">
							<Link to="/event-details" className="link-image"></Link>
						</div>
					</div>

					<div className="sian">
						<div className="event-app">
							<div className="event_app_header">
								{/* <div className="event_app_upcoming-badge">
									{events?.start_date &&
										events?.end_date &&
										getEventStatus(events.start_date, events.end_date)}
								</div> */}
							</div>

							<div className="elc">
								<div className="event_header_home_page">
									{events?.att_app_logo && (
										<img
											className="card_heading_image"
											src={events.att_app_logo}
											alt=""
										/>
									)}
									<div className="details-event-name">{events?.name}</div>
								</div>
								{/* Share Icon to toggle the modal */}
								<div
									onClick={toggleModal}
									style={{ paddingRight: "10px" }}
									className="cursor-pointer"
								>
									<Icon
										icon="material-symbols-light:share-outline"
										width={20}
										height={20}
										className="primary-color-icons"
									/>
								</div>

								{/* Share Modal */}
								{isOpen && (
									<div
										className="share-modal-overlay"
										onClick={e => {
											e.stopPropagation();
											setIsOpen(false);
										}}
									>
										<div
											className="share-modal share-modal-mob"
											ref={modalRefMobile}
											onClick={e => e.stopPropagation()}
										>
											<div className="share-modal-header">
												<h2 className="share-title">Share</h2>
											</div>

											<div className="share-options">
												{[
													{
														name: "WhatsApp",
														icon: "assets/whatsappgroup.svg",
														url: `https://api.whatsapp.com/send?text=${encodeURIComponent(
															window.location.href
														)}`
													},
													{
														name: "Facebook",
														icon: "assets/facebookgroup.svg",
														url: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
															window.location.href
														)}`
													},
													{
														name: "Email",
														icon: "assets/email-1.svg",
														url: `mailto:?subject=Check this out&body=${encodeURIComponent(
															window.location.href
														)}`
													},
													{
														name: "X",
														icon: "assets/twittergroup.svg",
														url: `https://twitter.com/intent/tweet?url=${encodeURIComponent(
															window.location.href
														)}`
													}
												].map(app => (
													<button
														key={app.name}
														className="share-option"
														onClick={e => {
															e.stopPropagation();
															window.open(app.url, "_blank");
														}}
													>
														<img
															src={app.icon}
															alt={app.name}
															className="share-option-icon"
														/>
														<span className="share-option-name">
															{app.name}
														</span>
													</button>
												))}
											</div>

											<div className="share-copy-link">
												<p className="share-copy-link-title">Copy Link</p>
												<div className="share-copy-link-input">
													<input
														type="text"
														value={window.location.href}
														readOnly
														className="share-copy-link-field"
													/>
													<button
														className="share-copy-button"
														onClick={e => {
															e.stopPropagation();
															copyToClipboard();
														}}
													>
														{copySuccess ? "Copied!" : "Copy"}
													</button>
												</div>
											</div>
										</div>
									</div>
								)}
							</div>

							{isExpanded && (
								<div className="event-detailsss">
									{events?.city && (
										<div className="detail-line">
											<>
												<img
													src="/assets/Address.svg"
													alt="Location"
													className="icon"
												/>
												<span className="detail-line2">{events.city}</span>
											</>
										</div>
									)}
									<div className="detail-line">
										<img
											src="/assets/calendar-1.svg"
											alt="Time"
											className="icon"
										/>
										<span className="detail-line2">
											{formatDate(events?.start_date)} -{" "}
											{formatDate(events?.end_date)}
										</span>
									</div>
									<div className="detail-line">
										<img
											src="/assets/clock-rewind.svg"
											alt="Time"
											className="icon"
										/>
										<span className="detail-line2">
											{events?.start_time && events?.time_zone
												? formatTimeWithZone(
													events.start_time,
													events.time_zone
												)
												: ""}{" "}
											<span className="zone">-</span>
											{events?.end_time && events?.time_zone
												? formatTimeWithZone(
													events.end_time,
													events.time_zone
												)
												: ""}
										</span>
									</div>

									{events?.additional_venue_details && (
										<div className="event-description">
											{isReadMore ? (
												<span>
													{events.additional_venue_details}
													<span
														className="read-less"
														onClick={toggleReadMore}
													>
														{" "}
														Read Less
													</span>
												</span>
											) : (
												<span>
													{events.additional_venue_details}
													<span
														className="read-more"
														onClick={toggleReadMore}
													>
														{" "}
														Read More
													</span>
												</span>
											)}
										</div>
									)}
								</div>
							)}

							<div className="menu-grid">
								{menuItems.map((item, index) => (
									<div
										className="menu-item-container"
										key={index}
										onClick={() => item.onClick && item.onClick(item)}
									>
										<Link to={item.url}>
											<div className="menu-item">
												<Icon
													icon={`material-symbols-light:${item.icon}`}
													width={40}
													height={40}
													className="primary-color-icons"
												/>
												{getBadgeCount(item?.label)}
											</div>
										</Link>
										<div className="itm">
											{item.label.length > 30
												? item.label.slice(0, 30) + "..."
												: item.label}
										</div>
									</div>
								))}
							</div>
						</div>
						{isModalOpen && <Modal closeModal={closeModal} />}
						<Footer eventId={""} eventName={""} />
					</div>
				</div>
			</div>

			<div className="desktop-view">
				<div className="desktop-view-side">
					<div>
						<SideNavbar />
					</div>
					<div className="desktop-left-container">
						<Header />
						{showModal && (
							<div
								onClick={handleClose}
								style={{
									position: "fixed",
									top: 0,
									left: 0,
									right: 0,
									bottom: 0,
									backgroundColor: "rgba(0, 0, 0, 0.5)",
									display: "flex",
									justifyContent: "flex-end",
									alignItems: "flex-start",
									zIndex: 1000
								}}
							>
								<div
									onClick={e => e.stopPropagation()}
									style={{
										width: "400px",
										maxHeight: "80vh",
										overflow: "auto",
										backgroundColor: "white",
										marginTop: "60px",
										marginRight: "20px",
										borderRadius: "8px",
										padding: "20px",
										boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)"
									}}
								>
									<NotificationModal />
								</div>
							</div>
						)}
						<div className="event-desktop">
							<div className="sian-desktop">
								<div className="event-app-desktop">
									<div className="display-flex justify-content-between">
										<div className="d-flex flex-wrap flex-row">
											<div>
												<img
													src={events?.event_poster_url}
													alt="Slide 3"
													className="event-banner-desktop"
												/>
											</div>
											<div className="desktop-view-event-info">
												{/* <div className="event_app_upcoming-badge-desktop">
													{events?.start_date &&
														events?.end_date &&
														getEventStatus(
															events.start_date,
															events.end_date
														)}
												</div> */}
												<div className="event_header_home_page event-name-wrapper-desktop">
													{events?.att_app_logo && (
														<img
															className="app-logo-desktop"
															src={events.att_app_logo}
															alt=""
														/>
													)}
													<div className="details-event-name-desktop">
														{events?.name}
													</div>
													<div
														onClick={toggleModalDesk}
														className="mt-3 pl-2 cursor-pointer"
													>
														<Icon
															icon="material-symbols-light:share-outline"
															width={35}
															height={35}
															className="primary-color-icons"
														/>
													</div>
												</div>

												{isDeskOpen && (
													<div
														className="share-modal-overlay"
														onClick={e => {
															e.stopPropagation();
															setIsDeskOpen(false);
														}}
													>
														<div
															className="share-modal"
															ref={modalRef}
															onClick={e => e.stopPropagation()}
														>
															<div className="share-modal-header">
																<h2 className="share-title">
																	Share
																</h2>
															</div>

															<div className="share-options">
																{[
																	{
																		name: "WhatsApp",
																		icon: "assets/whatsappgroup.svg",
																		url: `https://api.whatsapp.com/send?text=${encodeURIComponent(
																			window.location.href
																		)}`
																	},
																	{
																		name: "Facebook",
																		icon: "assets/facebookgroup.svg",
																		url: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
																			window.location.href
																		)}`
																	},
																	{
																		name: "Email",
																		icon: "assets/email-1.svg",
																		url: `mailto:?subject=Check this out&body=${encodeURIComponent(
																			window.location.href
																		)}`
																	},
																	{
																		name: "X",
																		icon: "assets/twittergroup.svg",
																		url: `https://twitter.com/intent/tweet?url=${encodeURIComponent(
																			window.location.href
																		)}`
																	}
																].map(app => (
																	<button
																		key={app.name}
																		className="share-option"
																		onClick={e => {
																			e.stopPropagation();
																			window.open(
																				app.url,
																				"_blank"
																			);
																		}}
																	>
																		<img
																			src={app.icon}
																			alt={app.name}
																			className="share-option-icon"
																		/>
																		<span className="share-option-name">
																			{app.name}
																		</span>
																	</button>
																))}
															</div>

															<div className="share-copy-link">
																<p className="share-copy-link-title">
																	Copy Link
																</p>
																<div className="share-copy-link-input">
																	<input
																		type="text"
																		value={window.location.href}
																		readOnly
																		className="share-copy-link-field"
																	/>
																	<button
																		className="share-copy-button"
																		onClick={e => {
																			e.stopPropagation();
																			copyToClipboard();
																		}}
																	>
																		{copySuccess
																			? "Copied!"
																			: "Copy"}
																	</button>
																</div>
															</div>
														</div>
													</div>
												)}
											</div>
										</div>
										{user && (
											<div>
												<div
													className="d-flex cursor-pointer"
													onClick={() => navigate("/my-profile")}
												>
													{profileImage(
														userData.image_url,
														userData.name,
														50,
														{
															width: "50px",
															height: "50px"
														}
													)}
												</div>
											</div>
										)}
									</div>
								</div>
								<div className="menu-grid-desktops">
									{menuItems.map((item, index) => (
										<div
											key={index}
											className="desktop-menu-cards menu-item-container"
											onClick={() => item.onClick && item.onClick(item)}
										>
											<Link to={item.url}>
												<div className="menu-item-desktops">
													<Icon
														icon={`material-symbols-light:${item.icon}`}
														width={40}
														height={40}
														className="primary-color-icons"
													/>
													{getBadgeCount(item?.label)}
												</div>
											</Link>
											{/* <div className="itm-desktops">{item.label}</div> */}
											<div className="itm">
												{item.label.length > 30
													? item.label.slice(0, 40) + "..."
													: item.label}
											</div>
										</div>
									))}
								</div>
								{isModalOpen && <Modal closeModal={closeModal} />}
							</div>
						</div>
					</div>
				</div>
			</div>
			{loading && <p>Loading event data...</p>}
			{error && <p>Error: {error}</p>}
		</>
	);
};

export default EventApp;
