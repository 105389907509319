import React from 'react';

interface NotificationBoxProps {
  message: string;
}

const NotificationBox: React.FC<NotificationBoxProps> = ({ message }) => {
  return (
    <div className="notification-box">
      {message}
    </div>
  );
};

export default NotificationBox;
