import React from 'react';
import { X, Bell } from 'lucide-react';
import './UnderMaintenance.css';

const UnderMaintenance: React.FC = () => {
    return (
        <> 
        <div className='phone-view'>
        <div className='phone-container font'>
            <div className="icon-container">
                <X className="icon left-icon" />
                <Bell className="icon right-icon" />
            </div>
            <div>
                <img
                    src="assets/website-under-construction.png"
                    alt="Welcome"
                    className="undermaintenance-image"
                />
                <h3 className="undermaintenance-text1 font">Page Not Found</h3>
                <p className='undermaintenance-text'>
                We apologize for any inconvenience <br></br> caused. Please check back soon!. Thank <br></br> you for your patience. 
                </p>
            </div>
        </div>
        </div>

        <div className='desktop-view'>
        <div className=' font'>
           
            <div className='errorpage'>
                <div> 
                <img
                    src="assets/website-under-construction.png"
                    alt="Welcome"
                    className="undermaintenance-image"
                />
                </div>
                <div className='errorpage1'>
                <div className="error-headings">Page Not Found</div>
                <p className='error-textts'>
                We apologize for any inconvenience <br></br> caused. Please check back soon!. Thank <br></br> you for your patience. 
                </p>
                </div>
            </div>
        </div>
        </div>
       
        </>
    );
};

export default UnderMaintenance;