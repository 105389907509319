import React from 'react';
import { useNavigate } from 'react-router-dom';
import './WelcomePage.css';

const WelcomePage: React.FC = () => {
    const navigate = useNavigate();

    const handleLoginClick = () => {
        navigate('/login-account');
    };

    return (
        <> 
        <div className='phone-view'>
        <div className="welcome-container">
            <h1 className="welcome-text">Welcome! Your account has been created successfully</h1>
            <div className='welcome-image-div'>
            <img
                src="assets/business-startup.svg"
                alt="Welcome"
                className="welcome-image"
            />
            </div>
            <div>
            </div>
            <button className="login-button" onClick={handleLoginClick}>
                Login
            </button>
        </div>
        </div>

        {/* second conatainer */}
        <div className='desktop-view'>
        <div className="welcome-container-desktop">
        <div className='welcome-image-div'>
            <img
                src="assets/business-startup.svg"
                alt="Welcome"
                className="welcome-image-desktop"
            />
            </div>
           
            <div className='desktop-welcome-text'>
            <h1 className="welcome-text-desktop">Welcome! Your account has <br></br> been created successfully</h1>
           
            <button className="welcome-button-desktop" onClick={handleLoginClick}>
                Login
            </button>
            </div>
        </div>
        </div>

        </>

    );
};

export default WelcomePage;
