import React from 'react';
import './DeleteSocialIcon.css';

interface DeleteSocialIconProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  socialPlatform: string;
}

const DeleteSocialIcon: React.FC<DeleteSocialIconProps> = ({ isOpen, onClose, onConfirm, socialPlatform }) => {
  if (!isOpen) return null;

  return (
    <div className="delete-social-icon">
      <div className="delete-social-icon-content">
        <h2 className='te'>Delete {socialPlatform}</h2>
        <p className='te'>Are you sure you want to delete URL of {socialPlatform}?</p>
        <div className="delete-social-icon-buttons">
          <button className="cancel-button_delete"  onClick={onClose} >Cancel</button>
          <button className="confirm-button_delete" onClick={onConfirm} >Yes</button>
        </div>
      </div>
    </div>
  );
};

export default DeleteSocialIcon;