import axios from "./axios";
import { apiUrl, eventBase } from "./../config/crud";

const API_URL = apiUrl;
const EVENT = eventBase;
const MODULE = "module";
const MODULE_ITEM = "item";

export const getAllModules = (eventId: string) => {
	return axios.get(`${API_URL}/${EVENT}/${eventId}/${MODULE}?source=2`);
};

export const getModule = (eventId: string, moduleId: string) => {
	return axios.get(`${API_URL}/${EVENT}/${eventId}/${MODULE}/${moduleId}/${MODULE_ITEM}`);
};
